import * as React from 'react';
import File from '../../models/File';

export interface IFileIconProps {
  file?: File
  height?: string
  preview?: boolean
}

export default class FileIcon extends React.Component<IFileIconProps> {

  private svgPath = `https://api.urioz.com/images/svg/`;
  // private svgPath = `https://${env.apiUrl}images/svg/`;

  getSvg(file: File) {
    if (!file || file.isFolder()) return `${this.svgPath}folder.svg`;
    if (!file.attachment) return "none";
    if (file.isImage() && this.props.preview) return file.attachment.url;
    if (file.attachment.ext === "jpeg") return `${this.svgPath}jpg.svg`
    return `${this.svgPath}${file.attachment.ext}.svg`;
  }

  getStyle = (file, height) => {
    return {
      backgroundImage: `url(${this.getSvg(file)})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: height || "80px",
      width: "auto"
    }
  }

  onDocumentLoadSuccess() {
    console.log("load")
  }

  public render() {
    const {file, height, preview} = this.props;
    const containerHeight = parseInt(height?.replace("px", "")) * 2 + "px" || "80px";
    if (file?.isImage() && preview) return <div style={this.getStyle(file, containerHeight)}></div>
    if (file?.isPdf() && preview)   return <div style={{height: containerHeight, overflow: 'hidden', display: 'flex', justifyContent: 'center'}}>
    </div>
    return <div className="row-center" style={{height: containerHeight || "80px"}}>
      <img style={{height: height || "40px"}} src={this.getSvg(file)} alt=""/>
    </div>
  }
}
