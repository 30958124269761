import SessionService from "../services/SessionService";
import Model from "./Model";
import Notification from "./notif/Notification";
import Relationship from "./Relationship";
import Role from './Role';
// import SessionService from "../services/SessionService";

export default class User extends Model {

  public email: string;
  public firstname: string;
  public lastname: string;
  public password: string;
  public role: Role;
  public roleId: number;
  public avatar: string;
  public job: string;
  public linkedin: string;
  public phone: string;
  public relationToMe: Relationship;
  public roomIds: number[]
  public relationIds: number[]
  public responsible: User;
  public responsibleId: number;
  public children: User[];
  public notifications: Notification[];

  public requiredField = ["firstname", "lastname"];
  public modelName = "user"
  static modelUrl = "users";

  constructor(json: any) {
    super(json)
    this.email            = json.email;
    this.firstname        = json.firstname;
    this.lastname         = json.lastname;
    this.role             = json.role;
    this.linkedin         = json.linkedin;
    this.job              = json.job;
    this.phone            = json.phone;
    this.avatar           = json.avatar;
    this.roomIds          = json.roomIds;
    this.relationToMe     = json.relationToMe && new Relationship(json.relationToMe);
    this.role             = json.role && new Role(json.role);
    this.roleId           = json.roleId;
    this.children         = this.mapArrayModel(User, json.children);
    this.notifications    = this.mapArrayModel(Notification, json.notifications);
    this.responsible      = json.responsible && new User(json.responsible);
    this.responsibleId    = json.responsibleId;
    this.relationIds      = json.relationIds;
  }

  isAdmin    = () => this.role.name === "administrateur";

  getFullName() {
    let str = [this.firstname, this.lastname].filter(s => s);
    return str.join(" ");
  }

  isStranger = () => this.relationToMe === null;
  isFriend = () => this.relationToMe?.status === "friend";
  isRequested = () => this.relationToMe?.status === "send";
  isWaitingToBeAccepted = () => this.relationToMe?.status === "pending";
  isMe = () => SessionService.user.id === this.id;
  toMap() {
    return {
      id: this.id,
      lastname: this.lastname,
      firstname: this.firstname,
      avatar: this.avatar
    }
  }

  toMapApi() {
    return {
      id: this.id,
      email: this.email,
      password: this.password,
      lastname: this.lastname,
      firstname: this.firstname,
      avatar: this.avatar,
      roleId: this.roleId,
      job: this.job,
      linkedin: this.linkedin,
      phone: this.phone,
    }
  }

  getRoomUrl(): string {
    return `/messages/private/${this.getFullName().replace(" ", ".").toLowerCase()}.${this.id}`;
  }

  getFrontUrl(): string {
    return `/profile/${this.getFullName().replace(" ", ".").toLowerCase()}.${this.id}`;
  }
  // isMe() {
    // return this.id === SessionService.getUserId();
    // return true;
  // }
}
