import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import { DropTarget } from 'react-dnd';
import { Collapse } from 'reactstrap';
import { projectBloc } from '../../bloc/ProjectBloc';
import Column from '../../models/Column';
import Ticket from '../../models/Ticket';
import DOMService from '../../services/DOMService';
import RightService from '../../services/RightService';
import SessionService from '../../services/SessionService';
import IconButton from '../buttons/IconButton';
import EditableText from '../common/EditableText';
import ColumnForm from '../form/ColumnForm';
import TicketModal from '../modal/TicketModal';
import DraggableTicketCol from './DraggableTicketCol';
import TicketComponent from './TicketComponent';

export interface ITicketColProps {
  tickets: Ticket[]
  column: Column;
  index: number
}

export interface ITicketColState {
  open: boolean
}

let collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    item: monitor.getItem(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()}
}

const target = {
  drop(props, monitor, component: TicketCol) {
    let item = monitor.getItem()
    if (component.hasSameColumn(item)) return;

    /// Pour éviter l'effet du ticket qui revient en arrière le temps de la requete
    let currentProject = projectBloc.state.currentProject;
    let indexCol = currentProject.columns.findIndex(col => col.id === item.model.columnId)
    currentProject.columns[indexCol].tickets = currentProject.columns[indexCol].tickets.filter(ticket => ticket.id !== item.model.id);
    item.model.columnId = props.column.id;
    props.column.tickets.push(item.model);
    projectBloc.setState({ currentProject });

    projectBloc.updateColumn(item.model, props.column);
    return { moved: true }
  }
}

class TicketCol extends React.PureComponent<any, ITicketColState> {
  constructor(props: any) {
    super(props);

    this.state = {
      open: false
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOver && this.props.isOver) {
      // You can use this as enter handler
    }

    if (prevProps.isOver && !this.props.isOver) {
      // You can use this as leave handler
    }

    if (prevProps.isOverCurrent && !this.props.isOverCurrent) {
      // You can be more specific and track enter/leave
      // shallowly, not including nested targets
    }
  }

  hasSameColumn(item) {
    return item.model.columnId === this.props.column.id
  }

  instantiateTicket = () => {
    return new Ticket({columnId: this.props.column.id, createdBy: SessionService.getUserId()})
  }

  onOpen = () => {
    this.setState({open: !this.state.open})
  }

  public render() {
    const { tickets, column, index } = this.props
    const { isOver, canDrop, connectDropTarget, item } = this.props

    let classDrop = ''
    if (canDrop) {
      classDrop = 'canDrop'
    }
    if (isOver && canDrop && !this.hasSameColumn(item)) {
      classDrop = 'overDrop'
    }
    return (
      <DraggableTicketCol className={classNames({"ticket-col": true, [classDrop]: true})} item={{id: column.id, model: column, index: index}}>
        { connectDropTarget(
          <div className="h-100">
            <div className="ticket-col-header">
              <div className="row-between">
                <div className="row-flex">
                  <div className="section-title">
                    <EditableText disabled={!RightService.can("projects")} light model={column} name={"name"} />
                  </div>
                  <div className="px-3 py-2 pointer" onClick={ () => this.onOpen()}>
                    <FontAwesomeIcon rotation={this.state.open ? 180 : null} icon={["fal", "chevron-down"]} />
                  </div>
                </div>
                <IconButton tooltip="Créer un ticket" light onClick={() => DOMService.modal(<TicketModal ticket={this.instantiateTicket()} />, {maxWidth: "600px"})} iconData={["fal", "plus"]}/>
              </div>
              <Collapse isOpen={this.state.open}><ColumnForm column={column} /></Collapse>
            </div>
            <div className="h-100">
              {/* <div style={{height: classDrop === 'overDrop' ? item.height + "px" : "0px", overflow: "hidden"}} className="row-center">
                <div className="m-auto"><FontAwesomeIcon icon={["fal", "chevron-down"]} /></div>
              </div> */}
              { tickets?.map(t => <TicketComponent key={t.id} ticket={t} color={column.color} />) }
            </div>
          </div>
        )}
      </DraggableTicketCol>
    );
  }
}
export default DropTarget("ticket", target, collect)(TicketCol)