import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { eventsBloc } from '../bloc/EventsBloc';
import AgendaSidebar from '../components/events/AgendaSidebar';
import Calendar from '../components/events/Calendar';
import Page from '../components/layout/Page';
import NewEventModal from '../components/modal/NewEventModal';
import DOMService from '../services/DOMService';
import BasePage from './BasePage';

export interface IEventsPageProps {
}

export interface IEventsPageState {
}

export default class EventsPage extends BasePage<IEventsPageProps, IEventsPageState> {

  public title = "Agenda"

  constructor(props: IEventsPageProps) {
    super(props);

    this.setTitle();
    this.state = {
    }
  }

  componentDidMount() {
    eventsBloc.init();
  }

  public render() {
    return (
      <div className="event-page">
        <Page>
          <div className="d-flex">
            <div className="d-none d-md-block pr-3" style={{flex: "0 0 200px"}}>
              <AgendaSidebar/>
            </div>
            <div style={{flex: 1}}>
              <Calendar/>
            </div>
          </div>
          <div onClick={() => DOMService.modal(<NewEventModal/>)} className="floating-mobile-btn d-flex d-md-none">
            <FontAwesomeIcon icon={["fal", "plus"]} />
          </div>
        </Page>
      </div>
    );
  }
}
