import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RelationBloc from './bloc/RelationBloc';
import VisioBloc from './bloc/VisioBloc';
import MessagingBloc from './bloc/MessagingBloc';
import DriveBloc from './bloc/DriveBloc';
import SearchBloc from './bloc/SearchBloc';
import RoomBloc from './bloc/RoomBloc';
import NotifBloc from './bloc/NotifBloc';
import EventsBloc from './bloc/EventsBloc';

export interface IAppWrapperProps {
}

export default class AppWrapper extends React.Component<IAppWrapperProps> {
  public render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <RelationBloc>
          <EventsBloc>
            <NotifBloc>
              <VisioBloc>
                <MessagingBloc>
                  <DriveBloc>
                    <SearchBloc>
                      <RoomBloc>
                        {this.props.children}
                      </RoomBloc>
                    </SearchBloc>
                  </DriveBloc>
                </MessagingBloc>
              </VisioBloc>
            </NotifBloc>
          </EventsBloc>
        </RelationBloc>
      </DndProvider>
    );
  }
}
