import * as React from 'react';
import GroupNotification from '../../models/notif/GroupNotification';
import Notif from './Notif';

export interface INewGroupNotifProps {
  notification: GroupNotification
}

export interface INewGroupNotifState {
}

class NewGroupNotif extends React.Component<INewGroupNotifProps, INewGroupNotifState> {
  constructor(props: INewGroupNotifProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { notification } = this.props;
    return (
      <div className="notif-text">
        <span>Vous avais été ajouté dans le groupe </span>
        <span className="fw-bold">{notification.group.name}</span>
      </div>
    );
  }
}

export default Notif(NewGroupNotif)