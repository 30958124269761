import Model from "./Model";

export default class Attachment extends Model {

  public url: string;
  public type: string;
  public name: string;
  public size: number;
  public refName: string;
  public ext: string;
  public attachableId: number;
  public attachableType: string;
  public file: File;
  public isStored: boolean = false;
  static modelUrl = "attachments";

  constructor(json: any) {
    super(json)
    this.url  = json.url;
    this.type = json.type;
    this.name = json.name;
    this.size = json.size;
    this.refName = json.refName;
    this.ext = json.ext;
    this.attachableId = json.attachableId;
    this.attachableType = json.attachableType;
  }

  fillWithFile(file: File) {
    this.name = file.name;
    this.refName = file.name;
    this.type = file.type;
    this.size = file.size;
    this.ext = this.getExt(file);
    this.file = file;
    return this
  }

  getExt(file: File) {
    let split = file.name.split(".");
    return split[split.length - 1]
  }

  attachable(model: Model) {
    this.attachableId = model.id;
    this.attachableType = model.getBackendModelType();
    return this;
  }

  isImage() {
    return this.type?.split("/")[0] === "image";
  }

  toMapApi() {
    return {
      url: this.url,
      type: this.type,
      name: this.name,
      size: this.size,
      refName: this.refName,
      ext: this.ext,
      attachableId: this.attachableId,
      attachableType: this.attachableType,
    }
  }

}
