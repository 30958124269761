import * as React from 'react';
import Agenda from '../../models/Agenda';
import EditableText from '../common/EditableText';
import TextIcon from '../common/TextIcon';

export interface IAgendaItemProps {
  agenda: Agenda
}

export interface IAgendaItemState {
}

export default class AgendaItem extends React.Component<IAgendaItemProps, IAgendaItemState> {
  constructor(props: IAgendaItemProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { agenda } = this.props;
    return (
      <div className="agenda-item row-flex mb-2">
        <TextIcon spacing={3} leftIcon={["fal", "calendar-alt"]}>
          <EditableText model={agenda} name="name" light />
        </TextIcon>
      </div>
    );
  }
}
