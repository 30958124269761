import Dropbox from "dropbox"
import fetch from "isomorphic-fetch"
import File from '../models/File';
import Attachment from '../models/Attachment';
import env from "../config/env";

class DropboxService {

  public dbx: Dropbox.Dropbox;
  public clientId = "ih87t5hz9dqnx1w";
  public token: string;
  public authentificationUrl: string;

  init() {
    let params: Dropbox.DropboxOptions = { fetch, clientId: this.clientId };
    this.token = localStorage.getItem("dropboxToken");
    if (this.token) params["accessToken"] = this.token;
    this.dbx = new Dropbox.Dropbox(params);
    this.authentificationUrl = this.dbx.getAuthenticationUrl(`${env.protocol}${env.frontUrl}/dropbox/auth`);
  }

  isAuthenticated() {
    return !!this.token;
  }

  signOut() {
    localStorage.removeItem("dropboxToken")
  }

  async loadFiles(id: string = ""): Promise<File[]> {
    let response = await this.getList(id);
    return response.entries.map((entry) => this.toUriozFile(entry));
  }

  async getList(id: string): Promise<Dropbox.files.ListFolderResult> {
    let response = await this.dbx.filesListFolder({ path: id })
    return response;
  }

  createFolder = async (path: string) => {
    return await this.dbx.filesCreateFolderV2({path});
  }

  async download(path: string) {
    return await this.dbx.filesDownload({path})
  }

  async upload(file, path: string) {
    return await this.dbx.filesUpload({contents: file, path})
  }

  toUriozFile(entry): File {
    let split = null;
    if (entry[".tag"] === "file") {
      split = entry.name.split(".")
      split = split[split.length - 1]
    }
    return new File({
      id: entry.id,
      name: entry.name,
      size: entry.size,
      type: split,
      updatedAt: entry.client_modified,
      attachment: new Attachment({ext: split})
    });
  }

}

export default new DropboxService();
