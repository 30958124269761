import * as React from 'react';
import { NavLink } from 'react-router-dom';
import User from '../../models/User';
import { AvatarSize } from './Avatar';
import AvatarLetter from './AvatarLetter';

export interface IAvatarNameProps {
  user: User
  renderSubTitle?: JSX.Element
  withActivity?: boolean
  size?: AvatarSize
  link?: boolean;
  invert?: boolean
}

export interface IAvatarNameState {
}

export default class AvatarName extends React.Component<IAvatarNameProps, IAvatarNameState> {
  constructor(props: IAvatarNameProps) {
    super(props);

    this.state = {
    }
  }

  link(child) {
    if (!this.props.link) return child;
    return <NavLink to={this.props.user.getFrontUrl()}>{child}</NavLink>
  }

  public render() {
    const { user, renderSubTitle, withActivity, invert, size } = this.props;
    return this.link(
      <div className={"row-flex" + (invert ? " flex-row-reverse" : "")}>
        <AvatarLetter withActivity={withActivity} key={user.id} size={size || AvatarSize.sm} user={user}/>
        <div className={invert ? "mr-3" : "ml-3"}>
          <div className={"fw-bold"}>{user.getFullName()}</div>
          { renderSubTitle && <div>{renderSubTitle}</div>}
        </div>
        {/* { invert && <AvatarLetter withActivity={withActivity} key={user.id} size={AvatarSize.sm} user={user}/>} */}
      </div>
    );
  }
}
