import EntityManager from "./EntityManager";
import Notification from "../models/notif/Notification";
import TicketNotification from "../models/notif/TicketNotification";
import GroupNotification from "../models/notif/GroupNotification";
import EventNotification from "../models/notif/EventNotification";
import MessageNotification from "../models/notif/MessageNotification";

export default class NotificationManager extends EntityManager<Notification> {

  protected modelUrl: string = "notifications";

  protected getEntity(json: any): Notification {
    switch (this.cleanType(json.type)) {
      case "NewTicket": return new TicketNotification(json);
      case "NewGroup": return new GroupNotification(json);
      case "NewEvent": return new EventNotification(json);
      case "NewMessage": return new MessageNotification(json);
      default: return new Notification(json)
    }
  };

  read() {
    return this.post("read", {}, true);
  }

  push() {}

  cleanType(type: string) {
    return type.split('\\')[2];
  }

}
