import * as React from 'react';
import { Col, Row } from 'reactstrap';
import ActuComponent from '../components/common/ActuComponent';
import Datable from '../components/common/Datable';
import HeadBand from '../components/common/HeadBand';
import Loading from '../components/common/Loading';
import FutureEvents from '../components/events/FutureEvents';
import HomeManager from '../managers/HomeManager';
import Event from '../models/Event';
import Model from '../models/Model';
import BasePage from './BasePage';
import Visio from '../models/Visio';
import EntityManager from '../managers/EntityManager';
import SocketService from '../services/SocketService';

export interface IHomePageProps {
}

export interface IHomePageState {
  isLoaded: boolean
  models: Model[]
  futureEvents: Event[]
  visios: Visio[]
}

export default class HomePage extends BasePage<IHomePageProps, IHomePageState> {

  public homeManager = new HomeManager();
  public title = "Actualité"

  constructor(props: IHomePageProps) {
    super(props);

    this.setTitle();
    this.state = {
      isLoaded: false,
      models: [],
      futureEvents: [],
      visios: []
    }

    SocketService.on("destroy visio", this.onVisioDestroyed);
  }

  onVisioDestroyed = async (data: any) => {
    let visios = this.state.visios
    visios = visios.filter(v => v.id.toString() !== data.visioId)
    this.setState({visios})
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    SocketService.off("destroy visio", this.onVisioDestroyed);
  }

  init = async () => {
    let promises: Promise<any>[] = [
      this.homeManager.getModels(),
      EntityManager.all<Event>(Event, {onlyFuture: true}),
      EntityManager.all<Visio>(Visio)
    ]
    let result = await Promise.all(promises);
    this.setState({models: result[0], futureEvents: result[1].models, visios: result[2].models, isLoaded: true})
  }

  public render() {
    const { isLoaded, models, futureEvents, visios } = this.state;
    return (
      <div className="home-page">
        <HeadBand visios={visios} />
        <Row className="m-0">
          <Col className="p-0" xs={12} md={6}>
            <div className="page">
            <div>
              <div className="mb-3 section-title">
                Dernières activitées
              </div>
              { !isLoaded ? <Loading/> : models.map(m => <ActuComponent key={m.id.toString() + m.modelName} model={m} /> )}
            </div>
            </div>
          </Col>
          <Col className="p-0" xs={12} md={6}>
            <div className="page">
              <div className="mb-3 section-title">
                Evènement du mois
              </div>
              { !isLoaded ? <Loading/> : <Datable array={futureEvents} dateProperty="startAt" render={(e) => <FutureEvents key={e.id} event={e} />} /> }
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
