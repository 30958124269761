import Model from "./Model";

export default class CheckItem extends Model {

  public content: string;
  public isDone: boolean
  public ticketId: number
  public userId: number
  static modelUrl = "checkItems";

  constructor(json: any) {
    super(json)
    this.content = json.content;
    this.isDone = json.isDone;
    this.ticketId = json.ticketId;
    this.userId = json.userId;
  }

}
