import moment from "moment";
import React from "react";
import Room from "../../models/Room";
import User from "../../models/User";
import Message from "../../models/Message";
import RoomComposition from "../../models/RoomComposition";
import LoadMoreLabel from "./loadMoreLabel";
import ChatDateLabel from "./chatDateLabel";
import MessageOwnerLabel from "./messageOwnerLabel";
import SelfMessageComponent from "./SelfMessageComponent";
import ReadAvatars from "./readAvatars";
import IsTyping from "./isTyping";
import { roomBloc } from '../../bloc/RoomBloc';
import OtherMessageComponent from "./OtherMessageComponent";


export interface IMessagesBoxProps {
  room: Room,
  typingUsers: User[]
  innerRef: React.RefObject<HTMLDivElement>
}

export default class MessagesBox extends React.Component<IMessagesBoxProps, any> {

  componentDidMount() {
      this.props.innerRef?.current?.lastElementChild?.scrollIntoView();
  }

  componentDidUpdate() {
    this.props.innerRef?.current?.lastElementChild?.scrollIntoView();
  }

  buildContent = () => {
    const { room, typingUsers } = this.props
    let components: JSX.Element[] = [];
    let previousUserId;
    let dateRef: Date;
    let userRm = room.roomCompositions.filter((rc) => !rc.isMine());

    if (!roomBloc.allIsLoaded()) components.push(<LoadMoreLabel key={"loadmore"}/>);
    room.messages.forEach((message: Message, index: number) => {
      if (dateRef == null || moment(message.createdAt).diff(moment(dateRef)) > 60000) {
        components.push(<ChatDateLabel key={"ChatDateLabel" + index} date={message.createdAt}/>);
      }
      dateRef = message.createdAt;

      if (!message.isFromMe()) {
        if (message.user.id !== previousUserId) {
          components.push(<MessageOwnerLabel key={"MessageOwnerLabel" + index}/>);
        }
        components.push(<OtherMessageComponent key={"msg" + index} message={message} />);
      } else {
        components.push(<SelfMessageComponent key={"msg" + index} message={message} />);

        let roomCompositions: RoomComposition[] = [];
        const lastIndex = room.messages.length - 1;

        if (room.messages[lastIndex].isFromMe()) {
          userRm.forEach((rm, i: number) => {
            let firstMessage: Message = room.messages.slice(index + 1).find((m) => m.isFromMe());
            if (firstMessage && !rm.hasRead(firstMessage) && rm.hasRead(message)) {
              roomCompositions.push(rm);
              userRm = userRm.splice(i, 1);
            }
            if (lastIndex === index && rm.hasRead(message)) roomCompositions.push(rm);
          });

          if (roomCompositions.length > 0) components.push(<ReadAvatars key={"ReadAvatars" + index} roomCompositions={roomCompositions}/>);
        }

      }

      previousUserId = message.user.id;

    });

    typingUsers.forEach((user) => components.push(<IsTyping key={user.id + "type"} user={user} />));

    return components.map((c) => c);

  }

  render() {
    return (
      <div className="messages-box">
        <div ref={this.props.innerRef} className="msg-list">
          {this.buildContent()}
        </div>
      </div>
    )
  }
}
