import SessionService from "./SessionService";
import RightTypeManager from '../managers/RightTypeManager';
import RightType from '../models/RightType';

class RightService {

  public rightTypes: RightType[] = []
  public rightTypeManager = new RightTypeManager()

  can(name: string) {
    if (!name) return true;
    return SessionService.isAdmin() || this.find(name) !== undefined;
  }

  find(name: string) {
    return SessionService.user.role.rights.find((r) => r.name === name);
  }

  async loadRights() {
    this.rightTypes = (await this.rightTypeManager.index()).models;
  }
}

export default new RightService();
