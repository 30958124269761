import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { consumeProjectBloc, IProjectBlocState, projectBloc } from '../../bloc/ProjectBloc';
import Closable from '../common/Closable';
import NewColumnInput from './NewColumnInput';
import TicketColumnContainer from './TicketColumnContainer';

export interface ITicketColumnsProps {
  filter?: string
}

export interface ITicketColumnsState {}

class TicketColumns extends React.Component<IProjectBlocState & ITicketColumnsProps, ITicketColumnsState> {

  constructor(props: IProjectBlocState & ITicketColumnsProps) {
    super(props);

    this.state = {}
  }

  onMoving = (dragIndex, hoverIndex) => {
    let columns = this.props.currentProject.columns
    if (dragIndex === undefined) return;
    let temp = columns[hoverIndex]
    columns[hoverIndex] = columns[dragIndex];
    columns[dragIndex] = temp;
    this.props.currentProject.columns = columns
    this.setState({})
  }

  public render() {
    const { currentProject, newColumnInput } = this.props;
    if (!currentProject) return <></>;
    // let grouped = groupBy(currentProject.tickets, (t) => t.column.id);
    return (
      <div className="d-flex pl-2">
        { currentProject.columns.length === 0 && <NewColumnInput/> }
        { currentProject.columns.map((column, index) => (
          <TicketColumnContainer
            moving={ this.onMoving }
            key={ column.id }
            index={ index }
            tickets={ column.tickets || [] }
            column={ column }
          />
        ))}
        <Closable className="ticket-column-container" close={projectBloc.cancelColumnInput} show={newColumnInput}>
          <NewColumnInput/>
        </Closable>
        {!newColumnInput && <div data-tip={"navtip"} data-for={"tt_col"} onClick={() => projectBloc.showNewColumnInput()} className="add-column-btn mx-2">
          <FontAwesomeIcon icon={ ["fal", "plus"] } />
          <ReactTooltip delayShow={ 500 } effect="solid" place="bottom" id={ "tt_col" }>Créer une colonne</ReactTooltip>
        </div>}
      </div>
    );
  }
}

export default consumeProjectBloc(TicketColumns)