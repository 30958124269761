import * as React from 'react';
import GroupBloc from '../bloc/GroupBloc';
import GroupView from '../components/groups/GroupView';
import BasePage from './BasePage';

export interface IGroupPageProps {
}

export interface IGroupPageState {
}

export default class GroupPage extends BasePage<IGroupPageProps, IGroupPageState> {

  public title = "Groupes"

  constructor(props: IGroupPageProps) {
    super(props);

    this.setTitle();
    this.state = {
    }
  }

  public render() {
    return (
      <GroupBloc>
        <GroupView/>
      </GroupBloc>
    );
  }
}
