import { IconName } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export interface IFillableIconProps {
  iconName: IconName
  className?: string
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

export interface IFillableIconState {
  filled: boolean
}

export default class FillableIcon extends React.Component<IFillableIconProps, IFillableIconState> {
  constructor(props: IFillableIconProps) {
    super(props);

    this.state = {
      filled: false
    }
  }

  public render() {
    return <div className="p-2 row-center" onMouseEnter={() => this.setState({filled: true})} onMouseLeave={() => this.setState({filled: false})}>
      <FontAwesomeIcon
        icon={[this.state.filled ? "fas" : "fal", this.props.iconName]}
        className={this.props.className}
        onClick={this.props.onClick}
      />
    </div>;
  }
}
