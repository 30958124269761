import classNames from 'classnames';
import React from "react";
import { Input } from 'reactstrap';
import SmartInput from './SmartInput';
import { ISmartInputProps } from './SmartInput';


export interface ISmartSelectProps extends ISmartInputProps {
  options: any[]
  optionName?: string
}

export default class SmartSelect extends SmartInput<ISmartSelectProps, {}> {

  public render() {
    const {
      name,
      label,
      floating,
      model,
      rows,
      className,
      placeholder,
      style,
      autoFocus,
      disable,
      id,
      optionName,
      options,
      noMargin,
      required
    }: any = this.props;
    return (
      <div className={classNames({"smart-input": true, "floating-input": floating, "mb-4": floating && !noMargin, "mb-2": !floating && !noMargin})}>
        { (label && !floating) && <label htmlFor={ id || name }>{ label }</label> }
        <Input
          id={ id }
          onKeyDown={ (e) => { if (e.key === "Enter" && this.props.onEnter) this.props.onEnter() } }
          disabled={ disable }
          required={ model?.isRequired(name) || required }
          style={ style }
          className={ className || "" }
          rows={ rows }
          onChange={ this.handleChange }
          name={ name }
          type="select"
          defaultValue={ this.getValue() }
          placeholder={ placeholder || label }
          autoFocus={ autoFocus }
        >
          {options.map(s => <option value={s.id} >{s[optionName || "name"]}</option>)}
        </Input>
        { (label && floating) && <label className="floating-label" htmlFor={ id || name }>{ label }</label> }
        {/* { model && <div className="error-message"></div>} */}
      </div>
    );
  }
}

