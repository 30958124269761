import Notification from './Notification';
import Ticket from '../Ticket';

export default class TicketNotification extends Notification {

  public ticket: Ticket;

  constructor(json: any) {
    super(json);
    this.ticket = json.data.ticket && new Ticket(json.data.ticket);
    this.notifier = this.ticket.creator
  }

  link() {
    return "/tickets/mine?show=" + this.ticket.id;
  }

  humanName() {
    return "Nouveau ticket !";
  }
}