import { IconName } from '@fortawesome/fontawesome-svg-core';
import Colors from '../../../theme/color';
import FormElement from '../FormElement';
import { FormElementType } from '../FormElement';

export default class HyperLink extends FormElement {

  public humanName = "Lien"
  public icon: IconName = "link"
  public typeKey = FormElementType.HyperLink;
  public key = "HyperLink"

  public isCutomable = true;
  public positionnable = true;
  public hasUrl = true;

  setDefaultValue() {
    this.style = {
      textAlign: "left",
      color: Colors.primary
    }
    this.label = "Mon lien"
  }
}
