
import TextInput from '../models/form/formElements/TextInput';
import FileSelect from '../models/form/formElements/FileSelect';
import Header from '../models/form/formElements/Header';
import HyperLink from '../models/form/formElements/HyperLink';
import Paragraph from '../models/form/formElements/Paragraph';
import Tags from '../models/form/formElements/Tags';
import TextArea from '../models/form/formElements/TextArea';
import Switch from '../models/form/formElements/Switch';
import DatePicker from '../models/form/formElements/DatePicker';
import Select from '../models/form/formElements/Select';
import Image from '../models/form/formElements/Image';
import { FormElementType } from '../models/form/FormElement';

export function instantiate(type: FormElementType, json = {}) {
  if (type === FormElementType.TextInput)  return new TextInput(json);
  if (type === FormElementType.TextArea)   return new TextArea(json);
  if (type === FormElementType.Switch)     return new Switch(json);
  if (type === FormElementType.Header)     return new Header(json);
  if (type === FormElementType.Paragraph)  return new Paragraph(json);
  if (type === FormElementType.Image)      return new Image(json);
  if (type === FormElementType.HyperLink)  return new HyperLink(json);
  if (type === FormElementType.Tags)       return new Tags(json);
  if (type === FormElementType.DatePicker) return new DatePicker(json);
  if (type === FormElementType.Select)     return new Select(json);
  if (type === FormElementType.FileSelect) return new FileSelect(json);
  return null;
}