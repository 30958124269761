import Toaster from "../components/common/Toaster";


class ToastrService {

  public toaster: Toaster;

  toast() {

  }

  setToaster(toaster) {
    this.toaster = toaster;
  }

}


export default new ToastrService();
