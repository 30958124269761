import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import { messagingBloc } from '../../bloc/MessagingBloc';
import StaticMessage from '../../models/StaticMessage';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import FillableIcon from '../common/FillableIcon';

export interface IMessageRowProps {
  message: StaticMessage
}

export interface IMessageRowState {
}

export default class MessageRow extends React.Component<IMessageRowProps, IMessageRowState> {
  constructor(props: IMessageRowProps) {
    super(props);

    this.state = {
    }
  }

  public delete = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await this.props.message.delete();
    messagingBloc.loadMessage();
  }

  public render() {
    const { message } = this.props;
    const user = message.isSent() ? message.user : message.sender
    const fullname = message.isSent() ? message.user?.getFullName() : message.sender?.getFullName()
    return (
      <NavLink to={`/messaging/${message.id}`}>
        <div className="message-row">
          <div className="row-flex">
            { !message.isSent() && <div className="mr-3 message-head row-flex">
              <div onClick={(e) => messagingBloc.onToggleImportant(e, message)}>
                { message.isImportant ? <FontAwesomeIcon icon={["fas", "star"]}/> : <FontAwesomeIcon icon={["fal", "star"]}/>}
              </div>
              <div onClick={(e) => messagingBloc.onReply(e, message)}>
                <FillableIcon className="ml-3" iconName={"reply"} />
              </div>
            </div>
            }<NavLink to={`/messaging/${message.id}`}></NavLink>
            <div className="row-flex mr-3 message-from">
              <AvatarLetter size={AvatarSize.sm} user={user}/>
              <div className="ml-3">{fullname}</div>
            </div>
            <div className="message-content mr-3">
              { message.content }
            </div>
            <div className="row-end message-date">
              <div onClick={this.delete} className="gray-btn"><FontAwesomeIcon icon={["fal", "trash"]}/></div>
              <Moment fromNow>{message.createdAt}</Moment>
            </div>
          </div>
          <div>
          </div>
        </div>
      </NavLink>
    );
  }
}
