import * as React from 'react';
import { ModalBody } from 'reactstrap';
import { consumeEventsBloc, eventsBloc, IEventsBlocState } from '../../bloc/EventsBloc';
import TextIcon from '../common/TextIcon';
import InvitationItem from '../events/InvitationItem';
import CustomModalHeader from './customModalHeader';

export interface IInvitationsModalProps {
}

export interface IInvitationsModalState {
  showSuccessMessage: boolean
}

class InvitationsModal extends React.Component<IEventsBlocState, IInvitationsModalState> {

  public timeout: NodeJS.Timeout;

  constructor(props: IEventsBlocState) {
    super(props);

    this.state = {
      showSuccessMessage: false
    }
  }

  onAction = (action: string) => {
    if (action === "accept") {
      eventsBloc.loadEvents();
      this.showSuccessMessage();
    }
    eventsBloc.refreshInvitations();
  }

  showSuccessMessage() {
    this.setState({ showSuccessMessage: true })
    this.timeout = setTimeout(() => this.setState({showSuccessMessage: false}), 5000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  public render() {
    const { invitations } = this.props;
    const { showSuccessMessage } = this.state;
    if (!invitations) return <></>;
    return (
      <div className="invitations-modal">
        <CustomModalHeader title={"Demandes d'ajout d'évènement"} />
        <ModalBody>
          { showSuccessMessage && <div className="success-message mb-2">
            <TextIcon className="text-success" leftIcon={["fal", "check"]}>L'évènement a été ajouté à votre agenda privé</TextIcon>
          </div>}
          { invitations.length > 0 ?
            <div className="section-title mb-3">
              Invitation reçus
            </div> :
            <div className="darkgray text-center">
              Aucune invitation en attente pour le moment.
            </div>
          }
          <div>
            { invitations.map(i => <InvitationItem key={i.id} onAction={this.onAction} invitation={i} />)}
          </div>
        </ModalBody>
      </div>
    );
  }
}

export default consumeEventsBloc(InvitationsModal)