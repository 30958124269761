import { groupBy } from "lodash";
import React from 'react';
import RelationshipManager from '../managers/RelationshipManager';
import User from '../models/User';
import UserService from '../services/UserService';
import { appBloc } from './AppBloc';

export interface IRelationBlocProps {}

export interface IRelationBlocState {
  relations: User[]
  pendings: User[]
  sends: User[]
  members: User[]
  isLoading: boolean
  friendsLoaded: boolean
  memberInput: string
  totalPage: number
  currentPage: number
}

export const Context = React.createContext({})

export let relationBloc: RelationBloc

class RelationBloc extends React.Component<IRelationBlocProps, IRelationBlocState> {

  public relationshipManager: RelationshipManager = new RelationshipManager();

  constructor(props: IRelationBlocProps) {
    super(props)
    relationBloc = this

    this.state = {
      relations: [],
      pendings: [],
      sends: [],
      memberInput: "",
      members: [],
      totalPage: 1,
      currentPage: 1,
      isLoading: false,
      friendsLoaded: false
    }

  }
  getRelations = async () => {
    let result = await UserService.loadFriends();
    this.updateState(result);
  }

  updateState = (friends: User[]) => {
    let groupUser = groupBy(friends, (e) => e.relationToMe.status);
    this.setState({
      relations: groupUser.friend || [],
      pendings: groupUser.pending || [],
      sends: groupUser.send || [],
      isLoading: false,
      friendsLoaded: true
    });
  }

  reload = async () => {
    this.getRelations();
  }

  changeMemberInput = (memberInput) => {
    this.setState({memberInput});
  }

  clearMemberInput = () => {
    this.setState({memberInput: ""});
    this.getMembers();
  }

  cancelFriend = async (user: User) => {
    await this.relationshipManager.cancelFriend(user);
    this.reload();
  }

  acceptFriend = async (user: User) => {
    await this.relationshipManager.acceptFriend(user);
    this.reload();
  }

  requestFriend = async (user: User) => {
    await this.relationshipManager.requestFriend(user);
    this.reload();
  }

  getMembers = async (param = {}) => {
    let response = (await UserService.loadUsersWithPagination(param));
    this.setState({members: response.models, totalPage: response.lastPage, currentPage: response.currentPage})
  }

  changePageTo(page: number) {
    appBloc.push({search: "?page=" + page.toString()});
    this.getMembers({page});
  }

  onSearch = (search: string) => {
    this.getMembers({search})
  }

  reloadMembers = () => {
    this.getMembers({search: this.state.memberInput});
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default RelationBloc;

export function consumeRelationBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
