import * as React from 'react';
import { Button } from 'reactstrap';
import { consumeGroupBloc, IGroupBlocState } from '../../bloc/GroupBloc';
import DOMService from '../../services/DOMService';
import RightService from '../../services/RightService';
import Page from '../layout/Page';
import NewGroupModal from '../modal/GroupModal';
import GroupsList from './GroupsList';

export interface IGroupViewProps {
}

export interface IGroupViewState {
}

class GroupView extends React.Component<IGroupBlocState, IGroupViewState> {

  constructor(props: IGroupBlocState) {
    super(props);

    this.state = {
    }
  }


  public render() {
    const { groups } = this.props;
    return (
      <Page>
        { RightService.can('group') && <Button className="mb-3" color="primary" onClick={() => DOMService.modal(<NewGroupModal/>)}>Creer un groupe</Button> }
        <div className="mb-3 section-title">
          Groupes auquels vous appartenez
        </div>
        <GroupsList groups={groups} />
      </Page>
    );
  }
}

export default consumeGroupBloc(GroupView)
