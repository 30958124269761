import * as React from 'react';
import { Form } from 'reactstrap';
import Model from '../../models/Model';
import DOMService from '../../services/DOMService';

export interface FormState {
  isSubmitting: boolean
  isEdit: boolean
}

export interface IBaseFormProps<T> {
  onSubmitted?: (model: T) => void
  model: T
  render: (formState: FormState) => any
}

export interface IBaseFormState {
  isSubmitting: boolean
}

class BaseForm<T extends Model> extends React.Component<IBaseFormProps<T>, IBaseFormState> {

  constructor(props: IBaseFormProps<T>) {
    super(props)

    this.state = {
      isSubmitting: false
    }
  }

  public onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const { model } = this.props;
    e.preventDefault();
    this.setState({isSubmitting: true})
    if (model.isValid()) {
      if (this.isEdit()) await this.submitted(model.update())
      else               await this.submitted(model.create());
    } else {
      this.displayInvalid()
    }
    DOMService.close();
    this.setState({isSubmitting: false})
  }

  submitted = async (promise: Promise<any>) => {
    return promise.then((m) => {
      this.props.onSubmitted && this.props.onSubmitted(m);
    }).catch((e) => this.displayError(e));
  }

  isEdit = () => {
    return !!this.props.model.id;
  }

  displayInvalid() {}

  displayError(e) {}

  public render() {
    return (
      <Form onSubmit={this.onSubmit}>
        {this.props.render({isSubmitting: this.state.isSubmitting, isEdit: this.isEdit()})}
      </Form>
    );
  }
}

export default BaseForm