import { AxiosResponse } from "axios";
import Group from '../models/Group';
import User from '../models/User';
import EntityManager from "./EntityManager";

export default class AssignableManager extends EntityManager<any> {

  protected modelUrl: string = "assignables";

  protected buildIndexResponse(response: AxiosResponse): any {
    let groups: any = response.data.groups.map(m => new Group(EntityManager.toCamelCase(m)));
    let users: any = response.data.users.map(m => new User(EntityManager.toCamelCase(m)));
    return {models: {groups, users}}
  }

  getModels(search = {}) {
    return this.index(search) as Promise<any>
  }

}
