import React from 'react';
import EntityManager from '../managers/EntityManager';
import Invitation from '../models/Agenda';
import Group from '../models/Group';
import ToastrService from '../services/ToastrService';

export interface IGroupBlocProps {}

export interface IGroupBlocState {
  groups: Group[]
  invitations: Invitation[]
}

export const Context = React.createContext({})

export let groupBloc: GroupBloc

class GroupBloc extends React.Component<IGroupBlocProps, IGroupBlocState> {

  constructor(props: IGroupBlocProps) {
    super(props)
    groupBloc = this

    this.state = {
      groups: [],
      invitations: []
    }

  }

  createGroup = async (group: Group, merge) => {
    await group.create({merge});
    ToastrService.toaster.show("Urioz", "Le groupe a bien été créé.");
    this.getGroups()
  }

  update = async (group: Group) => {
    await group.update();
    ToastrService.toaster.show("Urioz", "Le groupe a bien été mis à jour.");
    this.getGroups()
  }

  getGroups = async() => {
    let groups = (await EntityManager.all<Group>(Group)).models;
    this.setState({groups});
  }

  deleteGroup = async(group: Group) => {
    await group.delete();
    this.getGroups();
  }

  async componentDidMount() {
    this.getGroups();
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default GroupBloc;

export function consumeGroupBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
