import * as React from 'react';
import { appBloc } from '../../bloc/AppBloc';
import authService from '../../services/AuthService';
import StorageService from '../../services/StorageService';
import MediaFileButton from '../buttons/MediaFileButton';
import TextIcon from '../common/TextIcon';
import AdminView from './AdminView';

export interface IAdminAppProps {
}

export interface IAdminAppState {
}

export default class AdminApp extends React.Component<IAdminAppProps, IAdminAppState> {
  constructor(props: IAdminAppProps) {
    super(props);

    this.state = {
    }
  }

  onLogoChange = async (file: File) => {
    let url = await StorageService.uploadFile("logo", file);
    appBloc.state.application.logoUrl = url;
    authService.updateApplication(appBloc.state.application);
    appBloc.setState({})
    this.setState({});
  }

  onBannerChange = async (file: File) => {
    let url = await StorageService.uploadFile("logo", file);
    appBloc.state.application.bannerUrl = url;
    authService.updateApplication(appBloc.state.application);
    appBloc.setState({})
    this.setState({});
  }


  public render() {
    return (
      <AdminView title="Personnalisation de l'application">
        <div className="d-flex">
          <div className="mr-5">
            <div className="row-between mb-3">
              <div>Logo</div>
              <MediaFileButton id="logo" onFileChange={this.onLogoChange}>
                <div className="btn btn-success">
                  <TextIcon leftIcon={["fal", "edit"]}>
                    Modifier
                  </TextIcon>
                </div>
              </MediaFileButton>
            </div>
            <div>
              <div style={{width: "200px"}}>
                <img className="img-fluid" src={appBloc.state.application.logoUrl} alt=""/>
              </div>
            </div>
          </div>
          <div>
            <div className="row-between mb-3">
              <div>
                Bannière
              </div>
              <MediaFileButton id="banner" onFileChange={this.onBannerChange}>
                <div className="btn btn-success">
                  <TextIcon leftIcon={["fal", "edit"]}>
                    Modifier
                  </TextIcon>
                </div>
              </MediaFileButton>
            </div>
            <div style={{width: "200px"}}>
              <img className="img-fluid" src={appBloc.state.application.bannerUrl} alt=""/>
            </div>
          </div>
        </div>
      </AdminView>
    );
  }
}
