import Notification from './Notification';
import StaticMessage from '../StaticMessage';

export default class MessageNotification extends Notification {

  public staticMessage: StaticMessage;

  constructor(json: any) {
    super(json);
    this.staticMessage = json.data.staticMessage && new StaticMessage(json.data.staticMessage);
    this.notifier = this.staticMessage.sender;
  }

  link() {
    return this.staticMessage.getFrontUrl();
  }

  humanName() {
    return "Nouveau message !";
  }
}