import * as React from 'react';
import { eventsBloc } from '../../bloc/EventsBloc';
import Event from '../../models/Event';
import SessionService from '../../services/SessionService';
import IconButton from '../buttons/IconButton';
import AvatarName from '../common/AvatarName';
import TextIcon from '../common/TextIcon';
import DateRangeText from './DateRangeText';

export interface IEventShowProps {
  event: Event
  onEdit: () => void
  onInvite: () => void
}

export interface IEventShowState {
}

export default class EventShow extends React.Component<IEventShowProps, IEventShowState> {
  constructor(props: IEventShowProps) {
    super(props);

    this.state = {
    }
  }

  onDelete = async () => {
    await this.props.event.delete({toaster: "L'évènement a bien été supprimé."});
    eventsBloc.loadEvents();
  }

  onInvite = async () => {
    this.props.onInvite();
  }

  public render() {
    const { event } = this.props;
    return (
      <div>
        <div className="mb-4">
          <div className="row-between mb-1">
            <div className="event-name capitalize">
              <div>{event.name}</div>
            </div>
            <TextIcon iconStyle={ { color: event.type.color } } rightIcon={ ["fas", "circle"] } >{ event.type.name}</TextIcon>
          </div>
          <DateRangeText withIcon event={event} />
          { event.place && <TextIcon iconStyle={{fontSize: "1.1rem"}} className="event-place" leftIcon={["fal", "map-marker-alt"]}>{event.place}</TextIcon>}
        </div>
        <div className="event-description mb-4">
          <div className="gray fw-600 mb-1">{event.description ? "Description" : "Aucune description"}</div>
          {event.description}
        </div>
        <div className="row-between">
          { (event.isFromMe() || SessionService.isAdmin()) && <div className="row-flex">
            <IconButton tooltip="Inviter" className="text-pink"  iconData={["fal", "user-plus"]} onClick={() => this.onInvite()} />
            <IconButton tooltip="Modifier l'évènement" className="text-primary ml-2" iconData={["fal", "edit"]}  onClick={() => this.props.onEdit()} />
            <IconButton tooltip="Supprimer" className="text-danger ml-2"  iconData={["fal", "trash"]} onClick={() => this.onDelete()} />
          </div>
          }
          <AvatarName invert user={event.creator} />
        </div>
      </div>
    );
  }
}
