import * as React from 'react';

export interface ISearchItemBaseProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export interface ISearchItemBaseState {
}

export default class SearchItemBase extends React.Component<ISearchItemBaseProps, ISearchItemBaseState> {
  constructor(props: ISearchItemBaseProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { children, onClick } = this.props;
    return (
      <div onClick={onClick} className="search-item-base">
        {children}
      </div>
    );
  }
}
