import * as React from 'react';
import { Route } from 'react-router';
import { NavLink, Switch } from 'react-router-dom';
import { Button } from 'reactstrap';
import noFormImage from '../assets/img/noForm.jpg';
import TextIcon from '../components/common/TextIcon';
import FormBuilder from '../components/formBuilder/FormBuilder';
import SubmissionList from '../components/formBuilder/SubmissionList';
import EntityManager from '../managers/EntityManager';
import Form from '../models/form/Form';

export interface IFormPageProps {
}

export interface IFormPageState {
  forms: Form[]
  selectedForm: Form
}

export default class FormPage extends React.Component<IFormPageProps, IFormPageState> {

  public title = "Formulaires"

  constructor(props: IFormPageProps) {
    super(props);

    this.state = {
      selectedForm: null,
      forms: null
    }
  }

  componentDidMount() {
    this.loadForms();
  }

  async loadForms() {
    let forms = (await EntityManager.all<Form>(Form)).models;
    let selectedForm;
    if (forms.length > 0) selectedForm = forms[0]
    this.setState({forms, selectedForm})
  }

  onFormChange = (e) => {
    this.setState({ selectedForm: this.state.forms.find(f => f.id.toString() === e.target.value)})
  }

  public render() {
    const { selectedForm, forms } = this.state;
    if (!forms) return <></>
    return (
        <Switch>
          <Route exact path="/forms/new" component={FormBuilder} />
          <Route path="/forms" component={ () => {
            if (forms.length === 0) return (
              <div className="bg-white h-100">
                <div className="row-center py-4">
                  <img className="img-fluid" src={ noFormImage } alt="" />
                </div>
                <h2 className="fw-600 darkgray text-center mb-5">
                  Créez votre premier formulaire
                </h2>
                <NavLink to="/forms/new" ><div className="row-center">
                  <Button size="lg" color="success"><TextIcon leftIcon={ ["fal", "plus"] }>Créez un formulaire</TextIcon></Button>
                </div></NavLink>
              </div>
            )
            return <div>
              <div className="page">
                <div className="mb-3 row-between">
                  <select className="form-control" style={{width: "auto"}} onChange={this.onFormChange} name="" id="" value={selectedForm.id}>
                    { forms.map((form) => <option value={ form.id }>{ form.title }</option>) }
                  </select>
                  <NavLink to="/forms/new" >
                    <Button color="primary">Créer un formulaire</Button>
                  </NavLink>
                </div>
                { selectedForm && <SubmissionList form={ selectedForm } />}
              </div>
            </div>
          }} />
        </Switch>
    );
  }
}
