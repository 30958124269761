import Model from "../Model";
import Event from "../Event";
import StaticMessage from '../StaticMessage';
import User from '../User';

export default class Notification extends Model {

  public notifiableId;
  public notifiableType;
  public data;
  public readAt;
  public event: Event;
  public staticMessage: StaticMessage;
  public notifier: User;
  static modelUrl = "notifications";

  constructor(json: any) {
    super(json)
    this.notifiableId   = json.notifiableId;
    this.notifiableType = json.notifiableType;
    this.data           = json.data;
    this.readAt         = json.readAt;
  }


  link() {
    return "";
  }

  humanName() {
    return "";
  }

}
