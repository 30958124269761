import SessionService from "../../services/SessionService";
import Model from "../Model";
import FormElement from './FormElement';
import { instantiate } from '../../core/FormBuilderCore';

export default class Form extends Model {

  public title: string;
  public subTitle: string;
  public footer: string;
  public createdBy: number;
  public publicUrl: string;
  public submissionsCount: number;
  public imageUrl: string;
  public formElements: FormElement[];
  static modelUrl = "forms";

  public requiredField = ["title"]

  constructor(json: any) {
    super(json)
    this.title            = json.title;
    this.subTitle         = json.subTitle;
    this.footer           = json.footer;
    this.createdBy        = json.createdBy;
    this.publicUrl        = json.publicUrl;
    this.submissionsCount = json.submissionsCount;
    this.imageUrl         = json.imageUrl;
    this.formElements     = json.formElements?.map(element => instantiate(element.typeKey, element)) || [];
  }

  isPublic() {
    return !!this.publicUrl
  }

  fromMe() {
    this.createdBy = SessionService.getUserId();
    return this;
  }

  initOrder() {
    this.formElements.forEach((e, index) => {
      this.formElements[index].position = index;
    })
  }

  getSubmittableElements() {
    return this.formElements.filter((e) => e.isSubmittable);
  }

  toMapApi() {
    return {
      title: this.title,
      subTitle: this.subTitle,
      createdBy: this.createdBy,
      formElements: this.formElements,
      publicUrl: this.publicUrl,
      imageUrl: this.imageUrl
    }
  }
}
