import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import User from '../../models/User';
import Colors from '../../theme/color';
import ProfileShow from '../members/ProfileShow';
import { AvatarSize } from './Avatar';
import classNames from 'classnames';
import Closable from './Closable';

export enum AvatarLetterSize {
  xs = 35,
  sm = 45,
  md = 80,
  lg = 150,
}

export interface IAvatarLetterProps {
  user: User
  size: AvatarSize;
  className?: string
  title?: string
  withActivity?: boolean
  showable?: boolean
}

export default class AvatarLetter extends React.Component<IAvatarLetterProps, any> {

  constructor(props) {
    super(props)

    this.state = {
      showProfile: false
    }
  }

  public colors = ['#cb4c93', '#cb4c4c', '#4ccbb9', '#4ecb4c', '#cb7b4c', '#cbaf4c', '#5f4ccb', '#4c96cb'];

  getStyle(): React.CSSProperties {
    let url = this.props.user.avatar;
    let style: React.CSSProperties = {
      width: this.props.size,
      minWidth: this.props.size,
      height: this.props.size,
      fontSize: this.props.size / 2.5 + "px",
      color: Colors.LightGray,
      // position: "relative",
      borderRadius: "50%",
      display: 'flex'
    }
    if (url) {
      style.backgroundImage = `url(${url})`;
      style.backgroundSize = "cover";
      style.backgroundPosition = "center";
    } else {
      style.backgroundColor = this.getColor();
    }
    return style;
  }

  open = () => {
    if (this.props.showable) this.setState({showProfile: true})
  }

  getColor = () => {
    let index = this.props.user.id % this.colors.length;
    return this.colors[index]
  }

  getLetter = (user: User) => {
    let letter = "";
    if (user.firstname) letter += user.firstname[0];
    if (user.lastname) letter += user.lastname[0];
    return letter;
  }

  render() {
    const { user, className, withActivity, showable } = this.props;
    if (!user) return <></>
    return (
      <div title={user.getFullName()} style={this.getStyle()} className={className}>
        { !user.avatar &&
          <div onClick={this.open} className={classNames({"avatar-letter position-relative": true, "pointer": showable})}>
          { this.getLetter(user) }
          <Closable show={this.state.showProfile} close={() => this.setState({showProfile: false})}>
            <div className="custom-frame p-3" style={ { right: "-50%" } }>
              <ProfileShow small user={user} />
            </div>
          </Closable>
          </div>
        }
        { withActivity &&
          <div className="activity-circle">
            <FontAwesomeIcon size={"sm"} className="text-success" icon={["fas", "circle"]} />
          </div>
        }
      </div>
    );
  }
}
