import * as React from 'react';
import Room from '../../models/Room';
import MatchedRoomItem from './matchedRoomItem';

export interface IMatchedRoomsProps {
  rooms: Room[]
}

export default class MatchedRooms extends React.Component<IMatchedRoomsProps> {
  public render() {
    const { rooms } = this.props;
    if (rooms?.length > 0) return rooms.map((room, i) => (
      <React.Fragment key={i}>
        <MatchedRoomItem room={room} />
      </React.Fragment>
    ));
    else return (
      <div className="text-center gray p-3 d-none d-md-block">
        Aucun résultat.
      </div>
    )
  }
}
