import StorageService from "../../services/StorageService";
import Model from "../Model";
import User from '../User';
import FormElement from './FormElement';
import Attachable from '../mixin/Attachable';
import { instantiate } from "../../core/FormBuilderCore";

export default class SubmissionValue extends Model {

  public value: any;
  public formElementId: number;
  public formElement: FormElement;
  public user: User;
  public attachable: Attachable;
  static modelUrl = "submissionValues";

  public file: File;

  constructor(json: any) {
    super(json)
    this.user          = json.user && new User(json.user);
    this.formElementId = json.formElementId;
    this.formElement   = instantiate(json.formElement.typeKey, json.formElement);
    this.value         = this.parseValue(json.value);
  }

  parseValue(value: any) {
    // if (type === "boolean") return value === 1;
    // if (type === "date" || type === "datetime") return value ? new Date(value) : new Date();
    return value;
  }

  isChartable() {
    return this.formElement.isChartable
  }

  async uploadFile() {
    this.value = await StorageService.uploadFile("forms", this.file)
  }

  toMapApi() {
    return {
      value: this.value,
      formElementId: this.formElementId,
    }
  }

  getBackendModelType(): string {
    return `App\\SubmissionValue`;
  }

}
