import * as React from 'react';
import { NavLink } from 'react-router-dom';
import TicketNotification from '../../models/notif/TicketNotification';
import Notif from './Notif';

export interface INewTicketNotifProps {
  notification: TicketNotification
}

export interface INewTicketNotifState {
}

class NewTicketNotif extends React.Component<INewTicketNotifProps, INewTicketNotifState> {
  constructor(props: INewTicketNotifProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { notification } = this.props;
    let notifier = notification.notifier;
    return (
      <div className="notif-text">
        <NavLink className="fw-bold" to={notifier.getFrontUrl()}>{notifier.getFullName()}</NavLink>
        <span> vous a assigné un ticket </span>
      </div>
    );
  }
}

export default Notif(NewTicketNotif)