import * as React from 'react';
import { Input, InputProps } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';

export interface ISearchInputProps {
  onSearch: (arg: string) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChanged?: (arg: string) => void
  onClear?: () => void
  placeholder?: string
  className?: string
  innerRef?: React.RefObject<HTMLInputElement>
}

export default class SearchInput extends React.Component<ISearchInputProps & InputProps, any> {

  public debouncedCall

  constructor(props) {
    super(props);
    this.debouncedCall = debounce((e: string) => props.onSearch(e), 250);
    this.state = {
      isFocus: false,
    };
  }

  onChange = (e) => {
    console.log(e)
    let value: string = e.target.value;
    this.debouncedCall(value)
    if (this.props?.onChanged) this.props.onChanged(value);
  }

  onFocus = (e) => {
    if (this.props?.onFocus) this.props?.onFocus(e);
    this.setState({isFocus: true});
  }

  onIconClick = () => {
    if (this.props.onClear) this.props.onClear();
  }

  public render() {
    return (
      <div className={this.props.className || ""}>
        <div className="icon-input">
          <Input innerRef={this.props.innerRef} placeholder={this.props.placeholder} value={this.props.value} onChange={this.onChange} onFocus={this.onFocus} onBlur={() => this.setState({isFocus: false})} />
          <FontAwesomeIcon onClick={this.onIconClick} style={{cursor: "pointer"}} icon={["fal", this.props.value !== "" ? "times" : "search"]} className={this.state.isFocus ? "text-primary icon" : "gray icon"} />
        </div>
      </div>
    );
  }
}
