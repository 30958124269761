import * as React from 'react';
import { DragSource } from 'react-dnd';

export interface IDraggableTicketColProps {
  className?: string
}

export interface IDraggableTicketColState {
}

const cardSource = {
  canDrag(props) {
    return true
  },

  isDragging(props, monitor) {
    return monitor.getItem().id === props.item.model.id
  },

  beginDrag(props, monitor, component) {
    const item = { id: props.item.id, index: props.item.index, model: props.item.model }
    return item
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

class DraggableTicketCol extends React.Component<any, IDraggableTicketColState> {
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { connectDragSource, isDragging, className } = this.props;
    let style = isDragging ? {display: "none"} : {}
    return connectDragSource(<div className={className || ""} style={style}>{this.props.children}</div>)
  }
}

export default DragSource("column", cardSource, collect)(DraggableTicketCol)
