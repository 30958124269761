import React from 'react';
import NewEventNotif from '../components/notifications/NewEventNotif';
import NewGroupNotif from '../components/notifications/NewGroupNotif';
import NewMessageNotif from '../components/notifications/NewMessageNotif';
import NewTicketNotif from '../components/notifications/NewTicketNotif';
import NotificationManager from '../managers/NotificationManager';
import EventNotification from '../models/notif/EventNotification';
import GroupNotification from '../models/notif/GroupNotification';
import MessageNotification from '../models/notif/MessageNotification';
import Notification from '../models/notif/Notification';
import TicketNotification from '../models/notif/TicketNotification';
import DOMService from '../services/DOMService';
import SocketService from '../services/SocketService';
import ConnectedNotif from '../components/notifications/ConnectedNotif';
import User from '../models/User';
import Event from '../models/Event';
import AlertNotif from '../components/notifications/AlertNotif';
import EntityManager from '../managers/EntityManager';
import Alert from '../models/Alert';


export interface NotifBlocState {
  notifications: Notification[]
  unreadNotifCount: number,
}

export const Context = React.createContext({})

export let notifBloc: NotifBloc

class NotifBloc extends React.Component<any, NotifBlocState> {

  public notifManager = new NotificationManager();
  public alertRead;

  constructor(props: any) {
    super(props)
    notifBloc = this

    this.state = {
      notifications: [],
      unreadNotifCount: 0
    }
    SocketService.on("newEvent",   (data) => this.onNewNotif(data.notifId));
    SocketService.on("newStaticMessage", (data) => this.onNewNotif(data.notifId));
    SocketService.on("newGroup",   (data) => this.onNewNotif(data.notifId));
    SocketService.on("newTicket",  (data) => this.onNewNotif(data.notifId));

    SocketService.on("alert event", this.alertEvent)

    SocketService.on("user connected", this.onUserConnected)
  }

  componentDidMount() {
    this.loadNotifs();
  }

  onNewNotif = async (notifId) => {
    let notif = await this.notifManager.show(notifId);
    if (!notif) return;
    this.push(notif);
    this.setState({notifications: [notif, ...this.state.notifications], unreadNotifCount: this.state.unreadNotifCount +1 })
  }

  getNotifComponent(notif: Notification) {
    if (notif instanceof EventNotification)   return <NewEventNotif key={notif.id} notification={notif} />
    if (notif instanceof MessageNotification) return <NewMessageNotif key={notif.id} notification={notif} />
    if (notif instanceof GroupNotification)   return <NewGroupNotif key={notif.id} notification={notif} />
    if (notif instanceof TicketNotification)  return <NewTicketNotif key={notif.id} notification={notif} />
  }

  onUserConnected = (data) => {
    this.push(<ConnectedNotif user={new User(data)} />);
  }

  read = async () => {
    if (this.state.unreadNotifCount > 0) {
      await this.notifManager.read();
      this.setState({unreadNotifCount: 0})
    }
  }

  loadNotifs = async () => {
    let notifications = (await this.notifManager.index()).models;
    this.setState({notifications, unreadNotifCount: notifications.filter(n => !n.readAt).length})
  }

  push(element: Notification | JSX.Element) {
    DOMService.notifierRef.show(element);
  }

  alertEvent = (data) => {
    let alertId = data.alertId;
    let event = new Event(EntityManager.toCamelCase(data.event));
    this.push(<AlertNotif event={ event } onSnooze={ () => this.snoozeAlert(alertId)}/>);
    this.alertRead = setTimeout(() => this.updateActiveAlert(alertId, false) , 500);
  }

  updateActiveAlert(alertId, active: boolean) {
    new EntityManager(Alert).update({id: alertId, active})
  }

  snoozeAlert(alertId) {
    this.updateActiveAlert(alertId, true)
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default NotifBloc;

export function consumeNotifBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
