import * as React from 'react';
import Event from '../../models/Event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

export interface IFutureEventsProps {
  event: Event
}

export interface IFutureEventsState {
}

export default class FutureEvents extends React.Component<IFutureEventsProps, IFutureEventsState> {
  constructor(props: IFutureEventsProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { event } = this.props;
    return (
      <div className="event-item">
        <div className="">
          <div className="row-flex event-date">
            <Moment format={"DD MMMM YYYY"}>{event.startAt}</Moment>
            <div className="mx-2">
              <FontAwesomeIcon size={"sm"} className="text-primary" icon={["fas", "circle"]} />
            </div>
            <Moment format={"DD MMMM YYYY"}>{event.endAt}</Moment>
          </div>
          <div className="event-name">
            {event.name}
          </div>
        </div>
      </div>
    );
  }
}
