import SessionService from "../services/SessionService";
import Model from "./Model";

export default class Alert extends Model {

  public date: number | string;
  public active: boolean
  public eventId: number
  public userId: number
  static modelUrl = "alerts";

  constructor(json: any) {
    super(json)
    this.date = json.date || "15";
    this.active  = json.active;
    this.eventId = json.eventId;
    this.userId  = json.userId;
  }

  static getOptions() {
    return [{
      label: "Un quart d'heure avant",
      value: "15"
    },{
      label: "Une demi heure avant",
      value: "30"
    },{
      label: "Une heure avant",
      value: "60"
    },{
      label: "Deux heures avant",
      value: "120"
    },{
      label: "Six heures avant",
      value: "120"
    }]
  }

  fromMe() {
    this.userId = SessionService.getUserId();
    return this;
  }

  static getOptionLabel(value): string {
    return Alert.getOptions().find(o => o.value.toString() === value.toString())?.label || "";
  }

}
