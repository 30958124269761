import classNames from 'classnames';
import React from "react";
import { Input } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import SmartInput, { ISmartInputProps } from './SmartInput';

export interface ISmartInputTextProps extends ISmartInputProps {
  type?: InputType
}

export default class SmartInputText extends SmartInput<ISmartInputTextProps, {}> {

  public render() {
    const {
      name,
      label,
      floating,
      model,
      className,
      placeholder,
      type,
      style,
      autoFocus,
      disable,
      id,
      children,
      noMargin,
      required
    } = this.props;
    return (
      <div className={classNames({"smart-input": true, "floating-input": floating, "mb-4": floating && !noMargin, "mb-2": !floating && !noMargin})}>
        { (label && !floating) && <label htmlFor={ id || name }>{ label }</label> }
        <Input
          id={ id }
          onKeyDown={ (e) => { if (e.key === "Enter" && this.props.onEnter) this.props.onEnter() } }
          disabled={ disable }
          required={ model?.isRequired(name) || required }
          style={ style }
          className={ className || "" }
          type={type}
          onChange={ this.handleChange }
          name={ name }
          defaultValue={ this.getValue() }
          placeholder={ placeholder || label }
          autoFocus={ autoFocus }
        >{ children }</Input>
        { (label && floating) && <label className="floating-label" htmlFor={ id || name }>{ label }</label> }
        {/* { model && <div className="error-message"></div>} */}
      </div>
    );
  }
}

