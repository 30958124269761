import { IconName } from '@fortawesome/fontawesome-svg-core';
import FormElement from '../FormElement';
import { FormElementType } from '../FormElement';

export default class DatePicker extends FormElement {

  public humanName = "Sélecteur de date"
  public icon: IconName = "calendar-alt"
  public typeKey = FormElementType.DatePicker;
  public key = "DatePicker"
  public value = new Date();

  public withTime = false;
  public isField = true;
  public isSubmittable = true;

  setDefaultValue() {
    super.setDefaultValue();
    this.label = "Label"
  }
}
