import * as React from 'react';
import FormElement, { FormElementType } from '../../models/form/FormElement';
import DropZoneComponent from '../common/DropZoneComponent';
import SmartDatePicker from '../input/SmartDatePicker';
import SmartInputText from '../input/SmartInputText';
import SmartInputTextarea from '../input/SmartInputTextarea';
import SmartSelect from '../input/SmartSelect';
import SmartSwitch from '../input/SmartSwitch';

export interface IFormElementComponentProps {
  element?: FormElement
  edit?: boolean
}

export interface IFormElementComponentState {
}

class FormElementComponent extends React.Component<IFormElementComponentProps, IFormElementComponentState> {

  constructor(props: IFormElementComponentProps) {
    super(props);

    this.state = {
    }
  }

  handleChange = (value) => {
    this.props.element.value = value;
    this.setState({});
  }

  public render() {
    const { element, edit } = this.props;
    switch (element.typeKey) {
      case FormElementType.Header: return (
        <h3 style={element.style} className="">{element.label}</h3>
      )
      case FormElementType.Paragraph: return (
        <p style={element.style} >{element.label}</p>
      )
      case FormElementType.HyperLink: return (
        <a style={element.style} href={edit ? "/" : (element.url || "")} className="link text-primary d-block" target="_blank" rel="noopener noreferrer">{element.label}</a>
      )
      case FormElementType.Image: return (
        <div className="d-flex">
          <img style={element.style} src={ element.url } alt={ element.label }/>
        </div>
      )
      case FormElementType.TextInput: return (
        <SmartInputText name={element.id} value={element.value} onChange={(e, str) => this.handleChange(str)} label={element.label} floating />
      )
      case FormElementType.TextArea: return (
        <SmartInputTextarea name={element.id} value={element.value} onChange={(e, str) => this.handleChange(str)} label={element.label} floating />
      )
      case FormElementType.DatePicker: return (
        <SmartDatePicker value={element.value} withTime={element["withTime"]} onchanged={(date) => this.handleChange(date)} name={element.id} label={element.label}/>
      )
      case FormElementType.Switch: return (
        <SmartSwitch name={element.id} onChange={(str) => this.handleChange(str)} label={element.label}/>
      )
      case FormElementType.FileSelect: return (
        <div>
          <label htmlFor={element.label}>{element.label}</label>
          <DropZoneComponent attachable={ element["attachable"]} />
        </div>
      )
      case FormElementType.Select: return (
        <SmartSelect name={element.id} onChange={(e, str) => this.handleChange(str)} label={element.label} floating value={element.value} options={element.formSelectOptions} />
      )
      default: return <></>
    }
  }
}

export default FormElementComponent