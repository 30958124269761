import * as React from 'react';
import User from '../../models/User';
import AvatarName from '../common/AvatarName';
import SearchableSelect from '../common/SearchableSelect';
import UserService from '../../services/UserService';

export interface IMembersSelectProps {
  render?: (arg: User) => void;
  overflowHeight?: string;
  users?: User[]
}

export default class MembersSelect extends SearchableSelect<IMembersSelectProps, any> {

  public availableUsers: User[] = [];

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      selectedUsers: [],
      users: []
    };

  }

  async componentDidMount() {
    if (!this.props.users) {
      let users = await UserService.loadFriends();
      this.setState({users})
    }
  }

  loadData = async ({search}) => {
    let users;
    if (this.props.users) {
      users = this.props.users.filter((u) => {
        let toS = u.getFullName() + u.email;

        return toS.match(search);
    });
    } else {
      users = (await UserService.loadUsers(search)).models;
    }
    this.setState({users})
  }

  getListStyle() {
    const listStyle = {}
    if (this.props.overflowHeight) {
      listStyle["maxHeight"] = this.props.overflowHeight;
      listStyle["overflow"] = "auto";
    }
    return listStyle;
  }

  public render() {
    const { render } = this.props;
    const { users } = this.state;
    return (
      <div className="friends-list">
        <div className="py-2 mt-2">
          {this.renderSearchInput()}
        </div>
        <div style={this.getListStyle()} className="py-2">
        { users.map((user: User)  => (
          <div key={user.id} className="friend-item row-between p-2">
            <AvatarName user={user} />
            { render && render(user) }
          </div>
        ))}
        </div>
      </div>
    );
  }
}
