import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { visioBloc } from '../../bloc/VisioBloc';
import Visio from '../../models/Visio';

export interface IRunningVisioProps {
  visios: Visio[]
}

export interface IRunningVisioState {
}

export default class RunningVisio extends React.Component<IRunningVisioProps, IRunningVisioState> {
  constructor(props: IRunningVisioProps) {
    super(props);

    this.state = {
    }
  }

  joinVisio(visio: Visio) {
    visioBloc.joinVisio(visio);
  }

  public render() {
    let visio = this.props.visios[0];
    return (
      <div className="running-visio">
        <div className="row-between">
          <div className="row-flex">
            <div className="visio-icon mr-3">
              <FontAwesomeIcon size="2x" icon={["fal", "video"]} />
            </div>
            <div>
              <div className="">
                Une visio est en cours...
              </div>
              <div className="text-small">
                {visio.users.length} Membres invités
              </div>
            </div>
          </div>
          <div>
            <Button onClick={() => this.joinVisio(visio)} color="primary">Rejoindre</Button>
          </div>
        </div>
      </div>
    );
  }
}
