import * as React from 'react';
import Form from '../../models/form/Form';

export interface IPublicFormContainerProps {
  form: Form
}

export interface IPublicFormContainerState {
}

export default class PublicFormContainer extends React.Component<IPublicFormContainerProps, IPublicFormContainerState> {
  constructor(props: IPublicFormContainerProps) {
    super(props);

    this.state = {
    }
  }

  getBackground(form): React.CSSProperties {
    return {
      backgroundImage: form.imageUrl,
      backgroundPosition: "center",
      backgroundSize: "cover"
    }
  }

  public render() {
    const { children, form } = this.props
    console.log( this.getBackground(form))
    return (
      <div style={ this.getBackground(form) } className="public-form-container">
        <div className="h-100" style={ { paddingBottom: "120px" } }>
          <div style={ { height: "40%" } } className="row-center">
            <div>
              <h1>{ form.title }</h1>
              { form.subTitle && <h4>
                { form.subTitle }
              </h4>}
            </div>
          </div>
          <div className="auth-card m-auto">
            <div className="auth-card-body">
              {children}
            </div>
          </div>
          { form.footer && <div style={{height: "15%"}} className="row-center">
            <h1>{form.footer}</h1>
          </div>}
        </div>
      </div>
    );
  }
}
