import * as React from 'react';
import RightService from '../../services/RightService';
import ChatSidebarItem from './ChatSidebarItem';
import SidebarItem from './SidebarItem';

export interface INavigationProps {
  minimize?: boolean
}

export interface INavigationState {
}

export default class Navigation extends React.Component<INavigationProps, INavigationState> {
  constructor(props: INavigationProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { minimize } = this.props;
    return (
      <div>
        <SidebarItem iconData={["fal", "home"]}          minimize={minimize} label="Actualité"   to="/" exact />
        <SidebarItem iconData={["fal", "calendar-alt"]}  minimize={minimize} label="Agenda"      to="/events" />
        <SidebarItem iconData={["fal", "tasks"]}         minimize={minimize} label="Tâches"      to="/projects" />
        <SidebarItem iconData={["fal", "cloud"]}         minimize={minimize} label="Documents"   to="/documents" />
        <ChatSidebarItem minimize={minimize}/>
        <SidebarItem iconData={["fal", "network-wired"]} minimize={minimize} label="Relations"   to="/relations" />
        {/* { RightService.can("forms") && <SidebarItem iconData={["fal", "file-check"]}    minimize={minimize} label="Formulaires" to="/forms" />} */}
        <SidebarItem iconData={["fal", "cogs"]}          minimize={minimize} label="Paramètres"  to="/settings" />
      </div>
    );
  }
}
