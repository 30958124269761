import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Row } from 'reactstrap';
import { consumeDriveBloc, IDriveBlocState } from '../../bloc/DriveBloc';
import File from '../../models/File';
import NewFolderCard from './NewFolderCard';

export interface IDriveGridProps {
  renderItem: (file: File) => any
}

export interface IDriveGridState {
}

class DriveGrid extends React.Component<IDriveBlocState & IDriveGridProps, IDriveGridState> {

  public render() {
    const { currentFile, isCreatingFolder, renderItem } = this.props;
    if (!currentFile) return <></>
    return (
      <div className="mt-3">
        <Dropzone>
          {({getRootProps, getInputProps, isDragActive}) => (
            <div {...getRootProps()}>
              <Row className="mx-2">
                { isCreatingFolder && <NewFolderCard/>}
                { currentFile.files.map(file => renderItem(file))}
              </Row>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default consumeDriveBloc(DriveGrid)
