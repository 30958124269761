import Column from './Column';
import Model from "./Model";
import User from './User';

export default class Project extends Model {

  public name: string;
  public description: number;
  public isPublic: boolean;
  public createdBy: number;
  public users: User[];
  public columns: Column[];
  static modelUrl = "projects";

  public requiredField = ["name"]

  constructor(json: any) {
    super(json);
    this.name          = json.name;
    this.description   = json.description;
    this.isPublic      = json.isPublic;
    this.createdBy     = json.createdBy;
    this.users         = this.mapArrayModel(User, json.users) || [];
    this.columns       = this.mapArrayModel(Column, json.columns) || [];
  }

  toMapApi() {
    const {id, name, description, isPublic, users, createdBy } = this;
    return {id, name, description, isPublic, userIds: users.map(u => u.id), createdBy}
  }

}