
import Model from "../Model";

export default class FormSelectOption extends Model {

  public label: string;
  public formElementId: number;
  static modelUrl = "formSelectOptions";

  constructor(json: any) {
    super(json)
    this.label             = json.label;
    this.formElementId     = json.formElementId;
  }

}