import Form from "../models/form/Form";
import EntityManager from "./EntityManager";

export default class FormManager extends EntityManager<Form> {

  protected modelUrl: string = "forms";

  protected modelClass = Form;

}
