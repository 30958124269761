import * as React from 'react';
import { consumeDriveBloc, IDriveBlocState, driveBloc } from '../../bloc/DriveBloc';
import { Progress, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomModalHeader from '../modal/customModalHeader';
import FileIcon from './fileIcon';
import Loading from '../common/Loading';
import Colors from '../../theme/color';

export interface IUploadFrameProps {
}

export interface IUploadFrameState {
}

class UploadFrame extends React.Component<IDriveBlocState, IUploadFrameState> {
  constructor(props: IDriveBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { uploadPercent, uploadingFile } = this.props;
    if (!uploadingFile) return <></>
    return (
      <div className="upload-frame">
        <CustomModalHeader onClose={driveBloc.hideUploadFrame} title={uploadPercent < 100 ? "Importation en cours" : "Importation terminé"} />
        <div className="uploaded-el">
          <div>
            <div className="row-flex" style={{opacity: uploadPercent < 100 ? 0.5 : 1}}>
              <FileIcon height="35px" file={uploadingFile} />
              <div className="ml-2">
                {uploadingFile.name}
              </div>
            </div>
            { uploadPercent < 100 && <div>
              <Button onClick={driveBloc.cancelUpload} size="sm" color="link" >Annuler</Button>
            </div>
          }
          </div>
          <div className="p-2 row-flex">
            <Progress className="w-100 mr-2" striped color="success" value={uploadPercent} />
            { uploadPercent < 100 ? <Loading color={Colors.success} size="20px"/> : <FontAwesomeIcon className="text-success" icon={["fal", "check"]} />}
          </div>
        </div>
      </div>
    );
  }
}

export default consumeDriveBloc(UploadFrame)
