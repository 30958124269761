import * as React from 'react';
import { consumeRelationBloc, IRelationBlocState, relationBloc } from '../../bloc/RelationBloc';
import FriendCard from '../cards/FriendCard';
import RelationItem from './RelationItem';

export interface IFriendsListProps {
}

export interface IFriendsListState {
}

class FriendsList extends React.Component<IRelationBlocState, IFriendsListState> {
  constructor(props: IRelationBlocState) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    relationBloc.getRelations();
  }

  public render() {
    const { relations } = this.props;
    return (
      <div className="friends-list">
        { relations.length === 0 && <div className="darkgray text-center">
          <div>
            Vous n'avez aucune relations pour le moment.
          </div>
          <div>
            Recherchez et invitez des membres à faire parti de vos relations.
          </div>
        </div>  }
        { relations.map(u => <RelationItem key={u.id} user={u} />)  }
        <FriendCard/>
      </div>
    );
  }
}

export default consumeRelationBloc(FriendsList)
