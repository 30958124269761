import Store from 'beedle';

let _onPost;

const store = new Store({
  actions: {
    setData(context, data, saveData) {
      context.commit('setData', data);
      if (saveData) this.save(data);
    },

    create(context, element) {
      const { data } = context.state;
      data.push(element);
      this.setData(context, data, true);
    },

    delete(context, element) {
      const { data } = context.state;
      data.splice(data.indexOf(element), 1);
      this.setData(context, data, true);
    },

    updateOrder(context, elements) {
      this.setData(context, elements, true);
    },

    save(data) {
      _onPost({ task_data: data });
    },
  },

  mutations: {
    setData(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.data = payload;
      return state;
    },
  },

  initialState: {
    data: [],
  },
});

store.setExternalHandler = (onLoad, onPost) => {
  _onPost = onPost;
};

export default store;
