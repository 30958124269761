import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Dropdown } from 'reactstrap';
import { driveBloc } from '../../bloc/DriveBloc';
import File from '../../models/File';
import { DropDownDirection, DropDownPosition } from '../common/Dropdown';
import TextIcon from '../common/TextIcon';

export interface IDropboxDropdownProps {
  file: File
}

export interface IDropboxDropdownState {
}

export default class DropboxDropdown extends React.Component<IDropboxDropdownProps, IDropboxDropdownState> {
  constructor(props: IDropboxDropdownProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { file } = this.props
    return (
      <Dropdown
        position={DropDownPosition.bottom}
        direction={DropDownDirection.left}
        button={<div className="py-2 px-4 gray-btn"><FontAwesomeIcon size={"2x"} icon={["fal", "ellipsis-h"]} /></div>}
        content={(
          <div className="dropdown-frame">
            <TextIcon onClick={() => driveBloc.onDownload(file)} className="dropdown-item" leftIcon={["fal", "cloud-download-alt"]} >Télécharger</TextIcon>
          </div>
        )}
      />
    );
  }
}
