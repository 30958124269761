import * as React from 'react';
import { Button } from 'reactstrap';
import { consumeMessagingBloc, IMessagingBlocState, messagingBloc } from '../../bloc/MessagingBloc';
import DOMService from '../../services/DOMService';
import PaginationComponent from '../common/PaginationComponent';
import TextIcon from '../common/TextIcon';
import NewMessageModal from '../modal/NewMessageModal';

export interface IMessagingHeaderProps {
}

export interface IMessagingHeaderState {
}

class MessagingHeader extends React.Component<IMessagingBlocState, IMessagingHeaderState> {
  constructor(props: IMessagingBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { totalPage, currentPage } = this.props;
    return (
      <div className="row-between mb-3">
        <PaginationComponent total={totalPage} currentPage={currentPage} onPageChange={(p) => {messagingBloc.onPageChange(p)}} />
        <Button className="d-none d-sm-block" onClick={() => DOMService.modal(<NewMessageModal/>)} color="primary">
          <TextIcon leftIcon={["fal", "plus"]}>Nouveau message</TextIcon>
        </Button>
      </div>
    );
  }
}
export default consumeMessagingBloc(MessagingHeader)
