import * as React from 'react';
import RoomItem from './roomItem';
import Room from '../../models/Room';

export interface IRoomItemsProps {
  rooms: Room[];
}

export default class RoomItems extends React.Component<IRoomItemsProps> {
  public render() {
    const { rooms } = this.props;
    if (rooms.length > 0) return rooms.map((room, i) => (
      <React.Fragment key={i}>
        <RoomItem room={room} />
      </React.Fragment>
    ));
    else return (
      <div className="text-center dark-gray p-3 light-blue-bg d-none d-md-block">
        Aucune discussion.
      </div>
    )
  }
}
