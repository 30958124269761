import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Redirect } from 'react-router';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import BuildingView from '../components/common/BuildingView';
import SmartInputText from '../components/input/SmartInputText';
import AuthPage from '../components/layout/AuthPage';
import Application from '../models/Application';
import Licence from '../models/Licence';
import User from '../models/User';
import authService from '../services/AuthService';
import ToastrService from '../services/ToastrService';

export interface ISigninPageProps {
}

export interface ISigninPageState {
  isBuilding: boolean
  redirect: boolean
  domainUnavailable: boolean
}

export default class SigninPage extends React.Component<ISigninPageProps, ISigninPageState> {

  public user: User = new User({roleId: 1});
  public application: Application;
  public debouncedCheckDomain;

  constructor(props: ISigninPageProps) {
    super(props);

    this.state = {
      isBuilding: false,
      redirect: false,
      domainUnavailable: false
    }

    this.application = new Application({licence: new Licence({type: "app", nbUsers: 1})})

    // this.debouncedCheckDomain = debounce(this.checkDomain, 1000);
  }

  async componentDidMount() {
  }

  isValid() {
    return !this.state.domainUnavailable;
  }

  // checkDomain = async () => {
  //   this.setState({domainUnavailable: false});
  //   let result = await authService.checkDomain(this.application.name);
  //   this.setState({domainUnavailable: result === "unavailable"});
  // }

  submit = async (e) => {
    e.preventDefault();
    if (!this.isValid()) return this.setState({});
    this.setState({isBuilding: true})
    try {
      this.application.licence.email = this.user.email;
      await authService.createApplication(this.application, this.user)
      this.setState({redirect: true});
      // window.location.href = `${env.protocol}${application.name}.${env.frontUrl}/login`;
    } catch (error) {
      this.setState({isBuilding: false});
      ToastrService.toaster.show("Erreur", "Une erreur est survenue! Veuillez réessayer !", "danger")
    }
  }

  // renderDomainMessage = () => {
  //   if (this.state.domainUnavailable) return (
  //     <TextIcon className="text-danger" leftIcon={["fal", "exclamation-triangle"]}>
  //       Ce nom de domaine est indisponible
  //     </TextIcon>
  //   );
  //   return <div className="row-flex">
  //     <TextIcon leftIcon={["fal", "check"]}>
  //       Votre nom de domaine sera
  //     </TextIcon>
  //     <span className="fw-bold ml-1 text-pink">
  //       {`${this.application.name}.${env.frontUrl}`}
  //     </span>
  //   </div>
  // }

  public render() {
    const { redirect, isBuilding } = this.state;
    if (redirect) return <Redirect to="/login" />
    return (
      <AuthPage>
        <div className="auth-card-container">
          <div className="auth-card">
            { isBuilding ? <BuildingView/> : <>
            <div className="auth-card-header">
              <div className="subtitle mb-4 fw-bold">
                Inscrivez-vous maintenant
              </div>
            </div>
            <div className="auth-card-body">
              <Form onSubmit={this.submit}>
                {/* <InputRow label="Nom de votre application">
                  <SmartInputText className={domainUnavailable ? "is-invalid" : ""} onChange={() => this.debouncedCheckDomain()} name="name" model={this.application}/>
                  {this.application.name && <div className="darkgray my-1">
                    {this.renderDomainMessage()}
                  </div>}
                </InputRow> */}
                  <FormGroup>
                    <FontAwesomeIcon icon={["fal", "user"]} />
                    <SmartInputText type="email" name="email" model={this.user} placeholder="Email"/>
                  </FormGroup>
                  <FormGroup>
                    <FontAwesomeIcon icon={["fal", "lock"]} />
                    <SmartInputText type="password" name="password" model={this.user}/>
                  </FormGroup>
                <Row>
                  <Col>
                    <SmartInputText name="firstname" model={this.user} label="Prénom"/>
                  </Col>
                  <Col>
                    <SmartInputText name="lastname" model={this.user} label="Nom"/>
                  </Col>
                </Row>
                <SmartInputText label="Nombre d'utilisateurs" name="nbUsers" type="number" model={this.application.licence} floating />
                <FormGroup className="text-center mt-3">
                  <Button type="submit" className="btn-pink btn-lg">Créer mon intranet</Button>
                </FormGroup>
              </Form>
              </div>
            </>}
          </div>
        </div>
      </AuthPage>
    );
  }
}
