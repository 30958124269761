import Model from "./Model";
import Licence from "./Licence";

export default class Application extends Model {

  public name: string;
  public licenceId: number;
  public logoUrl: string;
  public bannerUrl: string;
  public licence: Licence;
  static modelUrl = "applications";

  public requiredField = ["name"]

  constructor(json: any) {
    super(json);
    this.name = json.name;
    this.licenceId = json.licenceId;
    this.logoUrl = json.logoUrl;
    this.bannerUrl = json.bannerUrl;
    this.licence = json.licence && new Licence(json.licence);
  }

}
