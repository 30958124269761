import * as React from 'react';
import Dropzone from 'react-dropzone';
import { consumeDriveBloc, IDriveBlocState, driveBloc } from '../../bloc/DriveBloc';
import FileRow from './FileRow';
import NewFolderRow from './NewFolderRow';
import classNames from 'classnames';
import File from '../../models/File';

export interface IDriveTableProps {
  renderItem: (file: File) => any
}

export interface IDriveTableState {
}

class DriveTable extends React.Component<IDriveBlocState & IDriveTableProps, IDriveTableState> {

  public render() {
    const { currentFile, isCreatingFolder, searchMode, searchedFiles, renderItem } = this.props;
    if (!currentFile) return <></>
    return (
      <div className="drive-table">
        <div className="t-header">
          <div className="row-flex">
            <div className="c-1"></div>
            <div className="c-2">Nom</div>
            <div className="c-3">Ajouté par</div>
            <div className="c-4">Dernière modification</div>
            <div className="c-5">Taille</div>
            <div className="c-6"></div>
          </div>
          <div className="t-border"></div>
        </div>
        { searchMode && (
          <div className="t-body">
          { isCreatingFolder && <NewFolderRow/>}
          {searchedFiles.map((f) => (
            <FileRow
              key={f.id}
              file={f}
            />
          ))}
        </div>
        )}
        { !searchMode && (
          <Dropzone onDrop={(files) => driveBloc.onUpload(files[0])}>
            {({getRootProps, getInputProps, isDragActive}) => (
              <div className={classNames({"t-body": true, "isDragContainer": isDragActive})} {...getRootProps()}>
                <div>
                  { isCreatingFolder && <NewFolderRow/>}
                  { currentFile.files.map(file => renderItem(file))}
                </div>
              </div>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}

export default consumeDriveBloc(DriveTable)
