import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Closable from './Closable';

export interface IDropdownProps {
  content: JSX.Element
  button: JSX.Element
  position: DropDownPosition
  direction: DropDownDirection
  dontCloseOnClick?: boolean;
  spacing?: string
}

export interface IDropdownState {
  show: boolean,
}

export enum DropDownPosition {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right'
}

export enum DropDownDirection {
  top = 'bottom',
  bottom = 'top',
  left = 'right',
  right = 'left'
}

export default class Dropdown extends React.Component<IDropdownProps, IDropdownState> {

  public translate;
  public contentRef = React.createRef<HTMLDivElement>();

  constructor(props: IDropdownProps) {
    super(props);

    this.state = {
      show: false
    }

    this.translate = {
      top: `translate(0, calc(-100% - ${props.spacing || '0.5rem'}))`,
      bottom: `translate(0, calc(100% + ${props.spacing || '0.5rem'}))`,
      left: `translate(calc(-100% - ${props.spacing || '0.5rem'}), 0%)`,
      right: `translate(calc(100% + ${props.spacing || '0.5rem'}), 0%)`,
    }
  }

  getStyle(): any {
    let style = {
      position: "absolute",
      [this.props.position]: 0,
      [this.props.direction]: 0,
      transform: this.translate[this.props.position],
      zIndex: 10000
    };
    return style;
  }

  close = (e: any) => {
    // if (this.props.dontCloseOnClick && this.contentRef.current.contains(e.target)) return;
    this.setState({show: false});
    // appBloc.cancelClickBody(this.close)
  }

  onClickDropdown(e) {
    e.stopPropagation();
    this.setState({show: this.props.dontCloseOnClick})
  }

  toggle = (e) => {
    e.stopPropagation();
    if (this.state.show) return;
    this.setState({show: true});
  }

  public render() {
    const { button, content } = this.props;
    const { show } = this.state;
    return (
      <div className="drop-btn row-center">
        <div className="row-center" onClick={this.toggle}>{button}</div>
        <Closable show={show} close={() => this.setState({show: false})}>
          <div className="position-relative">
            <div onClick={(e) => this.onClickDropdown(e)} ref={this.contentRef} key={"drop"} style={this.getStyle()}>
              {content}
            </div>
          </div>
        </Closable>
      </div>
    );
  }
}

export class DropdownNavItem extends React.Component<any> {

  renderItem(child) {
    const { label, to } = this.props;
    if (!to) return <div className="dropdown-nav-item">{child}</div>
    return <NavLink className="row-flex dropdown-nav-item" key={label} exact to={to}>{child}</NavLink>
  }

  render() {
    const { label, icon, onClick } = this.props;
    return (
      this.renderItem(
        <div onClick={onClick}>
          <div className="row-flex">
            { icon && (
              <div className="f-icon">
                <FontAwesomeIcon icon={icon}/>
              </div>
            ) }
            <div>{label}</div>
          </div>
        </div>
      )
    )
  }
}
