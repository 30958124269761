import * as React from 'react';
import Moment from 'react-moment';

export interface IDatableProps<T> {
  array: T[]
  dateProperty: string
  render: (arg: T) => void
}

export interface IDatableState {
}

export default class Datable<T> extends React.Component<IDatableProps<T>, IDatableState> {
  constructor(props: IDatableProps<T>) {
    super(props);

    this.state = {
    }
  }

  renderDateLine = (date: Date) => {
    const calendarStrings = {
      lastDay : '[Hier]',
      sameDay : '[Aujourd\'hui]',
      nextDay : '[Demain]',
      lastWeek : '[dernier] dddd',
      nextWeek : 'dddd',
      sameElse : 'L'
  };
    return <div key={date.toString()} className="dateline">
      <Moment calendar={calendarStrings}>{date}</Moment>
    </div>
  }

  public render() {
    const { array, dateProperty, render } = this.props;
    let dateRef: Date;
    let components = [];

    array.forEach((e) => {
      if (!dateRef || e[dateProperty].getDay() !== dateRef.getDay()) {
        dateRef = e[dateProperty];
        components.push(this.renderDateLine(e[dateProperty]));
      }
      components.push(render(e));
    })

    return (
      <div>
      { components.map(e => e)}
      </div>
    );
  }
}
