import UserManager from '../managers/UserManager';
import User from '../models/User';

class UserService {

  public userManager = new UserManager();
  public users: User[];
  public friends: User[];

  public getUsers = async (param = {}) => {
    if (!this.users) this.users = (await this.loadUsers(param)).models;
    return this.users;
  }

  public loadUsers = async (param = {}) => {
    return (await this.userManager.index(param))
  }

  public loadUsersWithPagination = async (param = {}) => {
    return (await this.userManager.indexWithPagination(param))
  }

  public getFriends = async (): Promise<User[]> => {
    if (!this.friends) return await this.loadFriends();
    return this.friends;
  }

  public loadFriends = async (): Promise<User[]> => {
    this.friends = (await this.userManager.relations()).models;
    return this.friends;
  }

  public sendNewUserMail = async (param) => {
    let result = await this.userManager.post("newUserMail", param);
    return result;
  }

  public checkNewUserToken = async (token) => {
    return await this.userManager.post("newUserToken", {token});
  }

  public getNbUsers = async () => {
    return await this.userManager.get("count", {}, true);
  }

}

export default new UserService()
