import * as React from 'react';
import { ModalBody } from 'reactstrap';
import Group from '../../models/Group';
import GroupForm from '../form/GroupForm';
import CustomModalHeader from './customModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { groupBloc } from '../../bloc/GroupBloc';

export interface IGroupModalProps {
  group?: Group
  edit?: boolean
}

export default class GroupModal extends React.Component<IGroupModalProps, any> {

  public render() {
    const { group } = this.props;
    const title = !group ? "Nouveau Groupe" : group.name;
    return (
      <div className="group-modal">
        <CustomModalHeader title={title} actions={group ? [
          <div style={{cursor: "pointer"}} onClick={() => groupBloc.deleteGroup(group)} className="px-3">
            <FontAwesomeIcon icon={["fal", "trash"]} />
          </div>
        ] : []} />
        <ModalBody>
          <GroupForm group={group}/>
        </ModalBody>
      </div>
    );
  }
}
