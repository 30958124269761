import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { consumeNotifBloc, notifBloc, NotifBlocState } from '../../bloc/NotifBloc';

export interface INotifFrameProps {
}

export interface INotifFrameState {
}

class NotifFrame extends React.Component<NotifBlocState, INotifFrameState> {
  constructor(props: NotifBlocState) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    notifBloc.read();
  }

  public render() {
    const { notifications } = this.props;

    return (
      <div className="notif-frame">
        <div className="text-center mb-2">
          <FontAwesomeIcon size={"lg"} icon={["fal", "bell"]} />
        </div>
        <div className="text-center mb-2">{`Vous avez ${notifications.length} notifications non lues.`}</div>
        <div>
          {notifications.map(n => notifBloc.getNotifComponent(n))}
        </div>
      </div>
    );
  }
}

export default consumeNotifBloc(NotifFrame)
