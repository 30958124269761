import * as React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';
import ModalService from '../../services/DOMService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ICustomModalHeaderProps {
  title: string;
  onClose?: () => void
  onReturn?: () => void
  style?: React.CSSProperties
  actions?: JSX.Element[]
}

export default class CustomModalHeader extends React.Component<ICustomModalHeaderProps> {
  public render() {
    const { title, style, onReturn, actions } = this.props;
    return (
      <div style={style} className="modal-header">
        { onReturn && <div className="p-2" style={{cursor: "pointer"}} onClick={() => onReturn()}>
          <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
        </div>}
        <div className="modal-title">{title}</div>
        <div className="row-end">
          {actions?.map(a => a)}
          <div key={uniqueId()} onClick={() => this.props.onClose ? this.props.onClose() : ModalService.close()} className="close-btn p-2">
            <FontAwesomeIcon icon={["fal", "times"]}/>
          </div>
        </div>
      </div>
    );
  }
}
