import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';

export interface ITextIconProps {
  leftIcon?: IconProp;
  rightIcon?: IconProp;
  spacing?: number;
  iconStyle?: React.CSSProperties;
  iconClassName?: string
  className?: string
  onClick?: () => void
}

export default class TextIcon extends React.Component<ITextIconProps & React.HTMLProps<HTMLDivElement>> {
  public render() {
    const { leftIcon, rightIcon, children, spacing, className, onClick, iconStyle, iconClassName } = this.props;
    return (
      <div className={className} onClick={onClick} >
        <div className="row-flex">
          { leftIcon && <FontAwesomeIcon style={iconStyle} className={classNames({[`mr-${spacing || 2}`]: true, [iconClassName]: true})} icon={leftIcon} /> }
          <span>{children}</span>
          { rightIcon && <FontAwesomeIcon style={iconStyle} className={classNames({[`ml-${spacing || 2}`]: true, [iconClassName]: true})} icon={rightIcon} /> }
        </div>
      </div>
    );
  }
}
