import * as React from 'react';

export interface IPageProps {
  noPadding?: boolean
  className?: string
}

export interface IPageState {
}

export default class Page extends React.Component<IPageProps, IPageState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className={"page " + (this.props.noPadding ? "p-0 " : "") + (this.props.className || "")}>
        {this.props.children}
      </div>
    );
  }
}
