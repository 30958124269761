import * as React from 'react';
import { Button } from 'reactstrap';
import { consumeEventsBloc, eventsBloc, IEventsBlocState } from '../../bloc/EventsBloc';
import DOMService from '../../services/DOMService';
import IconButton from '../buttons/IconButton';
import TextIcon from '../common/TextIcon';
import SmartCheckbox from '../input/SmartCheckbox';
import SmartSwitch from '../input/SmartSwitch';
import AgendaModal from '../modal/AgendaModal';
import NewEventModal from '../modal/NewEventModal';

export interface IAgendaSidebarProps {
}

export interface IAgendaSidebarState {
}

class AgendaSidebar extends React.Component<IEventsBlocState, IAgendaSidebarState> {

  constructor(props: IEventsBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { activeAgendaIds, agendas, filters } = this.props
    if (!agendas) return <></>;
    return (
      <div className="col-between h-100">
        <div>
        <div className="row-between py-1 mb-3">
          <Button className="" onClick={() => DOMService.modal(<NewEventModal/>, eventsBloc.modalStyle)} color="primary">
            <TextIcon leftIcon={["fal", "plus"]}>Créer</TextIcon>
          </Button>
          {/* <CalendarSubmenu/> */}
        </div>
          <div className="mb-2 fw-bold">Type d'évènement</div>
          <div>
            { filters.map((filter, index) => <div className="py-1" key={filter.eventType.id}>
              <SmartSwitch
                name={filter.eventType.name}
                label={filter.eventType.name}
                color={filter.eventType.color}
                onChange={(e, checked) => filter.onChange(checked, index)}
                checked={filter.isActive}
              />
            </div>
            )}
          </div>
          <div className="mt-4 mb-2 row-between">
            <div className="fw-bold">Agendas</div>
            <div className="row-end">
              <IconButton tooltip="Modifier vos agendas" onClick={() => DOMService.modal(<AgendaModal agendas={agendas} />)} iconData={["fal", "pen"]} light />
            </div>
          </div>
          <div style={ { maxHeight: "350px", overflow: "auto"} }>
            <SmartCheckbox
              label={"Tous"}
              className="mb-1"
              checked={activeAgendaIds.length === agendas.length}
              onChange={(e) => eventsBloc.onAllAgenda(activeAgendaIds.length === agendas.length)}
            />
            { agendas.map((agenda, index) => <div className="mb-1" key={agenda.id}>
              <SmartCheckbox
                label={agenda.name}
                checked={activeAgendaIds.includes(agenda.id)}
                onChange={(e) => eventsBloc.onAgendaFilterChange(agenda.id)}
              />
            </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default consumeEventsBloc(AgendaSidebar)
