import * as React from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router';

export interface IDropBoxAuthProps {
}

export interface IDropBoxAuthState {
}

export default class DropBoxAuth extends React.Component<IDropBoxAuthProps, IDropBoxAuthState> {

  public render() {
    let info: any = queryString.parse(window.location.hash);
    localStorage.setItem("dropboxToken", info.access_token);
    return <Redirect to="/documents/dropbox" />
  }
}
