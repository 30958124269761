import * as React from 'react';

export interface IFriendCardProps {
}

export interface IFriendCardState {
}

export default class FriendCard extends React.Component<IFriendCardProps, IFriendCardState> {
  constructor(props: IFriendCardProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>

      </div>
    );
  }
}
