import { capitalize } from 'lodash';
import EntityManager from '../managers/EntityManager';
import ToastrService from '../services/ToastrService';
import DOMService from '../services/DOMService';


export default class Model {

  public id: any;
  public modelName: string;
  static modelUrl: string;
  public createdAt: Date;
  public updatedAt: Date;
  public parentModel: Model;
  public requiredField: string[] = []

  public alertTitle: string = "Supprimer cet élément ?";
  public confirmMessage: string = "Vous allez supprimer cet élément définitivement.";

  // public emitter: EventEmitter = new EventEmitter();

  public constructor(json: any, parentModel?: Model) {
    this.id = json?.id;
    this.createdAt   = json.createdAt && new Date(json.createdAt);
    this.updatedAt   = json.updatedAt && new Date(json.updatedAt);
    this.parentModel = parentModel;
  }

  isRequired(field: string) {
    return this.requiredField.includes(field);
  }

  isValid() {
    let valid = true;
    this.requiredField.forEach(r => {
      if (!this[r]) valid = false;
    });
    return valid;
  }

  mapArrayModel(modelClass, jsonArray: any[], parentModel?: Model) {
    if (parentModel) return jsonArray?.map((e) => new modelClass(e, parentModel)) || [];
    return jsonArray?.map((e) => new modelClass(e)) || [];
  }

  getBackendModelType(): string {
    return `App\\${capitalize(this.constructor.name)}`;
  }

  getFrontUrl(): string {
    let classs: any = this.constructor;
    return `/${classs.modelUrl}/${this.id}`;
  }

  getEntityManager(): EntityManager<this> {
    return new EntityManager<this>(this.constructor);
  }

  /// CRUD ///

  async create(options: CreateOptions = {}) {
    let param = this.getApiParam(options.only);
    if (options.merge) param = Object.assign(param, options.merge);
    // if (this.hasOwnProperty("attachable")) return this.getEntityManager().createWithFiles(param, this["file"])
    return this.getEntityManager().create(param);
  }

  async update(options: UpdateOptions = {}) {
    let param = this.getApiParam(options.only);
    if (options.merge) param = Object.assign(param, options.merge);
    return this.getEntityManager().update(param);
  }

  async delete(options: DeleteOptions = {}): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      let toaster = options.toaster || "L'élément a bien été supprimé";
      let onConfirm = async () => {
        await this.getEntityManager().delete(this.id);
        ToastrService.toaster.show("Urioz", toaster);
        DOMService.close();
        resolve();
      }
      DOMService.alert({
        title: this.alertTitle,
        message: this.confirmMessage,
        buttonText: "Supprimer",
        onCancel: () => DOMService.close(),
        onConfirm: onConfirm
      })
    })
    return promise;
  }

  getApiParam(only?: string[]) {
    let param;
    if (only) {
      param = {id: this.id};
      only.forEach(attr => {
        param[attr] = this[attr];
      })
    } else {
      param = this.toMapApi();
    }
    return param;
  }
  // onRefresh(callback: () => void) {
  //   this.emitter.on("refresh", callback);
  // }

  // refresh() {
  //   this.emitter.emit("refresh");
  // }

  // refreshParent() {
  //   this.parentModel?.refresh();
  // }

  // refreshHigherParent() {
  //   if (this?.parentModel?.parentModel) return this.parentModel.refreshHigherParent()
  //   if (this?.parentModel) this.refreshParent();
  //   return this.refresh();
  // }

  // withRefresh(component: React.Component) {
  //   this.onRefresh(() => component.setState({}))
  //   return this;
  // }

  toMapApi(): any {
    return this;
  }

  getElementId() {
    return this.modelName + this.id;
  }
}

export interface CreateOptions {
  only?: string[]
  merge?: Object
}

export interface UpdateOptions {
  only?: string[]
  merge?: Object
}

export interface DeleteOptions {
  confirmMessage?: string
  toaster?: string
}