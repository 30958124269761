import classNames from 'classnames';
import { uniqueId } from 'lodash';
import * as React from 'react';
import SmartInput, { ISmartInputProps } from './SmartInput';


export interface ISmartSwitchProps extends ISmartInputProps {
  checked?: boolean;
  primary?: boolean;
  color?: string;
}

export default class SmartSwitch extends SmartInput<ISmartSwitchProps, {}> {

  public id = uniqueId();
  public targetProperty = "checked";

  public render() {
    const { name, label, className, color, primary } = this.props;
    return (
      <div className={ classNames({ [className]: !!className, "smart-switch": true, "smart-switch-primary": primary })}>
        <input id={this.id} checked={this.getValue()} onChange={this.handleChange} name={name} type="checkbox"/>
        <label style={{background: this.getValue() ? color : null}} htmlFor={this.id} className="check-trail">
          <span className="check-handler"></span>
        </label>
        <label htmlFor={this.id} className="switch-label mb-0">
          {label}
        </label>
      </div>
    );
  }
}
