import * as React from 'react';
import { consumeVisioBloc, IVisioBlocState } from '../../bloc/VisioBloc';
import RealTimeFrame from './RealTimeFrame';
import VideoCallView from './VideoCallView';

export interface IVisioProps {
}

export interface IVisioState {
}

class Visio extends React.Component<IVisioBlocState, IVisioState> {
  constructor(props: IVisioBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <>
      { this.props.calledBy && <VideoCallView user={this.props.calledBy} />}
      {/* { this.props.userCalled && <VideoCalling user={this.props.userCalled} />} */}
      { this.props.visio?.isActive && <RealTimeFrame/>}
      </>
    );
  }
}

export default consumeVisioBloc(Visio)