import * as React from 'react';
import { appBloc } from '../../bloc/AppBloc';

export interface IClosableProps {
  show: boolean
  className?: string
  close: () => void
}

export interface IClosableState {
}

export default class Closable extends React.Component<IClosableProps, any> {

  public ref = React.createRef<HTMLInputElement>();

  componentDidUpdate() {
    if (!this.props.show) appBloc.cancelClickBody(this.close);
  }

  close = (e) => {
    if (this.ref.current?.contains(e.target)) return;
    this.props.close();
    appBloc.cancelClickBody(this.close);
  }

  public render() {
    const { show, children, className } = this.props;
    if (!show) return <></>;
    appBloc.onClickBody(this.close)
    return (
      <div className={className || ""} ref={this.ref}>
        {children}
      </div>
    );
  }
}
