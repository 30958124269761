import { IconProp } from '@fortawesome/fontawesome-svg-core';
export default class TabItem {

  public label: string;
  public path: string;
  public component: any;
  public right: string;
  public icon: IconProp;

  constructor({label, path, right = null, component, icon = null}) {
    this.label = label;
    this.path = path;
    this.right = right;
    this.component = component;
    this.icon = icon;
  }
}
