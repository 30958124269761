import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Moment from 'react-moment';
import { projectBloc } from '../../bloc/ProjectBloc';
import Comment from '../../models/Comment';
import Ticket from '../../models/Ticket';
import SessionService from '../../services/SessionService';
import CommentsComponent from '../comments/CommentsComponent';
import AvatarName from '../common/AvatarName';
import EditableText from '../common/EditableText';
import TextIcon from '../common/TextIcon';
import SmartSelect from '../input/SmartSelect';
import AttachmentComponent from '../messaging/AttachmentComponent';
import CheckList from './CheckList';

export interface ITicketShowProps {
  ticket: Ticket
}

export interface ITicketShowState {
}

export default class TicketShow extends React.Component<ITicketShowProps, ITicketShowState> {

  public priorityOption = [
    { id: 3, name: "Haute" },
    { id: 2, name: "Modéré" },
    { id: 1, name: "Basse" }
  ];

  public addComment = async (content: string) => {
    let comment = new Comment({userId: SessionService.getUserId(), content, ticketId: this.props.ticket.id})
    let newComment = await projectBloc.addComment(comment);
    newComment.user = SessionService.user;
    this.props.ticket.commentable.models.push(newComment)
    this.setState({})
  }

  public render() {
    const { ticket } = this.props;
    return (
      <div className="ticket-show">
        { ticket.deadline && <div className={"mb-3 deadline-ticket" + (ticket.isLate ? " late" : "")}>
          { !ticket.isLate ? <div>
            <TextIcon className="darkgray fw-bold" leftIcon={["fal", "alarm-clock"]}>DeadLine</TextIcon>
            A effectuer avant le <Moment format={"DD MMMM - hh:mm"}>{ticket.deadline}</Moment>
          </div> : <div className="text-danger row-flex">
            <FontAwesomeIcon className="mr-2" icon={["fal", "alarm-exclamation"]}/>
            <span className="mr-1">En retard </span><Moment locale="fr-short" fromNow>{ticket.deadline}</Moment>
          </div>}
        </div>}
        <div className="row-between">
          <SmartSelect name="priority" autoSave onModelUpdated={() => projectBloc.loadProject()} label="Priorité" model={ticket} floating options={this.priorityOption} />
          <SmartSelect name="columnId" autoSave onModelUpdated={() => projectBloc.loadProject()} label="Statut" model={ticket} floating options={projectBloc.state.currentProject.columns} />
        </div>
        {/* <div className="mb-3">
          <EditableText model={ticket} name="title" light />
        </div> */}
        <b className="darkgray">Description</b>
        <div className="ticket-description mb-3">
          <EditableText type="textarea" nullText="Aucune description" onValidate={(text) => projectBloc.updateDescription(ticket, text)} model={ticket} name="description" />
        </div>
        <CheckList ticket={ticket} />
        { ticket.attachable.models.length > 0 && <div className="mb-3">
            Pièces jointes
          </div>}
          <div>
            { ticket.attachable.models.map(a => <AttachmentComponent key={a.id} attachment={a} />)}
          </div>
        <div className="row-between">
          <div className="">
            <div className="fw-bold mb-2 darkgray">Rapporteur</div>
            <AvatarName user={ticket.creator} />
          </div>
          <div className="">
            <div className="fw-bold mb-2 darkgray">Responsable</div>
            <AvatarName user={ticket.user} />
          </div>
        </div>
        <CommentsComponent withTitle onComment={() => projectBloc.loadProject()}  commentable={ticket.commentable} />
      </div>
    );
  }
}
