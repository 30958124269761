/**
  * <Toolbar />
  */

import React from 'react';
import { instantiate } from '../../../core/FormBuilderCore';
import FormElement from '../../../models/form/FormElement';
import DatePicker from '../../../models/form/formElements/DatePicker';
import FileSelect from '../../../models/form/formElements/FileSelect';
import Header from '../../../models/form/formElements/Header';
import HyperLink from '../../../models/form/formElements/HyperLink';
import Paragraph from '../../../models/form/formElements/Paragraph';
import Select from '../../../models/form/formElements/Select';
import Switch from '../../../models/form/formElements/Switch';
import TextArea from '../../../models/form/formElements/TextArea';
import TextInput from '../../../models/form/formElements/TextInput';
import store from './stores/store';
import ToolbarItem from './toolbar-draggable-item';
import ID from './UUID';

export interface IToolbarState {
  elementTypes: FormElement[]
  store: any
}

export default class Toolbar extends React.Component<any, IToolbarState> {
  constructor(props) {
    super(props);

    this.state = {
      elementTypes: [
        new Header({}),
        new Paragraph({}),
        new HyperLink({}),
        // new Image({}),
        new TextInput({}),
        new TextArea({}),
        new Switch({}),
        new DatePicker({}),
        new Select({}),
        // new Tags({}),
        new FileSelect({}),
      ],
      store: null
    };
    store.subscribe(state => this.setState({ store: state }));
    this.create = this.create.bind(this);
  }

  create(item: FormElement) {

    let element = instantiate(item.typeKey, {
      ...item,
      id: ID.uuid(),
    })
    element.setDefaultValue();

    return element;
  }

  _onClick(item) {
    store.dispatch('create', this.create(item));
  }

  render() {
    if (!this.state.elementTypes) return <></>
    return (
      <div className="react-form-builder-toolbar">
        <div>
          {
            this.state.elementTypes.map((item) => (
              <ToolbarItem data={ item } key={ item.key } onClick={ this._onClick.bind(this, item) } onCreate={ this.create } />
            ))
          }
        </div>
      </div>
    );
  }
}
