import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import TextIcon from '../components/common/TextIcon';
import SmartInputText from '../components/input/SmartInputText';
import AuthPage from '../components/layout/AuthPage';
import UserManager from '../managers/UserManager';
import ToastrService from '../services/ToastrService';

export interface IForgotPasswordProps {
}

export interface IForgotPasswordState {
  email: string
  successPage: boolean
}

export default class ForgotPassword extends React.Component<IForgotPasswordProps, IForgotPasswordState> {
  constructor(props: IForgotPasswordProps) {
    super(props);

    this.state = {
      email: "",
      successPage: false
    }
  }

  resetPassword = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    try {
      await new UserManager().forgotPassword(email);
      this.setState({successPage: true})
    } catch (error) {
      ToastrService.toaster.show("Urioz", "Une erreur est survenue.", "danger")
    }
  }

  public render() {
    const { email, successPage } = this.state;
    return (
      <AuthPage>
        <div className="auth-card-container">
          <div className="auth-card">
            { successPage ?
              <div className="auth-card-body">
                <h4 className="text-center mb-4">
                  <TextIcon>Le mot de passe provisoire a été envoyé à votre adresse email {email} </TextIcon>
                </h4>
                <div className="row-center">
                  <NavLink className="btn btn-primary" to="/login">Se connecter</NavLink>
                </div>
              </div>
              : <>
                <div className="auth-card-header row-center mb-2">
                  <h4> Mot de passe oublié ?</h4>
                </div>
                <div className="text-center text-primary mb-4">
                  Vous recevrez votre mot de passe provisoire par email.
                </div>
                <div className="auth-card-body">
                  <Form onSubmit={this.resetPassword}>
                    <SmartInputText required label="Email" name="email" type="email" onChange={(e, email) => this.setState({email})} value={email} floating />
                    <div className="row-center">
                      <Button size='lg' type="submit" color="primary">Valider</Button>
                    </div>
                  </Form>
                </div>
              </>
            }
          </div>
        </div>
      </AuthPage>
    );
  }
}
