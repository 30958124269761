import * as React from 'react';
import { appBloc } from '../../bloc/AppBloc';
import { projectBloc } from '../../bloc/ProjectBloc';
import Project from '../../models/Project';
import RightService from '../../services/RightService';
import IconButton from '../buttons/IconButton';
import EditableText from '../common/EditableText';
import TextIcon from '../common/TextIcon';
import AvatarList from './AvatarList';

export interface IProjectShowProps {
  project: Project
}

export interface IProjectShowState {
}

export default class ProjectShow extends React.Component<IProjectShowProps, IProjectShowState> {
  constructor(props: IProjectShowProps) {
    super(props);

    this.state = {
    }
  }

  onDelete = async (project) => {
    await project.delete();
    await projectBloc.loadProjects();
    appBloc.push("/projects");
  }

  public render() {
    const { project } = this.props;
    return (
      <div className="p-2">
        <div className="section-title mb-3 row-between">
          <EditableText disabled={!RightService.can("projects")} model={project} name="name" />
          <IconButton onClick={() => this.onDelete(project)} light iconData={["fal", "trash"]} />
            {/* <IconButton iconStyle={{fontSize: "1.5rem"}} light iconData={["fal", "ellipsis-v"]} /> */}
        </div>
        <div className="mb-2">
          <label htmlFor="">Description</label>
          <EditableText disabled={!RightService.can("projects")} type="textarea" model={project} name="description" nullText="Aucune description" />
        </div>
        {/* <div className="text-small">
          { project.description }
        </div> */}
        { project.isPublic ? <div className="py-2">
            <TextIcon leftIcon={["fal", "globe"]}>Public</TextIcon>
          </div> : <div>
          <div>Membres</div>
          <AvatarList users={project.users} />
        </div>}
      </div>
    );
  }
}
