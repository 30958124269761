import SessionService from "../services/SessionService";
import EventType from './Option';
import Model from "./Model";
import User from "./User";
import Alert from './Alert';
import Option from "./Option";

export default class Event extends Model {

  public name: string;
  public description: string;
  public place: string;
  public type: EventType;
  public typeId: number;
  public startAt: Date;
  public status: string;
  public endAt: Date;
  public agendaId: number | string;
  public creator: User;
  public allDay: boolean;
  public fromGoogleAgenda: boolean;
  public createdBy: number;
  public googleAgendaId: string;
  public alerts: Alert[];

  public requiredField = ["name"]
  static modelUrl = "events";
  public modelName = "event";

  public alertTitle: string = "Supprimer cet évènement ?";
  public confirmMessage: string = "Vous allez supprimer cet évènement définitivement.";

  constructor(json: any) {
    super(json)
    this.name              = json.name;
    this.description       = json.description;
    this.place             = json.place;
    this.status            = json.status;
    this.type              = json.type || new Option({color: "rgb(56 58 69)", name: "Non définis"});
    this.typeId            = json.typeId;
    this.startAt           = new Date(json.startAt);
    this.endAt             = new Date(json.endAt);
    this.createdBy         = json.createdBy;
    this.agendaId          = json.agendaId;
    this.allDay            = json.allDay;
    this.googleAgendaId    = json.googleAgendaId;
    this.fromGoogleAgenda  = json.fromGoogleAgenda;
    this.creator           = json.creator && new User(json.creator);
    this.alerts            = this.mapArrayModel(Alert, json.alerts) || [];
  }

  fromMe() {
    this.createdBy = SessionService.getUserId();
    return this;
  }

  isFromMe() {
    return this.createdBy === SessionService.getUserId();
  }

  isHoliday() {
    return this.typeId === 4
  }


  getFrontUrl() {
    return "/events?show=" + this.id
  }

  toMapApi() {
    const {id, name, description, startAt, endAt, createdBy, typeId, place, googleAgendaId, agendaId, allDay} = this;
    return {id, name, description, startAt, endAt, createdBy, typeId, place, googleAgendaId, agendaId, allDay}
  }

}
