import { IconName } from '@fortawesome/fontawesome-svg-core';
import FormElement from '../FormElement';
import { FormElementType } from '../FormElement';

export default class TextInput extends FormElement {

  public humanName = "Champ de texte"
  public icon: IconName = "keyboard"
  public typeKey = FormElementType.TextInput;
  public key = "TextInput"

  public isSubmittable = true;
  public isField = true;

  setDefaultValue() {
    super.setDefaultValue();
    this.label = "Label"
  }

}
