import DocumentsService from './DocumentsService';
import FileModel from '../models/File';
import SessionService from './SessionService';
import EntityManager from '../managers/EntityManager';
import StorageService from './StorageService';

export default class LocalDocumentsService extends DocumentsService {

  public baseUrl = "/documents/local";

  async createFolder(currentFile: FileModel, name: string) {
    let folder = new FileModel({
      name,
      refName: name,
      parentId: currentFile.id,
      createdBy: SessionService.getUserId()
    });
    await folder.create();
  }

  async upload(file: File, currentFile: FileModel, progressIndicator?) {
    console.log(currentFile)
    let newFileModel = new FileModel({ parentId: currentFile?.id }).fillFromFile(file);
    console.log(newFileModel)
    let url = await StorageService.uploadFile("data", file, progressIndicator);
    newFileModel.attachment.url = url;
    await newFileModel.create();
  }

  async load(folderId: string) {
    return await EntityManager.get<FileModel>(FileModel, folderId);
  }

  async download(fileModel?: FileModel) {
    await StorageService.downloadFile(fileModel.attachment);
  }

  getFileUrl(file) {
    if (file.id === 1) return this.baseUrl;
    return `${this.baseUrl}/${file.id}`;
  }

  getParentFileUrl(file) {
    if (file.parentId === 1) return this.baseUrl;
    return `${this.baseUrl}/${file.parentId}`;
  }

}
