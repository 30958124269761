import * as React from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

export interface ISmallDropZoneProps {
  onDrop: (file: File) => void
}

export interface ISmallDropZoneState {
}


export default class SmallDropZone extends React.Component<ISmallDropZoneProps, ISmallDropZoneState> {
  constructor(props: ISmallDropZoneProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <Dropzone
        onDrop={(files) => this.props.onDrop(files[0])}>
        {({getRootProps, getInputProps, isDragActive}) => (
          <div {...getRootProps()} className={classNames({"dropzone": true, "drag-active": isDragActive})}>
            <input {...getInputProps()} />
            <div>
              Glisser/Déposer vos fichier ici ou <span className="text-primary pointer">cliquez</span> pour importer
            </div>
          </div>
        )}
      </Dropzone>
    );
  }
}