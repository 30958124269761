import Model from "./Model";
import OptionType from './OptionType';

export default class Option extends Model {

  public name: string;
  public color: string;
  public style: string;
  public optionTypeId: number;
  public deletable: boolean;
  public optionType: OptionType;
  static modelUrl = "options";

  constructor(json: any) {
    super(json)
    this.name         = json.name;
    this.color        = json.color;
    this.deletable    = json.deletable;
    this.optionType   = json.optionType && new OptionType(json.optionType);
    this.optionTypeId = json.optionTypeId;
    this.style        = json.style;
  }

}
