import { isArray } from 'lodash';
import Model from './Model';
import User from './User';
import SessionService from '../services/SessionService';
import { appBloc } from '../bloc/AppBloc';

export default class Visio extends Model {

  public users: User[];
  public caller: User;
  public callerId: number;
  public roomId: number;
  public isActive = false;
  public status: string;
  public token: string;
  static modelUrl = "visios";

  constructor(json?) {
    super(json)
    this.users    = json.users;
    this.token    = json.token;
    this.caller   = json.caller;
    this.callerId = json.callerId;
    this.roomId   = json.roomId;
    this.status   = json.status;
    this.users    = this.mapArrayModel(User, json.users) || [];
  }

  active() {
    this.isActive = true;
  }

  public fromMe() {
    this.caller = SessionService.user;
    this.callerId = this.caller.id;
    return this;
  }

  getVisioRef(): string {
    return appBloc.getAppId() + "/" + this.id;
  }

  public add(user: User | User[]) {
    if (isArray(user)) this.users.concat(user);
    else                         this.users.push(user);
  }
}