import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';
import { FirebaseStorage, Reference, UploadTask, UploadTaskSnapshot } from '@firebase/storage-types';
import Attachment from '../models/Attachment';

const firebaseConfig = {
  apiKey: "AIzaSyDdg71N9IcyVqWkpo4BZcbfewUTV4CME-k",
  authDomain: "uriozintranet.firebaseapp.com",
  databaseURL: "https://uriozintranet.firebaseio.com",
  projectId: "uriozintranet",
  storageBucket: "uriozintranet.appspot.com",
  messagingSenderId: "1020154323887",
  appId: "1:1020154323887:web:6b087c08b05906c814c55a",
};

class StorageService {

  public storage: FirebaseStorage;
  public app;
  private runningTask: UploadTask;
  public token: any = "ya29.a0AfH6SMCKa1r9Z-5HAjVVif5HQAZJ8egZs3cKJnkMMILaOc4TKcVDiyEa_3Jk-jXeHeg6tWhjAQKJ9CzcoQNBr_3cvQ9bIQn5Wdc47CDTTAha_yxdOOpGl2zjoJFwzvVW7vhQkHMMJPj89kaGNFqsVDuWY3iGJxWcSWI"

  constructor() {
    this.app = firebase.initializeApp(firebaseConfig);
    this.storage = firebase.storage();
  }

  // Upload file and return file path
  public uploadFile = async (folder: string, file: File, progressCallback?): Promise<string> => {
    let storageRef: Reference = this.storage.ref().child(`apps/${folder}/${file.name}`);
    let task: UploadTask = storageRef.put(file);
    this.runningTask = task;
    if (progressCallback) task.on(firebase.storage.TaskEvent.STATE_CHANGED, progressCallback)
    let response: UploadTaskSnapshot = await task;
    return response.ref.getDownloadURL();
  }

  public cancelUpload = () => {
    this.runningTask.cancel();
  }

  public getDownloadUrl = async (filename: string) => {
    // this.storage.app.auth().signInWithCustomToken()
    let storageRef: Reference = this.storage.ref().child(`data/${filename}`);
    return storageRef.getDownloadURL();
  }

  public downloadFile = async (attachment: Attachment) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      var blob = xhr.response;
      this.downloadBlob(blob, attachment.refName)
    };
    xhr.open('GET', attachment.url);
    xhr.send();
  }

  downloadBlob(blob, name) {
    const blobUrl = URL.createObjectURL(blob);

    const link = document.createElement("a");

    link.href = blobUrl;
    link.download = name;

    document.body.appendChild(link);

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
    document.body.removeChild(link);
  }

  public getTypeOfFile(file: File) {
    return file.type.split("/")[0];
  }

}

export default new StorageService();
