import * as React from 'react';
import File from '../../models/File';
import DriveLight from './DriveLight';

export interface IMovingFrameProps {
  movingFile: File
  onSubmit: (e: any) => void
}

export interface IMovingFrameState {
}

export default class MovingFrame extends React.Component<IMovingFrameProps, IMovingFrameState> {
  constructor(props: IMovingFrameProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className="move-frame">
        <DriveLight onSubmit={this.props.onSubmit} movingFile={this.props.movingFile} />
      </div>
    );
  }
}
