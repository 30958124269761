import { EventEmitter } from 'events';
import queryString from 'query-string';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import EntityManager from '../managers/EntityManager';
import NotificationManager from '../managers/NotificationManager';
import RoomManager from '../managers/RoomManager';
import Application from '../models/Application';
import New from '../models/New';
import OptionType from '../models/OptionType';
import RightType from '../models/RightType';
import Role from '../models/Role';
import ApiService from '../services/ApiService';
import authService from '../services/AuthService';
import OptionService from '../services/OptionService';
import RightService from '../services/RightService';
import RoomService from '../services/RoomService';
import SocketService from '../services/SocketService';


export interface IAppBlocState {
  minimize: boolean
  initialized: boolean
  news: New[]
  darkmode: boolean
  modernmode: boolean
  application: Application
  roles: Role[]
}

export const Context = React.createContext({})

export let appBloc: AppBloc

class AppBloc extends React.Component<RouteComponentProps, IAppBlocState> {

  public emitter = new EventEmitter();
  public routeListener;
  public pathname;
  public search;
  public application;
  public notificationManager = new NotificationManager()

  constructor(props: RouteComponentProps) {
    super(props)
    appBloc = this

    this.state = {
      minimize: false,
      initialized: false,
      news: [],
      application: null,
      darkmode: localStorage.getItem("darkmode") === "1",
      modernmode: localStorage.getItem("modernmode") === "1",
      roles: [],
    }

    document.body.addEventListener("click", (e) => this.clickBody(e));

    this.subscribeRoute(props);

  }

  getDomain() {
    return this.state?.application?.name;
  }

  subscribeRoute(props) {
    this.setRouteData(props.location)
    this.routeListener = props.history.listen((l) => {
      this.setRouteData(l)
      this.emitter.emit("urlChange", {pathname: this.pathname, search: this.search})
    })
  }

  onUrlChange(callback) {
    this.emitter.on("urlChange", callback);
  }

  setRouteData(location) {
    this.pathname = location.pathname;
    this.search = queryString.parse(location.search);
  }

  loadApplication = async () => {
    try {
      let data: any = await authService.getData();
      await OptionService.loadOptions();
      let { application, roles, rightTypes, optionTypes, news, rooms } = data;
      application = new Application(EntityManager.toCamelCase(application));
      RoomService.rooms = rooms.map(r => new RoomManager().getEntity(EntityManager.toCamelCase(r)));
      RightService.rightTypes = rightTypes.map(e => new RightType(EntityManager.toCamelCase(e)));
      OptionService.optionTypes = optionTypes.map(e => new OptionType(EntityManager.toCamelCase(e)));
      roles = roles.map(e => new Role(EntityManager.toCamelCase(e)));
      news = news.map(e => new New(EntityManager.toCamelCase(e)));
      this.setState({ application, roles, news });
    } catch (error) {}
  }

  loadRoles = async () => {
    const roles = (await EntityManager.all<Role>(Role)).models;
    this.setState({roles})
  }

  loadNews = async () => {
    const news = (await EntityManager.all<New>(New)).models;
    this.setState({news})
  }

  initApp = async () => {
    await this.loadApplication();
    SocketService.initSocket();
    this.setState({
      initialized: true
    });
  }

  public toggleMinimize = () => {
    this.setState({minimize: !this.state.minimize})
  }

  public clickBody(e) {
    this.emitter.emit("bodyClick", e);
  }

  public onClickBody(callBack) {
    this.emitter.on("bodyClick", callBack);
  }

  public cancelClickBody(callBack) {
    this.emitter.off("bodyClick", callBack);
  }

  async updateAppConfig(param) {
    await ApiService.put('app', param);
  }

  async getAppConfig() {
    await ApiService.get('app');
  }

  getAppId() {
    return this.state.application.id;
  }

  toggleDarkMode() {
    this.setState({darkmode: !this.state.darkmode}, () => localStorage.setItem("darkmode", this.state.darkmode ? "1" : "0"));
  }

  toggleModernMode() {
    this.setState({modernmode: !this.state.modernmode}, () => localStorage.setItem("modernmode", this.state.modernmode ? "1" : "0"));
  }

  addSearchParam(prop: Object) {
    let search = this.search;
    Object.assign(search, prop)
    appBloc.push({search: queryString.stringify(search)});
  }

  removeSearchParam(prop: string) {
    let search = this.search;
    delete search[prop];
    appBloc.push({search: queryString.stringify(search)});
  }

  push(pushparam) {
    this.props.history.push(pushparam)
  }

  pop() {
    this.props.history.goBack();
  }

  componentWillUnmount() {
    this.routeListener()
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default withRouter(AppBloc);

export function consumeAppBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
