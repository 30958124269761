import EntityManager from "../managers/EntityManager";
import Option from "../models/Option";
import OptionType from "../models/OptionType";

class OptionService {

  public optionTypes: OptionType[]
  public options: Option[]

  getOptionsByType = (type: string) => {
    return this.options.filter((o) => o.optionType.name === type);
  }

  getOptionTypeByName(type: string) {
    return this.optionTypes.find(ot => ot.name === type);
  }

  loadOptions = async () => {
    this.options = (await EntityManager.all<Option>(Option)).models;
  }

}

export default new OptionService()