import * as React from 'react';
import Moment from 'react-moment';

export interface IChatDateLabelProps {
  date: Date
}

export interface IChatDateLabelState {
}

export default class ChatDateLabel extends React.Component<IChatDateLabelProps, IChatDateLabelState> {

  public render() {
    return (
      <div className="chat-date-label">
        <Moment locale="fr" fromNow >{this.props.date}</Moment>
      </div>
    );
  }
}
