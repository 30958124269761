import * as React from 'react';
import { NavLink } from 'react-router-dom';
import MessageNotification from '../../models/notif/MessageNotification';
import Notif from './Notif';

export interface INewMessageNotifProps {
  notification: MessageNotification
}

export interface INewMessageNotifState {
}

class NewMessageNotif extends React.Component<INewMessageNotifProps, INewMessageNotifState> {
  constructor(props: INewMessageNotifProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { notification } = this.props;
    return (
      <div className="notif-text">
        <div>
          <span>Nouveau </span>
          {/* <NavLink className="fw-bold" to={notification.event.getFrontUrl()}>nouvel évènement</NavLink> */}
          <span className="fw-bold">message</span>
          <span> de </span>
          <NavLink className="fw-bold" to={notification.notifier.getFrontUrl()}>{notification.notifier.getFullName()}</NavLink>
        </div>
        {/* <div>
          <div className="message-show-content mb-2">
            <div style={{whiteSpace: "nowrap"}}>
              {notification.staticMessage.content}
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Notif(NewMessageNotif)