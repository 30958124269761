import * as React from 'react';
import User from '../../models/User';
import AvatarName from '../common/AvatarName';
import MemberDropdown from './MemberDropdown';

export interface IMemberItemProps {
  user: User
}

export interface IMemberItemState {
}

export default class MemberItem extends React.Component<IMemberItemProps, IMemberItemState> {

  constructor(props: IMemberItemProps) {
    super(props);

    this.state = {
    }
  }


  public render() {
    const { user } = this.props;
    return (
      <div className="member-item row-between">
        <AvatarName link user={user} />
        <MemberDropdown
          user={user}
        />
      </div>
    );
  }
}
