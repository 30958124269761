import * as React from 'react';

export interface IAdminViewProps {
  title: string
}

export interface IAdminViewState {
}

export default class AdminView extends React.Component<IAdminViewProps, IAdminViewState> {
  constructor(props: IAdminViewProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
        <div className="row-between mb-2">
          <div className="section-title">{this.props.title}</div>
        </div>
        <div className="divider mx-0 mb-3"></div>
        {this.props.children}
      </div>
    );
  }
}
