import Room from "./Room";


export default class CustomRoom extends Room {

  public type = "custom";
  public connectedTotal: number;

  constructor(json: any) {
    super(json)
    this.connectedTotal = json.connectedTotal;
  }

  getName(): string {
    return this.name || "";
  }

  getChildId(): number {
    return this?.id;
  }

  // getChild(): Group {
  //   return this.group;
  // }

  getAvatar(): string {
    return null;
    // return this.group?.pictureUrl || "";
  }

  getFrontUrl(): string {
    return `/messages/custom/${this.name.replace(new RegExp(" ", "g"), ".").toLowerCase()}.${this.id}`;
  }

  getState(): string {
    if (!this.connectedTotal || this.connectedTotal === 0) return "Inactif"
    return `${this.connectedTotal} connectés`
  }

  hasConnected() {
    return this.connectedTotal && this.connectedTotal > 0;
  }

}
