import * as React from 'react';
import { Toast, ToastHeader, ToastBody } from 'reactstrap';
import ToastrService from '../../services/ToastrService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IToasterProps {
}

export interface IToasterState {
  title: string
  type: string
  content: string
  show: boolean
}

export default class Toaster extends React.Component<IToasterProps, IToasterState> {
  constructor(props: IToasterProps) {
    super(props);

    this.state = {
      title: "",
      content: "",
      show: false,
      type: ""
    }

    ToastrService.setToaster(this);
  }

  getIcon(type) {
    switch (type) {
      case "success":
        return "check"
      case "danger":
        return "times"
      case "warning":
        return "exclamation-triangle"
      default: return "circle"
    }
  }

  getTextStyle(type) {
    switch (type) {
      case "success": return "text-success light-bg-success"
      case "danger": return "text-danger light-bg-danger"
      case "warning": return "text-warning light-bg-warning"
      default:
        break;
    }
  }

  show(title: string, content: string, type: string = "success") {
    this.setState({title, content, type, show: true});
    setTimeout(() => this.setState({show: false}), 6000);
  }

  public render() {
    const { title, content, show, type } = this.state;
    return (
      <Toast onClick={() => this.setState({show: false})} isOpen={show}>
        <ToastHeader className={this.getTextStyle(type)}>
          <div className={"row-flex"}>
            <FontAwesomeIcon icon={["fal", this.getIcon(type)]} />
            <div className="ml-3">
              {title}
            </div>
          </div>
        </ToastHeader>
        <ToastBody>
          {content}
        </ToastBody>
      </Toast>
    );
  }
}
