import * as React from 'react';
import { NavLink } from 'react-router-dom';
import EventNotification from '../../models/notif/EventNotification';
import Notif from './Notif';

export interface INewEventNotifProps {
  notification: EventNotification
}

export interface INewEventNotifState {
}

class NewEventNotif extends React.Component<INewEventNotifProps, INewEventNotifState> {
  constructor(props: INewEventNotifProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { notification } = this.props;
    let notifier = notification.notifier;
    return (
      <div className="notif-text">
        <span>Un </span>
        {/* <NavLink className="fw-bold" to={notification.event.getFrontUrl()}>nouvel évènement</NavLink> */}
        <span className="fw-bold"> évènement</span>
        <span> vous concernant a été ajouté à votre agenda par </span>
        <NavLink className="fw-bold" to={notifier.getFrontUrl()}>{notifier.getFullName()}</NavLink>
      </div>
    );
  }
}

export default Notif(NewEventNotif)