import * as React from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import SmartInput from './SmartInput';
import classNames from 'classnames';
import { ISmartInputProps } from './SmartInput';

export default class SmartInputTextarea extends SmartInput<ISmartInputProps, {}> {

  public render() {
    const {
      name,
      label,
      floating,
      model,
      rows,
      className,
      placeholder,
      style,
      autoFocus,
      disable,
      id,
      required
    } = this.props;
    return (
      <>
        { (label && !floating) && <label htmlFor={ id || name }>{ label }</label> }
        <TextareaAutosize
          id={ id }
          onKeyDown={ (e) => { if (e.key === "Enter" && this.props.onEnter) this.props.onEnter() } }
          disabled={ disable }
          required={ model?.isRequired(name) || required }
          style={ style }
          className={classNames({"form-control": true, [className]: !!className})}
          rows={ rows }
          onChange={ this.handleChange }
          name={ name }
          defaultValue={ this.getValue() }
          placeholder={ placeholder || label }
          autoFocus={ autoFocus }
        />
        { (label && floating) && <label className="floating-label" htmlFor={ id || name }>{ label }</label> }
      </>
    );
  }
}
