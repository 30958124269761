import * as React from 'react';
import FluxRss from './FluxRss';
import RunningVisio from './RunningVisio';
import Visio from '../../models/Visio';
import { consumeAppBloc, IAppBlocState } from '../../bloc/AppBloc';

export interface IHeadBandProps {
  visios: Visio[]
}

export interface IHeadBandState {
}

class HeadBand extends React.Component<IHeadBandProps & IAppBlocState, IHeadBandState> {
  constructor(props: IHeadBandProps & IAppBlocState) {
    super(props);

    this.state = {
    }
  }

  getStyle = (): React.CSSProperties => {
    return {
      position: "relative",
      backgroundImage: `url(${this.props.application.bannerUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      width: "100%",
      height: "400px",
    };
  }

  public render() {
    return (
      <div style={this.getStyle()}>
        {this.props.visios.length > 0 && <RunningVisio visios={this.props.visios} />}
        <FluxRss/>
      </div>
    );
  }
}

export default consumeAppBloc(HeadBand)
