import Model from "./Model";

export default class RightType extends Model {

  public name: string;
  public type: string;
  public label: string;
  static modelUrl = "rightTypes";

  constructor(json: any) {
    super(json)
    this.name   = json.name;
    this.type   = json.type;
    this.label  = json.label;
  }

}
