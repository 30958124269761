import * as React from 'react';
import Attachment from '../../models/Attachment';
import IconButton from '../buttons/IconButton';
import StorageService from '../../services/StorageService';

export interface IAttachmentComponentProps {
  attachment: Attachment
}

export interface IAttachmentComponentState {
}

export default class AttachmentComponent extends React.Component<IAttachmentComponentProps, IAttachmentComponentState> {
  constructor(props: IAttachmentComponentProps) {
    super(props);

    this.state = {
    }
  }

  onDownload = () => {
    StorageService.downloadFile(this.props.attachment);
  }

  getStyle = () => {
    const { attachment } = this.props;
    if (attachment.isImage()) return {
      backgroundImage: `url(${attachment.url})`,
      backgroundPosition: "center",
      backgroundSize: "cover"
    }
    return {}
  }

  public render() {
    const { attachment } = this.props;
    return (
      <div className="attachment" style={this.getStyle()}>
        { !attachment.isImage() && <div className="mb-3">
          {attachment.name}
        </div>}
        <div className="row-end">
          <IconButton onClick={() => this.onDownload()} iconData={["fal", "cloud-download-alt"]}/>
        </div>
      </div>
    );
  }
}
