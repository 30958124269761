import * as React from 'react';
import { Button } from 'reactstrap';
import TextIcon from '../common/TextIcon';

export interface IAlertDialogProps {
  title: string
  message: string
  buttonText: string
  onConfirm: () => void
  onCancel: () => void
}

export interface IAlertDialogState {
}

export default class AlertDialog extends React.Component<IAlertDialogProps, IAlertDialogState> {
  constructor(props: IAlertDialogProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { title, message, onConfirm, onCancel, buttonText } = this.props;
    return (
      <div className="alert-dialog">
        <div className="alert-dialog-header">
          <TextIcon iconClassName="text-danger" leftIcon={["fas", "exclamation-circle"]}>{title}</TextIcon>
        </div>
        <div className="alert-dialog-body">
          {message}
        </div>
        <div className="alert-dialog-footer">
          <Button className="mr-3" onClick={() => onCancel()} color="light">Annuler</Button>
          <Button onClick={() => onConfirm()} color="danger">{buttonText}</Button>
        </div>
      </div>
    );
  }
}
