import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { appBloc } from '../../bloc/AppBloc';
import EntityManager from '../../managers/EntityManager';
import Role from '../../models/Role';
import DOMService from '../../services/DOMService';
import RightService from '../../services/RightService';
import ToastrService from '../../services/ToastrService';
import TextIcon from '../common/TextIcon';
import SmartSwitch from '../input/SmartSwitch';
import RoleModal from '../modal/RoleModal';
import AdminView from './AdminView';
import CrudAdmin from './CrudAdmin';

export interface IRoleAdminProps {
}

export interface IRoleAdminState {
  selectedRole: Role
}

export default class RoleAdmin extends React.Component<IRoleAdminProps, IRoleAdminState> {

  public newButtonText = "Ajouter un rôle";

  constructor(props: IRoleAdminProps) {
    super(props);

    this.state = {
      selectedRole: null
    }
  }

  selectRole = (role: Role) => {
    this.setState({selectedRole: role})
  }

  handleChangeRight = (rt) => {
    this.state.selectedRole.updateRightIds(rt)
    this.setState({})
  }

  updateAll = async () => {
    await this.state.selectedRole.update()
    ToastrService.toaster.show("Urioz", "Les modifications ont été enregistré.")
  }

  render() {
    const { selectedRole } = this.state;
    return <AdminView title="Gestion des rôles">
        <CrudAdmin<Role>
        em={new EntityManager(Role)}
        afterAction={ (action, model) => appBloc.loadRoles() }
        render={(models, create, updateAll, onDelete) => (
          <div>
            <div className="mb-3 header-crud row-between">
              <div className="row-flex">
                <Button color="success" onClick={() => DOMService.modal(<RoleModal submit={create} role={new Role({})} />)} >{this.newButtonText}</Button>
                <Button className="ml-2" color="primary" onClick={() => this.updateAll()} >Valider les modifications</Button>
              </div>
            </div>
            <Row>
              <Col xs="12" md="6">
                { models?.map(role => (
                  <div key={"role" + role.id} onClick={() => this.selectRole(role)} className={classNames({"row-between p-2 selectable-row": true, "active": role.id === selectedRole?.id})}>
                    <div style={{textTransform: "capitalize"}}>
                      <TextIcon leftIcon={["fal", "user"]}>{role.name}</TextIcon>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={["fal", "chevron-right"]} />
                    </div>
                  </div>
                ))}
              </Col>
              <Col xs="12" md="6">
                { selectedRole && <div>
                  <div className="section-title mb-3">
                    Droits de <span className="capitalize">{selectedRole.name}</span>
                  </div>
                  <div className="">
                    { selectedRole.name === "administrateur"
                    ? <div className="fw-600 text-success">Ce rôle dispose de tout les droits !</div>
                    : RightService.rightTypes.map(rt => (
                      <div key={"rt" + rt.id} className="p-2">
                        <SmartSwitch
                          primary
                          label={rt.label}
                          name={rt.name}
                          onChange={() => this.handleChangeRight(rt)}
                          checked={selectedRole.can(rt.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>}
              </Col>
            </Row>
          </div>
        )}
      />
    </AdminView>
  }


}
