import * as React from 'react';
import Comment from '../../models/Comment';
import CommentComponent from './commentComponent';

export interface ICommentsListProps {
  comments: Comment[];
  canReply?: boolean;
}

export interface ICommentsListState {
}

export default class CommentsList extends React.Component<ICommentsListProps, ICommentsListState> {

  public render() {
    const { comments } = this.props;
    if (comments.length === 0) return <></>
    return (
      <div className="comments-list">
        {comments.map((comment) => <CommentComponent key={comment.id} comment={comment} />)}
      </div>
    );
  }
}
