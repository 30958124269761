import Model from "./Model";
import RightType from "./RightType";

export default class Role extends Model {

  public name: string;
  public deletable: boolean;
  public rights: RightType[];
  public rightTypeIds: number[];
  static modelUrl = "roles";

  public requiredField = ["name"]

  constructor(json: any) {
    super(json);
    this.name = json.name;
    this.deletable = json.deletable;
    this.rights           = this.mapArrayModel(RightType, json.rights) || [];
    this.rightTypeIds     = json.rightTypeIds || json.rights?.map(r => r.id) || [];
  }

  can(id: number) {
    return this.rightTypeIds.includes(id);
  }

  updateRightIds(rightType: RightType) {
    if (this.rightTypeIds.includes(rightType.id)) this.rightTypeIds = this.rightTypeIds.filter(rti => rti !== rightType.id);
    else this.rightTypeIds.push(rightType.id)
  }

}
