import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import RoomManager from '../managers/RoomManager';
import Message from '../models/Message';
import Room from '../models/Room';
import User from '../models/User';
import RoomService from '../services/RoomService';
import SocketService from '../services/SocketService';
import StorageService from '../services/StorageService';
import humps from 'lodash-humps';
import OneOneRoom from '../models/OneOneRoom';


export interface IRoomBlocState {
  isLoading: boolean;
  isSending: boolean;
  roomsLoaded: boolean;
  roomInput: string;
  searchInput: string;
  matchedRooms: Room[];
  typingUsers: User[];
  activeRoom: Room;
  showFilePreview: boolean;
  showSearchInput: boolean;
  isSearching: boolean;
  showSecondarySidebar: boolean;
  showReplyPreview: boolean;
  previewUrl: string;
  unreadCount: number;
}

export const Context = React.createContext({})

export let roomBloc: RoomBloc

class RoomBloc extends React.Component<any, IRoomBlocState> {

  public file: File;
  public page = 1;
  public urlParam: any;
  public replyMessage: Message;
  public messageListRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  public inputRef: React.RefObject<HTMLInputElement>;
  public roomManager = new RoomManager()

  constructor(props: RouteComponentProps) {
    super(props)
    roomBloc = this

    this.state = {
      activeRoom: null,
      isSending: false,
      roomsLoaded: false,
      roomInput: "",
      searchInput: "",
      matchedRooms: [],
      isLoading: false,
      isSearching: false,
      showSearchInput: false,
      typingUsers: [],
      showFilePreview: false,
      showReplyPreview: false,
      showSecondarySidebar: false,
      previewUrl: null,
      unreadCount: 0
    }

    SocketService.on("newMessage", this.onNewMessage);
    SocketService.on("typing", this.onTypingOn);
    SocketService.on("untyping", this.onTypingOff);
    SocketService.on("read", this.refreshReadAt);
  }

  componentWillUnmount() {
    SocketService.off("newMessage", this.onNewMessage);
    SocketService.off("typing", this.onTypingOn);
    SocketService.off("untyping", this.onTypingOff);
  }

  async setUrlParam(param) {
    this.urlParam = param;
    await this.loadRoom(param)
    this.focusInput();
  }

  loadRoom = async (param) => {
    this.setState({isLoading: true})
    let room = await RoomService.loadRoom(param);
    // if (!room.isRead()) {
    //   await RoomService.updateReadAt(room.id);
    //   SocketService.get().notifyRoom("read", {roomCompositionId: room.findMyRoomComposition().id}, room.id)
    // }

    this.setState({activeRoom: room, isLoading: false, unreadCount: 0})
    this.focusInput();
  }

  // loadRooms = async () => {
  //   let rooms = await RoomService.getRooms();
  //   // let rooms = await SocketService.get().emitWhoIsConnected(roomService.rooms);
  //   this.setState({roomsLoaded: true});
  // }


  toggleSecondarySidebar = () => this.setState({showSecondarySidebar: !this.state.showSecondarySidebar});

  toggleShowSearchInput = () => this.setState({showSearchInput: !this.state.showSearchInput});

  handleSearchInput = async (e) => {
    let searchInput = e.target.value;
    roomBloc.setState({searchInput})
    if (searchInput.length > 0) {
      let matchedRooms = await RoomService.roomsManager.search(searchInput);
      this.setState({matchedRooms, isSearching: true})
      RoomService.roomsManager.from({roomId: this.state.activeRoom.id, messageId: 5})
    } else {
      this.setState({isSearching: false})
    }
  }

  setPreviewUrl = (previewUrl: string) => this.setState({previewUrl});

  focusInput = () => this.inputRef.current.focus();

  startReply = (message: Message) => {
    this.replyMessage = message;
    this.focusInput();
    this.setState({showReplyPreview: true})
  }

  closeReply = () => {
    this.replyMessage = null;
    this.focusInput();
    this.setState({showReplyPreview: false, showFilePreview: false})
  }

  closeFilePreview = () => {
    this.file = null;
    this.setState({showFilePreview: false, previewUrl: ""});
  }

  allIsLoaded = (): boolean => {
    return true;
  }

  scrollToBottom = () => {
    var element: any = document.querySelector('.msg-list')
    element?.lastChild?.scrollIntoView()
  }

  onLoadFile = (file: File) => {
    this.file = file;
    this.setState({showFilePreview: true});
    this.scrollToBottom()
  }

  clearInput = () => {
    this.setState({roomInput: ""})
  }

  handleChange = (roomInput) => {
    SocketService.get().type(this.state.activeRoom);
    this.setState({roomInput});
  }

  getActiveRoomCompo() {
    return this.state.activeRoom;
  }

  reset = () => {
    this.file = null;
    this.replyMessage = null;
    this.setState({
      roomInput: "",
      previewUrl: "",
      showFilePreview: false,
      showReplyPreview: false
    });
  }

  buildMessage = async () => {
    let message = new Message({
      content: this.state.roomInput,
      parentMessageId: this.replyMessage && this.replyMessage.id
    }).fromMe();
    if (this.file) {
      message.mediaUrl  = await StorageService.uploadFile("photo", this.file);
      message.mediaType = StorageService.getTypeOfFile(this.file);
    }
    // if (this.selectedGalleryItem) message.mediaUrl = this.selectedGalleryItem.url;
    return message;
  }

  findRoom(roomId: number) {
    return RoomService.rooms.find((r) => r.id === roomId);
  }

  findRoomIndex(roomId: number) {
    return RoomService.rooms.findIndex((r) => r.id === roomId);
  }

  refreshReadAt = (data) => {
    let rcId = data.roomCompositionId;
    let activeRoom = this.state.activeRoom;
    let index = activeRoom.roomCompositions.findIndex((rc) => rc.id === rcId);
    activeRoom.roomCompositions[index].readAt = new Date();
    this.setState({activeRoom})
  }

  updateRoom = async (newMessage: Message) => {
    let { activeRoom } = this.state;
    if (!activeRoom) return;
    if (activeRoom.isNew()) {
      await RoomService.loadRooms();
      SocketService.get().emitAuth();
      return this.loadRoom({roomId: newMessage.roomId})
    }
    const index = this.findRoomIndex(newMessage.roomId);

    if (this.isInSameRoom(newMessage.roomId)) {
      activeRoom.messages.push(newMessage);
      if (index === -1) {
        await RoomService.loadRooms();
        SocketService.get().emitAuth();
        this.setState({activeRoom})
      } else {
        RoomService.rooms[index].lastMessage = newMessage;
        this.setState({activeRoom})
      }
    } else {
      if (index === -1) {
        await RoomService.loadRooms();
        SocketService.get().emitAuth();
      } else {
        RoomService.rooms[index].lastMessage = newMessage;
        this.setState({})
      }
    }
  }

  sendMessage = async () => {
    const { roomInput, previewUrl, activeRoom } = this.state;
    if (roomInput === "" && !previewUrl) return;
    this.setState({isSending: true})

    SocketService.get().emitUntyping(activeRoom.id);

    const message = await this.buildMessage();
    const newMessage: Message = await RoomService.sendMessage(message, activeRoom);

    await this.updateRoom(newMessage);

    this.reset();
    this.focusInput();
    this.setState({isSending: false})
    this.emitNewMessage(newMessage, activeRoom)
  }

  public emitNewMessage = (newMessage, activeRoom) => {
    if (activeRoom instanceof OneOneRoom && activeRoom.isNew()) return SocketService.get().notify("newMessage", newMessage, [activeRoom.user.id]);
    return SocketService.get().notifyRoom("newMessage", newMessage, activeRoom.id);
  }

  public async createRoom(room: Room) {
    room.userIds = room.users.map(u => u.id);
    return await this.roomManager.create(room.toMap())
  }

  public isInSameRoom = (roomId: number): boolean => {
    return this.state.activeRoom && this.state.activeRoom.id === roomId;
  }

  onLiveRoomPage = () => {
    this.setState({activeRoom: null})
  }

  //** Socket Event */
  onNewMessage = async (data) => {
    let messageData = humps(data.message)
    let message: Message = new Message(messageData);
    if (message.isFromMe()) return;
    await this.updateRoom(message);
    if (!this.isInSameRoom(message.roomId)) this.setState({unreadCount: this.state.unreadCount + 1})
  }

  onTypingOn = (data) => {
    let user: User = new User(data.user);
    if (this.isInSameRoom(data.roomId)) this.setState({typingUsers: [...this.state.typingUsers, user]})
  }

  onTypingOff = (data) => {
    let user: User = new User(data.user);
    if (this.isInSameRoom(data.roomId)) this.setState({typingUsers: this.state.typingUsers.filter((typingUser, i) => typingUser.id !== user.id)})
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default RoomBloc;

export function consumeRoomBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
