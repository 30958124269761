
export default class Colors {


  static primary       = "#4a66cd"
  static success       = "#3bbf8f";
  static warning       = "#ffed4a"

  static PrimaryDark   = "#292c35";
  static SecondaryDark = "#343844";
  static StrongDark    = "#1a171c";
  static MediumDark    = "#252028";
  static LightDark     = "#2f323c";
  static DarkGray      = "#535767";
  static Gray          = "#737990";
  static MediumGray    = "#cbd1e8";
  static MidGray       = "#e2e5ec";
  static LightGray     = "#f8faff";
  static LighterGray   = "#f8f8f8";

}
