import * as React from 'react';
import { Col, Row } from 'reactstrap';
import TextIcon from '../components/common/TextIcon';
import FormList from '../components/formBuilder/FormList';
import FormShow from '../components/formBuilder/FormShow';
import SubmissionValueDisplayer from '../components/formBuilder/SubmissionValueDisplayer';
import Page from '../components/layout/Page';
import ProfileShow from '../components/members/ProfileShow';
import EntityManager from '../managers/EntityManager';
import UserManager from '../managers/UserManager';
import Form from '../models/form/Form';
import Submission from '../models/form/Submission';
import User from '../models/User';
import SessionService from '../services/SessionService';
import BasePage from './BasePage';

export interface IProfilePageProps {
  userId: string
}

export interface IProfilePageState {
  user: User
  forms: Form[]
  selectedForm: Form
  submissions: Submission[]
}

export default class ProfilePage extends BasePage<IProfilePageProps, IProfilePageState> {

  constructor(props: IProfilePageProps) {
    super(props);

    this.state = {
      user: null,
      forms: null,
      selectedForm: null,
      submissions: null
    }
  }

  async componentDidMount() {
    this.loadForms();
    this.loadSubmissions();
    let user = await new UserManager().show(parseInt(this.props.userId));
    this.title = user.getFullName();
    this.setTitle();
    this.setState({user})
  }

  async loadSubmissions() {
    let submissions = (await EntityManager.all<Submission>(Submission, {}, SessionService.user)).models;
    this.setState({submissions})
  }

  async loadForms() {
    let forms = (await EntityManager.all<Form>(Form)).models;
    this.setState({forms})
  }

  public render() {
    const {user, forms, selectedForm, submissions} = this.state;
    if (!user || !forms) return <></>
    return (
      <Row>
        <Col xs={12} md={6}>
          <Page>
            <ProfileShow user={user} />
          </Page>
        </Col>
        <Col>
          { forms.length > 0 &&
            <>
              <div className="page">
                { !selectedForm && <FormList selectedForm={selectedForm} onSelectForm={(form) => this.setState({selectedForm: form})} forms={forms} />}
                { selectedForm &&
                  <div>
                    <div className="mb-3 btn btn-link">
                      <TextIcon onClick={() => this.setState({selectedForm: null})} leftIcon={["fal", "chevron-left"]}>Retour</TextIcon>
                    </div>
                    <FormShow withTitle onFormSubmitted={() => this.setState({selectedForm: null})} formId={selectedForm.id} />
                  </div>
                }
              </div>
              <div className="page">
                <div className="mb-3">Demandes en cours</div>
                <div>
                  { submissions?.map(sub => (
                    <div className="row-between">
                      { sub.submissionValues.map(val => <SubmissionValueDisplayer submissionValue={val} />)}
                      <div style={{color: sub.status.color}}>{sub.status.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          }
        </Col>
      </Row>
    );
  }
}
