import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Form as FormHTML } from 'reactstrap';
import Form from '../../models/form/Form';
import FormElement from '../../models/form/FormElement';
import IconButton from '../buttons/IconButton';
import ReturnButton from '../buttons/ReturnButton';
import TextIcon from '../common/TextIcon';
import Page from '../layout/Page';
import Preview from './builder/preview';
import Toolbar from './builder/toolbar';
import FormElementEdit from './FormElementEdit';
import FormForm from './FormForm';
import FullscreenFormPreview from './FullscreenFormPreview';

export interface IFormBuilderProps {
}

export interface IFormBuilderState {
  selectedFormElement: FormElement
  editMode: boolean
  editElement: FormElement
  realPreview: boolean
  showFormProperty: boolean
  builderView: boolean
}

export default class FormBuilder extends React.Component<IFormBuilderProps, IFormBuilderState> {

  public form = new Form({}).fromMe();
  public editForm = React.createRef<HTMLDivElement>();
  public preview = React.createRef<Preview>();

  constructor(props: IFormBuilderProps) {
    super(props);

    this.state = {
      selectedFormElement: null,
      editMode: false,
      editElement: null,
      realPreview: false,
      showFormProperty: false,
      builderView: false
    }
  }

  submit = async (e) => {
    e.preventDefault();
    this.form.initOrder();
    await this.form.create();
  }

  editModeOn = (editElement) => {
    this.setState({ editMode: true, editElement });
  }

  onUpdate = ({ task_data }) => {
    this.form.formElements = task_data;
    this.setState({})
  }

  public render() {
    const { realPreview, editMode, editElement, builderView } = this.state;
    if (realPreview) return <FullscreenFormPreview close={() => this.setState({realPreview: false})} form={this.form}/>
    return (
      <Page>
        { builderView ?
          <div className="d-flex justify-content-between">
            <div className="form-builder-sidebar">
              <div onClick={() => this.setState({builderView: false})} className="p-2 pointer mb-2">
                <FontAwesomeIcon icon={["fal", "chevron-left"]} style={{fontSize: "1.5rem"}} />
              </div>
              {
                !editMode ? <Toolbar/> :
                  <div className="edit-form" ref={this.editForm}>
                  { editElement &&
                    <FormElementEdit onClose={() => this.setState({editMode: false, editElement: null})} id={editElement.id} formElement={editElement} onChange={() => this.setState({})} />
                  }
                </div>
              }
            </div>
            <div className="w-50 m-auto">
              <div className="preview-container">
                <Preview
                  ref={this.preview}
                  parent={this}
                  form={this.form}
                  onPost={this.onUpdate}
                  // onDelete={() => this.setState({editMode: false, editElement: null})}
                  editModeOn={this.editModeOn}
                  editMode={editMode}
                  editElement={ editElement }
                />
              </div>
            </div>
          </div>: <FormHTML onSubmit={this.submit} >
            <div className="row-between mb-4">
              <div style={{flex: 1}}>
                <ReturnButton/>
              </div>
              <h4 className="fw-600 darkgray" style={{flex: 1}}>Créateur de formulaire</h4>
              <div className="row-end" style={ { flex: 1 } }>
                { this.form.isPublic() && <IconButton className="mr-2" onClick={() => this.setState({realPreview: true})} iconData={["fal", "eye"]} tooltip="Preview" />}
                <div onClick={() => this.setState({builderView: true})} className="btn btn-primary mr-3">
                  <TextIcon leftIcon={["fal", "edit"]}>Construire</TextIcon>
                </div>
                <Button type="submit" color="primary">
                  <TextIcon leftIcon={["fal", "plus"]}>Créer le formulaire</TextIcon>
                </Button>
              </div>
            </div>
            <FormForm form={ this.form } />
          </FormHTML>
        }
      </Page>
    );
  }
}
