import * as React from 'react';
import { consumeRoomBloc, IRoomBlocState } from '../../bloc/RoomBloc';
import { visioBloc } from '../../bloc/VisioBloc';
import GroupRoom from '../../models/GroupRoom';
import OneOneRoom from '../../models/OneOneRoom';
import IconButton from '../buttons/IconButton';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import AvatarName from '../common/AvatarName';
import TextIcon from '../common/TextIcon';
import CustomRoom from '../../models/CustomRoom';


export interface IRoomHeaderState {
}

class RoomHeader extends React.Component<IRoomBlocState, IRoomHeaderState> {
  constructor(props: IRoomBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { activeRoom } = this.props;
    // if (!activeRoom) return (
    //   <div className="room-header">
    //     <AvatarLetter user={activeRoom.getChild() as User} size={AvatarSize.sm} />
    //   </div>
    // );
    return (
      <div className="room-header">
        <div className="row-flex">
          { (activeRoom instanceof OneOneRoom) && <AvatarName user={activeRoom.users[0]} />}
          { activeRoom instanceof GroupRoom && (
            <div className="row-flex">
              <TextIcon leftIcon={["fal", "users-class"]} className="fw-bold darkgray mr-3">{activeRoom.group.name}</TextIcon>
              { activeRoom.users.map((u) => <AvatarLetter key={u.id} user={u} size={AvatarSize.sm} />)}
            </div>
          )}
          { activeRoom instanceof CustomRoom && (
            <div className="row-flex">
              <TextIcon leftIcon={["fal", "comments"]} className="fw-bold darkgray mr-3">{activeRoom.name}</TextIcon>
              { activeRoom.users.map((u) => <AvatarLetter key={u.id} user={u} size={AvatarSize.sm} />)}
            </div>
          )}
          {/* { rooms.find((rc) => activeRoom.id === rc.id)?.hasConnected() &&
            <FontAwesomeIcon icon={["fad", "circle"]} className={`ml-3 text-success`} />
          }
        </div>
        <div className="room-live-actions d-none d-sm-flex">
          {/* <IconButton icon={["fas", "comment-alt-plus"]} size="lg" hoverBg onClick={() => DOMService.modal(<NewRoomModal/>)} /> */}
          {/* <MediaFileButton className="ml-2" onPreviewReady={(previewUrl) => roomBloc.setPreviewUrl(previewUrl)} onFileChange={roomBloc.onLoadFile} >
            <IconButton size="lg" hoverBg icon={["far", "photo-video"]}/>
          </MediaFileButton>
          <IconButton className="ml-2 d-none d-lg-flex" size="lg" hoverBg icon={["fad", "arrow-alt-to-right"]} onClick={roomBloc.toggleSecondarySidebar}/> */}
        </div>
        <div className="row-end">
          <IconButton tooltip="Appel visio" className="ml-2" onClick={() => visioBloc.createVisio(activeRoom.users, activeRoom) } iconData={["fal", "video"]} />
        </div>
      </div>
    );
  }
}

export default consumeRoomBloc(RoomHeader);
