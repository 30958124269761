import * as React from 'react';
import Moment from 'react-moment';
import { appBloc } from '../../bloc/AppBloc';
import Notification from '../../models/notif/Notification';
import SessionService from '../../services/SessionService';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import classNames from 'classnames';

export interface INotifProps {
  notification: Notification
}

export interface INotifState {
}

export default function Notif(Component) {

  return class extends React.Component<INotifProps, INotifState> {

    navigate(notification) {
      appBloc.push(notification.link());
    }

    public render() {
      const { notification } = this.props;
      return (
        <div onClick={() => this.navigate(notification)} className={classNames({"notif": true, "read-notif": !!notification.readAt})}>
          <div className="d-flex">
            <AvatarLetter size={AvatarSize.sm} user={notification.notifier || SessionService.user} />
            <div className="ml-2 h-100">
              {<Component {...this.props} />}
              <div className="text-primary">
                <Moment fromNow>{notification.createdAt}</Moment>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

