import * as React from 'react';
import User from '../../models/User';
import MemberItem from './MemberItem';
import RelationItem from '../friends/RelationItem';
import { relationBloc, consumeRelationBloc, IRelationBlocState } from '../../bloc/RelationBloc';
import PaginationComponent from '../common/PaginationComponent';

export interface IMembersListProps {
  users: User[]
}

export interface IMembersListState {
}

class MembersList extends React.Component<IRelationBlocState, IMembersListState> {
  constructor(props: IRelationBlocState) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    relationBloc.getMembers()
  }

  public render() {
    const { members, currentPage, totalPage } = this.props;
    return (
      <div>
        {members.map(u => {
          if (u.isFriend()) return <RelationItem withDropdown key={u.id} user={u}/>
          return <MemberItem key={u.id} user={u}/>
        })}
        <div className="mt-3">
          <PaginationComponent total={totalPage} currentPage={currentPage} onPageChange={(p) => {relationBloc.changePageTo(p)}} />
        </div>
      </div>
    );
  }
}

export default consumeRelationBloc(MembersList)
