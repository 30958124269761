import { debounce } from 'lodash';
import * as React from 'react';
import { appBloc } from '../../bloc/AppBloc';
import { driveBloc } from '../../bloc/DriveBloc';
import File from '../../models/File';
import StorageService from '../../services/StorageService';
import ToastrService from '../../services/ToastrService';
import { AvatarSize } from '../common/Avatar';
import AvatarName from '../common/AvatarName';
import CustomTooltip from '../common/CustomTooltip';
import TextIcon from '../common/TextIcon';
import LocalDropdown from './LocalDropdown';

export interface IFileItemProps {
  file: File;
}

export interface IFileItemState {
  isRenaming: boolean
  filename: string
  isMoving: boolean
  showTooltip: boolean
}

export default class FileItem extends React.Component<IFileItemProps & any, IFileItemState> {

  public inputRef = React.createRef<HTMLInputElement>();
  public movingFrameRef = React.createRef<HTMLDivElement>()
  public debouncedShowTooltip;

  constructor(props: IFileItemProps) {
    super(props);

    this.state = {
      isRenaming: false,
      filename: props.file.name,
      isMoving: false,
      showTooltip: false
    }

    this.debouncedShowTooltip = debounce(this.showTooltip, 1000)
  }

  showTooltip = () => {
    this.setState({showTooltip: true})
  }

  hideTooltip = () => {
    this.debouncedShowTooltip.cancel();
    this.setState({showTooltip: false})
  }

  onDoubleClick = () => {
    const {file} = this.props;
    if (!file.isFolder()) return;
    driveBloc.navigateInFolder(file)
  }

  closeRename = (e) => {
    if (this.inputRef.current !== e.target) {
      this.setState({isRenaming: false, filename: this.props.file.name});
      appBloc.cancelClickBody(this.closeRename);
    }
  }

  startRename = () => {
    this.setState({isRenaming: true}, () => appBloc.onClickBody(this.closeRename));
  }

  updateName = () => {
    if (this.state.filename === "") return;
    this.props.file.name = this.state.filename;
    driveBloc.renameFile(this.props.file, this.state.filename);
    this.setState({isRenaming: false});
  }

  handleRename = (e) => {
    let filename = e.target.value;
    this.setState({filename})
  }

  closeMovingFrame = (e) => {
    if (!this.movingFrameRef.current.contains(e.target)) {
      this.close()
    }
  }

  close = () => {
    this.setState({isMoving: false}, () => appBloc.cancelClickBody(this.closeMovingFrame))
  }

  onStartMove = () => {
    this.setState({isMoving: true}, () => appBloc.onClickBody(this.closeMovingFrame))
  }

  createLink = async () => {
    let url = await StorageService.getDownloadUrl(this.props.file.attachment.refName);
    await navigator.clipboard.writeText(url);
    ToastrService.toaster.show("Urioz", "Lien copié dans le presse-papier.")
  }

  noShare() {
    return this.props.file.users.length + this.props.file.groups.length === 0;
  }

  renderTooltip() {
    const { file } = this.props;
    return <CustomTooltip left={"150px"} top="0">
    <div className="p-3">
      {/* <div className="mb-1">Informations de {file.name}</div>
      <div>

      </div> */}
      { file.isPublic && <div className=" darkgray fw-bold mb-2">Cet élément est public</div>}
      { this.noShare() ? <label htmlFor="">Aucun partage</label> : (
        <>
          <label htmlFor="">Partagé avec</label>
          <div>
            { file.users.map((u) => (
              <div className="py-1">
                <AvatarName user={u} size={AvatarSize.xs} />
              </div>
            ))}
          </div>
          <div>
            { file.groups.map((g) => (
              <div className="py-1">
                <TextIcon iconStyle={{fontSize: "1.7rem"}} leftIcon={["fal", "users-class"]}>
                  <div className="fw-bold ml-2">{g.name}</div>
                </TextIcon>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  </CustomTooltip>
  }

  renderDropdown() {
    return <LocalDropdown file={this.props.file} startRename={this.startRename} />
  }

}
