import * as React from 'react';
import TextIcon from '../common/TextIcon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface ISearchTitleProps {
  count: number
  icon: IconProp
  title: string
}

export interface ISearchTitleState {
}

export default class SearchTitle extends React.Component<ISearchTitleProps, ISearchTitleState> {
  constructor(props: ISearchTitleProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { count, icon, title } = this.props;
    return (
      <div className="search-title">
        <TextIcon leftIcon={icon}>{title}</TextIcon>
        <div className="search-count">{`${count} élément${count === 1 ? "" : "s"}`}</div>
      </div>
    );
  }
}
