import * as React from 'react';
import Moment from 'react-moment';
import Comment from '../../models/Comment';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';

export interface ICommentComponentProps {
  comment: Comment;
}

export interface ICommentComponentState {
  isReplying: boolean;
}

export default class CommentComponent extends React.Component<ICommentComponentProps, ICommentComponentState> {

  public inputRef = React.createRef<HTMLInputElement>();

  constructor(props: ICommentComponentProps) {
    super(props);

    this.state = {
      isReplying: false
    }
  }


  public render() {
    const { comment } = this.props;

    return (
      <div className="comment">
        <div className="comment-header">
          <div className="d-flex">
            <AvatarLetter user={comment.user} size={AvatarSize.xs} />
            <div className="comment-body">
              <div className="fw-bold">{comment.user.getFullName()}</div>
              <div className="comment-content">{comment.content}</div>
            </div>
          </div>
          <div className="comment-right">
            <Moment className="" locale="fr-short" fromNow>{comment.createdAt}</Moment>
          </div>
        </div>
      </div>
    );
  }
}

