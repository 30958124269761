import React from 'react';
import EntityManager from '../managers/EntityManager';
import Attachment from '../models/Attachment';
import Comment from '../models/Comment';
import Project from '../models/Project';
import Ticket from '../models/Ticket';
import Column from '../models/Column';
import DOMService from '../services/DOMService';
import StorageService from '../services/StorageService';
import { appBloc } from './AppBloc';
import Attachable from '../models/mixin/Attachable';

export interface IProjectBlocProps {}

export interface IProjectBlocState {
  isDragging: boolean
  projects: Project[]
  currentProject: Project
  newColumnInput: boolean
  isLoaded: boolean
}

export const Context = React.createContext({})

export let projectBloc: ProjectBloc

class ProjectBloc extends React.Component<IProjectBlocProps, IProjectBlocState> {

  public filter: string;
  public projectId;

  public newColumnInput = React.createRef<HTMLDivElement>();

  constructor(props: IProjectBlocProps) {
    super(props)
    projectBloc = this

    this.state = {
      isDragging: false,
      projects: null,
      currentProject: null,
      newColumnInput: false,
      isLoaded: false
    }

  }

  ////// PROJECT ///////

  loadProject = async () => {
    let param = this.filter ? { filter: this.filter } : {};
    EntityManager.show<Project>(Project, this.projectId || this.state.currentProject.id, param)
      .then((currentProject) => this.setState({ currentProject }))
      .catch((error) => {
        if (error.response?.status === 404) appBloc.push("/projects");
      })
  }

  loadProjects = async () => {
    let projects = (await EntityManager.all<Project>(Project)).models;
    this.setState({projects, isLoaded: true});
  }

  onProjectCreated = async (project: Project) => {
    await this.loadProjects();
    this.handleChangeProject(project.id.toString())
  }

  handleChangeProject(projectId: string) {
    appBloc.push("/projects/" + projectId + "/all")
  }

  ////// COLUMN ///////

  createColumn = async (column: Column) => {
    let newColumn = await column.create();
    this.state.currentProject.columns.push(newColumn);
    this.setState({newColumnInput: false})
  }

  cancelColumnInput = () => {
    this.setState({newColumnInput: false})
  }

  showNewColumnInput = () => {
    this.setState({newColumnInput: true})
  }

  reorderColumn = async () => {
    this.state.currentProject.columns.forEach((c, i) => {
      c.position = i;
    })
    EntityManager.updateMany(Column, this.state.currentProject.columns.map(c => { return {id: c.id, position: c.position}}));
  }

  ////// TICKET ///////

  onTicketCreated = async (ticket: Ticket, attachable: Attachable) => {
    if (attachable.models.length > 0) {
      await this.uploadAttachments(attachable.models, ticket);
      await EntityManager.createMany(Attachment, attachable.models);
    }
    this.loadProject();
    return ticket;
  }

  uploadAttachments = async (attachments: Attachment[], ticket: Ticket) => {
    let uploads: Promise<string>[] = [];
    attachments.forEach((a) => {
      if (!a.isStored) {
        a.attachable(ticket);
        let promise = StorageService.uploadFile("data", a.file);
        uploads.push(promise);
        promise.then((url) => {
          a.url = url;
        });
      }
    });
    await Promise.all(uploads);
  }

  updateColumn = async (ticket: Ticket, column: Column) => {
    ticket.columnId = column.id;
    await ticket.update({only: ["columnId"]});
    this.loadProject();
  }

  updatepriority = async (ticket: Ticket, priority: string) => {
    ticket.priority = parseInt(priority);
    await ticket.update({only: ["priority"]});
    this.loadProject();
  }

  updateDescription = async (ticket: Ticket, description: string) => {
    ticket.description = description;
    await ticket.update({only: ["description"]});
    this.loadProject();
  }

  addComment = async (comment: Comment) => {
    return await comment.create();
  }

  deleteTicket = async (ticket: Ticket) => {
    await ticket.delete()
    this.loadProject();
    DOMService.close();
  }

  findTicketById(id: number) {
    let tickets = []
    this.state.currentProject.columns.forEach(col =>
      tickets = [...tickets, ...col.tickets]
    );
    return tickets.find(t => t.id.toString() === id.toString())
  }


  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default ProjectBloc;

export function consumeProjectBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
