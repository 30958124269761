import Model from "./Model";

export default class Licence extends Model {

  public type: string;
  public applicationId: number;
  public nbUsers: number;
  public email: string;
  static modelUrl = "licences";

  public requiredField = ["type"]

  constructor(json: any) {
    super(json);
    this.type = json.type;
    this.applicationId = json.applicationId;
    this.nbUsers = json.nbUsers;
    this.email = json.email;
  }

}