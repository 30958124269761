import * as React from 'react';
import { Button } from 'reactstrap';
import EntityManager from '../../managers/EntityManager';
import Option from '../../models/Option';
import OptionType from '../../models/OptionType';
import OptionService from '../../services/OptionService';
import IconButton from '../buttons/IconButton';
import ColorInput from '../input/SmartColorInput';
import SmartInputText from '../input/SmartInputText';
import EditableText from './EditableText';

export interface ICrudOptionProps {
  optionType: OptionType
  afterAction?: (action: string, option?: Option) => void
}

export interface ICrudOptionState {
}

export default class CrudOption extends React.Component<ICrudOptionProps, ICrudOptionState> {

  public option: Option;

  constructor(props: ICrudOptionProps) {
    super(props);

    this.state = {
    }

    this.option = this.instanciateOption();
  }

  public instanciateOption = () => {
    return new Option({optionTypeId: this.props.optionType.id, color: "#c34a60"})
  }

  public onUpdateMany = async (filteredOptions) => {
    await EntityManager.updateMany<Option>(Option, filteredOptions)
    OptionService.loadOptions();
  }

  public onCreate = async () => {
    await this.option.create();
    this.option = this.instanciateOption();
    OptionService.loadOptions();
  }

  public onDelete = async () => {
    await this.option.delete();
    OptionService.loadOptions();
  }

  public render() {
    const { optionType } = this.props;
    let filteredOptions = OptionService.getOptionsByType(optionType.name);
    return (
      <>
        {/* <div className="mb-3 darkgray fw-bold">Ajouter un type d'évènement</div> */}
        <SmartInputText model={this.option} name="name" label="Type d'évènement" floating></SmartInputText>
        <div className="row-flex mb-4">
          <Button color="primary" onClick={() => this.onCreate()} >Ajouter</Button>
        </div>
        <div className="mb-3 darkgray fw-bold">{ optionType.label }</div>

          {filteredOptions.map((option, i) => (
            <div key={option.id} className="row-between mb-2">
              <div className="row-flex">
                <ColorInput model={ option } />
                <div className="ml-2">
                  <EditableText model={option} name="name" light />
                </div>
              </div>
              <div>
                { option.deletable && <IconButton iconData={["fal", "times"]} onClick={() => this.onDelete()} className="text-danger"/>}
              </div>
            </div>
          ))}
          <Button className="mt-3" color="primary" onClick={() => this.onUpdateMany(filteredOptions)} >Valider les modifications</Button>
      </>
    );
  }
}
