import * as React from 'react';
import { Button, Form, ModalBody } from 'reactstrap';
import { appBloc } from '../../bloc/AppBloc';
import { roomBloc } from '../../bloc/RoomBloc';
import Room from '../../models/Room';
import User from '../../models/User';
import DOMService from '../../services/DOMService';
import RoomService from '../../services/RoomService';
import SessionService from '../../services/SessionService';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import SmartInputText from '../input/SmartInputText';
import MembersSelectMultiple from '../members/MembersSelectMultiple';
import CustomModalHeader from './customModalHeader';

export interface INewRoomModalProps {
}

export interface INewRoomModalState {
}

export default class NewRoomModal extends React.Component<INewRoomModalProps, INewRoomModalState> {

  public room = new Room({users: [SessionService.user], type: "custom" });

  constructor(props: INewRoomModalProps) {
    super(props);

    this.state = {
    }
  }

  onCompositionChange = (users: User[]) => {
    this.room.users = users;
    this.setState({})
  }

  onSubmit = async (e) => {
    e.preventDefault();
    let room = await roomBloc.createRoom(this.room);
    DOMService.close();
    await RoomService.loadRooms();
    appBloc.push(room.getFrontUrl());
    roomBloc.setUrlParam({roomId: room.id})
  }

  public render() {
    return (
      <Form onSubmit={this.onSubmit}>
        <CustomModalHeader title="Nouvelle discussion de groupe" />
        <ModalBody className="">
          <SmartInputText label="Nom de la discussion" className="nice-input" model={this.room} name="name" floating/>
          <div>
            <label htmlFor="">Membres de la discussion</label>
          </div>
          <div className="flex-wrap row-flex">
            {this.room.users.map(u => <AvatarLetter title={u.getFullName()} className="mb-2 mr-2" key={u.id} size={AvatarSize.sm} user={u} />)}
          </div>
          <div>
            <label htmlFor="">Ajouter des membres à la discussion</label>
          </div>
          <MembersSelectMultiple overflowHeight="350px" defaultValue={this.room.users} onChange={this.onCompositionChange} />
          <div className="row-end">
            <Button type="submit" color="primary">Créer la discussion</Button>
          </div>
        </ModalBody>
      </Form>
    );
  }
}
