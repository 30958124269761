import Model from "./Model";

export default class OptionType extends Model {

  public label: string;
  public name: string;
  static modelUrl = "optionTypes";

  constructor(json: any) {
    super(json)
    this.label    = json.label;
    this.name    = json.name;
  }

}
