import EntityManager, { IndexResponse } from "./EntityManager";
import User from "../models/User";
import { AxiosResponse } from "axios";
import ApiService from "../services/ApiService";
import { LoginResponse, Credential } from "../services/AuthService";

export default class UserManager extends EntityManager<User> {

  protected modelUrl: string = "users";

  protected getEntity(json: any): User {
    return new User(json);
  };

  public async login(credential: Credential): Promise<LoginResponse> {
    let response: AxiosResponse = await ApiService.post(`${this.modelUrl}/login`, credential);
    return {
      user: this.toModel(response.data.user),
      token: response.data.token,
      appId: response.data.appId
    }
  }

  public async forgotPassword(email: string) {
    let response: AxiosResponse = await ApiService.post(`${this.modelUrl}/forgotPassword`, {email});
    return response;
  }

  public async me(): Promise<User> {
    return await this.get("me") as User;
  }

  public async relations() {
    return (await this.get("relations")) as Promise<IndexResponse<User>>;
  }

  public organization = async (): Promise<IndexResponse<User>> => {
    return (await this.get("organization")) as Promise<IndexResponse<User>> ;
  }

}
