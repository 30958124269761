import { IconName } from '@fortawesome/fontawesome-svg-core';
import FormElement from '../FormElement';
import { FormElementType } from '../FormElement';
import Colors from '../../../theme/color';

export default class Header extends FormElement {

  public humanName = "Titre"
  public icon: IconName = "text"
  public typeKey = FormElementType.Header;
  public key = "Header"

  public isCutomable = true;
  public positionnable = true;

  setDefaultValue() {
    this.style = {
      textAlign: "left",
      color: Colors.PrimaryDark
    }
    this.label = "Mon titre"
  }

}
