import * as React from 'react';
import User from '../../models/User';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';

export interface IIsTypingProps {
  user: User
}

export interface IIsTypingState {
}

export default class IsTyping extends React.Component<IIsTypingProps, IIsTypingState> {
  constructor(props: IIsTypingProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { user } = this.props;
    return (
      <div className="other-message py-2">
        <div className="row-flex">
          <AvatarLetter size={AvatarSize.xs} user={user} />
          <div className="ml-2 darkgray">En train d'écrire ...</div>
        </div>
      </div>
    );
  }
}
