import * as React from 'react';
import { consumeDriveBloc, driveBloc, IDriveBlocState } from '../../bloc/DriveBloc';
import SearchInput from './SearchInput';

export interface IDriveSearchInputProps {
}

export interface IDriveSearchInputState {
}

class DriveSearchInput extends React.Component<IDriveBlocState, IDriveSearchInputState> {

  public input = React.createRef<HTMLInputElement>();

  constructor(props: IDriveBlocState) {
    super(props);

    this.state = {
    }
  }


  public render() {
    return (
      <div className='drive-search'>
        <SearchInput
          innerRef={this.input}
          onSearch={driveBloc.handleSearch}
          onChanged={driveBloc.handleChange}
          value={this.props.searchInput}
          onClear={driveBloc.clearSearch}
          placeholder='Rechercher ...'
        />
      </div>
    );
  }
}

export default consumeDriveBloc(DriveSearchInput);
