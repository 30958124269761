import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { DragSource } from 'react-dnd';
import Moment from 'react-moment';
import Ticket from '../../models/Ticket';
import DOMService from '../../services/DOMService';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import TextIcon from '../common/TextIcon';
import TicketModal from '../modal/TicketModal';

export interface ITicketComponentProps {
  ticket: Ticket
}

export interface ITicketComponentState {
}


const source = {
  canDrag(props) {
    return true
  },

  isDragging(props, monitor) {
    return monitor.getItem().id === props.ticket.id
  },

  beginDrag(props, monitor, component: TicketComponent) {
    const item = { id: props.ticket.id, model: props.ticket, height: component.height }
    return item
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

class TicketComponent extends React.Component<ITicketComponentProps & any, ITicketComponentState> {

  public ref = React.createRef<HTMLDivElement>()
  public height;

  constructor(props: ITicketComponentProps & any) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    this.height = this.ref.current.clientHeight;
  }

  isLateAndNotDone(ticket: Ticket) {
    return ticket.isLate;
  }


  public render() {
    const { ticket, connectDragSource, isDragging, color} = this.props;
    return (
      <div className="ticket-container">
        {connectDragSource(
          <div
            ref={this.ref}
            className={ticket.deadline ? "late-ticket ticket" : "ticket"}
            onClick={() => DOMService.modal(<TicketModal ticket={ticket} />, {maxWidth: "600px"})}
            style={{borderLeftColor: color, display: isDragging ? "none" : "block"}}
          >
            { (ticket.hasImageAttachment()) && <div className="ticket-image">
              {/* <div
                style={{
                  backgroundImage: `url(${ticket.getFirstImageAttachment()})`,
                  height: "150px",
                  backgroundPosition: "center",
                  backgroundSize: "cover"
                }}
              ></div> */}
              <img className="" src={ticket.getFirstImageAttachment()} alt=""/>
            </div>}
            <div className="ticket-title">
              {ticket.title}
            </div>
            { (ticket.deadline) && <div className="text-small">
              { this.isLateAndNotDone(ticket) ?
                <div className="row-flex text-danger">
                  <FontAwesomeIcon className="mr-2" icon={["fal", "alarm-exclamation"]}/>
                  <Moment locale="fr-short" fromNow>{ticket.deadline}</Moment>
                </div>
                :
                <div className="row-flex text-primary">
                  <FontAwesomeIcon className="mr-2" icon={["fal", "alarm-clock"]}/>
                  <Moment format={"DD MMMM - hh:mm"}>{ticket.deadline}</Moment>
                </div>
              }
            </div>}
            <div className="ticket-footer mt-2">
              <div className="row-flex">
                <TextIcon iconStyle={{color: ticket.getPriorityColor()}} leftIcon={["fas", "circle"]}><div>{ticket.getPriority()}</div></TextIcon>
                <TextIcon className="mx-2" spacing={1} leftIcon={["fal", "comment"]}><div>{ticket.commentable.models.length}</div></TextIcon>
                { ticket.hasCheckList() && <TextIcon className="mr-2" spacing={1} leftIcon={["fal", "check-square"]}><div>{ticket.getCheckState()}</div></TextIcon>}
                { ticket.hasAttachments() && <TextIcon spacing={1} leftIcon={["fal", "paperclip"]}><div>{ticket.attachments.length}</div></TextIcon>}
              </div>
              <AvatarLetter size={AvatarSize.xs} user={ticket.creator} />
            </div>
          </div>
        )}
      </div>
    );
  }
}


export default DragSource("ticket", source, collect)(TicketComponent)