import * as React from 'react';

export interface ICustomTooltipProps {
  left?: any
  right?: any
  top?: any
  bottom?: any
}

export interface ICustomTooltipState {
}

export default class CustomTooltip extends React.Component<ICustomTooltipProps, ICustomTooltipState> {
  constructor(props: ICustomTooltipProps) {
    super(props);

    this.state = {
    }
  }

  getStyle() {
    return {
      left: this.props.left,
      right: this.props.right,
      top: this.props.top,
      bottom: this.props.bottom,
    }
  }

  public render() {
    return (
      <div className="custom-tooltip" style={this.getStyle()}>
        {this.props.children}
      </div>
    );
  }
}
