import * as React from 'react';
import { Input, Button } from 'reactstrap';
import Model from '../../models/Model';
import { InputType } from 'reactstrap/lib/Input';
import { appBloc } from '../../bloc/AppBloc';
import classNames from 'classnames';
import Closable from './Closable';

export interface IEditableTextProps {
  type?: InputType
  light?: boolean
  model?: Model
  name?: string
  nullText?: string
  disabled?: boolean
  onValidate?: (arg: string) => void
  onUpdated?: () => void
}

export interface IEditableTextState {
  edit: boolean,
  textState: string
}

export default class EditableText extends React.Component<IEditableTextProps, IEditableTextState> {
  constructor(props: IEditableTextProps) {
    super(props);

    this.state = {
      edit: false,
      textState: props.model[props.name]
    }
  }

  handChange = (e) => {
    let textState = e.target.value;
    this.setState({textState})
  }

  onEdit = (e) => {
    if (this.props.disabled) return;
    this.setState({edit: true}, () => appBloc.onClickBody(this.onCancel))
  }

  onValidate = async (e) => {
    const { light, model, name, onValidate, onUpdated } = this.props;
    model[name] = this.state.textState;
    onValidate && onValidate(this.state.textState);
    light && await model.update();
    onUpdated && onUpdated();
    this.setState({edit: false})
  }

  onCancel = () => {
    this.setState({edit: false, textState: this.props.model[this.props.name]})
    appBloc.cancelClickBody(this.onCancel);
  }

  public render() {
    const { type, light, nullText, disabled } = this.props;
    const { edit, textState } = this.state;
    return (
      <>
        <Closable show={edit} close={() => this.setState({edit: false})}>
          <Input onKeyDown={(e) => { if (e.key === "Enter") this.onValidate(e) }} autoFocus type={type} onChange={this.handChange} value={textState} ></Input>
          { !light &&
            <div className="row-end mt-1">
              <Button onClick={this.onCancel} className="mr-2" color="light" size="sm">Annuler</Button>
              <Button onClick={this.onValidate} color="primary" size="sm">Valider</Button>
            </div>
          }
        </Closable>
        { !edit && <div onClick={this.onEdit} className={classNames({"editable-text": true, "disabled": disabled, "empty": !textState})}>{textState || nullText}</div>
        }
      </>
    );
  }
}
