import * as React from 'react';
import Group from '../../models/Group';
import GroupItem from './GroupItem';
import { Row, Col } from 'reactstrap';

export interface IGroupsListProps {
  groups: Group[]
}

export interface IGroupsListState {
}

export default class GroupsList extends React.Component<IGroupsListProps, IGroupsListState> {
  constructor(props: IGroupsListProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { groups } = this.props;
    return (
      <div className="group-list">
        <Row>
          {groups.map(g => <Col key={g.id} className="mb-3" xs="12" md={"6"} xl={"4"}><GroupItem group={g} /></Col>)}
        </Row>
      </div>
    );
  }
}
