import * as React from 'react';
import EntityManager from '../../managers/EntityManager';
import Invitation from '../../models/Invitation';
import DOMService from '../../services/DOMService';
import IconButton from '../buttons/IconButton';
import CommentsComponent from '../comments/CommentsComponent';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import DateRangeText from './DateRangeText';

export interface IInvitationItemProps {
  invitation: Invitation
  onAction?: (action: string) => void
}

export interface IInvitationItemState {
}

export default class InvitationItem extends React.Component<IInvitationItemProps, IInvitationItemState> {
  constructor(props: IInvitationItemProps) {
    super(props);

    this.state = {
    }
  }

  onAccept = async () => {
    this.props.invitation.status = "accepted";
    await EntityManager.post(Invitation, this.props.invitation.id + "/accept")
    this.props.onAction && this.props.onAction("accept");
  }

  onRefuse = async () => {
    let onConfirm = async () => {
      this.props.invitation.status = "refused";
      await this.props.invitation.update({only: ["status"]})
      this.props.onAction && this.props.onAction("refuse");
    }
    DOMService.alert({
      title: "Refuser cet évènement ?",
      message: "Vous êtes sur le point de refuser cet évènement définitivement.",
      buttonText: "Refuser",
      onCancel: () => DOMService.close(),
      onConfirm: onConfirm
    })
  }

  onComment() {
    this.props.onAction && this.props.onAction("comment");
  }

  public render() {
    const { invitation } = this.props;
    return (
      <div>
        <div className="row-between">
          <div>
            <div className="row-flex">
              <AvatarLetter user={invitation.creator} size={AvatarSize.sm} />
              <div className="pl-2">
                <div className="fw-bold capitalize">{ invitation.event.name}</div>
                <div className="text-small darkgray"><DateRangeText event={invitation.event} /></div>
              </div>
            </div>
          </div>
          <div className="row-end mb-2">
            <IconButton iconData={['fal', 'times']} className="mr-2" onClick={() => this.onRefuse()}/>
            <IconButton className="text-success" iconData={['fal', 'check']} onClick={() => this.onAccept()}/>
          </div>
        </div>
        <div className="row-between mb-2">
          <div className="text-small">
          </div>
        </div>
        <CommentsComponent onComment={this.onComment} commentable={invitation.commentable} />
      </div>
    );
  }
}
