import classNames from 'classnames';
import * as React from 'react';
import { Button } from 'reactstrap';
import { consumeProjectBloc, IProjectBlocState, projectBloc } from '../../bloc/ProjectBloc';
import TabItem from '../../core/TabItem';
import Project from '../../models/Project';
import User from '../../models/User';
import DOMService from '../../services/DOMService';
import RightService from '../../services/RightService';
import ToastrService from '../../services/ToastrService';
import AdminTab from '../admin/AdminTab';
import IconButton from '../buttons/IconButton';
import Closable from '../common/Closable';
import TextIcon from '../common/TextIcon';
import SelectDesign from '../input/SelectDesign';
import MembersSelectMultiple from '../members/MembersSelectMultiple';
import ProjectModal from '../modal/ProjectModal';
import AvatarList from './AvatarList';
import ProjectShow from './ProjectShow';
import TicketColumns from './TicketColumns';

export interface IProjectHeaderProps {
}

export interface IProjectHeaderState {
  open: boolean
  show: boolean
}

class ProjectHeader extends React.Component<IProjectBlocState, IProjectHeaderState> {

  public getTabs(projectId): TabItem[] {
    return [
      new TabItem({label: "Tous",              path: `/projects/${projectId}/all`, component: TicketColumns}),
      new TabItem({label: "Mes tickets",       path: `/projects/${projectId}/mine`, component: TicketColumns}),
      new TabItem({label: "Tickets en retard", path: `/projects/${projectId}/late`, component: TicketColumns}),
    ];
  }
  constructor(props: IProjectBlocState) {
    super(props);

    this.state = {
      open: false,
      show: false
    }
  }

  openInvite = () => {
    this.setState({open: true})
  }

  share = async () => {
    try {
      await this.props.currentProject.update();
      ToastrService.toaster.show("Urioz", "Le partage du projet à été mis à jour.");
      this.setState({ open: false })
      projectBloc.loadProject();
    } catch (error) {
      ToastrService.toaster.show("Urioz", "Une erreur est survenue.", "Danger");
    }
  }

  onCompositionChange = (users: User[]) => {
    this.props.currentProject.users = users;
  }

  public render() {
    const { isLoaded, projects, currentProject } = this.props;
    if (!isLoaded || !currentProject) return <></>;
    let tabs = this.getTabs(currentProject.id);

    return (
      <div className="project-header">
        <div className="row-between">
          <div style={{flex: "1 1"}} className="row-flex position-relative">
            <div>
              <SelectDesign<Project>
                options={projects}
                value={currentProject}
                field={"name"}
                onChange={(model) => projectBloc.handleChangeProject(model.id.toString())}
                render={(model, selected) => (
                  // <div className="row-flex">
                  //   <Avatar size={"40px"} />
                    <div className={classNames({"option": true, "selected": selected})}>{model.name}</div>
                  // </div>
                )}
              />
              {/* <Input type="select" onChange={(e) => projectBloc.handleChangeProject(e.target.value)} value={currentProject.id}>
                { projects.map((a) => <option key={a.id} value={a.id}>{a.name}</option> )}
              </Input> */}
            </div>
            <div onClick={() => this.setState({show: true})} className="ml-3 position-relative">
              <IconButton tooltip="Information du projet" iconData={["fal", "info"]} />
              <Closable show={this.state.show} close={() => this.setState({show: false})} >
                <div className="custom-frame" style={{left: "80%"}}>
                  <ProjectShow project={currentProject} />
                </div>
              </Closable>
            </div>
            { RightService.can("projects") && <div className="ml-2">
              <IconButton tooltip="Créer un projet" onClick={() => DOMService.modal(<ProjectModal/>)} iconData={["fal", "plus"]}/>
            </div>}
          </div>
          <div style={{flex: "1 1 auto"}} className="row-center">
            { tabs.map(t => <AdminTab white key={t.label} label={t.label} path={t.path} icon={t.icon}/>)}
          </div>
          <div style={ { flex: "1 1" } } className="row-end position-relative">
            <AvatarList users={currentProject.users} />
            <div className="ml-3">
              <Button disabled={ currentProject.isPublic } onClick={ () => this.openInvite() } className="btn-pink">
                <TextIcon leftIcon={ ["fal", "users"] }>Inviter</TextIcon>
              </Button>
            </div>
            <Closable show={this.state.open} close={() => this.setState({open: false})} >
              <div className="custom-frame" style={{right: 0}}>
                <MembersSelectMultiple overflowHeight="500px" defaultValue={currentProject.users} onChange={this.onCompositionChange} />
                <div className="row-end mt-3">
                  <Button onClick={() => this.share()} color="primary">Partager</Button>
                </div>
              </div>
            </Closable>
            {/* <FontAwesomeIcon className="text-danger" icon={["fal", "trash"]} /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default consumeProjectBloc(ProjectHeader)
