import * as React from 'react';
import { Button } from 'reactstrap';
import { appBloc } from '../../bloc/AppBloc';
import TextIcon from '../common/TextIcon';

export interface IReturnButtonProps {
}

export default class ReturnButton extends React.Component<IReturnButtonProps> {
  public render() {
    return <Button style={{marginLeft: "-0.5rem"}} onClick={() => appBloc.pop()} color="link"><TextIcon leftIcon={["fal", "chevron-left"]}>Retour</TextIcon></Button>;
  }
}
