import SessionService from "../services/SessionService";
import Attachment from './Attachment';
import Model from "./Model";
import User from './User';

export default class StaticMessage extends Model {

  public content: string;
  public userId: number;
  public user: User;
  public sendBy: number;
  public sender: User;
  public parentId: number;
  public isArchived: boolean;
  public isRead: boolean;
  public isImportant: boolean;
  public attachments: Attachment[];

  static modelUrl = "staticMessages";
  public modelName = "staticMessage";

  constructor(json: any) {
    super(json)
    this.content     = json.content;
    this.userId      = json.userId;
    this.sendBy      = json.sendBy;
    this.parentId    = json.parentId;
    this.isArchived  = json.isArchived;
    this.isRead      = json.isRead;
    this.isImportant = json.isImportant;
    this.sender      = json.sender && new User(json.sender);
    this.user        = json.user && new User(json.user);
    this.attachments = this.mapArrayModel(Attachment, json.attachments);
  }

  isSent() {
    return this.sendBy === SessionService.getUserId();
  }

  isMine() {
    return this.user.id === SessionService.getUserId();
  }

}
