import * as React from 'react';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import User from '../../models/User';
import SessionService from '../../services/SessionService';

export interface IAvatarListProps {
  users: User[]
}

export interface IAvatarListState {
}

export default class AvatarList extends React.Component<IAvatarListProps, IAvatarListState> {

  public render() {
    return (
      <div className="avatar-list">
        { [...this.props.users, SessionService.user].map((u) => <AvatarLetter showable className="avatar-item" key={u.id} user={u} size={AvatarSize.xs} />) }
      </div>
    );
  }
}
