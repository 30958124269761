import * as React from 'react';
import Input from 'reactstrap/lib/Input';
import { consumeRoomBloc, IRoomBlocState, roomBloc } from '../../bloc/RoomBloc';
import MatchedRooms from './matchedRooms';
import RoomItems from './roomItems';
import RoomService from '../../services/RoomService';
import IconButton from '../buttons/IconButton';
import DOMService from '../../services/DOMService';
import NewRoomModal from '../modal/NewRoomModal';

class RoomsSidebar extends React.Component<IRoomBlocState> {

  public render() {
    const { searchInput, isSearching, matchedRooms, showSearchInput } = this.props;
    return (
      <div className="rooms-sidebar">
        <div className="rooms-sidebar-header" >
          { !showSearchInput && <div className="rooms-sidebar-title d-none d-md-flex">Discussions</div> }
          <IconButton tooltip="Créer une discussion de groupe" iconData={["fal", "comment-alt-plus"]} onClick={() => DOMService.modal(<NewRoomModal/>)} />
          { showSearchInput && <Input placeholder="Rechercher..." autoFocus className="round" onChange={(e) => roomBloc.handleSearchInput(e)} value={searchInput} />}
          {/* <div>
            <IconButton classNames={classNames({"ml-3": showSearchInput})} iconData={["fal", "search"]} onClick={roomBloc.toggleShowSearchInput}/>
          </div> */}
        </div>
        <div className="room-sidebar-body">
          { !isSearching && <RoomItems rooms={RoomService.rooms} />}
          { isSearching && <MatchedRooms rooms={matchedRooms} />}
        </div>
      </div>
    );
  }
}

export default consumeRoomBloc(RoomsSidebar);
