import React from "react";
import BaseModal from "../components/modal/BaseModal";
import AlertDialog from '../components/modal/AlertDialog';
import { IAlertDialogProps } from '../components/modal/AlertDialog';

export default class DOMService {

  static modalRef: BaseModal;
  static notifierRef;

  static modal(modal: JSX.Element, style?) {
    DOMService.modalRef.setModal(modal, style);
    return this;
  }

  static alert(props: IAlertDialogProps) {
    if (DOMService.modalRef.state.modal) {
      let temp = DOMService.modalRef.state.modal;
      props.onCancel = () => {
        DOMService.modalRef.setModal(temp)
      }
    }
    DOMService.modalRef.setModal(<AlertDialog {...props} />)
  }

  static pop(modal: JSX.Element, props: any = {}) {
    return this;
  }

  static show() {
    DOMService.modalRef.setState({show: true})
  }

  static close() {
    DOMService.modalRef.setState({show: false, modal: null})
  }

  static setNotifier(notifier) {
    this.notifierRef = notifier;
  }

  // static closeToast() {
  //   toaster.setState({show: false})
  // }

  // static toast(toast: JSX.Element) {
  //   toaster.setToast(toast);
  //   return this;
  // }

}
