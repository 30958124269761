import * as React from 'react';
import ProjectForm from '../form/ProjectForm';
import project_new from './../../assets/img/project_new.png';

export interface IProjectsListProps {
}

export interface IProjectsListState {
}

export default class ProjectsList extends React.Component<IProjectsListProps, IProjectsListState> {
  constructor(props: IProjectsListProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
        <div className="row-center">
          <img className="img-fluid" src={project_new} alt=""/>
        </div>
        <h4 className="fw-600 darkgray text-center mb-4">
          Créez votre premier projet
        </h4>
        <div>
          <div className="row-center">
            <ProjectForm/>
          </div>
        </div>
      </div>
    );
  }
}
