import EntityManager from "./EntityManager";
import Log from "../models/Log";

export default class LogManager extends EntityManager<Log> {

  protected modelUrl: string = "logs";
  public authBase = true;

  protected modelClass = Log;

}
