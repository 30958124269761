import * as React from 'react';
import FileIcon from '../drive/fileIcon';
import FileItem from '../drive/FileItem';
import SearchItemBase from './SearchItemBase';
import Moment from 'react-moment';

export interface IFileSearchItemProps {
}

export interface IFileSearchItemState {
}

export default class FileSearchItem extends FileItem {

  public render() {
    const { file } = this.props;
    return (
      <SearchItemBase>
        <div className="row-flex">
          <div style={{flex: "0 0 60px"}}>
            <FileIcon file={file}></FileIcon>
          </div>
          <div className="fw-bold" style={{flex: 1}}>{file.name}</div>
          <div style={{flex: 1}}>
            <div className="gray fw-600">Dernière modification</div>
            <Moment format={"DD MMMM YYYY - hh:mm"}>{file.updatedAt}</Moment>
          </div>
          <div style={{flex: "0 0 220px"}}>importé par {file.creator?.getFullName()}</div>
          <div style={{flex: " 0 0 50px"}}>
            {this.renderDropdown()}
          </div>
        </div>
      </SearchItemBase>
    );
  }
}
