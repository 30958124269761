import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import TextIcon from '../common/TextIcon';
import classNames from 'classnames';

export interface IAdminTabProps {
  label: string
  path: string
  icon: IconProp
  white?: boolean
}

export interface IAdminTabState {
}

export default class AdminTab extends React.Component<IAdminTabProps, IAdminTabState> {
  constructor(props: IAdminTabProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { label, path, icon, white} = this.props;
    return (
      <NavLink exact key={label} to={path}>
        <div className={ classNames({ "admin-tab mx-0": true, "admin-tab-white": white})}>
          <TextIcon iconStyle={{fontSize: "1.3rem", width: "30px"}} spacing={3} leftIcon={icon}>{label}</TextIcon>
        </div>
      </NavLink>
    );
  }
}
