import * as React from 'react';
import Alert from '../../models/Alert';
import IconButton from '../buttons/IconButton';
import SmartInputText from '../input/SmartInputText';

export interface IAlertFormProps {
  eventId: number
  onSubmit?: (alert: Alert) => void
}

export interface IAlertFormState {
}

export default class AlertForm extends React.Component<IAlertFormProps, IAlertFormState> {

  public alert: Alert;

  constructor(props: IAlertFormProps) {
    super(props);

    this.state = {
    }

    this.alert = new Alert({}).fromMe();
  }

  // handleDateChange = (date: Date) => {
  //   this.alert.date = date;
  // }

  onSubmit = async (e) => {
    this.props.onSubmit && this.props.onSubmit(this.alert);
  }

  public render() {
    return (
      <div >
        <div className="mb-2 row-between">
          <div className="w-100 mr-3">
            <SmartInputText noMargin type="select" name="date" model={this.alert} >
              {Alert.getOptions().map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
            </SmartInputText>
            {/* <SmartDatePicker
              name="date"
              onChange={(date) => {this.handleDateChange(date); this.setState({})}}
              value={this.alert.date}
            /> */}
          </div>
          <IconButton className="text-success" onClick={this.onSubmit} iconData={["fal", "check"]} />
        </div>
      </div>
    );
  }
}
