import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { driveBloc } from '../../bloc/DriveBloc';
import DOMService from '../../services/DOMService';
import Dropdown, { DropDownPosition, DropDownDirection } from '../common/Dropdown';
import TextIcon from '../common/TextIcon';
import NewMessageModal from '../modal/NewMessageModal';
import File from '../../models/File';

export interface ILocalDropdownProps {
  file: File
  startRename: () => void
}

export interface ILocalDropdownState {
}

export default class LocalDropdown extends React.Component<ILocalDropdownProps, ILocalDropdownState> {


  public render() {
    const { file, startRename } = this.props
    return (
      <Dropdown
        position={DropDownPosition.bottom}
        direction={DropDownDirection.left}
        button={
          <div className="py-2 px-4 gray-btn">
            <FontAwesomeIcon size={"2x"} icon={["fal", "ellipsis-h"]} />
          </div>
        }
        content={(
          <div className="dropdown-frame">
            {/* <TextIcon className="dropdown-item" leftIcon={["fal", "user-plus"]} >Inviter dans un groupe</TextIcon> */}
            {/* <TextIcon className="dropdown-item" leftIcon={["fal", "brackets-curly"]} >Voir</TextIcon> */}
            { !file.isFolder() && <TextIcon onClick={() => driveBloc.onDownload(file)} className="dropdown-item" leftIcon={["fal", "cloud-download-alt"]} >Télécharger</TextIcon>}
            <TextIcon onClick={() => driveBloc.onShareItem(file)} className="dropdown-item" leftIcon={["fal", "user-plus"]} >Partager</TextIcon>
            {/* <TextIcon onClick={() => this.createLink()} className="dropdown-item" leftIcon={["fal", "link"]} >Lien partageable</TextIcon> */}
            {/* <TextIcon onClick={() => this.onStartMove()} className="dropdown-item" leftIcon={["fal", "file-export"]} >Déplacer vers</TextIcon> */}
            <TextIcon onClick={() => DOMService.modal(<NewMessageModal files={[file]} />)} className="dropdown-item" leftIcon={["fal", "share-alt"]} >Transférer</TextIcon>
            <TextIcon onClick={() => startRename()} className="dropdown-item" leftIcon={["fal", "pen-alt"]} >Renommer</TextIcon>
            <TextIcon onClick={() => driveBloc.onDeleteItem(file)} className="dropdown-item" leftIcon={["fal", "trash"]} >Supprimer</TextIcon>
          </div>
        )}
      />
    );
  }
}
