import { IconName } from '@fortawesome/fontawesome-svg-core';
import FormElement from '../FormElement';
import { FormElementType } from '../FormElement';

export default class Switch extends FormElement {

  public humanName = "Switch"
  public icon: IconName = "toggle-on"
  public typeKey = FormElementType.Switch;
  public key = "Switch"

  public isField = true;
  public isSubmittable = true;
  public isChartable = true;

  setDefaultValue() {
    super.setDefaultValue();
    this.label = "Label"
  }
}
