import * as React from 'react';
import { Input } from 'reactstrap';

export interface IMediaFileButtonProps {
  onFileChange: (arg: File) => void;
  onPreviewReady?: CallableFunction;
  className?: string;
  id?: any
}

export interface IMediaFileButtonState {
}

export default class MediaFileButton extends React.Component<IMediaFileButtonProps, IMediaFileButtonState> {
  constructor(props: IMediaFileButtonProps) {
    super(props);

    this.state = {
    }
  }

  handleChange = (e) => {
    let file: File = e.target.files[0];
    this.props.onFileChange(file);
    if (this.props.onPreviewReady) this.loadPreview(file);
  }

  loadPreview = (file: File) => {
    let reader: FileReader = new FileReader();
    reader.onloadend = () => {
      this.props.onPreviewReady(reader.result);
    }
    reader.readAsDataURL(file)
  }

  public render() {
    return (
      <div className={this.props.className || ""}>
        <Input onChange={this.handleChange} type="file" name="file" id={this.props.id || "post-file"} className="media-file"/>
        <label className="m-0" htmlFor={this.props.id || "post-file"}>
          {this.props.children}
        </label>
      </div>
    );
  }
}
