import * as React from 'react';
import Moment from 'react-moment';
import { messagingBloc } from '../../bloc/MessagingBloc';
import StaticMessage from '../../models/StaticMessage';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import AttachmentComponent from './AttachmentComponent';
import ReturnButton from '../buttons/ReturnButton';
import { Button } from 'reactstrap';
import DOMService from '../../services/DOMService';
import NewMessageModal from '../modal/NewMessageModal';
import TextIcon from '../common/TextIcon';

export interface IMessageShowProps {
  id: number
}

export interface IMessageShowState {
  message: StaticMessage
}

export default class StaticMessageShow extends React.Component<IMessageShowProps, IMessageShowState> {
  constructor(props: IMessageShowProps) {
    super(props);

    this.state = {
      message: null
    }
  }

  async componentDidMount() {
    let message = await messagingBloc.messageManager.show(this.props.id);
    messagingBloc.read(message);
    this.setState({message})
  }

  public render() {
    const { message } = this.state;
    if (!this.state.message) return <></>
    let fullNameClass = "ml-3 fw-bold";
    if (message.isMine()) fullNameClass += " text-primary";
    return (
      <div>
        <div className="mb-2 row-between">
          <ReturnButton/>
          <Button className="d-none d-sm-block" onClick={() => DOMService.modal(<NewMessageModal/>)} color="primary">
            <TextIcon leftIcon={["fal", "plus"]}>Nouveau message</TextIcon>
          </Button>
        </div>
        <div key={message.id}>
          <div className="row-between">
            <div className="row-flex mr-3 py-2">
              <AvatarLetter size={AvatarSize.sm} user={message.user}/>
              <div className={fullNameClass}>{message.user.getFullName()}</div>
            </div>
            <div className="row-end message-date">
              <Moment fromNow>{message.createdAt}</Moment>
            </div>
          </div>
          <div className="message-show-content mb-2">
            <div>
              {message.content}
            </div>
          </div>
          { message.attachments.length > 0 && <div className="mb-3">
            Pièces jointes
          </div>}
          <div>
            { message.attachments.map(a => <AttachmentComponent key={a.id} attachment={a} />)}
          </div>
        </div>
      </div>
    );
  }
}
