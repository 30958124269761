import * as React from 'react';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import { consumeDriveBloc, IDriveBlocState, driveBloc } from '../../bloc/DriveBloc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IDriveBreadCrumbProps {
  rootFolder: boolean
}

export interface IDriveBreadCrumbState {
}

class DriveBreadCrumb extends React.Component<IDriveBlocState & IDriveBreadCrumbProps, IDriveBreadCrumbState> {

  public render() {
    const { currentFile, rootFolder } = this.props;
    if (!currentFile) return <></>
    return (
      <div className="row-flex">
        { !rootFolder &&
          <Button color="link" onClick={ () => driveBloc.returnFolder() }>
            <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
          </Button>
        }
        <Breadcrumb tag="nav" listTag="div">
          { currentFile.getPath().map((p, i) => <BreadcrumbItem className="pointer" onClick={() => driveBloc.navigateInFolder(p)} key={i} active={i === currentFile.getPath().length - 1}>{p.name}</BreadcrumbItem>) }
        </Breadcrumb>
      </div>
    );
  }
}

export default consumeDriveBloc(DriveBreadCrumb)
