import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Comment from '../../models/Comment';
import User from '../../models/User';
import SessionService from '../../services/SessionService';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import SmartInputText from '../input/SmartInputText';

export interface ICommentInputProps {
  onSubmit: (arg0: Comment) => Promise<void>;
}

export interface ICommentInputState {
  refContent: string;
  isSendable: boolean;
  showUserRef: boolean;
}


export default class CommentInput extends React.Component<ICommentInputProps, ICommentInputState> {

  public userReference: User[] = [];
  public comment: Comment;

  constructor(props: ICommentInputProps) {
    super(props);

    this.state = {
      refContent: "",
      showUserRef: false,
      isSendable: false
    }

    this.comment = this.instanceComment();
  }

  instanceComment(): Comment {
    return new Comment({content: ""}).fromMe()
  }

  onSubmit = async () => {
    await this.props.onSubmit(this.comment);
    this.comment = this.instanceComment()
    this.setState({});
  }


  public render() {
    return (
      <div className="comment-input">
        <AvatarLetter user={SessionService.user} size={AvatarSize.xs} />
        <SmartInputText
          model={this.comment}
          name="content"
          type="textarea"
          onEnter={() => this.onSubmit()}
          className="round ml-2"
          placeholder="Ecrivez votre commentaire ..."
        />
        <div onClick={() => this.onSubmit()} className="send-btn">
          <FontAwesomeIcon icon={["fal", "comment"]}/>
        </div>
      </div>
    );
  }
}
