import { debounce } from 'lodash';
import * as React from 'react';
import Model from '../../models/Model';

export interface ISmartInputProps {
  model?: Model;
  name?: string;
  value?: string;
  label?: string;
  floating?: boolean;
  autoSave?: boolean;
  autoFocus?: boolean;
  noMargin?: boolean;
  rows?: number;
  required?: boolean;
  className?: string;
  disable?: boolean;
  placeholder?: string;
  id?: string;
  style?: React.CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLElement>, arg0: any) => void;
  onUpdate?: () => void;
  onModelUpdated?: (arg0: Model) => void;
  onEnter?: () => void
}

export interface ISmartInputState {
}

export default class SmartInput<P extends ISmartInputProps, S extends ISmartInputState> extends React.Component<P, S> {

  public debouncedUpdate;
  public debounceWait = 300;
  public targetProperty = "value";

  constructor(props: P) {
    super(props)
    this.debouncedUpdate = debounce(this.update, this.debounceWait);
  }

  public handleChange = (e) => {
    console.log(e.target.checked)
    const { model, name, autoSave, onChange }: P = this.props;

    const value = e.target[this.targetProperty];
    if (model) model[name] = value;
    if (onChange) onChange(e, value);
    if (autoSave) {
      this.debouncedUpdate()
    }
    console.log(model)
    this.setState({})
  }

  componentWillUnmount() {
    this.debouncedUpdate.cancel();
  }

  public update = async () => {
    const { name, model, onUpdate, onModelUpdated } = this.props;
    if (onUpdate) onUpdate();
    const updatedModel = await model.update({only: [name]});
    if (onModelUpdated) onModelUpdated(updatedModel);
  }

  getValue = () => {
    const { model, name }: any = this.props;
    return model ? model[name] : this.props[this.targetProperty];
  }
}
