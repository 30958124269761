import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import AssignableManager from '../../managers/AssignableManager';
import Group from '../../models/Group';
import User from '../../models/User';
import Colors from '../../theme/color';
import AvatarName from '../common/AvatarName';
import SearchableSelect from '../common/SearchableSelect';
import TextIcon from '../common/TextIcon';
import UserService from '../../services/UserService';
import EntityManager from '../../managers/EntityManager';

export interface IAssignableSelectMultipleProps {
  onChange: (arg: any) => void;
  defaultValue?: any
  overflowHeight?: string;
}

export interface IAssignableSelectMultipleState {
  [arg: string]: any
  users: User[],
  groups: Group[]
  groupIds: number[]
  userIds: number[]
}

export default class AssignableSelectMultiple extends SearchableSelect<IAssignableSelectMultipleProps, IAssignableSelectMultipleState> {

  constructor(props: IAssignableSelectMultipleProps) {
    super(props);

    this.state = {
      ...this.state,
      users: props.defaultValue?.users,
      groups: props.defaultValue?.groups,
      groupIds: props.defaultValue?.groupIds || [],
      userIds: props.defaultValue?.userIds || []
    }
  }

  async componentDidMount() {
    if (!this.props.defaultValue) {
      let users = await UserService.loadFriends();
      let groups = (await EntityManager.all<Group>(Group)).models;
      this.setState({users, groups});
    }
  }

  loadData = async (search = {}) => {
    let models = (await new AssignableManager().getModels(search)).models;
    this.setState({ users: models.users, groups: models.groups })
  }

  public isSelected = (model: Group | User, type: string) => {
    return this.state[type + "Ids"].includes(model.id);
  }

  public add = (id: number, type: string) => {
    this.setState({[type + "Ids"]: [...this.state[type + "Ids"], id]}, () => this.props.onChange(this.state));
  }

  public remove = (id: number, type: string) => {
    let ids = this.state[type + "Ids"].filter((i) => id !== i)
    this.setState({[type + "Ids"]: ids}, () => this.props.onChange(this.state))
  }

  getListStyle() {
    const listStyle = {}
    if (this.props.overflowHeight) {
      listStyle["maxHeight"] = this.props.overflowHeight;
      listStyle["overflow"] = "auto";
      listStyle["borderRadius"] = "3px";
      listStyle["border"] = "1px solid " + Colors.MidGray;
    }
    return listStyle;
  }

  hasNoResult() {
    return this.state.users.length + this.state.groups.length === 0;
  }

  public render() {
    const { users, groups } = this.state;
    return (
      <div className="friends-list">
        <div className="py-2">
          {this.renderSearchInput()}
        </div>
        { (users && groups && this.hasNoResult()) && <div className="p-4 d-flex">
          <div className="m-auto darkgray">
            <TextIcon leftIcon={["fal", "times-circle"]}> Aucun résultat ne corresponds à votre recherche</TextIcon>
          </div>
        </div>}
        { (users && groups && !this.hasNoResult()) && <div>
          <div style={this.getListStyle()}>
            { users.length > 0 && <div className="assignable-select-title">
              <TextIcon leftIcon={["fal", "user"]}>{"Membres"}</TextIcon>
            </div>}
            {
              users.map(u => (
                <div key={u.id} className="row-between py-1 px-3">
                  <AvatarName user={u}/>
                  { this.isSelected(u, "user") ?
                    <div className="link text-danger" onClick={() => this.remove(u.id, "user")}>Retirer</div> :
                    <div className="link text-primary" onClick={() => this.add(u.id, "user")}>Ajouter</div>
                  }
                </div>
              ))
            }
            { groups.length > 0 && <div className="assignable-select-title">
              <TextIcon leftIcon={["fal", "users-class"]}>{"Groupes"}</TextIcon>
            </div>}
            {
              groups.map(g => (
                <div key={g.id} className="row-between py-1 px-3">
                  <div className="row-flex">
                    <FontAwesomeIcon size="3x" icon={["fas", "circle"]} className="lightgray mr-3" />
                    <div className="fw-bold">
                      {g.name}
                    </div>
                  </div>
                  { this.isSelected(g, "group") ?
                    <div className="link text-danger" onClick={() => this.remove(g.id, "group")}>Retirer</div> :
                    <div className="link text-primary" onClick={() => this.add(g.id, "group")}>Ajouter</div>
                  }
                </div>
              ))
            }
          </div>
        </div>}
      </div>
    );
  }
}
