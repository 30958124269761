import * as React from 'react';
import OptionService from '../../services/OptionService';
import CrudOption from '../common/CrudOption';
import AdminView from './AdminView';

export interface IAdminEventProps {
}

export interface IAdminEventState {
}

export default class AdminEvent extends React.Component<IAdminEventProps, IAdminEventState> {

  public render() {
    return (
      <AdminView title="Gestion des évènements">
        <CrudOption optionType={OptionService.getOptionTypeByName("eventType")} />
      </AdminView>
    );
  }
}
