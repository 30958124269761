import * as React from 'react';
import { consumeRelationBloc, IRelationBlocState, relationBloc } from '../bloc/RelationBloc';
import ReturnButton from '../components/buttons/ReturnButton';
import SearchInput from '../components/input/SearchInput';
import Page from '../components/layout/Page';
import MembersList from '../components/members/MembersList';

export interface IMemberPageProps {
}

export interface IMemberPageState {
}

class MemberPage extends React.Component<IRelationBlocState, IMemberPageState> {

  constructor(props: IRelationBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { memberInput } = this.props;
    return (
      <Page>
        <div className="mb-3">
          <ReturnButton/>
        </div>
        <SearchInput value={memberInput} onClear={relationBloc.clearMemberInput} onChanged={relationBloc.changeMemberInput} onSearch={relationBloc.onSearch} placeholder="Rechercher un membre..." />
        <div className="mt-3">
          <MembersList/>
        </div>
      </Page>
    );
  }
}

export default consumeRelationBloc(MemberPage)
