import * as React from 'react';

export interface IMessageOwnerLabelProps {
}

export default function MessageOwnerLabel (props: IMessageOwnerLabelProps) {
  return (
    <div>
      
    </div>
  );
}
