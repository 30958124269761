const env = process.env.NODE_ENV

const forceProd = true;

export interface EnvConfig {
    protocol: string
    frontUrl: string
    apiUrl: string
    nodeUrl: string
}

const envConfig = {
  development: {
    protocol: 'http://',
    frontUrl: 'localhost:3000',
    apiUrl: 'localhost:8000/',
    nodeUrl: 'localhost:3500'
  },
  production: {
    protocol: 'https://',
    frontUrl: 'app.urioz.com',
    apiUrl: 'api.urioz.com/',
    nodeUrl: 'rt.urioz.com'
  }
}

let configByEnv: EnvConfig = envConfig[forceProd ? "production" : env]

export default configByEnv
