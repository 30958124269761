import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { visioBloc } from '../../bloc/VisioBloc';
import IconButton from '../buttons/IconButton';
import AvatarName from '../common/AvatarName';
import MemberDropdown from '../members/MemberDropdown';
import NewMessageModal from '../modal/NewMessageModal';
import User from '../../models/User';
import DOMService from '../../services/DOMService';

export interface IRelationItemProps {
  user: User
  withDropdown?: boolean
}

export interface IRelationItemState {
}

export default class RelationItem extends React.Component<IRelationItemProps, IRelationItemState> {
  constructor(props: IRelationItemProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { user, withDropdown } = this.props;
    return (
      <div className="member-item row-between">
        <AvatarName link user={user} withActivity renderSubTitle={(
          <div className="row-flex relation-info mt-1">
            <FontAwesomeIcon icon={["fal", "envelope"]} />
            <span className="ml-2">{user.email}</span>
            { user.phone && <>
              <div className="mx-2">
                <FontAwesomeIcon size={"sm"} className="text-primary" icon={["fas", "circle"]} />
              </div>
              <FontAwesomeIcon icon={["fal", "phone"]} />
              <span className="ml-2">{user.phone}</span>
            </>}
          </div>
        )} />
        <div>
          { withDropdown ? <MemberDropdown user={user}/> : <div className="row-flex">
            <IconButton className="mr-2" onClick={() => visioBloc.createVisio([user]) } iconData={["fal", "video"]} />
            <IconButton onClick={() => DOMService.modal(<NewMessageModal to={[user]} />)} iconData={["fal", "comment"]} />
          </div>
          }
        </div>
      </div>
    );
  }
}
