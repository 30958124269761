import React from 'react';
import sortableElement from './sortable-element';
import classNames from 'classnames';


class PlaceHolder extends React.Component<any> {
  render() {
    return (
      this.props.show &&
      <div  className={ classNames({ 'form-place-holder': true, "canDrop-holder": this.props.canDrop }) } >
        <div>Déposer un élément ici...</div>
      </div>
    );
  }
}

export default sortableElement(PlaceHolder)