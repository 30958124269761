import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import FileModel from '../../models/File';
import Attachment from '../../models/Attachment';

export interface IAttachmentRowProps {
  attachment: Attachment
  onDelete?: (arg: Attachment) => void
}

export interface IAttachmentRowState {
}

export default class AttachmentRow extends React.Component<IAttachmentRowProps, IAttachmentRowState> {

  public render() {
    const { attachment } = this.props;
    return (
      <div className="attachment-row row-between">
        <div className="row-between w-100">
          <div>
            {attachment.name}
          </div>
          <div className="mr-4">
            {FileModel.sizeText(attachment.size)}
          </div>
        </div>
        <div onClick={() => this.props.onDelete(attachment)} className="">
          <FontAwesomeIcon icon={["fal", "times"]}/>
        </div>
      </div>
    );
  }
}
