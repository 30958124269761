import RoomManager from "../managers/RoomManager";
import RoomCompositionManager from "../managers/RoomCompositionManager";
import Room from "../models/Room";
import Group from "../models/Group";
import RoomComposition from "../models/RoomComposition";
import User from "../models/User";
import OneOneRoom from "../models/OneOneRoom";
import Message from "../models/Message";
import MessageManager from "../managers/MessageManager";
import moment from 'moment';
import SocketService from './SocketService';


interface RoomCompositonParam {
  userId?: string;
  roomId?: string;
  messageId?: string;
}

class RoomService {

  public messagesManager: MessageManager = new MessageManager();
  public roomsManager: RoomManager = new RoomManager();
  public roomCompositionManager: RoomCompositionManager = new RoomCompositionManager();

  public rooms: Room[];

  public loadRoom = async (param: RoomCompositonParam): Promise<Room> => {
    return await this.roomsManager.from(param);
  }

  public getRooms = async (): Promise<Room[]> => {
    if (!this.rooms) await this.loadRooms();
    return this.rooms;
  }

  public loadRooms = async (): Promise<Room[]> => {
    this.rooms = (await this.roomsManager.index()).models;
    return this.rooms;
  }

  public refreshRooms = async () => {
    await this.loadRooms();
    SocketService.get().emitAuth();
  }

  public getRoomIds = (): number[] => {
    return this.rooms?.map((r) => r.id);
  }

  public async updateReadAt(roomId: number): Promise<void> {
    let readAt = new Date();
    await this.roomCompositionManager.read({roomId, readAt})
  }

  public getLastUsedRoom() {
    let room = this.rooms[0];
    this.rooms.forEach((r) => {
      if (r.lastMessage && moment(r.lastMessage.createdAt).isAfter(room.lastMessage.createdAt)) room = r;
    })
    return room;
  }

  public createRoom = async (group: Group, room: Room, roomCompositions: RoomComposition[]) => {
    return await this.roomsManager.create({group, room: room.toMapApi(), roomCompositions});
  }

  public findRoomWith(user: User): Room {
    return this.rooms.find((r) => r instanceof OneOneRoom && r.user.id === user.id);
  }

  public sendMessage = async (message: Message, room: Room) => {

    let newMessage = await this.messagesManager.create({ message, room })
    // let type: string = `${(room?.type || "Private")}Message`
    // let to: string[] = room?.roomCompositions.map((rc) => rc.user.id) || [room.user.id];
    // appService.activitiesService.activity(type).notif(to, [newMessage]).cumulableBy("users", sessionService.getUserId()).create();
    // SocketService.get().notify("newMessage", message, roomComposition.user.id)
    // if (response.hasError()) {
    //   newMessage = new Message(messageParam).error();
    // } else {
      // if (room.isNew()) {
        // room.id = newMessage.roomId;
        // sessionService.user.rooms.push(room);
      //   SocketService.get().emitAuth();
      // }
    // }
    newMessage.setUser();
    return newMessage;
  }

}

export default new RoomService();
