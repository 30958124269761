import Model from "./Model";
import User from './User';
import { appBloc } from '../bloc/AppBloc';
import SessionService from "../services/SessionService";

export default class Log extends Model {

  public user: User;
  public userId: number;
  public applicationId: number;
  public type: string;
  public model: any;
  public data: any;
  static modelUrl = "logs";

  constructor(json: any) {
    super(json)
    this.user   = json.user && new User(json.user)
    this.type   = json.type;
    this.userId   = json.userId;
    this.applicationId   = json.applicationId;
    this.model  = json.model;
    this.data  = json.data;
  }

  fromMe() {
    this.applicationId = appBloc.state.application.id
    this.userId = SessionService.getUserId();
    return this;
  }

}
