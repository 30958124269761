import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { consumeGroupBloc, IGroupBlocState } from '../bloc/GroupBloc';
import TextIcon from '../components/common/TextIcon';
import FriendInvitations from '../components/friends/FriendInvitation';
import FriendsList from '../components/friends/FriendsList';
import GroupsList from '../components/groups/GroupsList';
import Page from '../components/layout/Page';
import NewGroupModal from '../components/modal/GroupModal';
import DOMService from '../services/DOMService';
import RightService from '../services/RightService';
import BasePage from './BasePage';

export interface IRelationsPageProps {
}

export interface IRelationsPageState {
}

class RelationsPage extends BasePage<IGroupBlocState, IRelationsPageState> {

  public title = "Relations"

  constructor(props: IGroupBlocState) {
    super(props);

    this.setTitle();
    this.state = {
    }
  }

  public render() {
    const { groups } = this.props;
    return (
      <Page>
        <FriendInvitations/>
        <div>
          <div className="row-between mb-2">
            <div className="section-title">Relations</div>
            <NavLink className="btn btn-primary" to="relations/members"><TextIcon leftIcon={["fal", "search"]}>Rechercher des membres</TextIcon></NavLink>
          </div>
          <FriendsList/>
        </div>
        <div className="mt-4">
          <div className="row-between mb-3">
            <div className="section-title">Groupes auquels vous appartenez</div>
            { RightService.can('group') && <Button style={{whiteSpace: "nowrap"}} color="primary" onClick={() => DOMService.modal(<NewGroupModal/>)}><TextIcon leftIcon={['fal', 'plus']}>Créer un groupe</TextIcon></Button> }
          </div>
          <GroupsList groups={groups} />
        </div>
      </Page>
    );
  }
}

export default consumeGroupBloc(RelationsPage)