import Model from "./Model";
import Ticket from "./Ticket";

export default class Column extends Model {

  public name: string;
  public color: string;
  public style: string;
  public position: number;
  public projectId: number
  static modelUrl = "columns";
  public tickets: Ticket[];

  public alertTitle: string = "Supprimer cette colonne ?";
  public confirmMessage: string = "En supprimant cette colonne, tous les tickets associés seront supprimé également.";

  constructor(json: any) {
    super(json)
    this.name  = json.name;
    this.color = json.color;
    this.style = json.style;
    this.position = json.position;
    this.projectId = json.projectId;
    this.tickets       = this.mapArrayModel(Ticket, json.tickets) || [];
  }

  hasTickets() {
    return this.tickets.length > 0;
  }

}
