import Comment from '../Comment';
import Model from '../Model';
import MorphMixin from './MorphMixin';

export default class Commentable extends MorphMixin<Comment> {

  public morphName = "commentable";

  constructor(model: Model, json) {
    super(model);
    this.models = this.parentModel.mapArrayModel(Comment, json.comments)
  }

}