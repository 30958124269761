import EntityManager from "./EntityManager";
import Message from "../models/Message";


export default class MessageManager extends EntityManager<Message> {

  protected modelUrl: string = "messages";

  protected modelClass = Message;

}
