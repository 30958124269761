import { IconName } from '@fortawesome/fontawesome-svg-core';
import FormElement from '../FormElement';
import { FormElementType } from '../FormElement';
import Attachable from '../../mixin/Attachable';

export default class FileSelect extends FormElement {

  public humanName = "Sélecteur de fichier"
  public icon: IconName = "file-minus"
  public typeKey = FormElementType.FileSelect;
  public key = "FileSelect";
  public attachable: Attachable;

  public isSubmittable = true;
  public isField = true;

  constructor(json) {
    super(json)
    this.attachable = new Attachable(this, {})
  }

  setDefaultValue() {
    super.setDefaultValue();
    this.label = "Label"
  }
}
