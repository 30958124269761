import * as React from 'react';
import ToastrService from '../../services/ToastrService';
import Model from '../../models/Model';
import EntityManager from '../../managers/EntityManager';
import Loading from '../common/Loading';

export interface ICrudAdminProps<T extends Model> {
  em: EntityManager<T>
  afterAction?: (action: string, model?: T) => void
  render: (arg: T[], arg2: (model: T) => void, arg3: () => void, arg4: (model: T) => void) => JSX.Element
}

export interface ICrudAdminState<T> {
  models: T[]
  creating: boolean
}

export default class CrudAdmin<T extends Model> extends React.Component<ICrudAdminProps<T>, ICrudAdminState<T>> {

  constructor(props: ICrudAdminProps<T>) {
    super(props);

    this.state = {
      models: null,
      creating: false
    }
  }

  componentDidMount() {
    this.load();
  }

  public load = async () => {
    let models = (await this.props.em.index()).models;
    this.setState({models});
  }

  public updateAll = async () => {
    await this.props.em.update({models: this.state.models});
    ToastrService.toaster.show("Urioz", "Les modifications ont bien été prises en compte.");
    this.props.afterAction && this.props.afterAction("updateAll", null);
  }

  public create = async (model: T) => {
    this.setState({creating: true})
    let createdModel = await this.props.em.create(model);
    this.setState({ creating: false, models: [...this.state.models, createdModel] });
    this.props.afterAction && this.props.afterAction("create", createdModel);
  }

  public delete = async (model: T) => {
    await model.delete();
    this.props.afterAction && this.props.afterAction("delete");
    this.load();
  }

  public render() {
    if (!this.state.models) return <div className="row-center">
      <Loading/>
    </div>
    return this.props.render(this.state.models, this.create, this.updateAll, this.delete);
  }
}
