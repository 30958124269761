import * as React from 'react';
import Form from '../../models/form/Form';
import classNames from 'classnames';
import TextIcon from '../common/TextIcon';

export interface IFormListProps {
  forms: Form[]
  onSelectForm: (form: Form) => void
  selectedForm: Form
}

export interface IFormListState {
}

export default class FormList extends React.Component<IFormListProps, IFormListState> {
  constructor(props: IFormListProps) {
    super(props);

    this.state = {
      forms: []
    }
  }

  public render() {
    const { forms, onSelectForm, selectedForm } = this.props;
    return (
      <div>
        <div className="mb-3">Formulaires</div>
        { forms.map(form => (
          <div key={form.id} onClick={() => onSelectForm(form)} className="mb-2">
            <div className={classNames({"selectable-row": true, "active": selectedForm?.id === form.id})}>
              <TextIcon leftIcon={["fal", "file-check"]} >{form.title + " (" + form.submissionsCount + ")"}</TextIcon>
            </div>
          </div>
        )) }
      </div>
    );
  }
}
