import * as React from 'react';
import { consumeSearchBloc, ISearchBlocState } from '../bloc/SearchBloc';
import Page from '../components/layout/Page';
import MemberItem from '../components/members/MemberItem';
import EventSearchItem from '../components/search/EventSearchItem';
import FileSearchItem from '../components/search/FileSearchItem';
import GroupSearchItem from '../components/search/GroupSearchItem';
import SearchTitle from '../components/search/SearchTitle';

export interface ISearchPageProps {
}

export interface ISearchPageState {
}

class SearchPage extends React.Component<ISearchPageProps & ISearchBlocState, ISearchPageState> {
  constructor(props: ISearchPageProps & ISearchBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {models} = this.props;
    if (!models) return <div></div>
    return (
      <Page>
        <div className="section-title">Résultat de votre recherche</div>
        { models?.event?.length > 0 && <SearchTitle icon={["fal", "calendar"]} title={"Evenements"} count={models?.event.length}/>}
        { models?.event?.map((e) => <EventSearchItem  key={e.id} event={e} />)}
        { models?.file?.length > 0 && <SearchTitle icon={["fal", "file-search"]} title={"Fichiers/Dossiers"} count={models?.file.length}/>}
        { models?.file?.map((e) => <FileSearchItem  key={e.id} file={e} />)}
        { models?.user?.length > 0 && <SearchTitle icon={["fal", "user"]} title={"Membres"} count={models?.user.length}/>}
        { models?.user?.map((e) => <MemberItem key={e.id} user={e} />)}
        { models?.group?.length > 0 && <SearchTitle icon={["fal", "users-class"]} title={"Groupes"} count={models?.group.length}/>}
        { models?.group?.map((e) => <GroupSearchItem  key={e.id} group={e} />)}
      </Page>
    );
  }
}

export default consumeSearchBloc(SearchPage)
