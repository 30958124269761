import * as React from 'react';
import { DropTarget } from 'react-dnd';
import { driveBloc } from '../../bloc/DriveBloc';
import File from '../../models/File';

export interface IFolderRowProps {
  file: File
}

export interface IFolderRowState {
}

let targetCollect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    item: monitor.getItem(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()}
}

const target = {
  drop(props, monitor, component: FolderTarget) {
    let item = monitor.getItem()
    if (props.file.id === item.model.id) return;
    driveBloc.moveInCurrentFile(props.file, item.model)
    return { moved: true }
  }
}

class FolderTarget extends React.Component<any> {

  render() {
    const { isOver, canDrop } = this.props
    let classDrop = 'folderBase'
    // if (canDrop) {
    //   classDrop = 'canFolder'
    // }
    if (isOver && canDrop) {
      classDrop = 'overFolder'
    }
    return this.props.connectDropTarget(
      <div className={classDrop}>
        {this.props.children}
      </div>
    );
  }

}

export default DropTarget("file", target, targetCollect)(FolderTarget)