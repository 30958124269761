import * as React from 'react';
import { Button, Input, ModalBody } from 'reactstrap';
import { appBloc } from '../../bloc/AppBloc';
import UserManager from '../../managers/UserManager';
import User from '../../models/User';
import DOMService from '../../services/DOMService';
import RightService from '../../services/RightService';
import ToastrService from '../../services/ToastrService';
import TextIcon from '../common/TextIcon';
import CustomModalHeader from './customModalHeader';

export interface IRightsModalProps {
  user: User
}

export interface IRightsModalState {
  rightTypeIds: number[]
  hasChange: boolean
}

export default class RightsModal extends React.Component<IRightsModalProps, IRightsModalState> {

  constructor(props) {
    super(props);

    this.state = {
      rightTypeIds: [],
      hasChange: false
    }
  }

  onUpdate = async () => {
    const { user } = this.props;
    await new UserManager().update({id: user.id, roleId: user.role.id});
    ToastrService.toaster.show("Urioz", `Les droits de ${user.getFullName()} ont été mis à jour. `)
    DOMService.close();
  }

  handleChangeRole = (e) => {
    let roleId = e.target.value;
    this.props.user.role = appBloc.state.roles.find(r => r.id.toString() === roleId.toString());
    this.setState({hasChange: true})
  }

  public render() {
    const { user } = this.props;
    return (
      <div className="rights-modal">
        <CustomModalHeader title={`Droits de ${user.getFullName()}`} />
        <ModalBody>
          <Input type="select" onChange={this.handleChangeRole} value={user.role.id} label={`Rôle`}>
            { appBloc.state.roles.map((et) => <option key={et.id} value={et.id}>{et.name}</option> )}
          </Input>
          { user.isAdmin() ? <div className="p-2">Cet utilisateur est admin</div> : RightService.rightTypes.map(rt => (
            <div className="p-2">
              { user.role.can(rt.id) ?
              <TextIcon key={rt.id} className="text-success" leftIcon={["fal", "check"]}>{rt.label}</TextIcon> :
              <TextIcon key={rt.id} className="text-danger" leftIcon={["fal", "times"]}>{rt.label}</TextIcon>
              }
            </div>
          ))}
        </ModalBody>
        <div className="row-end p-3">
          <Button disabled={!this.state.hasChange} onClick={this.onUpdate} color="primary">Valider les modifications</Button>
        </div>
      </div>
    );
  }
}
