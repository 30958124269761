import * as React from 'react';
import { consumeAppBloc, IAppBlocState } from '../../bloc/AppBloc';

export interface IFluxRssProps {
}

export interface IFluxRssState {
  activeIndex: number;
}

class FluxRss extends React.Component<IAppBlocState, IFluxRssState> {

  private interval;

  constructor(props: IAppBlocState) {
    super(props);

    this.state = {
      activeIndex: 0
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { activeIndex } = this.state;
      let index = activeIndex;
      if (activeIndex === this.props.news.length - 1) index = 0;
      else index++
      this.setState({activeIndex: index})
    }, 6000)
  }


  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getAnimationDelay(index, total) {
    let delay = 0.5 / total;
    return (index * delay).toString() + "s";
  }

  public render() {
    const { news } = this.props;
    const { activeIndex } = this.state;
    if (news.length === 0) return <></>
    return (
      <>
        <div className="flux-rss d-none d-md-flex">
          <div className="flux-text">
            {news[activeIndex].content}
            {/* {news[activeIndex].content.split("").map((letter, i) => <span style={{animationDelay: this.getAnimationDelay(i, news[activeIndex].content.length)}} key={activeIndex + "-" + i} className={"letter"}>{letter}</span>)} */}

          </div>
        </div>
        <div className="flux-rss d-flex d-md-none">
          {news[activeIndex].content}
        </div>
      </>
    );
  }
}

export default consumeAppBloc(FluxRss)
