import EntityManager from "./EntityManager";
import Relationship from "../models/Relationship";
import User from "../models/User";


export default class RelationshipManager extends EntityManager<Relationship> {

  protected modelUrl: string = "relationships";

  protected getEntity(json: any): Relationship {
    return new Relationship(json);
  };

  public cancelFriend = async (user: User) => {
    await this.post("cancel", user.toMap())
    // SocketService.get().notify("notif", null, [user.id]);
  }

  public acceptFriend = async (user: User) => {
    await this.post("accept", user.toMap())
    // appService.activitiesService.activity("FriendAcceptation")
    //                             .notif([user.id])
    //                             .create();
  }

  public requestFriend = async (user: User) => {
    await this.post("request", user.toMap())
    // appService.activitiesService.activity("FriendRequest")
    //                             .notif([user.id])
    //                             .create();
  }

}
