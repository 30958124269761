import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IRoomBlocState, roomBloc, consumeRoomBloc } from '../../bloc/RoomBloc';
import TextIcon from '../common/TextIcon';
import IconButton from '../buttons/IconButton';
import { Media, Input } from 'reactstrap';
import Dropdown, { DropDownPosition, DropDownDirection } from '../common/Dropdown';
import Picker from 'emoji-mart/dist-es/components/picker/picker';
import 'emoji-mart/css/emoji-mart.css';
import MediaFileButton from '../buttons/MediaFileButton';
import Loading from '../common/Loading';
import Colors from '../../theme/color';

export interface IRoomBottomBarProps {
  roomInput: string
}

export interface IRoomBottomBarState {
}

class RoomBottomBar extends React.Component<IRoomBlocState, IRoomBottomBarState> {

  constructor(props: IRoomBlocState) {
    super(props);

    this.state = {
    }

    roomBloc.inputRef = React.createRef();
  }

  public onPress = (e) => {
    if (e.key === "Enter") roomBloc.sendMessage();
  }

  public onSelect = (e) => {
    roomBloc.handleChange(this.props.roomInput + " " + e.native);
    roomBloc.focusInput()
  }

  public render() {
    const { roomInput, showFilePreview, previewUrl, showReplyPreview, activeRoom, isSending } = this.props;
    if (!activeRoom) return <></>;
    return (
      <div className="room-bottom-bar">
        { showReplyPreview && (
          <div className="reply-preview">
            <div className="">
              <div className="dark-gray fw-600 mb-2">
                <TextIcon leftIcon={["fal", "share"]}>
                  Vous répondez à <span className="fw-bold">{roomBloc.replyMessage.user.getFullName()}</span>
                </TextIcon>
              </div>
              <div className="gray">
                {roomBloc.replyMessage.content}
              </div>
            </div>
            <IconButton onClick={roomBloc.closeReply} iconData={["fal", "times"]}/>
          </div>
        )}
        { showFilePreview && (
          <div className="file-preview">
            <div className="d-flex">
              <Media className="img-preview" object src={previewUrl} />
              <div className="px-3 file-info">
                <div className="filename">{ roomBloc?.file?.name }</div>
                {/* <Filesize size={roomBloc?.file?.size} /> */}
              </div>
            </div>
            <IconButton onClick={roomBloc.closeFilePreview} iconData={["fal", "times"]}/>
          </div>
        )}
        <div className="row-between position-relative mx-3 mb-3">
          <div className="bottom-bar-actions">
            <Dropdown
              dontCloseOnClick
              position={DropDownPosition.top}
              direction={DropDownDirection.right}
              spacing={"1rem"}
              button={
                <div className="emojis-picker-btn">
                  <FontAwesomeIcon icon={["fal", "smile-beam"]} fixedWidth/>
                </div>
              }
              content={
                <Picker
                  emoji={"stuck_out_tongue_winking_eye"}
                  set='apple'
                  title={"Urioz"}
                  onSelect={this.onSelect}
                />
              }
            />
            <MediaFileButton className="add-media-btn" onPreviewReady={(previewUrl) => roomBloc.setPreviewUrl(previewUrl)} onFileChange={roomBloc.onLoadFile} >
              <FontAwesomeIcon icon={["fal", "photo-video"]}/>
            </MediaFileButton>
          </div>
          <Input
            innerRef={roomBloc.inputRef}
            autoFocus
            onKeyPress={this.onPress}
            onChange={(e) => roomBloc.handleChange(e.target.value)}
            value={roomInput}
            className="round"
            placeholder="Ecrivez votre message ..."
            style={{paddingLeft: "7rem"}}
          />
          { isSending ? <div><Loading color={Colors.primary} /></div> : <div onClick={() => roomBloc.sendMessage()} className="send-btn">
            <FontAwesomeIcon icon={["fal", "paper-plane"]}/>
          </div>}
        </div>
      </div>
    );
  }
}

export default consumeRoomBloc(RoomBottomBar);
