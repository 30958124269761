import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { consumeNotifBloc, notifBloc } from '../../bloc/NotifBloc';
import Notification from '../../models/notif/Notification';
import DOMService from '../../services/DOMService';
import TextIcon from './TextIcon';

export interface INotifierProps {
}

export interface INotifierState {
  notification: Notification
  show: boolean
  component: any
}

class Notifier extends Component<INotifierProps & any, INotifierState> {
  constructor(props: INotifierProps & any) {
    super(props);

    this.state = {
      notification: null,
      component: null,
      show: false,
    }

    DOMService.setNotifier(this);
  }


  show(element: Notification | JSX.Element) {
    let state = {show: true}
    if (element instanceof Notification) state["notification"] = element;
    else                                 state["component"] = element;
    this.setState(state);
    setTimeout(() => this.setState({show: false, notification: null, component: null}), 6000);
  }

  close() {
    this.setState({
      notification: null,
      component: null,
      show: false
    })
  }

  public render() {
    const { notification, show, component } = this.state;
    if (!show) return <></>;
    return (
      <div className="push-notif" onClick={() => this.setState({show: false})}>
        { notification &&
          <>
            <div className="push-notif-header">
              <TextIcon leftIcon={["fal", "bell"]} className="push-notif-title p-2">{notification.humanName()}</TextIcon>
              <div onClick={() => this.setState({show: false})} className="p-2 close-btn">
                <FontAwesomeIcon icon={["fal", "times"]}/>
              </div>
            </div>
            <div className="push-notif-body">
              { notifBloc.getNotifComponent(notification)}
            </div>
          </>
        }
        { component }
      </div>
    );
  }
}

export default consumeNotifBloc(Notifier)
