import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import DatePicker from 'react-date-picker';
import { Input } from 'reactstrap';
import SmartInput from './SmartInput';

export interface ISmartDatePickerProps {
  withTime?: boolean
}

export default class SmartDatePicker extends SmartInput<any, {}> {

  public hourOptions = [];

  constructor(props: any) {
    super(props)

    for (let i = 0; i < 24; i++) {
      this.hourOptions.push({label: `${i}:00`, value: {hour: i, minute: 0}})
      this.hourOptions.push({label: `${i}:30`, value: {hour: i, minute: 30}})
    }
  }

  getHourIndex = (date: Date): number => {
    return this.hourOptions.findIndex((o) => o.value.hour === date.getHours() && o.value.minute === date.getMinutes()) || 0 ;
  }

  onSelectHour = (e) => {
    const { model, name, onChange } = this.props;
    let hourValue = this.hourOptions[e.target.value].value;
    if (model) {
      model[name].setHours(hourValue.hour);
      model[name].setMinutes(hourValue.minute);
    }
    if (onChange) onChange(e, hourValue);
  }

  onSelectDate = (date: Date) => {
    const { model, name, onChange } = this.props;
    if (model) {
      model[name].setMonth(date.getMonth());
      model[name].setDate(date.getDate())
      model[name].setFullYear(date.getFullYear());
    }
    if (onChange) onChange(null, date);
  }

  public render() {
    const { name, label, withTime }: any = this.props;
    return (
      <div className={ classNames({ "smart-datepicker floating-input mb-4": true, "with-time": withTime})}>
        <DatePicker
          className={!withTime ? "form-control" : ""}
          name={name}
          clearIcon={null
          }
          calendarIcon={
            <FontAwesomeIcon className="darkgray text-large" icon={["fal", "calendar"]}/>
          }
          onChange={this.onSelectDate}
          value={this.getValue()}
        />
        { withTime &&
          <Input defaultValue={this.getHourIndex(this.getValue())} onChange={this.onSelectHour} type="select" name="endAtTime" >
            { this.hourOptions.map((o, index) => <option key={index} value={index}>{o.label}</option>) }
          </Input>
        }
        { (label) && <label className="floating-label" htmlFor={name}>{label}</label>}
      </div>
    );
  }
}
