import { AxiosResponse } from "axios";
import Event from "../models/Event";
import File from "../models/File";
import Group from '../models/Group';
import User from '../models/User';
import EntityManager from "./EntityManager";
import Ticket from '../models/Ticket';

export default class SearchManager extends EntityManager<any> {

  protected modelUrl: string = "searchItems";

  public toModelByType(searchModel) {
    let camelCaseJson = EntityManager.toCamelCase(searchModel);
    let dataParsed = camelCaseJson.data;
    switch (camelCaseJson.modelName) {
      case "user": return new User(dataParsed)
      case "ticket": return new Ticket(dataParsed)
      case "event": return new Event(dataParsed)
      case "group": return new Group(dataParsed)
      case "file": return new File(dataParsed)
      default: return null;
    }
  }

  protected buildIndexResponse(response: AxiosResponse) {
    let models = response.data.models;
    return {
      models: models.map(m => this.toModelByType(m)),
      count: response.data.count || response.data.models.length
    }
  }

  // public async index(param: Object = {}): Promise<IndexResponse<Search>> {
  //   let response: AxiosResponse = await ApiService.get(this.modelUrl, param);
  //   if (response.status === 200) {
  //     let map = response.data.models.map((m) => {
  //       this.toModel(m)
  //     });
  //     return map;
  //     // return this.buildIndexResponse(map)
  //   }
  //   else this.displayError(response);
  //   return {};
  // }

  search(text: string) {
    return this.index({search: text})
  }

}
