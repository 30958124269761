import classNames from 'classnames';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { Card, Col, Input } from 'reactstrap';
import { driveBloc } from '../../bloc/DriveBloc';
import File from '../../models/File';
import FileIcon from './fileIcon';
import FileItem from './FileItem';
import MovingFrame from './MovingFrame';

export interface IFileCardProps {
  file: File
}

export interface IFileCardState {
}

export default class FileCard extends FileItem {

  public render() {
    const { file, containerIsDragging } = this.props;
    const { filename, isRenaming, isMoving, showTooltip } = this.state;
    return (
      <Dropzone noClick onDrop={droppedFile => driveBloc.onUpload(droppedFile[0], file.isFolder() ? file : null)}>
        {({getRootProps, getInputProps, isDragActive}) => {
          return <Col xs={12} sm={6} md={4} lg={3} className={classNames({
              'file-dragging': containerIsDragging && !file.isFolder(),
              'folder-dragging': isDragActive && file.isFolder(),
              'mb-3': true,
              'px-2': true
            })}>
            <div
              className="position-relative"
              {...getRootProps()}
              onMouseLeave={this.hideTooltip}
              onMouseEnter={this.debouncedShowTooltip}
            >
              <Card onClick={this.onDoubleClick}>
                <div className="text-center">
                  <FileIcon preview height={"80px"} file={file} />
                  <div className="file-card-dropdown" onMouseEnter={this.hideTooltip}>
                    {this.renderDropdown()}
                  </div>
                </div>
                <div className="divider mx-2"></div>
                <div className="file-card-label">
                  {/* <div className="file-card-name"> */}
                  { isRenaming ?
                    <Input onKeyPress={(e) => { if (e.key === "Enter") this.updateName()}} innerRef={this.inputRef} value={filename} onChange={this.handleRename} autoFocus /> :
                    file.name}
                  </div>
                  {/* <div>
                    {file.getSizeText()}
                  </div> */}
                {/* </div> */}
              </Card>
              { isMoving && <div ref={this.movingFrameRef}><MovingFrame onSubmit={(this.close)} movingFile={file} /></div>}
              { showTooltip && this.renderTooltip()}
            </div>
          </Col>
        }}
      </Dropzone>
    );
  }
}
