import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';

export interface ISidebarItemProps {
  minimize?: boolean;
  to: string;
  iconData: IconProp;
  label: string;
  exact?: boolean
  childRender?: any
}


export default function SidebarItem (props: ISidebarItemProps) {
  return (
    <NavLink key={props.label} exact={props.exact} to={props.to}>
      <div className={classNames({"sidebar-item": true})}>
        <div className="sidebar-item-icon">
          <FontAwesomeIcon icon={props.iconData} />
        </div>
        {props.childRender && <div>{ props.childRender }</div>}
        {!props.minimize && <span className="sidebar-item-label">{props.label}<span className="sidebar-border"></span></span>}
      </div>
    </NavLink>
  );
}

