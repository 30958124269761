import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import CheckItem from '../../models/CheckItem';
import EditableText from '../common/EditableText';
import SmartCheckbox from '../input/SmartCheckbox';

export interface ICheckItemComponentProps {
  checkItem: CheckItem
  onToggleCheck?: (checkItem: CheckItem) => void
  deleteCheckItem?: (checkItem: CheckItem) => void
  updateCheckItem?: (checkItem: CheckItem) => void
}

export interface ICheckItemComponentState {
}

export default class CheckItemComponent extends React.Component<ICheckItemComponentProps, ICheckItemComponentState> {


  public render() {
    const { checkItem, onToggleCheck, deleteCheckItem } = this.props;
    return (
      <div className={classNames({"check-item": true, "is-done": checkItem.isDone})}>
        <div className="row-flex w-100">
          <SmartCheckbox
            checked={checkItem.isDone}
            onChange={(e) => onToggleCheck(checkItem)}
          />
          <div className="check-item-content mx-2 w-100">
            <EditableText model={checkItem} name="content" light />
          </div>
        </div>
        <div onClick={() => deleteCheckItem(checkItem)} className="gray-btn">
          <FontAwesomeIcon icon={["fal", "times"]}/>
        </div>
      </div>
    );
  }
}
