import EntityManager, { IndexResponse } from "./EntityManager";
import StaticMessage from "../models/StaticMessage";

export default class StaticMessageManager extends EntityManager<StaticMessage> {

  protected modelUrl: string = "staticMessages";

  protected getEntity(json: any): StaticMessage {
    return new StaticMessage(json);
  };

  async getSendedMessages() {
    return await (this.get("sended")) as Promise<IndexResponse<StaticMessage>>;
  }

  async getImportantMessage() {
    return await (this.get("imp")) as Promise<IndexResponse<StaticMessage>>;
  }

  async getTalk(id): Promise<StaticMessage[]> {
    let response = await (this.get("talk/" + id)) as Promise<IndexResponse<StaticMessage>>;
    return (await response).models
  }

  async getUnreadCount(): Promise<number> {
    return await this.get("unreadCount", {}, true) as number;
  }

}
