import * as React from 'react';
import { Button, ModalBody } from 'reactstrap';
import Event from '../../models/Event';
import TextIcon from '../common/TextIcon';
import EventShow from '../events/EventShow';
import EventForm from '../form/EventForm';
import MembersSelectMultiple from '../members/MembersSelectMultiple';
import User from '../../models/User';
import EntityManager from '../../managers/EntityManager';
import Invitation from '../../models/Invitation';
import SessionService from '../../services/SessionService';
import DOMService from '../../services/DOMService';
import ToastrService from '../../services/ToastrService';

export interface IEventModalProps {
  event?: Event
}

export default class EventModal extends React.Component<IEventModalProps, any> {

  public invitedUsers: User[] = [];

  constructor(props) {
    super(props);
    this.state = {
      view: "show"
    }
  }

  onEdit = () => {
    this.setState({view: "edit"});
  }

  onInvite = () => {
    this.setState({view: "invite"});
  }

  onReturn = () => {
    this.setState({view: "show"});
  }

  handleUsers = (users: User[]) => {
    this.invitedUsers = users;
  }

  toInvitations(users: User[], event: Event) {
    return users.map(u => { return {userId: u.id, eventId: event.id, createdBy: SessionService.getUserId()}})
  }

  sendInvitation = async () => {
    const { event } = this.props;
    await EntityManager.createMany(Invitation, this.toInvitations(this.invitedUsers, event))
    DOMService.close();
    ToastrService.toaster.show("Urioz", "Les invitations ont été envoyé !")
  }

  public render() {
    return (
      <div className="event-modal">
        { this.state.view !== "show" && < div className="p-3" style={{ cursor: "pointer" } } onClick={() => this.onReturn() }>
        <TextIcon leftIcon={ ["fal", "chevron-left"] } >Retour</TextIcon>
      </div>
        }
        <ModalBody>
          { this.state.view === "show"   && <EventShow onInvite={() => this.onInvite()} onEdit={() => this.onEdit()} event={this.props.event} />}
          { this.state.view === "edit"   && <EventForm onlyForm edit event={this.props.event}/>}
          { this.state.view === "invite" &&
            <div>
              <MembersSelectMultiple onChange={ this.handleUsers } overflowHeight={ "550px" } />
              <div className="row-end mt-2">
                <Button onClick={() => this.sendInvitation()} color="primary">Envoyer les invitations</Button>
              </div>
            </div>
          }
        </ModalBody>
      </div>
    );
  }
}
