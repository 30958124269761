import { IconName } from '@fortawesome/fontawesome-svg-core';
import FormElement from '../FormElement';
import { FormElementType } from '../FormElement';

export default class TextArea extends FormElement {

  public humanName = "Champ de texte multi-ligne"
  public icon: IconName = "keyboard"
  public typeKey = FormElementType.TextArea;
  public key = "TextArea"

  public isSubmittable = true;
  public isField = true;

  setDefaultValue() {
    super.setDefaultValue();
    this.label = "Label"
  }
}
