import { AxiosResponse } from "axios";
import Event from "../models/Event";
import File from "../models/File";
import Model from '../models/Model';
import StaticMessage from '../models/StaticMessage';
import EntityManager from "./EntityManager";

export default class HomeManager extends EntityManager<any> {

  protected modelUrl: string = "activities";


  public toModelByType(json) {
    let camelCaseJson = EntityManager.toCamelCase(json)
    switch (camelCaseJson.modelName) {
      case "event": return new Event(camelCaseJson)
      case "staticMessage": return new StaticMessage(camelCaseJson)
      case "file": return new File(camelCaseJson)
      default: return null;
    }
  }

  protected buildIndexResponse(response: AxiosResponse) {
    let models: any = Object.values(response.data.models);
    return models.map(m => this.toModelByType(m))
  }

  getModels() {
    return this.index() as Promise<Model[]>
  }

}
