import * as React from 'react';
import PublicFormContainer from '../components/form/PublicFormContainer';
import FormShow from '../components/formBuilder/FormShow';
import EntityManager from '../managers/EntityManager';
import Form from '../models/form/Form';

export interface IPublicPageProps {
  appId: string
}

export interface IPublicPageState {
  form: Form
}

export default class PublicPage extends React.Component<IPublicPageProps, IPublicPageState> {
  constructor(props: IPublicPageProps) {
    super(props);

    this.state = {
      form: null
    }
  }

  async componentDidMount() {
    let form = await EntityManager.get<Form>(Form, "url", {formUrl: window.location});
    this.setState({ form });
  }

  public render() {
    const { form } = this.state;
    if (!form) return <></>;
    return (
      <PublicFormContainer form={ form } >
        <FormShow form={ form } />
      </PublicFormContainer>
    );
  }
}
