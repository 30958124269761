import * as React from 'react';
import SearchInput from '../input/SearchInput';


export default abstract class SearchableSelect<P, S> extends React.Component<P & {placeholder?: string}, S & any> {

  inputRef = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);
    this.state = {
      input: ""
    }
  }

  async loadData(search) {};

  handleChange = (input) => {
    this.setState({input})
  }

  handleSearch = (search) => {
    if (!search) return;
    this.loadData({search})
  }

  onClear = () => {
    this.setState({input: ""});
    this.inputRef.current.focus();
  }

  renderSearchInput() {
    return <SearchInput
      innerRef={this.inputRef}
      className="discret-input"
      placeholder={this.props.placeholder || "Rechercher"}
      onSearch={this.handleSearch}
      onChanged={this.handleChange}
      onClear={() => this.onClear()}
      value={this.state.input}
    />
  }

}
