import * as React from 'react';
import { ModalBody } from 'reactstrap';
import ProjectForm from '../form/ProjectForm';
import CustomModalHeader from './customModalHeader';

export interface IProjectModalProps {
}

export default class ProjectModal extends React.Component<IProjectModalProps, any> {

  public render() {
    const title = "Création d'un projet";
    return (
      <div className="share-modal">
        <CustomModalHeader title={title} />
        <ModalBody>
          <ProjectForm/>
        </ModalBody>
      </div>
    );
  }
}
