import * as React from 'react';
import { ModalBody } from 'reactstrap';
import Event from '../../models/Event';
import EventForm from '../form/EventForm';
import CustomModalHeader from './customModalHeader';

export interface INewEventModalProps {
  event?: Event
  edit?: boolean
}

export default class NewEventModal extends React.Component<INewEventModalProps, any> {

  public render() {
    const title = !this.props.edit ? "Création d'un évènement" : "Modification d'un évènement";
    return (
      <div className="share-modal">
        <CustomModalHeader title={title} />
        <ModalBody>
          <EventForm edit={this.props.edit} event={this.props.event}/>
        </ModalBody>
      </div>
    );
  }
}
