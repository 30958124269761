import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import * as React from 'react';
import Moment from 'react-moment';
import Event from '../../models/Event';
import DOMService from '../../services/DOMService';
import EventModal from '../modal/EventModal';

export interface IAlertNotifProps {
  event: Event
  onSnooze?: () => void;
}

export interface IAlertNotifState {
}

export default class AlertNotif extends React.Component<IAlertNotifProps, IAlertNotifState> {
  constructor(props: IAlertNotifProps) {
    super(props);

    this.state = {
    }
  }

  isLessThan(minute: number) {
    return moment(this.props.event.startAt).isBefore(moment().add(minute, "minute"));
  }

  snooze = (e) => {
    e.stopPropagation();
    this.props.onSnooze && this.props.onSnooze();
    DOMService.notifierRef.close();
  }

  public render() {
    const { event } = this.props;
    return (
      <div className="alert-notif" onClick={() => DOMService.modal(<EventModal event={event} />)}>
        <FontAwesomeIcon size="2x" icon={["fal", "alarm-clock"]} className="text-pink mr-2" />
        <div className="ml-3">
          <div className="capitalize">
            <Moment fromNow>{ event.startAt }</Moment>
          </div>
          <div className="fw-bold darkgray mb-3">{ event.name }</div>
          { !this.isLessThan(15) && <div onClick={this.snooze} className="link text-primary">Me rappeler dans 15 minutes</div>}
        </div>
      </div>
    );
  }
}
