import * as React from 'react';
import { Button, Input, Progress } from 'reactstrap';
import CheckItem from '../../models/CheckItem';
import Ticket from '../../models/Ticket';
import SessionService from '../../services/SessionService';
import Closable from '../common/Closable';
import CheckItemComponent from './CheckItemComponent';

export interface ICheckListProps {
  ticket: Ticket
}

export interface ICheckListState {
  newInputVisible: boolean
  newInput: string
}

export default class CheckList extends React.Component<ICheckListProps, ICheckListState> {

  constructor(props: ICheckListProps) {
    super(props);

    this.state = {
      newInputVisible: false,
      newInput: ""
    }
  }

  onAddItem = () => {
    this.setState({newInputVisible: true})
  }

  onCreateItem = async () => {
    let item = new CheckItem({
      content: this.state.newInput,
      ticketId: this.props.ticket.id,
      userId: SessionService.getUserId()
    })
    let newCheckItem = await item.create();
    this.props.ticket.checkItems.push(newCheckItem);
    this.setState({newInputVisible: false, newInput: ""})
  }

  deleteCheckItem = async (checkItem: CheckItem) => {
    await checkItem.delete();
    this.props.ticket.checkItems = this.props.ticket.checkItems.filter(i => i.id !== checkItem.id);
    this.setState({})
  }

  updateCheckItem = async (checkItem: CheckItem) => {
    await checkItem.update({only: ["content"]})
    this.setState({})
  }

  getPercent(checkItems: CheckItem[]) {
    let total = checkItems.length;
    return (checkItems.filter(item => item.isDone).length / total) * 100;
  }

  onToggleCheck = async (checkItem: CheckItem) => {
    checkItem.isDone = !checkItem.isDone;
    await checkItem.update({only: ["isDone"]})
    this.setState({})
  }

  public render() {
    const { ticket } = this.props;
    // if (checkItems.length === 0) return <></>;
    return (
      <div>
        <div className="row-between mb-1">
          <div className="darkgray fw-bold">
            Checklist
          </div>
        </div>
        <div className="mb-2">
          <Progress className="w-100 mr-2 h-100" style={{height: "10px"}} color="success" value={this.getPercent(ticket.checkItems)} />
        </div>
        <div className="check-list">
          {ticket.checkItems.map(item => <CheckItemComponent key={item.id} updateCheckItem={this.updateCheckItem} deleteCheckItem={this.deleteCheckItem} onToggleCheck={this.onToggleCheck} checkItem={item}/>)}
          <Closable show={this.state.newInputVisible} close={() => this.setState({newInputVisible: false})}>
            <Input onKeyDown={(e) => { if (e.key === "Enter") this.onCreateItem()}} autoFocus onChange={(e) => this.setState({newInput: e.target.value})} value={this.state.newInput} />
          </Closable>
        </div>
        <div className="my-3">
          <Button size="sm" onClick={this.onAddItem} color="light">
            Ajouter
          </Button>
        </div>
      </div>
    );
  }
}
