import * as React from 'react';

export interface ICountProps {
  count: number
}

export interface ICountState {
}

export default class Count extends React.Component<ICountProps, ICountState> {


  constructor(props: ICountProps) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
  }


  display (seconds) {
    const format = val => `0${Math.floor(val)}`.slice(-2)
    // const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
  
    return [minutes, seconds % 60].map(format).join(':')
  }

  public render() {
    return (
      <div className="count">
        {this.display(this.props.count)}
      </div>
    );
  }
}
