import * as React from 'react';
import { appName } from '../../config/appConfig';
import logo from '../../assets/img/urioz_logo.png'

export interface ILogoProps {
  withTitle?: boolean
  size?: any;
  style?: React.CSSProperties
  imgStyle?: React.CSSProperties
  url?: string
}

export default function Logo(props: ILogoProps) {
  return (
    <div className="logo">
      <img width={props.size || 30} style={props.imgStyle} src={props.url || logo} alt=""/>
      { props.withTitle && <div className="sidebar-title" style={{fontSize: (props.size / 2) || "2rem", marginLeft: "0.75rem"}}>{appName}</div> }
    </div>
  );
}
