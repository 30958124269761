import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Event from '../../models/Event';
import DateRangeText from '../events/DateRangeText';
import SearchItemBase from './SearchItemBase';

export interface IEventSearchItemProps {
  event: Event
}

export interface IEventSearchItemState {
}

export default class EventSearchItem extends React.Component<IEventSearchItemProps, IEventSearchItemState> {
  constructor(props: IEventSearchItemProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { event } = this.props;
    return (
      <SearchItemBase>
        <div className="row-flex">
          <div style={{flex: "0 0 300px"}} className="event-date">
            <div className="fw-bold">{event.name}</div>
            <DateRangeText withIcon event={event} />
          </div>
          <div style={{flex: 1}}>
            <div className="gray fw-600">Lieu</div>
            <div>{event.place}</div>
          </div>
          <div style={{flex: "0 0 50px"}}>
            <NavLink to={event.getFrontUrl()} className="">Voir</NavLink>
          </div>
          <div>

          </div>
        </div>
      </SearchItemBase>
    );
  }
}
