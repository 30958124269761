import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../theme/color';

export enum AvatarSize {
  xs = 35,
  sm = 45,
  md = 80,
  lg = 150,
}

export interface IAvatarProps {
  url?: string;
  size: any;
  className?: string
  title?: string
}

export default function Avatar (props: IAvatarProps) {
  let url = props?.url || "/images/profile.jpg";
  let style={
    backgroundImage: `url(${url})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: props.size,
    minWidth: props.size,
    backgroundColor: Colors.LightGray,
    height: props.size,
    display: 'flex',
    borderRadius: "50%"
  }
  return (
    <div title={props.title} style={style} className={"avatar " + props.className}>
      <div className="m-auto">
        <FontAwesomeIcon icon={["fal", "file-chart-line"]} />
      </div>
    </div>
  );
}
