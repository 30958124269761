import * as React from 'react';
import { Button, Form } from 'reactstrap';
import { groupBloc } from '../../bloc/GroupBloc';
import Group from '../../models/Group';
import User from '../../models/User';
import DOMService from '../../services/DOMService';
import RoomService from '../../services/RoomService';
import SessionService from '../../services/SessionService';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import SmartInputText from '../input/SmartInputText';
import SmartSwitch from '../input/SmartSwitch';
import MembersSelectMultiple from '../members/MembersSelectMultiple';

export interface IGroupFormProps {
  group?: Group;
}

export interface IGroupFormState {
  [arg: string]: boolean
  withRoom: boolean
  withAgenda: boolean
}

export default class GroupForm extends React.Component<IGroupFormProps, IGroupFormState> {

  public group = new Group({createdBy: SessionService.getUserId(), users: [SessionService.user]});

  constructor(props: IGroupFormProps) {
    super(props);

    this.state = {
      withRoom: !props.group?.id || true,
      withAgenda: !props.group?.id || true,
    }

    if (props.group) this.group = props.group;
  }

  onCompositionChange = (selectedUser: User[]) => {
    this.group.users = selectedUser;
    this.setState({})
  }

  isEdit() {
    return !!this.group.id;
  }

  handleSwithChange = (checked: boolean, withType: string) => {
    this.setState({[withType]: checked});
  }

  submit = async (e) => {
    e.preventDefault();
    if (!this.group.name) return;
    this.group.userIds = this.group.users.map(u => u.id);
    if (this.isEdit()) {
      await groupBloc.update(this.group);
    }
    else {
      let merge = {};
      if (this.state.withRoom)   merge["withRoom"] = true;
      if (this.state.withAgenda) merge["withAgenda"] = true;
      await groupBloc.createGroup(this.group, merge);
    }
    if (this.state.withRoom) RoomService.refreshRooms();
    DOMService.close();
  }

  public render() {
    return (
      <Form onSubmit={this.submit}>
        <SmartInputText label="Nom du groupe" name="name" model={this.group} floating />
        { !this.props.group?.id && <div className="mb-2">
          <SmartSwitch
            primary
            className="mb-2"
            name={"withRoom"}
            label={"Créer une conversation de groupe"}
            onChange={(e, checked) => this.handleSwithChange(checked, "withRoom")}
            checked={this.state.withRoom}
          />
          <SmartSwitch
            primary
            name={"withAgenda"}
            label={"Créer un agenda de groupe"}
            onChange={(e, checked) => this.handleSwithChange(checked, "withAgenda")}
            checked={this.state.withAgenda}
          />
        </div>}
        <div>
          <label htmlFor="">Membres du groupe</label>
        </div>
        <div className="flex-wrap row-flex">
          {this.group.users.map(u => <AvatarLetter title={u.getFullName()} className="mb-2 mr-2" key={u.id} size={AvatarSize.sm} user={u} />)}
        </div>
        <div>
          <label htmlFor="">Assigner des personnes au groupe</label>
        </div>
        <MembersSelectMultiple overflowHeight="350px" defaultValue={this.group.users} onChange={this.onCompositionChange} />
        <div className="row-end mt-3">
          <Button type="submit" color="primary">{this.isEdit() ? "Valider les modifications" : "Creer le groupe"}</Button>
        </div>
      </Form>
    );
  }
}
