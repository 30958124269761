import * as React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { Input } from 'reactstrap';
import { appBloc } from '../bloc/AppBloc';
import { consumeDriveBloc } from '../bloc/DriveBloc';
import DropboxDocuments from '../components/drive/DropboxDocuments';
import LocalDocuments from '../components/drive/LocalDocuments';
import Page from '../components/layout/Page';
import BasePage from './BasePage';

export interface IDrivePageProps {
  selectedGed: string
}

export interface IDrivePageState {
}

class DrivePage extends BasePage<any, IDrivePageState> {

  private pathname;
  public title = "Documents"

  constructor(props: any) {
    super(props);

    this.setTitle();
    this.state = {
    }
  }

  shouldComponentUpdate(nextProps) {
    return appBloc.pathname !== this.pathname
  }

  async componentDidMount() {
    // driveBloc.getUsedStorage();
  }

  changeGed(value) {
    localStorage.setItem("ged", value)
    appBloc.push("/documents/" + value);
  }

  getGed() {
    return localStorage.getItem("ged") || "local";
  }

  public render() {
    this.pathname = appBloc.pathname;
    return (
      <div className="drive-page">
        <div className="row-between mt-2">
          <Input className="mt-2 mx-3 back-primary" style={{width: "150px"}} type="select" onChange={(e) => this.changeGed(e.target.value)} value=    {this.getGed()}>
            <option value="local">Intranet</option>
            <option value="dropbox">Dropbox</option>
          </Input>
        </div>
        <Page className="px-0">
          <Switch>
            <Route exact path="/documents"            component={()  => <Redirect to={"/documents/" + this.getGed()}/>} />
            <Route exact path="/documents/local"      component={(p) => <LocalDocuments rootFolder/>} />
            <Route path="/documents/local/:id"        component={(p) => <LocalDocuments folderId={p.match.params?.id}/>} />
            <Route exact path="/documents/dropbox"    component={(p) => <DropboxDocuments rootFolder folderId={""}/>} />
            <Route path="/documents/dropbox/:path"    component={(p) => <DropboxDocuments folderId={p.match.params?.path}/>} />
          </Switch>
        </Page>
      </div>
    );
  }
}

export default consumeDriveBloc(DrivePage)