import FileModel from '../models/File';
export default class DocumentsService {

  public baseUrl = "";

  async createFolder(currentFile: FileModel, name: string) { }

  async upload(file: File, currentFile: FileModel, progressIndicator?) { }

  async download(fileModel?: FileModel) { }

  async load(folderId: string): Promise<any> { }

  getFileUrl(file) {
    if (!file.id) return this.baseUrl;
    return `${this.baseUrl}/${file.id}`;
  }

  getParentFileUrl(file) {
    if (!file.parentId) return this.baseUrl;
    return `${this.baseUrl}/${file.parentId}`;
  }

}
