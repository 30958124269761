import Model from "../Model";
import FormSelectOption from './FormSelectOption';

export enum FormElementType {
  TextInput = 1,
  TextArea = 2,
  Switch = 3,
  Header = 4,
  Paragraph = 5,
  Image = 6,
  HyperLink = 7,
  Tags = 8,
  DatePicker = 9,
  Select = 10,
  FileSelect = 11
}

export default class FormElement extends Model {

  public label: string;
  public typeKey: number;
  public key: string;
  public formId: number;
  public position: number;
  public style: any;
  public provisionalId: string;
  public value: any;
  public required: boolean;
  public url: string;
  public visible: boolean;
  public formSelectOptions: FormSelectOption[];

  public humanName: string;
  public isField = false;
  public hasOptions = false;
  public isCutomable = false;
  public positionnable = false;
  public sizable = false;
  public hasUrl = false;
  public isChartable = false;
  public isSubmittable = false;

  static modelUrl = "formElements";

  public file: File;

  public requiredField = ["name"]

  constructor(json: any) {
    super(json)
    this.label             = json.label;
    this.required          = json.required;
    this.url               = json.url;
    this.visible           = json.visible;
    this.position          = json.position;
    this.style             = json.style || {};
    if (json.typeKey) this.typeKey = json.typeKey;
    if (json.key) this.key = json.key;
    this.formId            = json.formId;
    this.formSelectOptions = this.mapArrayModel(FormSelectOption, json.formSelectOptions) || [];
  }

  setDefaultValue() {
    this.visible = true;
  }

  toMapApi() {
    return {
      label: this.label,
      formId: this.formId,
      position: this.position,
      style: this.style,
      required: this.required,
      url: this.url,
    }
  }

}

