import * as React from 'react';
import File from '../../models/File';
import FileIcon from './fileIcon';

export interface IFileLightRowProps {
  file: File
  onOpenFolder: (arg: File) => void
}

export interface IFileLightRowState {
}

export default class FileLightRow extends React.Component<IFileLightRowProps, IFileLightRowState> {
  constructor(props: IFileLightRowProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { file, onOpenFolder } = this.props;
    return (
      <div className="file-light-row" onClick={() => onOpenFolder(file)}>
        <div className="row-flex">
          <FileIcon height={"20px"} file={file} />
          <div className="ml-3">{file.name}</div>
        </div>
      </div>
    );
  }
}
