import * as React from 'react';
import { consumeRelationBloc, IRelationBlocState } from '../../bloc/RelationBloc';
import WaitingFriendRow from './WaitingFriendRow';
import User from '../../models/User';

export interface IFriendInvitationsProps {
}

export interface IFriendInvitationsState {
}

class FriendInvitations extends React.Component<IRelationBlocState, IFriendInvitationsState> {
  constructor(props: IRelationBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { pendings } = this.props;
    if (pendings.length === 0) return <></>
    return (
      <div>
        <div className="section-title mb-3">Invitations</div>
        { pendings.map((user: User) => <WaitingFriendRow key={user.id} user={user}/>) }
      </div>
    );
  }
}

export default consumeRelationBloc(FriendInvitations)
