import Notification from './Notification';
import Group from '../Group';

export default class GroupNotification extends Notification {

  public group: Group;

  constructor(json: any) {
    super(json);
    this.group = json.data.group && new Group(json.data.group);
  }

  link() {
    return "/relations"
  }

  humanName() {
    return "Nouveau groupe !";
  }
}