import * as React from 'react';
import { consumeSearchBloc, ISearchBlocState, searchBloc } from '../../bloc/SearchBloc';
import SearchInput from './SearchInput';

export interface IUniversalSearchProps {
}

export interface IUniversalSearchState {
}

class UniversalSearch extends React.Component<IUniversalSearchProps & ISearchBlocState, IUniversalSearchState> {
  constructor(props: IUniversalSearchProps & ISearchBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className="w-100">
        <SearchInput
          // onFocus={searchBloc.onFocus}
          value={this.props.input}
          className="universal-search"
          placeholder="Rechercher..."
          onSearch={searchBloc.onSearch}
          onChanged={searchBloc.handleChange}
          onClear={searchBloc.onClear}
        />
      </div>
    );
  }
}

export default consumeSearchBloc(UniversalSearch)
