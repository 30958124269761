import Model from "./Model";
import User from "./User";
import Room from "./Room";
import Message from "./Message";
import moment from "moment";
import SessionService from "../services/SessionService";


export default class RoomComposition extends Model {

  public readAt: Date;
  public user: User;
  public userId: number;
  public room: Room;
  static modelUrl = "roomCompositions";

  public isReadable = true;

  constructor(json: any) {
    super(json)
    this.readAt        = new Date(json.readAt);
    this.userId        = json.userId;
    this.user          = json.user && new User(json.user);
  }

  public hasRead(message: Message): boolean {
    return this.readAt && moment(this.readAt).isAfter(moment(message.createdAt));
  }

  public isMine() {
    return this.userId === SessionService.getUserId();
  }

  getRoomUrl(): string {
    return this.room.getFrontUrl();
  }

}
