import * as React from 'react';
import EntityManager from '../../managers/EntityManager';
import Submission from '../../models/form/Submission';
import Option from '../../models/Option';
import ReturnButton from '../buttons/ReturnButton';
import AvatarName from '../common/AvatarName';
import SmartSelect from '../input/SmartSelect';
import SubmissionValueDisplayer from './SubmissionValueDisplayer';

export interface ISubmissionShowProps {
  submissionId: number | string
}

export interface ISubmissionShowState {
  submission: Submission
  status: Option[]
}

export default class SubmissionShow extends React.Component<ISubmissionShowProps, ISubmissionShowState> {
  constructor(props: ISubmissionShowProps) {
    super(props);

    this.state = {
      submission: null,
      status: null
    }
  }

  async componentDidMount() {
    const status = (await EntityManager.all<Option>(Option)).models;
    let submission = await EntityManager.show<Submission>(Submission, this.props.submissionId);
    this.setState({submission, status})
  }

  public render() {
    const { submission, status } = this.state;
    if (!submission) return <></>;
    return (
      <div className="page">
        <div>
          <ReturnButton/>
        </div>
        <div className="submission-content">
        <div className="row-between">
          <AvatarName user={submission.user} />
          <SmartSelect label="Statut" floating model={submission} name="submissionStatusId" options={status} />
        </div>
          { submission.submissionValues.map(field => (
            <div className="submission-field mb-3">
              <label htmlFor="">{field.formElement.label}</label>
              <SubmissionValueDisplayer submissionValue={field} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
