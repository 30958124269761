import * as React from 'react';
import { projectBloc } from '../../bloc/ProjectBloc';
import Column from '../../models/Column';
import SmartInputText from '../input/SmartInputText';
import ColorInput from '../input/SmartColorInput';

export interface INewColumnInputProps {
}

export interface INewColumnInputState {
}

export default class NewColumnInput extends React.Component<INewColumnInputProps, INewColumnInputState> {

  public column = new Column({});

  constructor(props: INewColumnInputProps) {
    super(props);

    this.state = {
    }
  }

  onCreateColumn = () => {
    this.column.position = projectBloc.state.currentProject.columns.length;
    this.column.projectId = projectBloc.state.currentProject.id;
    projectBloc.createColumn(this.column);
  }

  handleChangeColor = () => {

  }

  public render() {
    return <div ref={projectBloc.newColumnInput} className="new-column-input">
      <SmartInputText onEnter={this.onCreateColumn} autoFocus model={this.column} name="name" placeholder="Nom de la colonne" />
      <div className="mt-2 ml-3">
        <ColorInput model={this.column} />

      </div>
      {/* <SmartInputText className="w-25" type="color" model={this.column} name="color" /> */}
    </div>
  }
}
