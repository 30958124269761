import * as React from 'react';
import Group from '../../models/Group';
import SearchItemBase from './SearchItemBase';
import AvatarLetter from '../common/AvatarLetter';
import { AvatarSize } from '../common/Avatar';
import { Button } from 'reactstrap';
import DOMService from '../../services/DOMService';
import GroupModal from '../modal/GroupModal';

export interface IGroupSearchItemProps {
  group: Group
}

export interface IGroupSearchItemState {
}

export default class GroupSearchItem extends React.Component<IGroupSearchItemProps, IGroupSearchItemState> {
  constructor(props: IGroupSearchItemProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { group } = this.props;
    return (
      <SearchItemBase>
        <div className="row-between">
          <div className="row-flex">
            <div className="mr-3 fw-bold">
              {group.name}
            </div>
            <div className="mr-3">
              {group.users.length + " membres"}
            </div>
            <div className="row-flex">
              {group.users.map(user => (
                <AvatarLetter className="mr-1" key={user.id} size={AvatarSize.sm} user={user}/>
              ))}
            </div>
          </div>
          <div>
            <Button onClick={() => DOMService.modal(<GroupModal group={group} />)} color="primary">Voir</Button>
          </div>
        </div>
      </SearchItemBase>
    );
  }
}
