import Event from './Event';
import Commentable from './mixin/Commentable';
import Model from "./Model";
import User from './User';
import Colors from '../theme/color';

export default class Invitation extends Model {

  public status: string;
  public user: User;
  public creator: User;
  public event: Event;
  public userId: number;
  public eventId: number;
  public commentable: Commentable;

  static modelUrl = "invitations";

  constructor(json: any) {
    super(json)
    this.status      = json.status;
    this.user        = json.user && new User(json.user);
    this.creator     = json.creator && new User(json.creator);
    this.event       = json.event && new Event(json.event);
    this.userId      = json.userId;
    this.eventId     = json.eventId
    this.commentable = new Commentable(this, json);
  }

  getStatus() {
    switch (this.status) {
      case "waiting":
        return "En attente";
      case "commented":
        return "Commentaire";
      case "refused":
        return "A été refusé";
      case "canceled":
        return "A été annulé";
      default: return ""
    }
  }

  getStatusColor() {
    switch (this.status) {
      case "waiting":
        return Colors.warning;
      case "commented":
        return Colors.primary;
      // case "refused":
      //   return "A été refusé";
      // case "canceled":
      //   return "A été annulé";
      default: return Colors.primary
    }
  }

}
