import * as React from 'react';
import { Input } from 'reactstrap';
import SmartInput, { ISmartInputProps } from './SmartInput';

export interface ISmartColorInputProps extends ISmartInputProps {
}

export interface ISmartColorInputState {
}

export default class SmartColorInput extends SmartInput<ISmartColorInputProps, {}> {

  public render() {
    const {
      id,
      required
    } = this.props;
    return (
      <label className="color-input d-flex mb-0" htmlFor={ id }>
        { this.props.children ? this.props.children : <div style={{backgroundColor: this.getValue()}} className="color-circle"></div> }
        <Input className="smart-input" value={this.getValue()} name="color" onChange={this.handleChange} type="color" id={id} required={required} />
      </label>
    );
  }
}
