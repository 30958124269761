import * as React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import ProjectHeader from '../components/projects/ProjectHeader';
import ProjectView from '../components/projects/ProjectView';
import BasePage from './BasePage';
import ProjectsList from '../components/projects/ProjectList';
import { consumeProjectBloc, IProjectBlocState, projectBloc } from '../bloc/ProjectBloc';

export interface IProjectsPageProps {
}

export interface IProjectsPageState {
}

class ProjectsPage extends BasePage<IProjectBlocState, IProjectsPageState> {

  public title = "Tâches"

  constructor(props: IProjectBlocState) {
    super(props);

    this.setTitle();
    this.state = {
    }
  }

  componentDidMount() {
    projectBloc.loadProjects();
  }

  public render() {
    const { isLoaded, projects } = this.props;
    if (!isLoaded) return <></>
    return (
      <div>
        <Route exact path="/projects" render={ () => {
          if (projects.length > 0) return  <Redirect to={`/projects/${projects[0].id}/all`} />;
          return <ProjectsList />
        }}/>
        <Route path="/projects/:id/:scope" render={ (p) => <div className="">
          <div className="px-3">
            <ProjectHeader/>
          </div>
          <ProjectView projectId={p.match.params.id} scope={p.match.params.scope}/>
        </div>} />
      </div>
    );
  }
}

export default consumeProjectBloc(ProjectsPage)