import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { DropTarget, XYCoord } from 'react-dnd';
import { projectBloc } from '../../bloc/ProjectBloc';
import Dropdown, { DropDownDirection, DropDownPosition } from '../common/Dropdown';
import TextIcon from '../common/TextIcon';
import TicketCol from './TicketCol';

export interface ITicketColumnContainerProps {
  tickets: any
  column: any
  index: number
  moving: (arg: number, arg2: number) => void
}

export interface ITicketColumnContainerState {
}

let collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    item: monitor.getItem(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
    monitor: monitor,
  }
}

const target = {
  drop(props, monitor, component) {
    projectBloc.reorderColumn();

    return { moved: true }
  }
}

const moving = (props, monitor, component) => {
  let item = monitor.getItem()
  if (!component) {
    return
  }
  const dragIndex = item.index
  const hoverIndex = props.index

  if (dragIndex === hoverIndex) {
    return
  }

  const hoverBoundingRect = component?.getBoundingClientRect()
  const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
  const clientOffset = monitor.getClientOffset()
  const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left

  if (dragIndex < hoverIndex && hoverClientX > hoverMiddleX) {
    return
  }

  if (dragIndex > hoverIndex && hoverClientX < hoverMiddleX) {
    return
  }

  props.moving(dragIndex, hoverIndex)

  item.index = hoverIndex
}
class TicketColumnContainer extends React.Component<any, ITicketColumnContainerState> {

  public ref = React.createRef<HTMLDivElement>();

  constructor(props: ITicketColumnContainerProps) {
    super(props);

    this.state = {
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOver && this.props.isOver) {
      moving(this.props, this.props.monitor, this.ref.current)
    }

    if (prevProps.isOver && !this.props.isOver) {
      // You can use this as leave handler
    }

    if (prevProps.isOverCurrent && !this.props.isOverCurrent) {
      // You can be more specific and track enter/leave
      // shallowly, not including nested targets
    }
  }

  onDelete = async () => {
    await this.props.column.delete();
    await projectBloc.loadProject();
  }

  public render() {
    const {tickets, column, index} = this.props;
    const { connectDropTarget } = this.props

    return connectDropTarget(
      <div ref={this.ref} className="ticket-column-container" >
        <TicketCol index={index} tickets={tickets} column={column} />
        <div className="mb-5">
          <Dropdown
            position={DropDownPosition.bottom}
            direction={DropDownDirection.left}
            button={
              <div onClick={() => {}} className="column-menu-btn">
                <FontAwesomeIcon  icon={["fal", "ellipsis-h"]} />
              </div>
            }
            content={(
              <div className="dropdown-frame">
                <TextIcon onClick={this.onDelete} className="dropdown-item" leftIcon={["fal", "trash"]} >Supprimer</TextIcon>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

export default DropTarget("column", target, collect)(TicketColumnContainer)
