import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import ReactTooltip from 'react-tooltip';

export interface IIconButtonProps {
  iconData: IconProp
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  className?: string
  light?: boolean
  iconStyle?: React.CSSProperties
  tooltip?: string
}

export interface IIconButtonState {
}

export default class IconButton extends React.Component<IIconButtonProps, IIconButtonState> {
  constructor(props: IIconButtonProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { className, light, tooltip } = this.props;
    const dataFor = tooltip ? uniqueId("tt_") : null
    return (
      <div data-tip={tooltip ? "navtip" : null} data-for={dataFor} className={classNames({"icon-btn-light": light, "icon-btn": !light, [className]: !!className})} onClick={this.props.onClick}>
        <FontAwesomeIcon style={this.props.iconStyle} icon={this.props.iconData} />
        { tooltip && <ReactTooltip delayShow={ 500 } effect="solid" place="bottom" id={ dataFor }>{tooltip}</ReactTooltip> }
      </div>
    );
  }
}
