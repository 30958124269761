import * as React from 'react';
import OrganizationChart from "@dabeng/react-orgchart";
import UserService from '../services/UserService';
import User from '../models/User';

export interface IOrganizationPageProps {
}

export interface IOrganizationPageState {
  organization: any[]
}

export default class OrganizationPage extends React.Component<IOrganizationPageProps, IOrganizationPageState> {
  constructor(props: IOrganizationPageProps) {
    super(props);

    this.state = {
      organization: null
    }
  }

  async componentDidMount() {
    let organization = (await UserService.userManager.organization()).models;
    this.setState({organization})
  }

  convertDatasources(user: User) {
    return {title: user.job, name: user.getFullName(), children: user.children.map(u => this.convertDatasources(u))}
  }

  getData() {
    return this.convertDatasources(this.state.organization[0])
  }

  public render() {
    if (!this.state.organization) return <></>
    return (
      <div>
        <OrganizationChart datasource={this.getData()} />
      </div>
    );
  }
}
