import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import classNames from 'classnames';
import { StyleButtonProp } from './FormElementEdit';
import FormElement from '../../models/form/FormElement';

export interface IStyleButtonProps {
  btn: StyleButtonProp
  onStyleChange?: (a: string, b: string) => void
  formElement: FormElement
}

export interface IStyleButtonState {
}

export default class StyleButton extends React.Component<IStyleButtonProps, IStyleButtonState> {
  constructor(props: IStyleButtonProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { btn, onStyleChange, formElement } = this.props;
    return (
      <div onClick={() => onStyleChange && onStyleChange(btn.name, btn.value)} className={classNames({"style-btn": true, "active": formElement.style[btn.name] === btn.value})}>
              <FontAwesomeIcon icon={btn.icon} />
            </div>
    );
  }
}
