import * as React from 'react';
import Card from 'reactstrap/lib/Card';
import FileIcon from './fileIcon';
import { Input, Col } from 'reactstrap';
import { driveBloc } from '../../bloc/DriveBloc';

export interface INewFolderCardProps {
}

export interface INewFolderCardState {
  name: string
}

export default class NewFolderCard extends React.Component<INewFolderCardProps, INewFolderCardState> {
  constructor(props: INewFolderCardProps) {
    super(props);

    this.state = {
      name: ""
    }
  }

  handleName = (e) => {
    let name = e.target.value;
    this.setState({name})
  }

  public render() {
    return (
      <Col xs={12} sm={6} md={4} lg={3} xl={2} className="mb-4">
        <Card>
          <div className="text-center p-5">
            <FileIcon height={"80px"}/>
          </div>
          <div className="divider"></div>
          <div className="p-3">
            <Input onKeyPress={(e) => { if (e.key === "Enter") driveBloc.createFolder(this.state.name)}} innerRef={driveBloc.folderInputRef} value={this.state.name} onChange={this.handleName} autoFocus />
          </div>
        </Card>
      </Col>
    );
  }
}
