import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { NavLink, Route } from 'react-router-dom';
import { consumeMessagingBloc, IMessagingBlocState } from '../../bloc/MessagingBloc';
import SessionService from '../../services/SessionService';
import IconButton from '../buttons/IconButton';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import Dropdown, { DropDownDirection, DropDownPosition } from '../common/Dropdown';
import Logo from '../common/Logo';
import TextIcon from '../common/TextIcon';
import UniversalSearch from '../input/UniversalSearch';
import ModernNavigation from './ModernNavigation';
import Navigation from './Navigation';

export interface IModernNavbarProps {
}

export interface IModernNavbarState {
  notifOpen: boolean
  mobileMenuOpen: boolean
}

class ModernNavbar extends React.Component<IMessagingBlocState, IModernNavbarState> {
  constructor(props: IMessagingBlocState) {
    super(props);

    this.state = {
      notifOpen: false,
      mobileMenuOpen: false
    }
  }

  onOpenNotif = () => {
    this.setState({notifOpen: !this.state.notifOpen})
  }

  public render() {
    const { notifOpen, mobileMenuOpen } = this.state;
    return (
      <div className="modern-navbar">
        <div className="row-flex" style={{width: "250px"}}>
          <div className="d-flex d-lg-none mr-2 menu-responsive">
            <FontAwesomeIcon onClick={() => this.setState({mobileMenuOpen: !mobileMenuOpen})} icon={["fal", "bars"]} />
            { mobileMenuOpen && <div className="mobile-menu">
            <div onClick={() => this.setState({mobileMenuOpen: false})} className="overlay"></div>
              <div className="mobile-menu-panel">
                <div onClick={() => this.setState({mobileMenuOpen: false})} className="sidebar-body">
                  <Navigation/>
                </div>
              </div>
            </div>}
          </div>
          <Logo style={{width: "auto", marginRight: "0.5rem"}} />
          <UniversalSearch/>
        </div>
        <ModernNavigation/>
        <div className="row-end" style={{width: "250px"}}>
          <div id="visio-mini"></div>
          <NavLink className="mr-3" to={"/settings"}>
            <Route path={"/settings"} children={({ match }) => (
              <IconButton className={match ? "active" : ""} iconData={["fal", "cogs"]} ></IconButton>
            )} />
          </NavLink>
          <NavLink className="mr-3" to={"/messages"}>
            <Route path={"/messages"} children={({ match }) => (
              <IconButton className={match ? "active" : ""} iconData={["fal", "comments"]} ></IconButton>
            )} />
          </NavLink>
          <div className="position-relative">
            <Dropdown
              position={DropDownPosition.bottom}
              direction={DropDownDirection.left}
              button={<AvatarLetter user={SessionService.user} size={AvatarSize.sm} />
              }
              content={(
                <div className="dropdown-frame">
                  <div className="dropdown-item">
                    <NavLink to={"/organization"}>Organigramme</NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to={"/messaging"}>
                      <TextIcon leftIcon={["fal", "envelope"]} >Messagerie</TextIcon>
                    </NavLink>
                  </div>
                  <div className="dropdown-item">
                    <NavLink to={"/messaging"}>
                      <TextIcon leftIcon={["fal", "bell"]} >Notifications</TextIcon>
                    </NavLink>
                  </div>
                  {/* <div className="dropdown-item">
                    <NavLink to={"/settings"}>
                      <TextIcon leftIcon={["fal", "cogs"]} >Paramètre</TextIcon>
                    </NavLink>
                  </div> */}
                </div>
              )}
            />
          </div>
          {/* <div className="position-relative">
            { this.props.unreadCount > 0 && <div className="circle">
              {this.props.unreadCount}
            </div>}
          </div> */}
        </div>
        { notifOpen && <div className="notif-frame">
          <div className="text-center mb-3">
            <FontAwesomeIcon size={"lg"} icon={["fal", "bell"]} />
          </div>
          <div>Vous avez 0 notifications non lues.</div>
        </div>}
      </div>
    );
  }
}

export default consumeMessagingBloc(ModernNavbar)