import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Moment from 'react-moment';
import { Button } from 'reactstrap';
import Group from '../../models/Group';
import DOMService from '../../services/DOMService';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import GroupModal from '../modal/GroupModal';

export interface IGroupItemProps {
  group: Group
}

export interface IGroupItemState {
  adapt: boolean
}

export default class GroupItem extends React.Component<IGroupItemProps, IGroupItemState> {

  groupAvatar = React.createRef<HTMLDivElement>()
  constructor(props: IGroupItemProps) {
    super(props);

    this.state = {
      adapt: false
    }
  }

  componentDidMount() {
    this.setState({adapt: true})
  }

  public render() {
    const { group } = this.props;
    let avatars = [];
    if (this.state.adapt) {
      let width = this.groupAvatar.current.clientWidth;
      let max = Math.floor((width - 20) / 49);
      if (max < group.users.length) {
        for (let i = 0; i < max; i++) {
          avatars.push(<AvatarLetter className="mr-1" key={group.users[i].id} size={AvatarSize.sm} user={group.users[i]}/>)
        }
        if (max !== group.users.length) avatars.push(<div key={"plus"}>{`+${group.users.length - max}`}</div>)
      } else {
        avatars = group.users.map(user => <AvatarLetter className="mr-1" key={user.id} size={AvatarSize.sm} user={user}/>)
      }
    } else {
      avatars = group.users.map(user => <AvatarLetter className="mr-1" key={user.id} size={AvatarSize.sm} user={user}/>)
    }
    return (
      <div className="group-item">
        <div className="group-title row-center">
          {group.name}
          <Button color="link" onClick={() => DOMService.modal(<GroupModal group={group} />)}>
            <FontAwesomeIcon className="gray" icon={["fal", "edit"]} />
          </Button>
        </div>
        <div className="divider"></div>
        <div className="group-createdat text-center">
          <Moment format="DD MMMM YYYY">{group.createdAt}</Moment>
        </div>
        <div className="text-center">
          {group.users.length + " membres"}
        </div>
        <div ref={this.groupAvatar} className="group-avatars">
          {avatars}
        </div>
      </div>
    );
  }
}
