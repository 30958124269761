import * as React from 'react';
import { Button } from 'reactstrap';
import { projectBloc } from '../../bloc/ProjectBloc';
import Ticket from '../../models/Ticket';
import SessionService from '../../services/SessionService';
import DropZoneComponent from '../common/DropZoneComponent';
import SmartDatePicker from '../input/SmartDatePicker';
import SmartInput from '../input/SmartInputText';
import SmartSwitch from '../input/SmartSwitch';
import MembersSelect from '../members/MembersSelect';
import BaseForm from './BaseForm';

export interface ITicketFormProps {
  ticket?: Ticket
}

export interface ITicketFormState {
  autoAssign: boolean
  withDeadline: boolean
}

export default class TicketForm extends React.Component<ITicketFormProps, ITicketFormState> {


  constructor(props: ITicketFormProps) {
    super(props);

    this.state = {
      autoAssign: true,
      withDeadline: false,
    }
  }

  assignUser(user) {
    const { ticket } = this.props;
    ticket.user = user;
    ticket.userId = user.id;
    this.setState({})
  }

  handleChangeAutoAssign = (autoAssign) => {
    const { ticket } = this.props;
    if (autoAssign) {
      ticket.user = null;
      ticket.userId = SessionService.getUserId();
    }
    this.setState({autoAssign})
  }

  handleChangeWithDeadline = (withDeadline) => {
    const { ticket } = this.props;
    if (withDeadline) {
      ticket.deadline = new Date();
    }
    this.setState({withDeadline})
  }

  handleChangeDeadline = (date: Date) => {
    this.props.ticket.deadline = date;
    this.setState({});
  }

  public render() {
    const { ticket } = this.props;
    const { autoAssign, withDeadline } = this.state;
    return (
      <BaseForm<Ticket>
        model={ticket}
        onSubmitted={ (ticket) => projectBloc.onTicketCreated(ticket, ticket.attachable) }
        render={(state) => (
          <>
            <div className="row-between">
              <SmartInput type="select" name="priority" label="Priorité" model={ticket} floating>
                <option key="Haute" value={3} >Haute</option>
                <option key="Modéré" value={2} >Modéré</option>
                <option key="Basse" value={1} >Basse</option>
              </SmartInput>
              <SmartInput type="select" name="statusId" label="Statut" model={ticket} floating>
                {projectBloc.state.currentProject.columns.map(o => <option key={o.name} value={o.id}>{o.name}</option>)}
              </SmartInput>
            </div>
            <SmartInput label="Titre" model={ticket} name="title" floating/>
            <SmartInput label="Description" rows={4} type="textarea" model={ticket} name="description" floating />
            <DropZoneComponent attachable={ticket.attachable} />
            <div className="mb-2">
              <SmartSwitch
                label={"DeadLine"}
                primary
                onChange={(e) => this.handleChangeWithDeadline(e)}
                checked={withDeadline}
              />
            </div>
            { withDeadline &&
              <SmartDatePicker withTime name="deadline" model={ticket} />
            }
            <div className="mb-3">
              <SmartSwitch
                label={"Me l'assigner"}
                primary
                onChange={(e) => this.handleChangeAutoAssign(e)}
                checked={autoAssign}
              />
            </div>
            { !autoAssign && <div className="mb-3">
              <MembersSelect users={projectBloc.state.currentProject.users} overflowHeight="200px" render={(user) => {
                if (ticket.user !== user) return <div className="btn btn-link" onClick={() => this.assignUser(user)} >Choisir</div>
                return <Button color="primary">Assigné</Button>
              }}/>
            </div>}
            <div className="row-end">
              <Button type="submit" color="primary">Créer le ticket</Button>
            </div>
          </>
        )}
      />
    );
  }
}
