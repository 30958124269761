import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import UserManager from '../../managers/UserManager';
import SessionService from '../../services/SessionService';
import StorageService from '../../services/StorageService';
import AdminView from '../admin/AdminView';
import MediaFileButton from '../buttons/MediaFileButton';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import TextIcon from '../common/TextIcon';
import SmartInputText from '../input/SmartInputText';
import EntityManager from '../../managers/EntityManager';
import User from '../../models/User';
import ToastrService from '../../services/ToastrService';

export interface IAccountViewProps {
}

export interface IAccountViewState {
  [a: string]: string
  oldPassword: string
  newPassword: string
}

export default class AccountView extends React.Component<IAccountViewProps, IAccountViewState> {

  public user = SessionService.user;
  public userManager = new UserManager();

  constructor(props: IAccountViewProps) {
    super(props);

    this.state = {
      oldPassword: "",
      newPassword: ""
    }
  }

  submit = async () => {
    await this.userManager.update(this.user.toMapApi());
  }

  onAvatarChange = async (file: File) => {
    let url = await StorageService.uploadFile("avatar", file);
    this.user.avatar = url;
    this.setState({});
  }

  getAvatarText() {
    if (this.user.avatar) return "Modifier";
    return "Ajouter";
  }

  handleChange = (e, type: string) => {
    this.setState({[type]: e.target.value})
  }

  changePassword = async () => {
    try {
      await EntityManager.post<User>(User, "password", this.state);
      this.setState({oldPassword: "", newPassword: ""})
      ToastrService.toaster.show("Urioz", "Le mot de passe a été modifié");
    } catch (error) {
      ToastrService.toaster.show("Urioz", "L'ancien mot de passe ne corresponds pas.", "danger");
    }
  }

  public render() {
    return (
      <AdminView title="Compte utilisateur">
        <div className="user-form pt-2">
          <Row>
            <Col xs="12" md="3">
              <div className="col-center">
                <AvatarLetter className="mb-3" size={AvatarSize.lg} user={this.user} />
                <MediaFileButton onFileChange={this.onAvatarChange}>
                  <div className="btn btn-success">
                    <TextIcon leftIcon={["fal", this.user.avatar ? "edit" : "plus"]}>
                      {this.getAvatarText()}
                    </TextIcon>
                  </div>
                </MediaFileButton>
              </div>
            </Col>
            <Col xs="12" md="9">
              <SmartInputText label="Email" name="email" model={this.user} floating/>
              <Row>
                <Col>
                  <SmartInputText label="Prénom" name="firstname" model={this.user} floating/>
                </Col>
                <Col>
                  <SmartInputText label="Nom" name="lastname" model={this.user} floating/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SmartInputText label="Poste" name="job" model={this.user} floating/>
                </Col>
                <Col>
                  <SmartInputText label="Téléphone" name="phone" model={this.user} floating/>
                </Col>
              </Row>
              <SmartInputText label="Lien Linkedin" name="linkedin" model={this.user} floating/>
              <div className="row-end my-3">
                <Button onClick={this.submit} color="primary">Enregistrer mon profil</Button>
              </div>
            </Col>
          </Row>
          <div className="divider mx-0 mt-2 mb-4"></div>
          <div>
            <div className="fw-600 darkgray mb-3">
              <TextIcon leftIcon={["fal", "lock"]}>Modifier mon mot de passe</TextIcon>
            </div>
            <SmartInputText label="Ancien mot de passe" type="password" onChange={(e) => this.handleChange(e, "oldPassword")} value={this.state.oldPassword} floating />
            <SmartInputText label="Nouveau mot de passe" type="password" onChange={(e) => this.handleChange(e, "newPassword")} value={this.state.newPassword} floating />
          </div>
          <div className="row-end my-3">
            <Button onClick={this.changePassword} color="primary">Valider</Button>
          </div>
        </div>
      </AdminView>
    );
  }
}
