import Model from "../Model";

export default abstract class MorphMixin<T extends Model> {

  public morphName = "";
  public parentModel: Model;
  public models: T[];

  constructor(parentModel: Model) {
    this.parentModel = parentModel;
  }

  public async create(model: T): Promise<T> {
    model[this.morphName + "Type"] = this.parentModel.getBackendModelType();
    model[this.morphName + "Id"]   = this.parentModel.id;
    let newModel = await model.create();
    this.append(newModel);
    return newModel;
  }

  public add(model: T) {
    this.append(model);
  }

  public remove(model: T) {
    this.models = this.models.filter(a => a !== model);
  }

  public append(newModel: T) {
    this.models.unshift(newModel);
  }

}