import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

export interface IModernNavItemProps {
  to: string;
  icon: IconName;
  exact?: boolean
  label: string
}

export interface IModernNavItemState {
}

export default class ModernNavItem extends React.Component<IModernNavItemProps, IModernNavItemState> {

  constructor(props: IModernNavItemProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { to, icon, exact, label} = this.props;
    return (
      <NavLink data-tip="navtip" data-for={to} className="modern-nav-item" to={to} exact={exact}>
        {/* <Route path={to} children={({ match }) => (
          <FontAwesomeIcon icon={[match ? "fas" : "fal", icon]} />
        )} /> */}
        <FontAwesomeIcon icon={["fal", icon]} />
        <ReactTooltip delayShow={500} effect="solid" place="bottom" id={to}>{label}</ReactTooltip>
      </NavLink>
    );
  }
}
