import * as React from 'react';
import DropboxDropdown from './DropboxDropdown';
import FileCard from './FileCard';

export interface IDropboxFileCardProps {
}

export interface IDropboxFileCardState {
}

export default class DropboxFileCard extends FileCard {

  renderDropdown() {
    return <DropboxDropdown file={this.props.file} />
  }
}
