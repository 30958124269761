import * as React from 'react';
import Media from 'reactstrap/lib/Media';
import classNames from 'classnames';
import Message from '../../models/Message';
import TextIcon from '../common/TextIcon';
import IconButton from '../buttons/IconButton';
import { roomBloc } from '../../bloc/RoomBloc';
import SessionService from '../../services/SessionService';
import AvatarLetter from '../common/AvatarLetter';
import { AvatarSize } from '../common/Avatar';

export interface IMessageComponentProps {
  className: string;
  fromClass: string;
  message: Message;
}

export interface IMessageComponentState {
}

export default class MessageComponent extends React.Component<IMessageComponentProps, IMessageComponentState> {
  constructor(props: IMessageComponentProps) {
    super(props);

    this.state = {
    }
  }

  hasOnlyEmojis = (str: string) => {
    const ranges = [
      '\ud83c[\udf00-\udfff]',
      '\ud83d[\udc00-\ude4f]',
      '\ud83d[\ude80-\udeff]',
      ' ',
    ].join('|');

    return !str.replace(new RegExp(ranges, 'g'), '').length;
  }


  public render() {
    const { className, fromClass, message } = this.props;
    return (
      <>
        { message.mediaUrl &&
          <div className={fromClass + " picture"}>
            { message.mediaType === "image" && <Media className="img-preview" object src={message.mediaUrl} />}
            {/* { message.mediaType === "video" && <ReactPlayer url={message.mediaUrl} playing width="40%" height="auto" controls/>} */}
            {/* { message.mediaType === "audio" && (
              <AudioPlayer
                className="w-50"
                src={message.mediaUrl}
                onPlay={e => console.log("onPlay")}
              />
            )} */}
          </div>
        }
        { message.parentMessage &&
          <div className={fromClass + " reply-user"}>
            { message.user.id === SessionService.getUserId() ? "Votre réponse" : `${message.user.getFullName()} à répondu` }
          </div>
        }
        <div className={className}>
          <AvatarLetter className={message.user.isMe() ? "ml-2" : "mr-2"} user={message.user} size={AvatarSize.xs} />
          { (message.content || message.parentMessage) && <div className={classNames("message", {"only-emojis": this.hasOnlyEmojis(message.content)})} >
            { message.parentMessage && <div className="parent-message">
              <TextIcon leftIcon={["fal", "share"]}>{message.parentMessage.content}</TextIcon></div> }
            <span className={"message-content"} >{ message.content }</span>
          </div> }
          <div className="message-menu">
            <IconButton onClick={() => roomBloc.startReply(message)} iconData={["fal", "share"]} />
          </div>
        </div>
        </>
    );
  }
}
