import * as React from 'react';
import { appBloc } from '../bloc/AppBloc';
import { roomBloc } from '../bloc/RoomBloc';
import RoomsSidebar from '../components/messaging/RoomsSidebar';
import RoomsView from '../components/messaging/RoomsView';
import BasePage from './BasePage';

export interface IRoomsPageProps {
  params: any
}

export interface IRoomsPageState {
}

export default class RoomsPage extends BasePage<IRoomsPageProps, IRoomsPageState> {

  private pathname;
  public title = "Chat"

  constructor(props: IRoomsPageProps) {
    super(props);

    this.setTitle();
    this.state = {
    }
  }

  shouldComponentUpdate() {
    return appBloc.pathname !== this.pathname
  }

  componentDidMount() {
    roomBloc.setUrlParam(this.props.params)
  }

  componentDidUpdate() {
    roomBloc.setUrlParam(this.props.params)
  }

  componentWillUnmount() {
    roomBloc.onLiveRoomPage()
  }

  public render() {
    this.pathname = appBloc.pathname;
    return (
      <div className="rooms-page">
        <RoomsView/>
        <RoomsSidebar/>
      </div>
    );
  }
}
