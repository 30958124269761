import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminTab from '../components/admin/AdminTab';
import Page from '../components/layout/Page';
import MessageShow from '../components/messaging/MessageShow';
import MessagingContent from '../components/messaging/MessagingContent';
import NewMessageModal from '../components/modal/NewMessageModal';
import TabItem from '../core/TabItem';
import DOMService from '../services/DOMService';
import BasePage from './BasePage';

export interface IMessagesPageProps {
}

export interface IMessagesPageState {
}

export default class MessagesPage extends BasePage<IMessagesPageProps, IMessagesPageState> {

  public title = "Messagerie"

  public messageTabs: TabItem[] = [
    new TabItem({label: "Messages reçu", path: "/messaging/inbox", right: "", icon: ["fal", "mail-bulk"], component: MessagingContent}),
    new TabItem({label: "Messages envoyés", path: "/messaging/send", right: "users", icon: ["fal", "paper-plane"], component: MessagingContent}),
    new TabItem({label: "Importants", path: "/messaging/imp", right: "users", icon: ["fal", "star"], component: MessagingContent}),
  ]

  constructor(props: IMessagesPageProps) {
    super(props);

    this.setTitle();
    this.state = {
    }
  }

  public render() {
    return (
      <Page>
        <div className="d-flex">
          <div className="messaging-tabs">
            <h3 className="mb-3">Messagerie</h3>
            { this.messageTabs.map(t => <AdminTab key={t.label} label={t.label} path={t.path} icon={t.icon}/>)}
          </div>
          <div className="w-100">
            <Switch>
              <Route path={"/messaging/inbox"} component={() => <MessagingContent/>} />
              <Route path={"/messaging/send"} component={() => <MessagingContent view={"send"}  />} />
              <Route path={"/messaging/imp"} component={() => <MessagingContent  view={"imp"} />} />
              <Route path={"/messaging/:id"} component={(p) => <MessageShow id={p.match.params.id} />} />
            </Switch>
            <div onClick={() => DOMService.modal(<NewMessageModal/>)} className="floating-mobile-btn d-flex d-md-none">
              <FontAwesomeIcon icon={["fal", "plus"]} />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
