import * as React from 'react';
import Attachment from '../../models/Attachment';
import Attachable from '../../models/mixin/Attachable';
import AttachmentRow from '../messaging/AttachmentRow';
import SmallDropZone from './SmallDropZone';

export interface IDropZoneComponentProps {
  attachable: Attachable
}

export interface IDropZoneComponentState {
}

export default class DropZoneComponent extends React.Component<IDropZoneComponentProps, IDropZoneComponentState> {
  constructor(props: IDropZoneComponentProps) {
    super(props);

    this.state = {
    }
  }

  onDrop = (file: File) => {
    const { attachable } = this.props;
    let attachment = new Attachment({}).fillWithFile(file);
    attachable.add(attachment);
    this.setState({})
  }

  onDeleteAttachment = (attachment: Attachment) => {
    console.log(attachment)
    const { attachable } = this.props;
    attachable.remove(attachment);
    this.setState({})
  }

  public render() {
    const { attachable } = this.props;
    console.log(attachable)
    return (
      <div>
        <div className="attachments-area mb-2">
          { attachable.models.map((attachment) => <AttachmentRow key={attachment.name} attachment={attachment} onDelete={this.onDeleteAttachment} />) }
        </div>
        <div className="mb-3">
          <SmallDropZone onDrop={this.onDrop} />
        </div>
      </div>
    );
  }
}
