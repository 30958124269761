import * as React from 'react';

export interface IAuthPageProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export interface IAuthPageState {
}

export default class AuthPage extends React.Component<IAuthPageProps, IAuthPageState> {
  constructor(props: IAuthPageProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { children, onClick } = this.props;
    return (
      <div onClick={onClick} className="auth-page">
        {children}
      </div>
    );
  }
}
