import * as React from 'react';
import { NavLink } from 'react-router-dom';
import GroupRoom from '../../models/GroupRoom';
import OneOneRoom from '../../models/OneOneRoom';
import Room from '../../models/Room';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import AvatarName from '../common/AvatarName';
import CustomRoom from '../../models/CustomRoom';

export interface IRoomItemProps {
  room: Room;
}

export default class RoomItem extends React.Component<IRoomItemProps> {

  getFrontUrl = () => this.props.room.getFrontUrl();

  getAvatar = () => this.props.room.getAvatar();

  getName = () => this.props.room.getName();

  getSubTitle = () => this.props.room.lastMessage?.content || "";

  public render() {
    const { room } = this.props
    return (
      <NavLink to={room.getFrontUrl()}>
        <div className="room-item py-2">
          <div className="row-center m-auto py-1 d-md-none">
            { room instanceof OneOneRoom && <AvatarLetter user={room.users[0]} size={AvatarSize.xs} />}
            { room instanceof GroupRoom && <AvatarLetter user={room.users[0]} size={AvatarSize.xs} />}
            { room instanceof CustomRoom && <AvatarLetter user={room.users[0]} size={AvatarSize.xs} />}
          </div>
          <div className="row-end w-100 d-md-flex d-none px-3">
            { room instanceof OneOneRoom && <AvatarName invert user={room.users[0]} renderSubTitle={<div className="last-message inline-text text-right">{room.lastMessage?.content || ""}</div>} />}
            { room instanceof GroupRoom && (
              <div>
                <div className="fw-bold">{room.group.name}</div>
                <div className="last-message inline-text text-right">{room.lastMessage?.content || ""}</div>
              </div>
            )}
            { room instanceof CustomRoom && (
              <div>
                <div className="fw-bold">{room.name}</div>
                <div className="last-message inline-text text-right">{room.lastMessage?.content || ""}</div>
              </div>
            )}
            {/* <div className="short-from-now">
              {this.props.room.getState()}
            </div> */}
          </div>
          {/* <div>
            <Dropdown
              position={DropDownPosition.bottom}
              direction={DropDownDirection.right}
              button={
                <div className="room-item-menu">
                  <FontAwesomeIcon icon={["fad", "ellipsis-v"]} fixedWidth/>
                </div>
              }
              content={
                <div className="dropdown-container" >
                  <DropdownNavItem label="Voir profil" to={this.props.roomCompo.getFrontUrl()} icon={["fas", "user"]}/>
                  <DropdownNavItem label="Archiver" onClick={() => console.log("archive")} icon={["fad", "trash"]}/>
                </div>
              }
            />
          </div> */}
        </div>
      </NavLink>
    );
  }
}
