import * as React from 'react';
import { Input } from 'reactstrap';
import { driveBloc } from '../../bloc/DriveBloc';
import FileIcon from './fileIcon';

export interface INewFolderRowProps {
}

export interface INewFolderRowState {
  name: string
}

export default class NewFolderRow extends React.Component<INewFolderRowProps, INewFolderRowState> {

  constructor(props: INewFolderRowProps) {
    super(props);

    this.state = {
      name: ""
    }
  }

  handleName = (e) => {
    let name = e.target.value;
    this.setState({name})
  }

  public render() {
    return (
      <div className="drive-row">
        <div className="c-1 c-body-1"><FileIcon/></div>
        <div className="c-2">
          <Input onKeyPress={(e) => { if (e.key === "Enter") driveBloc.createFolder(this.state.name)}} innerRef={driveBloc.folderInputRef} value={this.state.name} onChange={this.handleName} autoFocus /></div>
        <div className="c-3"></div>
        <div className="c-4"></div>
        <div className="c-5"></div>
        <div className="c-6"></div>
      </div>
    );
  }
}
