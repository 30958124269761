import * as React from 'react';
import User from '../../models/User';
import AvatarLetter from '../common/AvatarLetter';
import { AvatarSize } from '../common/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '../buttons/IconButton';
import { appBloc } from '../../bloc/AppBloc';

export interface IConnectedNotifProps {
  user: User
}

export interface IConnectedNotifState {
}

export default class ConnectedNotif extends React.Component<IConnectedNotifProps, IConnectedNotifState> {
  constructor(props: IConnectedNotifProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { user } = this.props;
    return (
      <div className="connected-notif">
        <FontAwesomeIcon size="1x" icon={["fas", "circle"]} className="text-success mr-3" />
        <AvatarLetter size={AvatarSize.sm} user={user} />
        <div className="ml-3">
          { `${user.getFullName()} vient de se connecter`}
        </div>
        <div className="ml-5">
          <IconButton onClick={() => appBloc.push(user.getRoomUrl())} light iconData={["fal", "comment"]} />
        </div>
      </div>
    );
  }
}
