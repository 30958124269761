import EntityManager from "./EntityManager";
import RightType from "../models/RightType";

export default class RightTypeManager extends EntityManager<RightType> {

  protected modelUrl: string = "rightTypes";

  protected getEntity(json: any): RightType {
    return new RightType(json);
  };

}
