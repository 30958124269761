import SessionService from "../../services/SessionService";
import Commentable from "../mixin/Commentable";
import Model from "../Model";
import Option from '../Option';
import User from '../User';
import Form from './Form';
import FileSelect from './formElements/FileSelect';
import SubmissionValue from './SubmissionValue';

export default class Submission extends Model {

  public formId: number;
  public userId: number;
  public user: User;
  public statusId: number;
  public status: Option;
  public submissionValues: SubmissionValue[];
  public commentable: Commentable;
  static modelUrl = "submissions";

  public file: File;

  constructor(json: any) {
    super(json)
    this.formId             = json.formId;
    this.user               = json.user && new User(json.user);
    this.statusId           = json.statusId;
    this.status             = json.status && new Option(json.status);
    this.userId             = json.userId;
    this.submissionValues   = this.mapArrayModel(SubmissionValue, json.submissionValues);
    this.commentable        = new Commentable(this, json);
  }

  fromMe() {
    this.userId = SessionService.getUserId();
    return this;
  }

  hasFileInput() {
    let value = false;
    this.submissionValues.forEach(element => {
      if (element.formElement instanceof FileSelect) value = true;
    })
    return value;
  }

  // async uploadFiles() {
  //   let promises: Promise<void>[] = []
  //   this.submissionValues.filter(element => element.formElement instanceof FileSelect).forEach(val => {
  //     val.attachable = new Attachable(val, {})
  //     val.attachable.models = element.attachable.models;
  //     val.attachable.saveAll();
  //     promises.push(element.uploadFile());
  //   })
  //   await Promise.all(promises);
  // }


  initSubmissionValues(form: Form) {
    this.submissionValues = [];
    form.formElements.forEach(element => {
      if (element.isSubmittable) {
        let submissionValue = new SubmissionValue({formElementId: element.id, formElement: element, value: element.value});
        this.submissionValues.push(submissionValue);
      }
    })
  }

  toMapApi() {
    return {
      formId: this.formId,
      userId: this.userId,
      statusId: this.statusId,
      submissionValues: this.submissionValues.map(s => s.toMapApi()),
    }
  }
}
