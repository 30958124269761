/**
  * <Preview />
  */

import update from 'immutability-helper';
import React from 'react';
import FormElement from '../../../models/form/FormElement';
import EditableFormElementComponent from '../EditableFormElementComponent';
import PlaceHolder from './PlaceHolder';
import store from './stores/store';
import { uniqueId } from 'lodash';
import Form from '../../../models/form/Form';

export interface IPreviewProps {
  form?: Form
  url?: string
  saveUrl?: string
  editElement?: any
  manualEditModeOff?: any
  variables?: any
  parent?: any
  editModeOn?: any
  editMode?: any
  className?: any
  showCorrectColumn?: any
  onDelete?: any
  onPost?: any
  files?: any
}

export interface IPreviewState {
  data: FormElement[]
  answer_data: any
}

export default class Preview extends React.Component<IPreviewProps, IPreviewState> {

  editForm: any;
  seq: number;
  static defaultProps: { showCorrectColumn: boolean; files: any[]; editMode: boolean; editElement: any; className: string; };

  constructor(props) {
    super(props);

    const { onLoad, onPost } = props;
    store.setExternalHandler(onLoad, onPost);

    this.state = {
      data: [],
      answer_data: {},
    };
    this.seq = 0;

    const onUpdate = this._onChange.bind(this);
    store.subscribe(state => onUpdate(state.data));

    this.moveCard = this.moveCard.bind(this);
    this.insertCard = this.insertCard.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data !== state.data) {
      store.dispatch('updateOrder', props.data);
    }
    return null;
  }

  updateElement(element) {
    const { data } = this.state;
    let found = false;

    for (let i = 0, len = data.length; i < len; i++) {
      if (element.id === data[i].id) {
        data[i] = element;
        found = true;
        break;
      }
    }

    if (found) {
      this.seq = this.seq > 100000 ? 0 : this.seq + 1;
      store.dispatch('updateOrder', data);
    }
  }

  _onChange(data) {
    const answer_data = {};

    data.forEach((item) => {
      if (item && item.readOnly && this.props.variables[item.variableKey]) {
        answer_data[item.field_name] = this.props.variables[item.variableKey];
      }
    });

    this.setState({
      data,
      answer_data,
    });
  }

  _onDestroy = (item) => {
    store.dispatch('delete', item);
    // this.props.onDelete && this.props.onDelete()
  }

  insertCard(item, hoverIndex) {
    const { data } = this.state;
    data.splice(hoverIndex, 0, item);
    this.saveData(item, hoverIndex, hoverIndex);
  }

  moveCard(dragIndex, hoverIndex) {
    const { data } = this.state;
    const dragCard = data[dragIndex];
    this.saveData(dragCard, dragIndex, hoverIndex);
  }

  // eslint-disable-next-line no-unused-vars
  cardPlaceHolder(dragIndex, hoverIndex) {
    // Dummy
  }

  saveData(dragCard, dragIndex, hoverIndex) {
    const newData = update(this.state, {
      data: {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
      },
    });

    this.setState(newData);
    store.dispatch('updateOrder', newData.data);
  }

  getElement(element: FormElement, index) {
    return <EditableFormElementComponent
      id={ uniqueId() }
      seq={this.seq}
      index={index}
      moveCard={this.moveCard}
      insertCard={this.insertCard} mutable={false}
      parent={this.props.parent}
      editModeOn={this.props.editModeOn}
      isDraggable={true}
      key={element.id}
      sortData={element.id}
      isRequired={element.required}
      element={ element }
      onDestroy={ this._onDestroy }
      selected={this.props.editElement?.id === element.id}
    />
  }

  render() {
    let classes = this.props.className;
    const data = this.state.data.filter(x => !!x);
    const items = data.map((item, index) => this.getElement(item, index));
    return (
      <div className={classes}>
        <div className="Sortable">{items}</div>
        <PlaceHolder id="form-place-holder" show index={items.length} moveCard={this.cardPlaceHolder} insertCard={this.insertCard}/>
      </div>
    );
  }
}
Preview.defaultProps = {
  showCorrectColumn: false, files: [], editMode: false, editElement: null, className: 'react-form-builder-preview',
};
