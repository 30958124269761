import * as React from 'react';
import { Modal } from 'reactstrap';
import DOMService from '../../services/DOMService';

export default class BaseModal extends React.Component<any, any> {

  constructor(props) {
    super(props)
    DOMService.modalRef = this;
    this.state = {
      modal: null,
      show: false,
      style: null
    }
  }

  public setModal = (modal: JSX.Element, style = {}) => this.setState({modal, show: true, style})

  public render() {
    const { show, modal, style } = this.state;
    if (!show || !modal) return <></>;
    return (
      <Modal isOpen={show} style={style} toggle={() => this.setState({show: false})}>
        { modal }
      </Modal>
    );
  }
}
