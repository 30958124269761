import * as React from 'react';
import Draggable from 'react-draggable';
import { Button } from 'reactstrap';
import { visioBloc } from '../../bloc/VisioBloc';
import User from '../../models/User';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';

export interface IVideoCallViewProps {
  user: User
}

export interface IVideoCallViewState {
}

export default class VideoCallView extends React.Component<IVideoCallViewProps, IVideoCallViewState> {
  constructor(props: IVideoCallViewProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <Draggable>
        <div className="visiocall-frame">
          {/* <div className="row-between">
            <FontAwesomeIcon className="text-primary" icon={["fal", "video"]} />
            <FontAwesomeIcon className="" icon={["fal", "times"]} />
          </div> */}
          <div className="row-flex mb-3">
            <AvatarLetter size={AvatarSize.sm} user={this.props.user} />
            <div className="ml-3">
              <b>{this.props.user.getFullName()}</b> vous appelle
            </div>
          </div>
          <div className="row-between">
            <Button onClick={() => visioBloc.rejectCall()} color="danger">Annuler</Button>
            <Button onClick={() => visioBloc.joinVisioFromCall()} color="primary">Accepter</Button>
          </div>
        </div>
      </Draggable>
    );
  }
}
