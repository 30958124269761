import Model from "./Model"

export default class Relationship extends Model {

  public username: string;
  public status: string;

  static modelUrl = 'relationships';
  public modelName = "relationship";

  constructor(json: any) {
    super(json)
    this.status = json.status;
  }
}
