import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';
import * as React from 'react';
import Alert from '../../models/Alert';
import Event from '../../models/Event';
import Closable from '../common/Closable';
import TextIcon from '../common/TextIcon';
import AlertForm from '../form/AlertForm';

export interface IAlertCrudProps {
  event: Event
}

export interface IAlertCrudState {
  adding: boolean
}

export default class AlertCrud extends React.Component<IAlertCrudProps, IAlertCrudState> {
  constructor(props: IAlertCrudProps) {
    super(props);

    this.state = {
      adding: false
    }
  }

  onAlertCreated = async (alert: Alert) => {
    let event = this.props.event;
    if (event.id) {
      alert.eventId = event.id;
      alert = await alert.create();
    }
    event.alerts.push(alert);
    this.setState({adding: false});
  }

  delete = async (alert: Alert, index: number) => {
    let event = this.props.event;
    if (event.id) await alert.delete();
    event.alerts = event.alerts.splice(index, 1);
    this.setState({});
  }

  public render() {
    const { event } = this.props;
    const { adding } = this.state;
    return (
      <div className="alert-form">
        <div className="row-flex">
          <div className="fw-bold darkgray">Alerts</div>
          <div className="divider w-100 mr-0"></div>
        </div>
        <div>
          { event.alerts.map((a, i) =>
            <div key={uniqueId()} className="alert-row row-between">
              <TextIcon leftIcon={["fal", "alarm-clock"]}>
                {Alert.getOptionLabel(a.date)}
              </TextIcon>
              <div onClick={() => this.delete(a, i)} className="gray-btn">
                <FontAwesomeIcon icon={["fal", "times"]}/>
              </div>
            </div>
          )}
        </div>
        <Closable show={this.state.adding} close={() => this.setState({adding: false})}>
          <AlertForm onSubmit={this.onAlertCreated} eventId={event.id} />
        </Closable>
        { !adding && <div onClick={() => this.setState({adding: true})} className="btn btn-success mt-2 btn-sm">Ajouter</div>}
      </div>
    );
  }
}
