import * as React from 'react';
import SessionService from '../../services/SessionService';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import SidebarItem from './SidebarItem';

export interface IProfileMenuProps {
  minimize: boolean
}

export interface IProfileMenuState {
  collapsed: boolean
}

export default class ProfileMenu extends React.Component<IProfileMenuProps, IProfileMenuState> {
  constructor(props: IProfileMenuProps) {
    super(props);

    this.state = {
      collapsed: true
    }
  }

  toggleCollapse = () => {
    this.setState({collapsed: !this.state.collapsed})
  }

  public render() {
    const { collapsed } = this.state;
    return (
      <div style={{height: collapsed ? "60px" : "180px" }} className="sidebar-profile">
        <div onClick={this.toggleCollapse} className="profile-menu">
          <div className="profile-menu-avatar">
            <AvatarLetter size={AvatarSize.xs} user={SessionService.user} />
          </div>
          { !this.props.minimize && <div className="account-dd">{SessionService.user.getFullName()}</div>}
        </div>
        <div className="d-flex flex-column" style={{marginTop: "1px"}}>
          <SidebarItem iconData={["fal", "user"]} label={"Profil"} to={SessionService.user.getFrontUrl()}/>
          {/* <SidebarItem iconData={["fal", "user-edit"]} label={"Editer"} to="/profile/edit"/> */}
          <SidebarItem iconData={["fal", "sign-out"]} label={"Se déconnecter"} to="/logout"/>
        </div>
      </div>
    );
  }
}
