import Room from "./Room";
import User from "./User";


export default class OneOneRoom extends Room {

  public user: User;
  public type = "oneone";
  public connectedTotal: number;

  constructor(json: any) {
    super(json)
    this.user = (json.users && json.users.length > 0 && new User(json.users[0])) || json.user;
    this.connectedTotal = json.connectedTotal;
  }

  getName(): string {
    return this.user.getFullName() || "";
  }

  getChildId(): number {
    return this.user?.id;
  }

  getChild(): User {
    return this.user;
  }

  getAvatar(): string {
    return this.user?.avatar || "";
  }


  getState(): string {
    if (!this.connectedTotal || this.connectedTotal === 0) return "Inactif"
    return `${this.connectedTotal} connectés`
  }

  hasConnected() {
    return this.connectedTotal && this.connectedTotal > 0;
  }

  getFrontUrl(): string {
    return this.user.getRoomUrl();
    // return `/rooms/private/${this.event.name.replace(new RegExp(" ", "g"), ".").toLowerCase()}.${this.id}`;
  }

  getLoadParam(): any {
    if (this.id) return {roomId: this.id};
    if (this?.user?.id) return {userId: this.user.id};
    return null;
  }

}
