import * as React from 'react';
import CommentInput from './commentInput';
import CommentsList from './commentsList';
import Commentable from '../../models/mixin/Commentable';
import Comment from '../../models/Comment';

export interface ICommentsComponentProps {
  commentable: Commentable
  withTitle?: boolean
  onComment?: () => void
}

export interface ICommentsComponentState {
}

export default class CommentsComponent extends React.Component<ICommentsComponentProps, ICommentsComponentState> {

  public inputRef = React.createRef<HTMLInputElement>();

  constructor(props: ICommentsComponentProps) {
    super(props);

    this.state = {
    }
  }

  add = async (comment: Comment) => {
    await this.props.commentable.create(comment);
    this.props.onComment && this.props.onComment();
    this.setState({});
  }

  public render() {
    const { commentable, withTitle } = this.props;
    return (
      <div className="comments my-3">
        { withTitle && <div className="fw-bold mb-2 darkgray">Commentaires</div>}
        <CommentInput onSubmit={this.add} />
        <CommentsList canReply comments={commentable.models} />
      </div>
    );
  }
}
