import React from 'react';
import Visio from '../models/Visio';
import User from '../models/User';
import SessionService from '../services/SessionService';
import SocketService from '../services/SocketService';
import Room from '../models/Room';
import { appBloc } from './AppBloc';

interface ICallMade {
  offer: any
  user: User
}

export interface IVisioBlocState {
  remoteUser: User
  calledBy: User
  isMuted: boolean
  cameraActive: boolean
  visio: Visio
  userCalled: User
}

export const Context = React.createContext({})

export let visioBloc: VisioBloc

class VisioBloc extends React.Component<any, IVisioBlocState> {

  public localVideo       = React.createRef<HTMLVideoElement>();
  public stream: MediaStream;

  constructor(props: any) {
    super(props);
    visioBloc = this;

    this.state = {
      remoteUser: null,
      calledBy: null,
      isMuted: false,
      cameraActive: true,
      visio: null,
      userCalled: null
    };

    SocketService.on("visio-created", async (data: any) => {
      let visio = new Visio(data.visio);
      visio.active();
      this.setState({visio})
    })
    SocketService.on("call-received", async (data: any) => {
      let visio = new Visio(data.visio);
      visio.users = visio.users.map(u => new User(u));
      this.setState({calledBy: new User(data.visio.caller), visio});
    });
    SocketService.on("destroy visio", async (data: any) => {
      this.setState({calledBy: null})
    })

  }

  createVisio = (users: User[], room?: Room) => {
    let visio = new Visio({users: [...users, SessionService.user ], roomId: room?.id, token: SessionService.getToken()}).fromMe();
    SocketService.get().socket.emit("new-visio", {visio, users: users.map(u => u.toMap()), domain: appBloc.getAppId()});
  }

  joinVisio = async (visio: Visio) => {
    visio.active();
    this.setState({visio})
  }

  joinVisioFromCall = async () => {
    this.state.visio.active();
    this.setState({calledBy: null})
  }

  toggleMute() {
    this.stream.getTracks()[0].enabled = !this.stream.getTracks()[0].enabled;
    this.setState({isMuted: !this.state.isMuted});
  }

  toggleCamera() {
    this.stream.getTracks()[1].enabled = !this.stream.getTracks()[1].enabled;
    this.setState({cameraActive: !this.state.cameraActive});
  }

  cancelCall() {
    SocketService.get().socket.emit("cancel-call", {
      to: this.state.userCalled.id,
      user: SessionService.user.toMap()
    });
    // this.saveCancelledCall()
    this.setState({userCalled: null});
  }

  rejectCall() {
    SocketService.get().socket.emit("reject-call", {
      to: this.state.calledBy.id,
      user: SessionService.user.toMap()
    });
    // this.saveRejectedCall()
    this.setState({calledBy: null});
  }

  quitVisio() {
    this.stream = null;
    this.setState({visio: null, remoteUser: null});
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default VisioBloc;

export function consumeVisioBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}

