import * as React from 'react';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { appBloc } from '../../bloc/AppBloc';
import User from '../../models/User';
import ToastrService from '../../services/ToastrService';
import SmartInputText from '../input/SmartInputText';

export interface IUserFormProps {
  onSubmitted?: () => void
}

export interface IUserFormState {
}

export default class UserForm extends React.Component<IUserFormProps, IUserFormState> {

  public user: User;

  constructor(props: IUserFormProps) {
    super(props);

    this.state = {
    }

    this.newUser();
  }

  newUser() {
    this.user = new User({roleId: appBloc.state.roles[0].id});
  }

  async componentDidMount() {
    try {
      // let user = (await UserService.checkNewUserToken(this.props.token)) as User;
      // this.user = user;
      // this.setState({isChecked: true})
    } catch (error) {
      // this.setState({redirect: true})
    }
  }

  submit = async () => {
    await this.user.create();
    this.newUser()
    this.setState({});
    ToastrService.toaster.show("Urioz", "Le compte a été crée.")
    this.props.onSubmitted && this.props.onSubmitted();
  }

  public render() {
    return (
      <div>
        
        <FormGroup>
          {/* <FontAwesomeIcon icon={["fal", "user"]} /> */}
          <SmartInputText label="Email" type="email" name="email" model={this.user} floating />
        </FormGroup>
        <FormGroup>
          {/* <FontAwesomeIcon icon={["fal", "lock"]} /> */}
          <SmartInputText label="Mot de passe" type="password" name="password" model={this.user} floating />
        </FormGroup>
        {/* <FormGroup>
          <div>Role : <span className="capitalize fw-bold text-primary">{this.user.role.name}</span></div>
        </FormGroup> */}
          <SmartInputText label="Rôle" type="select" name="roleId" model={this.user} value={this.user.roleId.toString()} floating>
            { appBloc.state.roles.map((et) => <option key={et.id} value={et.id}>{et.name}</option> )}
          </SmartInputText>
        <Row>
          <Col>
            <SmartInputText label="Prénom" name="firstname" model={this.user} floating/>
          </Col>
          <Col>
            <SmartInputText label="Nom" name="lastname" model={this.user} floating/>
          </Col>
        </Row>
        <Row>
          <Col>
            <SmartInputText label="Poste" name="job" model={this.user} floating/>
          </Col>
          <Col>
            <SmartInputText label="Téléphone" name="phone" model={this.user} floating/>
          </Col>
        </Row>
        <FormGroup className="text-center">
          <Button onClick={this.submit} className="btn-pink btn-lg">Créer le compte</Button>
        </FormGroup>
      </div>
    );
  }
}
