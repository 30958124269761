import * as React from 'react';
import DropboxDropdown from './DropboxDropdown';
import FileRow from './FileRow';

export interface IDropboxFileRowProps {
}

export interface IDropboxFileRowState {
}

export default class DropboxFileRow extends FileRow {

  renderDropdown() {
    return <DropboxDropdown file={this.props.file} />
  }
}
