import { IconName } from '@fortawesome/fontawesome-svg-core';
import FormElement from '../FormElement';
import { FormElementType } from '../FormElement';

export default class Image extends FormElement {

  public humanName = "Image"
  public icon: IconName = "image"
  public typeKey = FormElementType.Image;
  public key = "Image"

  public positionnable = true;
  public sizable = true;
  public hasUrl = true;

  setDefaultValue() {
    super.setDefaultValue();
    this.url = "https://lunawood.com/wp-content/uploads/2018/02/placeholder-image.png";
    this.style = {
      width: "50%"
    }
  }
}
