import React from 'react';
import NewMessageModal from '../components/modal/NewMessageModal';
import StaticMessageManager from '../managers/StaticMessageManager';
import StaticMessage from '../models/StaticMessage';
import DOMService from '../services/DOMService';
import { appBloc } from './AppBloc';
import SocketService from '../services/SocketService';


export interface IMessagingBlocState {
  messages: StaticMessage[]
  unreadCount: number
  totalPage: number
  currentPage: number
}

export const Context = React.createContext({})

export let messagingBloc: MessagingBloc

class MessagingBloc extends React.Component<any, IMessagingBlocState> {

  public messageManager: StaticMessageManager = new StaticMessageManager();
  public routeListener;
  public view;

  constructor(props: any) {
    super(props)
    messagingBloc = this

    this.state = {
      messages: [],
      unreadCount: 0,
      totalPage: 1,
      currentPage: 1,
    }
    SocketService.on("newMessage", () => this.setState({unreadCount: this.state.unreadCount + 1}));
  }

  onPageChange(page: number) {
    appBloc.push({search: "?page=" + page.toString()});
  }

  async loadMessage() {
    let params = {page: this.state.currentPage}
    if (this.view) params["view"] = this.view
    let response = (await this.messageManager.indexWithPagination(params));
    this.setState({messages: response.models, totalPage: response.lastPage, currentPage: response.currentPage})
  }

  getConversation = async (id: number) => {
    return await this.messageManager.getTalk(id.toString());
  }

  read = async (message: StaticMessage) => {
    if (message.isRead) return;
    await this.messageManager.update({id: message.id, isRead: true});
    this.setState({unreadCount: this.state.unreadCount - 1});
  }

  onToggleImportant = async (e, message: StaticMessage) => {
    e.preventDefault();
    message.isImportant = !message.isImportant;
    await this.messageManager.update({id: message.id, isImportant: message.isImportant})
    this.setState({})
  }

  onReply = (e, message: StaticMessage) => {
    e.preventDefault();
    DOMService.modal(<NewMessageModal replyTo={message} />)
  }

  getUnreadCount = async () => {
    let unreadCount = await this.messageManager.getUnreadCount();
    this.setState({unreadCount});
  }

  componentDidMount() {
    this.getUnreadCount();
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default MessagingBloc;

export function consumeMessagingBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
