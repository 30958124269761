import * as React from 'react';

export interface ILoadMoreLabelProps {
}

export interface ILoadMoreLabelState {
}

export default class LoadMoreLabel extends React.Component<ILoadMoreLabelProps, ILoadMoreLabelState> {
  constructor(props: ILoadMoreLabelProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
        
      </div>
    );
  }
}
