import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { NavLink, Route } from 'react-router-dom';
import { consumeMessagingBloc, IMessagingBlocState } from '../../bloc/MessagingBloc';
import { consumeNotifBloc, NotifBlocState } from '../../bloc/NotifBloc';
import IconButton from '../buttons/IconButton';
import Closable from '../common/Closable';
import NotifFrame from '../common/NotifFrame';
import UniversalSearch from '../input/UniversalSearch';
import Navigation from './Navigation';

export interface INavbarProps {
}

export interface INavbarState {
  notifOpen: boolean
  mobileMenuOpen: boolean
}

class Navbar extends React.Component<IMessagingBlocState & NotifBlocState, INavbarState> {

  constructor(props: IMessagingBlocState & NotifBlocState) {
    super(props);

    this.state = {
      notifOpen: false,
      mobileMenuOpen: false
    }
  }

  onHelp() {}

  onOpenNotif = () => {
    if (!this.state.notifOpen) this.setState({notifOpen: true})
    else this.closeNotif();
  }

  closeNotif = () => {
    this.setState({notifOpen: false});
  }

  public render() {
    const { notifOpen, mobileMenuOpen } = this.state;
    let notifClassName = "ml-3"

    if (notifOpen) notifClassName += " active";
    return (
      <div className="navbar">
        <div className="row-flex left-navbar">
          <div className="d-flex d-lg-none mr-2 menu-responsive darkgray">
            <FontAwesomeIcon onClick={() => this.setState({mobileMenuOpen: !mobileMenuOpen})} icon={["fal", "bars"]} />
            { mobileMenuOpen && <div className="mobile-menu">
            <div onClick={() => this.setState({mobileMenuOpen: false})} className="overlay"></div>
              <div className="mobile-menu-panel">
                <div onClick={() => this.setState({mobileMenuOpen: false})} className="sidebar-body">
                  <Navigation/>
                </div>
              </div>
            </div>}
          </div>
          <UniversalSearch/>
        </div>
        <div className="row-end" style={{flex: 1}}>
          <div id="visio-mini"></div>
          {/* <div className="position-relative d-none d-sm-block">
            <Dropdown
              position={DropDownPosition.bottom}
              direction={DropDownDirection.left}
              button={<IconButton className="ml-3" iconData={["fal", "question-circle"]} ></IconButton>
              }
              content={(
                <div className="dropdown-frame">
                  <div className="dropdown-item">
                    <NavLink to={"/organization"}>Organigramme</NavLink>
                  </div>
                </div>
              )}
            />
          </div> */}
          <div className="position-relative">
            <NavLink to={"/messaging"}>
              <Route path={"/messaging"} children={({ match }) => (
                <IconButton tooltip="Messagerie" className={"ml-3" + (match ? " active" : "")} iconData={["fal", "envelope"]} ></IconButton>
              )} />
            </NavLink>
            { this.props.unreadCount > 0 && <div className="circle">
              {this.props.unreadCount}
            </div>}
          </div>
          <div data-tip="navtip" data-for={"tt_notif"} className="position-relative">
            <IconButton tooltip="Notifications" className={notifClassName} onClick={this.onOpenNotif} iconData={["fal", notifOpen ? "times" : "bell"]} ></IconButton>
            { this.props.unreadNotifCount > 0 && <div className="circle">
              {this.props.unreadNotifCount}
            </div>}
          </div>
        </div>
        <Closable show={notifOpen} close={this.closeNotif}>
          <NotifFrame/>
        </Closable>
      </div>
    );
  }
}

export default consumeMessagingBloc(consumeNotifBloc(Navbar))
