import * as React from 'react';
import { driveBloc } from '../../bloc/DriveBloc';
import File from '../../models/File';
import DropboxDocumentsService from '../../services/DropboxDocumentsService';
import DropboxService from '../../services/DropboxService';
import Documents from './Documents';
import DropboxFileCard from './DropboxFileCard';
import DropboxFileRow from './DropboxFileRow';
import DropboxLoginView from './DropboxLoginView';

export interface IDropboxDocumentsProps {
}

export interface IDropboxDocumentsState {
}

export default class DropboxDocuments extends Documents {

  constructor(props) {
    super(props);
    DropboxService.init();
  }

  componentDidMount() {
    driveBloc.documentsService = new DropboxDocumentsService();
    driveBloc.init(this.props?.folderId);
  }

  renderRowItem(f: File) {
    return <DropboxFileRow key={ f.id } file={ f } />
  }

  renderCardItem(f: File) {
    return <DropboxFileCard key={ f.id } file={ f } />
  }

  render() {
    if (!DropboxService.isAuthenticated()) return <DropboxLoginView />
    return super.render();
  }

}
