import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { createPortal } from "react-dom";
import Peer from "simple-peer";
import { visioBloc } from "../../bloc/VisioBloc";
import Visio from '../../models/Visio';
import SocketService from '../../services/SocketService';
import IconButton from "../buttons/IconButton";
import { AvatarSize } from "../common/Avatar";
import AvatarLetter from '../common/AvatarLetter';
import Count from "./Count";
import RemoteVideos from './RemoteVideos';

export interface IViseoProps {
  visio: Visio
  isMuted: boolean
  cameraActive: boolean
}


class Viseo extends React.Component<IViseoProps, any> {

  public userVideo = React.createRef<HTMLVideoElement>();
  public localStream: MediaStream;
  public peers: {[id: string]: Peer.Instance} = {};
  public hasSended: { [id: string]: boolean } = {};
  public screenShare = false;
  public screenStream: MediaStream;
  public interval;

  constructor(props) {
  super(props);
  this.state = {
      showMessages: false,
      mini: false,
      count: 0
    }
  }

  componentDidMount() {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
      this.localStream = stream;
      this.userVideo.current.srcObject = stream;
      visioBloc.stream = stream;
      SocketService.get().socket.emit("join visio", this.props.visio.getVisioRef());
      SocketService.on("all users", this.createAllUsers)
      SocketService.on("user joined", this.joinUser);
      SocketService.on("user leave", this.leaveUser);
      SocketService.on("receiving returned signal", this.receiveSignal);
    })
    this.startCount();
  }

  startCount() {
    this.interval = setInterval(() => this.setState({count: this.state.count + 1}), 1000)
  }

  cancelCount() {
    clearInterval(this.interval);
  }

  joinUser = payload => {
    const stream = visioBloc.stream;
    const peer = this.addPeer(payload.signal, payload.socketId, stream);

    this.peers[payload.socketId] = peer;
    this.setState({});
  }

  receiveSignal = payload => {
    const peer = this.peers[payload.id];
    if (peer) peer.signal(payload.signal);
  }

  leaveUser = async (data: any) => {
    this.peers[data.socketId].destroy();
    delete this.peers[data.socketId];
    delete this.hasSended[data.socketId];
    this.setState({});
  }

  createAllUsers = (socketIds) => {
    const stream = visioBloc.stream;
    socketIds.forEach(socketId => {
      const peer = this.createPeer(socketId, SocketService.get().socket.id, stream);
      this.peers[socketId] = peer;
    })
    this.setState({});
  }

  createPeer = (userToSignal, socketId, stream: MediaStream): Peer.Instance => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", signal => {
      if (!this.hasSended[userToSignal]) {
        SocketService.get().socket.emit("sending signal", { userToSignal, socketId, signal })
        this.hasSended[userToSignal] = true;
      }
    })

    return peer;
  }

  addPeer = (incomingSignal, socketId, stream: MediaStream) => {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    })

    peer.on("signal", signal => {
      SocketService.get().socket.emit("returning signal", { signal, socketId })
    })

    peer.signal(incomingSignal);
    return peer;

  }

  quit() {
    SocketService.get().socket.emit("leave visio", {visioRef: visioBloc.state.visio.getVisioRef()});
    visioBloc.stream.getTracks().forEach(t => t.stop());
    visioBloc.quitVisio();
  }

  toggleScreenShare = () => {
    if (!this.screenShare) {
      let mediaDevices: any = window.navigator.mediaDevices;
      mediaDevices.getDisplayMedia({ video: true, audio: true }).then(stream => {
        this.screenStream = stream;
        Object.keys(this.peers).forEach(key => {
          this.peers[key].removeStream(visioBloc.stream);
          this.peers[key].addStream(stream);
        })
      });
    } else {
      Object.keys(this.peers).forEach(key => {
        this.peers[key].removeStream(this.screenStream);
        this.peers[key].addStream(visioBloc.stream);
      })
    }
    this.screenShare = !this.screenShare;
  }

  unmini = () => {
    this.setState({ mini: false }, async () => {
      this.userVideo.current.srcObject = this.localStream;
    })
  }

  componentWillUnmount() {
    SocketService.off("all users", this.createAllUsers);
    SocketService.off("user joined", this.joinUser);
    SocketService.off("user leave", this.leaveUser);
    SocketService.off("receiving returned signal", this.receiveSignal);
    this.cancelCount();
  }

  render() {
    const { isMuted, cameraActive, visio } = this.props;
    const { mini, count } = this.state;

    if (mini) return createPortal(
      <div className="visio-mini">
        Visio
        <Count count={count} />
        <IconButton iconData={["fal", "expand-wide"]} light onClick={() => this.unmini()} />
      </div>,
      document.getElementById("visio-mini")
    )
    return (

      <div>
        <div className="active-users-panel">
          <div className="row-flex">
            { visio && visio.users.map(u => <AvatarLetter key={u.id} className="mr-1" size={AvatarSize.sm} user={u} />)}
            <Count count={count} />
          </div>
          <div className="actions-video">
            <div className="buttons-video">
              <div onClick={() => visioBloc.toggleCamera()} className="video-action-btn">
                <FontAwesomeIcon icon={["fal", cameraActive ? "video" : "video-slash"]} />
              </div>
              <div onClick={() => visioBloc.toggleMute()} className="video-action-btn">
                <FontAwesomeIcon icon={["fal", isMuted ? "microphone-slash" : "microphone"]} />
              </div>
              <div onClick={() => this.quit()} className="video-action-btn">
                <FontAwesomeIcon className="text-danger" icon={["fal", "phone"]} />
              </div>
              {/* <div onClick={() => this.toggleScreenShare()} className="video-action-btn">
                <FontAwesomeIcon className="text-danger" icon={["fal", "desktop"]} />
              </div> */}
            </div>
          </div>
          <div className="row-end">
            <IconButton className="mr-1" iconData={["fal", "minus"]} onClick={() => this.setState({mini: true})} />
            <IconButton iconData={["fal", "times"]} onClick={() => this.quit()} />
            {/* <IconButton iconData={["fal", "expand-wide"]} onClick={() => {}} /> */}
          </div>
        </div>
        <div className="video-container">
          <RemoteVideos localVideo={this.userVideo} peers={this.peers} />
        </div>
      </div>
    );
  }
};

export default Viseo;
