import * as React from 'react';
import IconButton from '../buttons/IconButton';
import PublicFormContainer from '../form/PublicFormContainer';
import FormShow from './FormShow';
import Form from '../../models/form/Form';

export interface IFullscreenFormPreviewProps {
  form: Form
  close: () => void
}

export interface IFullscreenFormPreviewState {
}

export default class FullscreenFormPreview extends React.Component<IFullscreenFormPreviewProps, IFullscreenFormPreviewState> {
  constructor(props: IFullscreenFormPreviewProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className="full-screen-preview">
        <div className="full-screen-preview-toolbar">
          <div className="row-between">
            <div></div>
            <IconButton onClick={this.props.close} iconData={["fal", "times"]} tooltip="Quitter" />
          </div>
        </div>
        <PublicFormContainer form={ this.props.form } >
          <FormShow form={ this.props.form } />
        </PublicFormContainer>
      </div>
    );
  }
}
