import * as React from 'react';
import ModernNavItem from './ModernNavItem';

export interface IModernNavigationProps {
}

export interface IModernNavigationState {
}

export default class ModernNavigation extends React.Component<IModernNavigationProps, IModernNavigationState> {
  constructor(props: IModernNavigationProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
        <ModernNavItem to="/"          label="Actualité"  icon={"home"} exact/>
        <ModernNavItem to="/drive"     label="Documents"  icon={"cloud"} />
        <ModernNavItem to="/events"    label="Agenda"     icon={"calendar-alt"} />
        <ModernNavItem to="/projects"   label="Tâches"     icon={"tasks"} />
        <ModernNavItem to="/relations" label="Relations"  icon={"network-wired"} />
        {/* <ModernNavItem to="/messages" icon={["fal", "comments"]} /> */}
        {/* <ModernNavItem to="/groups" icon={["fal", "users-class"]} /> */}
      </div>
    );
  }
}
