import { IconName } from '@fortawesome/fontawesome-svg-core';
import FormElement from '../FormElement';
import { FormElementType } from '../FormElement';

export default class Tags extends FormElement {

  public humanName = "Sélecteur multiple"
  public icon: IconName = "tags"
  public typeKey = FormElementType.Tags;
  public key = "Tags"

  public isField = true;
  public isSubmittable = true;
  public hasOptions = true;

  setDefaultValue() {
    super.setDefaultValue();
    this.label = "Label"
  }
}
