import Model from "./Model";

export default class New extends Model {

  public content: string;
  public link: string;
  static modelUrl = "news";

  constructor(json: any) {
    super(json)
    this.content  = json.content;
    this.link     = json.link;
  }

  hasLink() {
    return !!this.link;
  }

}
