import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Room from '../../models/Room';

export interface IMatchedRoomItemProps {
  room: Room;
}

export default class MatchedRoomItem extends React.Component<IMatchedRoomItemProps> {
  public render() {
    const { room } = this.props;
    return (
      <NavLink to={room.getFrontUrl()}>
        <div className="room-item py-2">
          <div className="row-center m-auto py-1 d-md-none">
            {/* <AvatarLetter url={room.getAvatar()} size={AvatarSize.xs} /> */}
          </div>
          <div className="row-between d-md-flex d-none px-3">
            {/* <HeadingUser
              avatar={room.getAvatar()}
              label={room.getName()}
              // subTitle={<div className="last-message inline-text">{room.getSubTitle()}</div>}
            /> */}
          </div>
          <div className="mr-2 dark-gray fs-small">
            { `${room.messages.length} messages` }
          </div>
        </div>
      </NavLink>
    );
  }
}
