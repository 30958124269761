import * as React from 'react';
import { appBloc } from '../../bloc/AppBloc';
import configByEnv from '../../config/env';
import Form from '../../models/form/Form';
import MediaFileButton from '../buttons/MediaFileButton';
import TextIcon from '../common/TextIcon';
import SmartInputText from '../input/SmartInputText';
import SmartSwitch from '../input/SmartSwitch';
import classNames from 'classnames';
import FormElement from '../../models/form/FormElement';

export interface IFormFormProps {
  form: Form
}

export interface IFormFormState {
  isPublic: boolean
}

export default class FormForm extends React.Component<IFormFormProps, IFormFormState> {
  constructor(props: IFormFormProps) {
    super(props);

    this.state = {
      isPublic: false,
    }
  }

  togglePublic = (isPublic) => {
    this.setState({ isPublic }, () => this.refreshPublicUrl());
  }

  refreshPublicUrl = () => {
    const { form } = this.props
    let formName: string = form.title?.replace(/ /g, "_")?.toLocaleLowerCase() || "";
    if (this.state.isPublic) form.publicUrl = `${configByEnv.protocol}${configByEnv.frontUrl}/public/${appBloc.getAppId()}/${formName}`;
    else form.publicUrl = null;
    this.setState({});
  }

  onBackgroundChange = () => {

  }

  onToggleVisibleColumn(element: FormElement) {
    element.visible = !element.visible;
    this.setState({})
  }

  public render() {
    const { form } = this.props
    const { isPublic } = this.state
    return (
      <div>
        <div>
          <SmartInputText onChange={this.refreshPublicUrl} label="Titre du formulaire" model={form} name="title" floating />
        </div>
        <div className="row-between mb-3">
          <SmartSwitch
            label={ "Rendre public" }
            onChange={(e) => this.togglePublic(e)}
            checked={ isPublic }
            className="mb-2"
          />
          { isPublic &&
            <div className="mb-2">
              <span>Adresse URL : </span>
              <span className="fw-600 text-primary">{ form.publicUrl }</span>
            </div>
          }
        </div>
        <div>
          <div className="mb-2">Colonnes visibles</div>
          <div className="d-flex">
            { form.getSubmittableElements().map(element => (
              <div onClick={() => this.onToggleVisibleColumn(element)} className={classNames({"tag": true, "selected": element.visible})}>
                { element.label }
              </div>
            ))}
          </div>
        </div>
        { isPublic && <>
            <div>
              <SmartInputText label="Sous titre" model={form} name="subTitle" floating />
            </div>
            <div>
              <SmartInputText label="Pied de page" model={form} name="footer" floating />
            </div>
            <MediaFileButton onFileChange={ this.onBackgroundChange }>
              <div className="btn btn-success">
                <TextIcon leftIcon={ ["fal", "plus"] }>
                  { "Image de fond" }
                </TextIcon>
              </div>
            </MediaFileButton>
          </>
        }
      </div>
    );
  }
}
