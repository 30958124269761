import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import GroupBloc from './bloc/GroupBloc';
import ProjectBloc from './bloc/ProjectBloc';
import RoomRedirect from './components/messaging/roomRedirect';
import DrivePage from './pages/DrivePage';
import DropBoxAuth from './pages/DropBoxAuth';
import EventsPage from './pages/EventsPage';
import FormPage from './pages/FormPage';
import GroupPage from './pages/GroupPage';
import HomePage from './pages/HomePage';
import MemberPage from './pages/MemberPage';
import MessagesPage from './pages/MessagesPage';
import OrganizationPage from './pages/OrganizationPage';
import ProfilePage from './pages/ProfilePage';
import ProjectsPage from './pages/ProjectsPage';
import FriendsPage from './pages/RelationsPage';
import RoomsPage from './pages/RoomsPage';
import SearchPage from './pages/SearchPage';
import SettingPage from './pages/SettingsPage';
import SubmissionShow from './components/formBuilder/SubmissionShow';
import Page404 from './pages/404Page';

export interface IAppRouterProps {
}

export default class AppRouter extends React.Component<IAppRouterProps> {

  public resolveProfileUrl = (pathname: string): string => {
    return pathname.split(".").pop();
  }

  public resolveRoomUrl = (pathname: string) => {
    if (!pathname) return null;
    let id = pathname.split("/").pop().split(".").pop()
    if (pathname.startsWith("/messages/private")) {
      return { userId: id };
    }
    if (pathname.startsWith("/messages/group")) {
      return { roomId: id };
    }
    if (pathname.startsWith("/messages/custom")) {
      return { roomId: id };
    }
    return null;
  }

  public render() {
    return (
      <Switch>
        <Route exact path="/"                   render={() => <HomePage/>} />
        <Route path="/search"                   component={() => <SearchPage/>} />
        <Route path="/dropbox/auth"             component={() => <DropBoxAuth/>} />
        <Route path="/documents"                render={() => <DrivePage/>} />
        <Route path="/events"                   render={() => <EventsPage/>} />
        <Route path="/messaging/inbox"          component={() => <MessagesPage/>} />
        <Route exact path="/messaging"          component={() => <Redirect to="/messaging/inbox" />} />
        <Route path="/messaging"                component={() => <MessagesPage/>} />
        <Route path="/projects"                 render={() => <ProjectBloc><ProjectsPage/></ProjectBloc>} />
        <Route       path={'/messages/:name'}   render={ (p) => <RoomsPage params={this.resolveRoomUrl(p.location.pathname)} /> }/>
        <Route       path={'/messages'}         render={ (p) => <RoomRedirect/>}/>
        <Route exact path="/relations"          render={() =><GroupBloc><FriendsPage/></GroupBloc>} />
        <Route exact path="/relations/members"  render={() => <MemberPage/>} />
        <Route path="/groups"                   render={() => <GroupPage/>} />
        <Route exact path="/settings"           component={() => <Redirect to="/settings/user" />} />
        <Route path="/settings"                 render={() => <SettingPage/>} />
        <Route path="/forms"                    render={() => <FormPage/>} />

        <Route path="/submissions/:id"          component={(p) => <SubmissionShow submissionId={p.match.params.id} />} />
        <Route path="/organization"             component={() => <OrganizationPage/>} />
        <Route path="/profile/:name"            component={(p) => <ProfilePage userId={this.resolveProfileUrl(p.match.params.name)}/>} />
        <Route path="" component={ (p) => <Page404 /> } />
      </Switch>
    );
  }
}
