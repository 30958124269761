import classNames from 'classnames';
import React from 'react';
import AppRouter from './AppRouter';
import AppWrapper from './AppWrapper';
import { appBloc, consumeAppBloc, IAppBlocState } from './bloc/AppBloc';
import FullScreenLoading from './components/common/FullScreenLoading';
import Notifier from './components/common/Notifier';
import ModernNavbar from './components/layout/ModernNavbar';
import Navbar from './components/layout/Navbar';
import Sidebar from './components/layout/Sidebar';
import BaseModal from './components/modal/BaseModal';
import Visio from './components/realtime/Visio';
import fontAwesome from './config/fontAwesome';
import LogService from './services/LogService';
import SocketService from './services/SocketService';
fontAwesome();


class App extends React.PureComponent<IAppBlocState, any> {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    appBloc.initApp()
    LogService.init();
  }

  componentWillUnmount() {
    SocketService.close();
  }

  render() {
    const { minimize, initialized, darkmode, modernmode } = this.props;
    if (!initialized) return <FullScreenLoading/>
    return (
      <AppWrapper>
        <div className={classNames({"app": true, "mini": minimize, "darkmode" : darkmode, "modernmode": modernmode})}>
          { !modernmode && <Sidebar/>}
          { !modernmode && <Navbar/>}
          { modernmode && <ModernNavbar/>}
          <div className="app-body">
            <AppRouter/>
          </div>
          <BaseModal/>
          <Visio/>
          <Notifier/>
        </div>
      </AppWrapper>
    );
  }
}

export default consumeAppBloc(App)
