import * as React from 'react';
import Moment from 'react-moment';
import DatePicker from '../../models/form/formElements/DatePicker';
import Select from '../../models/form/formElements/Select';
import Switch from '../../models/form/formElements/Switch';
import TextArea from '../../models/form/formElements/TextArea';
import TextInput from '../../models/form/formElements/TextInput';
import SubmissionValue from '../../models/form/SubmissionValue';

export interface ISubmissionValueDisplayerProps {
  submissionValue: SubmissionValue
}

export interface ISubmissionValueDisplayerState {
}

export default class SubmissionValueDisplayer extends React.Component<ISubmissionValueDisplayerProps, ISubmissionValueDisplayerState> {
  constructor(props: ISubmissionValueDisplayerProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { submissionValue } = this.props;
    console.log(submissionValue)
    if (submissionValue.formElement instanceof TextInput || submissionValue.formElement instanceof TextArea) return <div>{submissionValue?.value}</div>
    if (submissionValue.formElement instanceof Switch) return <div>{submissionValue.value ? "Oui" : "Non"}</div>
    if (submissionValue.formElement instanceof Select) return <div>{submissionValue.value?.toString()}</div>
    // if (submissionValue.isFile()) {
    //   return <img className="img-fluid" src={submissionValue.value} alt=""/>
    // }
    if (submissionValue.formElement instanceof DatePicker)   return <Moment format={"DD MMMM YYYY - hh:mm"} >{submissionValue.value}</Moment>
    return <></>;
  }
}
