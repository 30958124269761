import * as React from 'react';
import { consumeDriveBloc, driveBloc, IDriveBlocState } from '../../bloc/DriveBloc';
import RightService from '../../services/RightService';
import MediaFileButton from '../buttons/MediaFileButton';
import TextIcon from '../common/TextIcon';
import DriveSearchInput from '../input/DriveSearchInput';
import { Progress } from 'reactstrap';
import FileModel from '../../models/File';

export interface IDriveTopBarProps {
}

export interface IDriveTopBarState {
  filePreviewName: string
}

class DriveTopBar extends React.Component<IDriveBlocState, IDriveTopBarState> {
  constructor(props: IDriveBlocState) {
    super(props);

    this.state = {
      filePreviewName: ""
    }
  }

  onFileChange = (file: File) => {
    driveBloc.onUpload(file);
    // this.setState({filePreviewName: file.name})
  }

  formatUsedStorageText = (usedStorage: number) => {
    return FileModel.sizeText(usedStorage) + " utilisé sur 1 To"
  }

  getPercent = (usedStorage: number) => {
    return usedStorage / 1000000000000
  }

  public render() {
    // const { filePreviewName } = this.state;
    const { usedStorage } = this.props;
    return (
      <div className="drive-topbar">
        <DriveSearchInput/>
        <div className="row-center">
          <div className="px-3">
            <div className="text-small darkgray mb-2">{this.formatUsedStorageText(usedStorage)}</div>
            <Progress className="w-100 mr-2 h-100" style={{height: "6px"}} color="success" value={this.getPercent(usedStorage)} />
          </div>
          { RightService.can("drive") && <div onClick={driveBloc.onNewFolder} className="btn btn-primary mr-3">
            <TextIcon leftIcon={["fal", "folder-plus"]}>Créer un dossier</TextIcon>
          </div>}
          <MediaFileButton onFileChange={this.onFileChange}>
            <div className="btn btn-success">
              <TextIcon className="d-none d-md-block" leftIcon={["fal", "upload"]}>Ajouter un fichier</TextIcon>
              <TextIcon className="d-block d-md-none" leftIcon={["fal", "upload"]}>Importer</TextIcon>
            </div>
          </MediaFileButton>
        </div>
        {/* <div>{filePreviewName}</div> */}
      </div>
    );
  }
}

export default consumeDriveBloc(DriveTopBar)
