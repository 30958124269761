import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uniqueId } from 'lodash';
import * as React from 'react';

export interface IListInputsProps {
  options: any[]
  name: string
  title: string
  modelClass: any
  onChanged?: () => void
}

export interface IListInputsState {
}

export default class ListInputs extends React.Component<IListInputsProps, IListInputsState> {


  addOption = () => {
    const { modelClass, options, onChanged } = this.props;
    options.push(new modelClass({id: uniqueId()}))
    // this.setState({})
    onChanged && onChanged()
  }

  handleChange = (e, id) => {
    const { options, name, onChanged } = this.props;
    let index = options.findIndex(o => o.id === id);
    options[index][name] = e.target.value;
    onChanged && onChanged()
  }

  delete = (index) => {
    const { options, onChanged } = this.props;
    options.splice(index, 1)
    // this.setState({})
    onChanged && onChanged()
  }

  public render() {
    const { options, title, name } = this.props;
    return (
      <div>
        <div>{title}</div>
        {options.map((option, index) =>
          <div className="row-flex list-inputs-item">
            <div style={{minWidth: "30px"}}>
              <FontAwesomeIcon icon={ ["fas", "circle"] } className="grip-vertical mr-2" />
            </div>
            <input placeholder={"option " + (index + 1)} autoFocus className="light-input" onChange={(e) => this.handleChange(e, option.id)} value={option[name]}/>
            <FontAwesomeIcon onClick={() => this.delete(index)} icon={["fal", "times"]} className="ml-2 gray-btn"/>
          </div>
        ) }
        <div className="row-flex" onClick={ this.addOption } >
          <div style={{minWidth: "30px"}}>
            <FontAwesomeIcon className="mediumgray" icon={ ["fal", "plus"] } />
          </div>
          <div className="fake-input">Ajouter une option</div>
        </div>
      </div>
    );
  }
}
