import * as React from 'react';
import { Button } from 'reactstrap';
import { consumeAppBloc, IAppBlocState, appBloc } from '../../bloc/AppBloc';
import EntityManager from '../../managers/EntityManager';
import New from '../../models/New';
import NewRow from '../common/NewRow';
import SmartInputText from '../input/SmartInputText';
import AdminView from './AdminView';
import CrudAdmin from './CrudAdmin';

export interface IAdminNewsProps {
}

export interface IAdminNewsState {
  addingNew: boolean
}

class AdminNews extends React.Component<IAppBlocState, IAdminNewsState> {

  public inputRef = React.createRef<HTMLInputElement>();
  public neww = new New({});

  constructor(props: IAppBlocState) {
    super(props);

    this.state = {
      addingNew: false
    }
  }

  public render() {
    return (
      <AdminView title="Gestion des messages d'accueil">
      <CrudAdmin<New>
        em={ new EntityManager(New) }
        afterAction={ (action, model) => appBloc.loadNews() }
        render={(models, create, updateAll, onDelete) => (
          <>
            <div className="mb-3 darkgray fw-bold">Ajouter un message d'accueil</div>
            <SmartInputText model={this.neww} name="content" label="Message" floating type="textarea"></SmartInputText>
            <div className="row-flex mb-4">
              <Button color="primary" onClick={() => create(this.neww)} >Ajouter</Button>
            </div>
            <div className="mb-3 darkgray fw-bold">Messages d'accueils</div>
            <div className="py-2">
              { models.map(n => <NewRow key={n.id} onDelete={() => onDelete(n)} neww={n} />)}
            </div>
        </>
      )} />
      </AdminView>
    );
  }
}

export default consumeAppBloc(AdminNews)
