import * as React from 'react';
import { appBloc } from '../../bloc/AppBloc';
import AdminView from '../admin/AdminView';
import SmartSwitch from '../input/SmartSwitch';

export interface IThemeViewProps {
}

export interface IThemeViewState {
  darkMode: boolean
}

export default class ThemeView extends React.Component<IThemeViewProps, IThemeViewState> {
  constructor(props: IThemeViewProps) {
    super(props);

    this.state = {
      darkMode: false
    }
  }

  public render() {
    return (
      <AdminView title="Thème">
        <SmartSwitch
          primary
          className="mb-2"
          label="Dark Mode"
          onChange={() => appBloc.toggleDarkMode()}
          checked={appBloc.state.darkmode}
        />
        <SmartSwitch
          primary
          label="Modern Mode"
          onChange={() => appBloc.toggleModernMode()}
          checked={appBloc.state.modernmode}
        />
      </AdminView>
    );
  }
}
