import * as React from 'react';
import { relationBloc } from '../../bloc/RelationBloc';
import IconButton from '../buttons/IconButton';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import User from '../../models/User';

export interface IWaitingFriendRowProps {
  user: User
}

export default class WaitingFriendRow extends React.Component<IWaitingFriendRowProps> {
  public render() {
    const { user } = this.props;
    return (
      <div className="list-item row-between">
        <div className="w-100 row-flex">
          <AvatarLetter
            user={user} size={AvatarSize.sm}
          />
          <div className="ml-2">
            <b>{user.getFullName()}</b>
            <span> veut faire parti de vos relations.</span>
          </div>
        </div>
        <div className="row-flex" style={{"whiteSpace": "nowrap"}}>
          <IconButton className="text-success" onClick={() => relationBloc.acceptFriend(this.props.user)} iconData={["fal", "check"]}></IconButton>
          <IconButton className="text-danger ml-3" onClick={() => relationBloc.cancelFriend(this.props.user)} iconData={["fal", "times"]}></IconButton>
        </div>
      </div>
    );
  }
}
