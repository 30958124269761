import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Redirect } from 'react-router';
import { Button, FormGroup, Input, Form } from 'reactstrap';
import Logo from '../components/common/Logo';
import AuthPage from '../components/layout/AuthPage';
import authService, { AuthState } from '../services/AuthService';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import ToastrService from '../services/ToastrService';

export interface ILoginPageProps {
}

export interface ILoginPageState {
  [arg: string]: any
  email: string
  password: string
  isChecking: boolean
  redirect: boolean
  invalid: boolean
}

export default class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {
  constructor(props: ILoginPageProps) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isChecking: false,
      redirect: false,
      invalid: false
    }
  }

  onKeyPressed = (e) => {
    if (e.key === "Enter") this.submit(e)
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value, invalid: false})
  }

  onKeyPress = (e) => {
    if (e.key === "Enter") this.submit(e)
  }

  submit = async (e) => {
    e.preventDefault();
    this.setState({isChecking: true})
    let response: AuthState = await authService.login({email: this.state.email, password: this.state.password})
    if (response === AuthState.ok) {
      this.setState({redirect: true});
    }
    else {
      ToastrService.toaster.show("Erreur d'authentification", "Vos identifiant ne semble pas correspondre.", "danger")
      this.setState({invalid: true, isChecking: false});
    }
  }

  public render() {
    const {email, password, redirect, invalid} = this.state;
    if (redirect) return <Redirect to="/" />
    return (
      <AuthPage>
        <div className="auth-card-container">
          <div className="auth-card">
            <div className="auth-card-header">
              <div className="d-none d-md-block" style={{marginLeft: "-3rem"}}>
                <Logo size={220} withTitle />
              </div>
              <div className="d-md-none d-md-none">
                <Logo size={150} />
              </div>
              <div className="subtitle mb-4">
                Plateforme Intranet
              </div>
            </div>
            <div className="auth-card-body">
              <Form onSubmit={this.submit}>
                <FormGroup>
                  <FontAwesomeIcon icon={["fal", "user"]} />
                  <Input className={classNames({"input-icon": true, "is-invalid": invalid})} onKeyPress={this.onKeyPress} value={email} type="email" name="email" required onChange={this.handleChange} placeholder="Identifiant"/>
                </FormGroup>
                <FormGroup>
                  <FontAwesomeIcon icon={["fal", "lock"]} />
                  <Input required className={classNames({"input-icon": true, "is-invalid": invalid})} onKeyPress={this.onKeyPress} value={password} name="password" type="password" onChange={this.handleChange} placeholder="Mot de passe"/>
                </FormGroup>
                <FormGroup className="text-center">
                  <Button type="submit" className="btn-pink btn-lg">Se connecter</Button>
                </FormGroup>
                <div className="row-center p-3">
                  <NavLink to="/forgot_password">Mot de passe oublié ?</NavLink>
                </div>
                <div className="divider"></div>
                <div className="row-center mt-3">
                  <span className="mr-2">Pas de compte ?</span>
                  <NavLink className="fw-bold" to="/signin"> Incrivez vous dès maintenant</NavLink>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </AuthPage>
    );
  }
}
