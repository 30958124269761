/**
  * <ToolbarItem />
  */

import React from 'react';
import { DragSource } from 'react-dnd';
import TextIcon from '../../common/TextIcon';
import ID from './UUID';

const cardSource = {
  beginDrag(props) {
    return {
      id: ID.uuid(),
      index: -1,
      data: props.data,
      onCreate: props.onCreate,
    };
  },
};

class ToolbarItem extends React.Component<any> {
  render() {
    const { connectDragSource, data, onClick,isDragging } = this.props;
    if (!connectDragSource) return null;
    return (
      connectDragSource(
        <div style={{opacity: isDragging ? 0.2 : 1}} className="toolbar-item" onClick={onClick}>
          <TextIcon spacing={3} leftIcon={["fal", data.icon]} >{data.humanName}</TextIcon>
        </div>,
      )
    );
  }
}

export default DragSource("Card", cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(ToolbarItem);
