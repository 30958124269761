import { library } from '@fortawesome/fontawesome-svg-core'


import {
  faCloudDownloadAlt,
  faFolderOpen,
  faFileAlt,
  faHome,
  faQuestionCircle,
  faCalendarAlt,
  faCalendar,
  faPlus,
  faExclamationTriangle,
  faExclamationCircle,
  faInfo,
  faEnvelopeSquare,
  faFilter,
  faUser,
  faFileSearch,
  faArchive,
  faEdit,
  faPen,
  faUpload,
  faEnvelope,
  faStar,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faBell,
  faTimes,
  faImage,
  faGripLinesVertical,
  faDesktop,
  faSearch,
  faShareAlt,
  faBracketsCurly,
  faHandRock,
  faFileExport,
  faTimesCircle,
  faPhone,
  faPenAlt,
  faUserEdit,
  faCloud,
  faShare,
  faReply,
  faLink,
  faPaperclip,
  faChevronRight,
  faUserPlus,
  faUserFriends,
  faCommentAlt,
  faFolderPlus,
  faLock,
  faSignOut,
  faCheck,
  faComment,
  faEllipsisH,
  faTags,
  faEllipsisV,
  faSlidersH,
  faComments,
  faTrash,
  faFileCheck,
  faArrowAltLeft,
  faTh,
  faArrowAltToLeft,
  faChevronUp,
  faMicrophone,
  faMicrophoneSlash,
  faUsers,
  faChevronLeft,
  faArrowAltCircleUp,
  faCircle,
  faList,
  faTasks,
  faCogs,
  faBars,
  faClock,
  faBriefcase,
  faMarker,
  faAlarmClock,
  faAlarmExclamation,
  faMinus,
  faPaperPlane,
  faVideo,
  faCommentAltPlus,
  faVideoSlash,
  faInfoCircle,
  faUserCrown,
  faEye,
  faPhotoVideo,
  faMapMarkerAlt,
  faUsersClass,
  faCheckSquare,
  faFillDrip,
  faTint,
  faSmileBeam,
  faNetworkWired,
  faCaretDown,
  faText,
  faToggleOn,
  faKeyboard,
  faMousePointer,
  faFileMinus,
  faServer,
  faVoicemail,
  faGlobe,
  faExpandWide,
  faChevronDown,
  faFileChartLine,
  faMailBulk
} from "@fortawesome/pro-light-svg-icons"

import { faLinkedin, faGoogle } from "@fortawesome/free-brands-svg-icons";

import {
  faHome as faHomeSolid ,
  faCloud as faCloudSolid ,
  faCalendarAlt as faCalendarAltSolid ,
  faTasks as faTasksSolid ,
  faNetworkWired as faNetworkWiredSolid,
  faBell as faBellSolid,
  faCloudDownloadAlt as faCloudDownloadAltSolid,
  faStar as faStarSolid,
  faCircle as faCircleSolid,
  faReply as faReplySolid,
  faPhone as faPhoneSolid,
  faItalic as faItalicSolid,
  faBold as faBoldSolid,
  faInfoCircle as faInfoCircleSolid,
  faCaretDown as faCaretDownSolid,
  faGripLinesVertical as faGripLinesVerticalSolid,
  faGripVertical as faGripVerticalSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faEllipsisH as faEllipsisHSolid,
} from '@fortawesome/pro-solid-svg-icons'

export default () => library.add(
  faCloudDownloadAlt,
  faCloudDownloadAltSolid,
  faList,
  faMailBulk,
  faMapMarkerAlt,
  faServer,
  faGlobe,
  faFileChartLine,
  faEnvelopeSquare,
  faExclamationCircleSolid,
  faGripLinesVertical,
  faEllipsisHSolid,
  faGripLinesVerticalSolid,
  faFillDrip,
  faTint,
  faCaretDown,
  faBellSolid,
  faPen,
  faText,
  faGripVerticalSolid,
  faEye,
  faGoogle,
  faToggleOn,
  faKeyboard,
  faBoldSolid,
  faMousePointer,
  faItalicSolid,
  faFileMinus,
  faPaperPlane,
  faTasks,
  faVideoSlash,
  faSmileBeam,
  faInfoCircle,
  faPhotoVideo,
  faCaretDownSolid,
  faCheckSquare,
  faCommentAltPlus,
  faHomeSolid,
  faCloudSolid,
  faEllipsisV,
  faCalendarAltSolid,
  faTasksSolid,
  faNetworkWiredSolid,
  faInfoCircleSolid,
  faAlarmClock,
  faAlarmExclamation,
  faExclamationTriangle,
  faExclamationCircle,
  faFilter,
  faBriefcase,
  faClock,
  faMarker,
  faShareAlt,
  faMicrophone,
  faDesktop,
  faMicrophoneSlash,
  faTimesCircle,
  faPhoneSolid,
  faUserCrown,
  faVideo,
  faHandRock,
  faLinkedin,
  faEnvelope,
  faFolderOpen,
  faCircleSolid,
  faCircle,
  faArrowAltCircleUp,
  faArrowAltToLeft,
  faChevronDown,
  faChevronUp,
  faFileAlt,
  faPhone,
  faEdit,
  faMinus,
  faExpandWide,
  faEllipsisH,
  faFolderPlus,
  faChevronLeft,
  faChevronRight,
  faTh,
  faUserEdit,
  faLink,
  faArrowAltLeft,
  faSignOut,
  faBracketsCurly,
  faFileExport,
  faPenAlt,
  faBars,
  faFileCheck,
  faCheck,
  faComment,
  faImage,
  faComments,
  faTags,
  faUserPlus,
  faUser,
  faPaperclip,
  faSignOut,
  faFileSearch,
  faLock,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faSlidersH,
  faUserFriends,
  faCommentAlt,
  faReply,
  faSearch,
  faUpload,
  faPlus,
  faArchive,
  faQuestionCircle,
  faReplySolid,
  faStarSolid,
  faCalendar,
  faTimes,
  faStar,
  faShare,
  faTrash,
  faHome,
  faCalendarAlt,
  faCloud,
  faUsers,
  faInfo,
  faBell,
  faCogs,
  faUsersClass,
  faNetworkWired,
  faVoicemail
)
