import * as React from 'react';
import { appBloc } from '../../bloc/AppBloc';
import ToastrService from '../../services/ToastrService';
import UserService from '../../services/UserService';
import UserForm from '../form/UserForm';
import AdminView from './AdminView';

export interface IAdminAccountProps {
}

export interface IAdminAccountState {
  email: string
  roleId: number
  nbUsers: number
}

export default class AdminAccount extends React.Component<IAdminAccountProps, IAdminAccountState> {
  constructor(props: IAdminAccountProps) {
    super(props);

    this.state = {
      email: "",
      roleId: appBloc.state.roles[1].id,
      nbUsers: null
    }
  }

  componentDidMount() {
    this.getNbUser();
  }

  handleChange = (e) => {
    let email = e.target.value;
    this.setState({email})
  }

  getNbUser = async () => {
    let nbUsers = await UserService.getNbUsers();
    this.setState({nbUsers})
  }

  submit = async () => {
    const {email, roleId} = this.state;
    if (email === "") return;
    await UserService.sendNewUserMail({email, roleId});
    ToastrService.toaster.show("Urioz", "Email envoyé avec succès !");
  }

  handleChangeRole = (e) => {
    this.setState({roleId: appBloc.state.roles.find(r => r.id.toString() === e.target.value.toString())?.id})
  }

  hasReachMax() {
    return this.getMaxNbUser() === this.state.nbUsers;
  }

  getMaxNbUser() {
    return appBloc.state.application?.licence?.nbUsers;
  }

  public render() {
    const { nbUsers } = this.state;
    if (!nbUsers) return <></>;
    return (
      <AdminView title="Gestion des comptes utilisateurs">
        <div className="mb-3 text-small">
          <div>Nombre d'utilisateur</div>
          <div className="row-flex">
            <div className="mr-2">Actuel</div>
            <div className="mr-5 text-primary fw-bold">{nbUsers}</div>
            <div className="mr-2">Max</div>
            <div className="text-primary fw-bold">{this.getMaxNbUser()}</div>
          </div>
        </div>
        <div className="fw-600 darkgray mb-3">
          Creer un nouvel utilisateur
        </div>
        { this.hasReachMax() ? <div className="text-danger">Le nombre d'utilisateur maximum est atteint</div> : <UserForm onSubmitted={ () => this.setState({nbUsers: nbUsers + 1})}/>}
        
        {/* <small className="gray">L'utilisateur recevra un mail afin de choisir un mot de passe et de renseigner ses informations personnelles</small> */}
        {/* <InputRow>
          <SmartInputText disable={this.hasReachMax()} onChange={this.handleChange} value={email} type="email" label={"Email"} floating />
        </InputRow>
        <InputRow>
          <SmartInputText label="Rôle" disable={this.hasReachMax()} type="select" onChange={this.handleChangeRole} value={roleId.toString()} floating>
            { appBloc.state.roles.map((et) => <option key={et.id} value={et.id}>{et.name}</option> )}
          </SmartInputText>
        </InputRow> */}
          {/* <Button disabled={this.hasReachMax()} color="primary" onClick={() => this.submit()}>Envoyer l'email</Button> */}
      </AdminView>
    );
  }
}
