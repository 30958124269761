import * as React from 'react';
import Loading from './Loading';

export interface IBuildingViewProps {
}

export interface IBuildingViewState {
}

export default class BuildingView extends React.Component<IBuildingViewProps, IBuildingViewState> {
  constructor(props: IBuildingViewProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className="col-center">
        <h2 className="fw-bold darkgray">Création de votre intranet</h2>
        <h6>En cours ...</h6>
        <Loading/>
      </div>
    );
  }
}
