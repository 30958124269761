import * as React from 'react';
import { consumeProjectBloc, IProjectBlocState, projectBloc } from '../../bloc/ProjectBloc';
import TabItem from '../../core/TabItem';
import TicketColumns from './TicketColumns';
import classNames from 'classnames';

export interface IProjectViewProps {
  projectId: any
  scope: string
}

export interface IProjectViewState {
}

class ProjectView extends React.Component<IProjectBlocState & IProjectViewProps, IProjectViewState> {

  public getTabs(projectId): TabItem[] {
    return [
      new TabItem({label: "Tous les tickets",  path: `/projects/${projectId}/all`, component: TicketColumns}),
      new TabItem({label: "Mes tickets",       path: `/projects/${projectId}/mine`, component: TicketColumns}),
      new TabItem({label: "Tickets rapportés", path: `/projects/${projectId}/reported`, component: TicketColumns}),
      new TabItem({label: "Tickets en retard", path: `/projects/${projectId}/late`, component: TicketColumns}),
    ];
  }

  public ref = React.createRef<HTMLDivElement>()

  constructor(props: IProjectBlocState & IProjectViewProps) {
    super(props);

    this.state = {
    }
  }

  load() {
    const { projectId, scope } = this.props;
    projectBloc.filter = scope
    projectBloc.projectId = projectId
    projectBloc.loadProject();
  }

  onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (this.ref.current.scrollTop === 0) this.ref.current.className = "project-view";
    if (this.ref.current.scrollTop > 0)   this.ref.current.className += " is-scrolled";
  }

  componentDidMount() {
    this.load()
  }

  shouldComponentUpdate(prevProps) {
    return prevProps.projectId !== this.props.projectId
  }

  componentDidUpdate() {
    this.load()
  }

  public render() {
    return (
      <div ref={this.ref} onScroll={(e) => this.onScroll(e)} className={classNames({"project-view": true})}>
        <TicketColumns/>
      </div>
    );
  }
}

export default consumeProjectBloc(ProjectView)
