import * as React from 'react';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import { Button } from 'reactstrap';
import EntityManager from '../../managers/EntityManager';
import Attachment from '../../models/Attachment';
import FileModel from '../../models/File';
import StaticMessage from '../../models/StaticMessage';
import User from '../../models/User';
import DOMService from '../../services/DOMService';
import SessionService from '../../services/SessionService';
import StorageService from '../../services/StorageService';
import ToastrService from '../../services/ToastrService';
import UserService from '../../services/UserService';
import TextIcon from '../common/TextIcon';
import SmartInputTextarea from '../input/SmartInputTextarea';
import AttachmentRow from '../messaging/AttachmentRow';
import CustomModalHeader from './customModalHeader';

export interface INewMessageModalProps {
  replyTo?: StaticMessage
  to?: User[],
  files?: FileModel[]
}

export interface INewMessageModalState {
  isSending: boolean
  to: any[]
  users: User[]
  attachments: Attachment[]
}

export default class NewMessageModal extends React.Component<INewMessageModalProps, INewMessageModalState> {

  public message: StaticMessage;
  public isReply: boolean;

  constructor(props: INewMessageModalProps) {
    super(props);

    this.initModel();

    this.state = {
      isSending: false,
      to: props?.to?.map(u => this.toSelectObject(u)) || [],
      users: [],
      attachments: props.files?.map(f => f.attachment) || []
    }
  }

  async componentDidMount() {
    if (!this.isReply) {
      let users = await UserService.getUsers();
      this.setState({users})
    }
  }

  initModel = () => {
    this.message = new StaticMessage({
      sendBy: SessionService.getUserId(),
    });
    this.isReply = !!this.props.replyTo;
    if (this.isReply) {
      this.message.parentId = this.props.replyTo.id;
      this.setState({to: [this.toSelectObject(this.props.replyTo.sender)]});
    }
  }

  isValid = () => {
    return this.message.content.length > 0 && this.state.to.length > 0;
  }

  onSend = async () => {
    if (!this.isValid()) return;
    this.setState({isSending: true});
    let message = await this.message.create({merge: {toIds: this.state.to.map((u) => u.value.id)}});
    if (this.state.attachments.length > 0) {
      await this.uploadAttachments(message);
      const {attachments} = this.state;
      await EntityManager.createMany(Attachment, attachments);
    }
    this.setState({isSending: false});
    ToastrService.toaster.show("Urioz", "Le message a bien été envoyé.")
    DOMService.close();
  }

  uploadAttachments = async (message: StaticMessage) => {
    let uploads: Promise<string>[] = [];
    this.state.attachments.forEach((a) => {
      a.attachable(message);
      if (!a.isStored) {
        let promise = StorageService.uploadFile("data", a.file);
        uploads.push(promise);
        promise.then((url) => {
          a.url = url;
        });
      }
    });
    await Promise.all(uploads);
  }

  getOptions = () => {
    return this.state.users.map((u) => this.toSelectObject(u))
  }

  toSelectObject(user: User) {
    return { label: user.getFullName(), value: user }
  }

  onDeleteAttachment = (attachment: Attachment) => {
    let attachments = this.state.attachments;
    attachments = attachments.filter(a => a.id !== attachment.id);
    this.setState({attachments});
  }

  handleTo = (to) => {
    this.setState({to})
  }

  fileToAttachment(file: File): Attachment {
    return new Attachment({}).attachable(this.message).fillWithFile(file);
  }

  onFileAdded = (files: File[]) => {
    this.setState({attachments: this.state.attachments.concat(files.map(f => this.fileToAttachment(f)))});
  }

  public render() {
    const { isSending, to, attachments } = this.state;
    if (!this.message) return <></>
    return (
      <div className="new-message-frame">
        <CustomModalHeader title={this.isReply ? `Réponse à ${this.props.replyTo.sender.getFullName()}` : "Nouveau message"} />
        { !this.isReply && <div className="new-message-to">
          <Select
            name="to"
            options={this.getOptions()}
            value={to}
            onChange={(e) => this.handleTo(e)}
            placeholder="Destinataires"
            place
            isMulti
          />
        </div>}
        { this.isReply && <div className="replied-message">
          <TextIcon leftIcon={["fal", "reply"]}>
            {this.props.replyTo.content}
          </TextIcon>
        </div>
        }
        <div className="new-message-content">
          <SmartInputTextarea rows={10} name="content" model={this.message} autoFocus />
        </div>
        <div className="attachments-area px-3 pt-2">
          { attachments.map((attachment) => <AttachmentRow key={attachment.name} attachment={attachment} onDelete={this.onDeleteAttachment} />) }
        </div>
        <div className="p-3 row-between">
          <div className="paperclip-btn">
            <Dropzone onDrop={this.onFileAdded}>
              {({getRootProps, getInputProps, isDragActive}) => (
                <div {...getRootProps()}>
                <input {...getInputProps()} />
                <TextIcon leftIcon={["fal", "paperclip"]}>Ajouter une pièce jointe</TextIcon>
                </div>
              )}
            </Dropzone>
          </div>
          <Button onClick={this.onSend} color="primary">
            { isSending ? "En cours" : "Envoyer"}
          </Button>
        </div>
      </div>
    );
  }
}
