import * as React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export interface IPaginationComponentProps {
  total: number
  currentPage: number
  onPageChange: (arg: number) => void
}

export interface IPaginationComponentState {
}

export default class PaginationComponent extends React.Component<IPaginationComponentProps, IPaginationComponentState> {
  constructor(props: IPaginationComponentProps) {
    super(props);

    this.state = {
    }
  }

  renderPage() {
    const {total, currentPage, onPageChange} = this.props;
    let els = [];
    for (let i = 1; i < total + 1; i++) {
      els.push(
        <PaginationItem key={i} active={currentPage === i}>
          <PaginationLink onClick={() => onPageChange(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return els;
  }

  public render() {
    const {total, currentPage, onPageChange} = this.props;
    return (
      <div>
        <Pagination>
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink onClick={() => onPageChange(1)} first href="#" />
          </PaginationItem>
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink onClick={() => onPageChange(currentPage - 1)} previous href="#" />
          </PaginationItem>
          {this.renderPage()}
          <PaginationItem>
            <PaginationLink onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === total} next href="#" />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink onClick={() => onPageChange(total)} disabled={currentPage === total} last href="#" />
          </PaginationItem>
        </Pagination>
      </div>
    );
  }
}
