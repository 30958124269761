import { IconName } from '@fortawesome/fontawesome-svg-core';
import FormElement from '../FormElement';
import { FormElementType } from '../FormElement';

export default class Select extends FormElement {

  public humanName = "Choix"
  public icon: IconName = "mouse-pointer"
  public typeKey = FormElementType.Select;
  public key = "Select"

  public isField = true;
  public hasOptions = true;
  public isSubmittable = true;
  public isChartable = true;

  setDefaultValue() {
    super.setDefaultValue();
    this.label = "Label"
  }

  constructor(json) {
    super(json)
    if (this.formSelectOptions.length > 0) this.value = this.formSelectOptions[0].label
  }
}
