import * as React from 'react';
import RoomComposition from '../../models/RoomComposition';
import Avatar, { AvatarSize } from '../common/Avatar';

export interface IReadAvatarsProps {
  roomCompositions: RoomComposition[]
}

export interface IReadAvatarsState {
}

export default class ReadAvatars extends React.Component<IReadAvatarsProps, IReadAvatarsState> {
  constructor(props: IReadAvatarsProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { roomCompositions } = this.props;
    return (
      <div className="mb-2 px-2 row-flex-end">
        {roomCompositions.map((rc) => <Avatar key={rc.id} className="ml-1" url={rc.user.avatar} size={AvatarSize.xs} />)}
      </div>
    );
  }
}
