import FullCalendar, { DatesSetArg } from '@fullcalendar/react';
import React from 'react';
import EventModal from '../components/modal/EventModal';
import Filter from '../core/filter';
import EntityManager from '../managers/EntityManager';
import Agenda from '../models/Agenda';
import Alert from '../models/Alert';
import Event from '../models/Event';
import Invitation from '../models/Invitation';
import DOMService from '../services/DOMService';
import OptionService from '../services/OptionService';
import SessionService from '../services/SessionService';
import ToastrService from '../services/ToastrService';
import { appBloc } from './AppBloc';

export interface IEventsBlocState {
  events: Event[]
  filters: Filter[]
  groupFilterIds: number[]
  activeAgendaIds: number[]
  agendas: Agenda[]
  invitations: Invitation[]
  sendedInvitations: Invitation[]
}

export const Context = React.createContext({})

export let eventsBloc: EventsBloc

export default class EventsBloc extends React.Component<any, IEventsBlocState> {

  public calendar = React.createRef<FullCalendar>();
  public modalStyle = { maxWidth: "900px" };

  public initialView: string;
  public initialDate: Date;
  public events: Event[]

  constructor(props: any) {
    super(props)
    eventsBloc = this

    this.state = {
      events: [],
      filters: [],
      groupFilterIds: [],
      activeAgendaIds: [],
      invitations: [],
      sendedInvitations: [],
      agendas: null,

    }

    this.initialView = localStorage.getItem("agendaView") || "dayGridMonth";
    // if (localStorage.getItem("agendaStart")) {
    //   this.initialDate = new Date(localStorage.getItem("agendaStart"));
    // }

  }

  async init() {
    await this.loadAgenda();
    await this.loadInvitations();
    if (appBloc.search?.show) this.showEventModal();
    this.setState({filters: this.createFilters()});
  }

  loadInvitations = async () => {
    let invitations = (await EntityManager.all<Invitation>(Invitation, {}, SessionService.user)).models;
    // let sendedInvitations = (await EntityManager.get<Invitation>(Invitation, "sended")).models;
    this.setState({invitations});
  }

  loadAgenda = async () => {
    let agendas = (await EntityManager.all<Agenda>(Agenda)).models;
    this.setState({agendas, activeAgendaIds: agendas.map(a => a.id)}, () => this.loadEvents());
  }

  getPrivateAgendas() {
    return this.state.agendas.filter(a => a.isPrivate);
  }

  onAgendaFilterChange = (id) => {
    let activeAgendaIds;
    if (this.state.activeAgendaIds.includes(id)) activeAgendaIds = this.state.activeAgendaIds.filter(i => i !== id);
    else activeAgendaIds = [...this.state.activeAgendaIds, id];
    this.setState({activeAgendaIds}, () => this.loadEvents());
  }

  getPrivateAgendaId() {
    return this.state.agendas.find(a => a.isPrivate)?.id;
  }

  onAllAgenda(checked: boolean) {
    if (checked) this.setState({activeAgendaIds: []}, () => this.loadEvents());
    else         this.setState({activeAgendaIds: this.state.agendas.map(a => a.id)}, () => this.loadEvents());
  }

  onDateChanges(e: DatesSetArg) {
    // localStorage.setItem("agendaStart", e.start.toISOString());
  }

  onViewChange = (e) => {
    localStorage.setItem("agendaView", e.view.type);
  }

  createFilters() {
    return OptionService.getOptionsByType("eventType").map(et => new Filter({isActive: true, eventType: et, onChange: this.handleFilterChange}));
  }

  getEventTypeColor = (eventTypeId: number) => {
    return OptionService.getOptionsByType("eventType").find(t => t.id === eventTypeId)
  }

  handleFilterChange = (checked, index) => {
    let filters = this.state.filters;
    filters[index].isActive = checked;
    let events = this.getFilteredEvents(filters);
    this.setState({filters, events})
  }

  getFilteredEvents(filters: Filter[]) {
    let ids = filters.filter(f => f.isActive).map(f => f.eventType.id);
    return this.events.filter((e) => ids.includes(e.typeId));
  }

  findEventById(id: number) {
    return this.state.events.find(e => e.id.toString() === id.toString());
  }

  showEventModal() {
    DOMService.modal(<EventModal event={this.findEventById(appBloc.search?.show)} />, this.modalStyle);
    appBloc.removeSearchParam("show");
  }

  refreshInvitations() {
    let invitations = this.state.invitations;
    this.setState({invitations: invitations.filter(i => i.status === "waiting")})
  }

  loadEvents = async () => {
    let events = (await EntityManager.all<Event>(Event, { agendaIds: this.state.activeAgendaIds })).models;
    this.events = events;
    this.setState({events})
  }

  updateEvent = async (event: Event) => {
    await event.update();
    ToastrService.toaster.show("Urioz", "L'évènement a bien été modifié.")
    this.loadEvents();
  }

  createEvent = async (event: Event) => {
    const newEvent = await event.create();
    if (event.alerts.length > 0) {
      event.alerts.forEach(a => a.eventId = newEvent.id)
      await EntityManager.createMany(Alert, event.alerts);
    }
    ToastrService.toaster.show("Urioz", "L'évènement a bien été créé.");
    DOMService.close();
    this.loadEvents();
    return newEvent;
  }

  getCalendar() {
    return this.calendar.current.getApi();
  }

  componentWillUnmount() {
    // localStorage.removeItem("agendaStart");
    localStorage.removeItem("agendaView");
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export function consumeEventsBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
