import * as React from 'react';
import { Table } from 'reactstrap';
import { appBloc } from '../../bloc/AppBloc';
import EntityManager from '../../managers/EntityManager';
import Form from '../../models/form/Form';
import Submission from '../../models/form/Submission';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import SubmissionValueDisplayer from './SubmissionValueDisplayer';

export interface ISubmissionListProps {
  form: Form
}

export interface ISubmissionListState {
  submissions: Submission[]
}

export default class SubmissionList extends React.Component<ISubmissionListProps, ISubmissionListState> {
  constructor(props: ISubmissionListProps) {
    super(props);

    this.state = {
      submissions: []
    }
  }

  async componentDidMount() {
    let submissions = (await EntityManager.all<Submission>(Submission, {}, this.props.form)).models;
    this.setState({submissions})
  }

  getChartData = () => {
    let obj = {};
    this.state.submissions.forEach(sub => {
      sub.submissionValues.filter((value) => value.isChartable).forEach((val) => {
        if (obj[val.formElement.label]) {
          if (obj[val.formElement.label][val.value] !== undefined) obj[val.formElement.label][val.value] ++;
          else obj[val.formElement.label][val.value] = 1
        } else {
          obj[val.formElement.label] = {}
        }
      });
    })
    return obj;
  }

  getPercent() {
  }

  getTotal = (data: object) => {
    let keys = Object.keys(data);
    let total = 0;
    keys.forEach(k => total += data[k])
    return total;
  }

  public render() {
    const { form } = this.props;
    const { submissions } = this.state;
    return (<>
      <Table>
        <thead>
          <tr>
            <th></th>
            {form.formElements.filter(e => e.visible).map(element => <th key={element.id}>{element.label}</th> )}
            <th>Statut</th>
          </tr>
        </thead>
        <tbody>
          {submissions.map(submission => {
            return <tr onClick={() => appBloc.push("/submissions/" + submission.id)} key={submission.id}>
              <td><AvatarLetter user={submission.user} size={AvatarSize.sm} /></td>
              { submission.submissionValues.map(value => (
                <td key={ value.id }>
                  <SubmissionValueDisplayer submissionValue={ value } />
                </td>
              ))}
              <td>
                <div style={{color: submission.status.color}}>{submission.status.name}</div>
              </td>
            </tr>
          })}
        </tbody>
      </Table>

      <div>
        Statistiques
      </div>
      <div>
      {/* { this.obj} */}
      </div>
      </>
    );
  }
}
