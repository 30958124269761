import * as React from 'react';
import New from '../../models/New';
import IconButton from '../buttons/IconButton';
import EditableText from './EditableText';

export interface INewRowProps {
  neww: New
  onDelete?: () => void
}

export interface INewRowState {
}

export default class NewRow extends React.Component<INewRowProps, INewRowState> {

  constructor(props: INewRowProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { neww } = this.props;
    return (
      <div className="row-between mb-2">
        <EditableText model={neww} name="content" light />
        <div>
          <IconButton className="text-danger" iconData={["fal", "times"]} onClick={() => this.props.onDelete()}/>
        </div>
      </div>
    );
  }
}
