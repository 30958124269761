import { AxiosResponse } from "axios";
import { EventEmitter } from "events";
import humps from 'lodash-humps';
import Model from '../models/Model';
import apiService from "../services/ApiService";
import ToastrService from "../services/ToastrService";

export default class EntityManager<T extends Model> {

  protected modelUrl: string;
  protected parentModel: Model;
  protected modelClass = null;
  static emitter: EventEmitter = new EventEmitter();

  constructor(modelClass?: any, parentModel?: any) {
    if (modelClass) {
      this.modelUrl = modelClass.modelUrl;
      this.modelClass = modelClass;
    }
    if (parentModel) {
      const parentUrl = parentModel.constructor?.modelUrl;
      this.parentModel = parentModel;
      this.modelUrl = `${parentUrl}/${parentModel.id}/${this.modelClass.modelUrl}`;
    }
  }

  static all<T extends Model>(modelClass: any, params = {}, parentModel?: Model) {
    const em = new EntityManager<T>(modelClass, parentModel);
    return em.index(params);
  }

  static show<T extends Model>(modelClass: any, id, params = {}) {
    const em = new EntityManager<T>(modelClass);
    return em.show(id, params);
  }

  static createMany<T extends Model>(modelClass, data: any[], parentModel?: Model) {
    const em = new EntityManager<T>(modelClass, parentModel);
    return em.create({models: data});
  }

  static updateMany<T extends Model>(modelClass, data: any[], parentModel?: Model) {
    const em = new EntityManager<T>(modelClass, parentModel);
    return em.update({models: data});
  }

  static get<T extends Model>(modelClass: any, path: string, params = {}, direct = false) {
    const em = new EntityManager<T>(modelClass);
    return em.get(path, params, direct);
  }

  static post<T extends Model>(modelClass: any, path: string, params = {}) {
    const em = new EntityManager<T>(modelClass);
    return em.post(path, params);
  }

  protected getEntity(json: any): T {
    return new this.modelClass(json);
  };

  static toCamelCase(json) {
    return humps(json);
  }

  public toModel(json) {
    return this.getEntity(EntityManager.toCamelCase(json));
  }

  public toModelOrFail(response: AxiosResponse, event: string = null): T {
    if (response.status === 200) {
      if (!response.data) return null;
      let model = this.toModel(response.data);
      // if (event) EntityManager.emitter.emit(event, model);
      return model
    } else {
      this.displayError(response);
      return null;
    }
  }

  protected buildIndexResponse(response: AxiosResponse) {
    return {
      models: response.data.models.map(m => this.toModel(m)),
      count: response.data.count || response.data.models.length
    }
  }

  protected buildPaginateResponse(response: AxiosResponse) {
    return {
      models: response.data.models.data.map(m => this.toModel(m)),
      currentPage: response.data.models.current_page,
      from: response.data.models.from,
      lastPage: response.data.models.last_page,
      total: response.data.models.total,
    }
  }

  public async index(param: Object = {}): Promise<IndexResponse<T>> {
    let response: AxiosResponse = await apiService.get(`${this.getPath()}`, param);

    if (response.status === 200) {
      return this.buildIndexResponse(response);
    }
    else this.displayError(response);
    return {};
  }

  public async indexWithPagination(param: Object = {}): Promise<PaginateResponse<T>> {
    let response: AxiosResponse = await apiService.get(this.getPath(), {...param, paginate: true});
    if (response.status === 200) {
      return this.buildPaginateResponse(response);
    }
    else this.displayError(response);
    return {};
  }

  public async show(id: number | string, param: Object = {}): Promise<T> {
    let response: AxiosResponse = await apiService.get(`${this.getPath()}/${id}`, param);
    return this.toModelOrFail(response);
  }

  public async create(param: Object): Promise<T> {
    let response: AxiosResponse = await apiService.post(`${this.getPath()}`, param)
    return this.toModelOrFail(response, "create");
  }

  public async createWithFiles(param: Object, file?: File): Promise<T> {
    const formData = new FormData();
    Object.keys(param).forEach((key) => {
      formData.append(key, param[key]);
    })
    formData.append('file', file)
    let response: AxiosResponse = await apiService.post(`${this.getPath()}`, formData, {multipart: true})
    return this.toModelOrFail(response, "create");
  }

  public async update(param: any): Promise<T> {
    let response: AxiosResponse = await apiService.put(`${this.getPath()}/${param.id || 'all'}`, param)
    return this.toModelOrFail(response, "update");
  }

  public async delete(id): Promise<any> {
    let response: AxiosResponse = await apiService.delete(`${this.getPath()}/${id}`)
    return this.toModelOrFail(response, "delete");
  }

  public async get(path, param: Object = {}, direct = false): Promise<T | IndexResponse<T> | any> {
    let response: AxiosResponse = await apiService.get(`${this.getPath()}/${path}`, param)
    if (response.status === 200) {
      if (direct) return response.data;
      if (Array.isArray(response.data.models)) return this.buildIndexResponse(response);
      else                                     return this.toModel(response.data);
    } else {
      this.displayError(response);
    }
    return null;
  }

  public async post(path, param: Object = {}, direct = false): Promise<T | IndexResponse<T>> {
    let response: AxiosResponse = await apiService.post(`${this.getPath()}/${path}`, param)
    if (response.status === 200) {
      if (direct) return response.data;
      if (Array.isArray(response.data.models)) return this.buildIndexResponse(response);
      else                                     return this.toModel(response.data);
    } else {
      this.displayError(response);
    }
    return null;
  }

  getPath() {
    return this.modelUrl;
  }

  protected displayError(response: AxiosResponse) {
    // if (response.data.message === "Token has expired") AuthService.get().logout();
    // EntityManager.emitter.emit("error", new EntityManagerEvent<T>({
    //   status: response.status,
    //   type: "error",
    //   modelName: ""
    // }))
    if (response.status === 500) ToastrService.toaster.show("Urioz", "Une erreur est survenue", "danger");
  }

}

export interface IndexResponse<T> {
  models?: T[],
  count?: number
}

export interface PaginateResponse<T> {
  models?: T[]
  currentPage?: number
  from?: number
  lastPage?: number
  total?: number
}

export class EntityManagerEvent<T> {

  public status: number;
  public isOk: boolean;
  public type: string;
  public modelName: string;
  public data: T | T[]

  constructor(data) {
    this.status    = data.status;
    this.isOk      = data.status === 200;
    this.type      = data.type;
    this.modelName = data.modelName;
    this.data      = data.data;
  }

}
