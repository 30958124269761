import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdminAccount from '../components/admin/AdminAccount';
import AdminApp from '../components/admin/AdminApp';
import AdminEvent from '../components/admin/AdminEvent';
import AdminNews from '../components/admin/AdminNews';
import AdminRole from '../components/admin/AdminRole';
import AdminTab from '../components/admin/AdminTab';
import Page from '../components/layout/Page';
import AccountView from '../components/settings/AccountView';
import ThemeView from '../components/settings/ThemeView';
import TabItem from '../core/TabItem';
import RightService from '../services/RightService';
import BasePage from './BasePage';

export interface ISettingPageProps {
}

export interface ISettingPageState {
}

export default class SettingPage extends BasePage<ISettingPageProps, ISettingPageState> {

  public title = "Paramètres";
  public accountTabs: TabItem[] = [
    new TabItem({label: "Profil", path: "/settings/user", component: AccountView}),
    new TabItem({label: "Thème", path: "/settings/theme", component: ThemeView})
  ]
  public adminTabs: TabItem[] = [
    new TabItem({label: "Personnalisation", path: "/settings/app", right: "users", component: AdminApp}),
    new TabItem({label: "Comptes", path: "/settings/accounts", right: "users", component: AdminAccount}),
    new TabItem({label: "Roles", path: "/settings/roles", right: "users", component: AdminRole}),
    new TabItem({label: "Gestion de l'accueil", path: "/settings/news", right: "news", component: AdminNews}),
    new TabItem({label: "Evènements", path: "/settings/event", right: "eventType", component: AdminEvent}),
    // new TabItem({label: "Status des demandes", path: "/settings/submission", right: "forms", component: AdminSubmission})
  ]

  constructor(props: ISettingPageProps) {
    super(props);

    this.setTitle();
    this.state = {
    }
  }

  public render() {
    return (
      <Page>
        <div className="d-flex">
          <div className="admin-tabs">
            <h3 className="">Paramètres</h3>
            <div className="section-title p-2">Compte</div>
              { this.accountTabs.map(t => RightService.can(t.right) && <AdminTab key={t.label} label={t.label} path={t.path} icon={t.icon}/>)}
              { (RightService.can("users") || RightService.can("news") || RightService.can("eventType") || RightService.can("forms")) && <div className="section-title p-2 mt-2">Administration</div> }
              { this.adminTabs.map(t => RightService.can(t.right) && <AdminTab key={t.label} label={t.label} path={t.path} icon={t.icon}/>)}
          </div>
          <div className="w-100 px-4 py-2">
            <Switch>
              { this.adminTabs.concat(this.accountTabs).map(t => RightService.can(t.right) && <Route key={t.label} exact path={t.path} component={t.component}/>)}
            </Switch>
          </div>
        </div>
      </Page>
    );
  }
}
