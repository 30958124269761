import * as React from 'react';
import DropboxService from '../../services/DropboxService';

export interface IDropboxLoginViewProps {
}

export interface IDropboxLoginViewState {
}

export default class DropboxLoginView extends React.Component<IDropboxLoginViewProps, IDropboxLoginViewState> {
  constructor(props: IDropboxLoginViewProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className="text-center">
        <div className="section-title mb-3">
          Connectez vous à Dropbox pour afficher vos fichier ici
        </div>
        <div>
          <a className="btn btn-primary" id="authlink" href={DropboxService.authentificationUrl}>Se connecter</a>
        </div>
        {/* <div>
          Comment faire ?
        </div>
        <div>
          <a href="https://www.dropbox.com/developers/apps">Aller sur cette page</a>
          <div>
            Créez votre application
          </div>
          <img src="" alt=""/>
        </div>
        <div>Copiez le App Key ici</div>
        <input type="text"/>
        <div>
          Dans l'onglet Settings dans la section OAuth2 : Redirect URI
        </div>
        <div>
          Ajouter <span>https://app.urioz.com/drive/dropbox/auth</span>
        </div>
        <div>
          Clickez sur Generated access token et copier collez le ici
        </div>
        <input type="text"/> */}
      </div>
    );
  }
}
