import SessionService from "../services/SessionService";
import Attachment from './Attachment';
import Group from './Group';
import Model from "./Model";
import Right from "./Right";
import User from './User';

export default class File extends Model {

  public name: string;
  public type: string;
  public size: number;
  public parentId: number;
  public parent: File;
  public isPublic: boolean;
  public visible: boolean;
  public files: File[];
  public file: File;
  public rights: Right[];
  public creator: User;
  public groupIds: number[];
  public groups: Group[];
  public users: User[];
  public userIds: number[];
  public createdBy: number;
  public attachment: Attachment;
  public attachable: Attachment;

  static modelUrl = "files";
  public modelName = "file";

  constructor(json: any) {
    super(json)
    this.name       = json.name;
    this.type       = json.type;
    this.size       = json.size;
    this.isPublic   = json.isPublic || 0;
    this.visible    = json.visible || 1;
    this.groupIds   = json.groupIds;
    this.attachment = json.attachment && new Attachment(json.attachment);
    this.userIds    = json.userIds;
    this.parentId   = json.parentId;
    this.parent     = json.parent && new File(json.parent);
    this.files      = this.mapArrayModel(File, json.files);
    this.rights     = this.mapArrayModel(Right, json.rights);
    this.creator    = json.creator && new User(json.creator);
    this.createdBy  = json.createdBy;
    this.groups     = this.mapArrayModel(Group, json.groups);
    this.users = this.mapArrayModel(User, json.users);
  }

  getPath(): File[] {
    let path = []
    return [...this.buildPath(this, path), this];
  }

  buildPath(file, array) {
    if (file.parent) {
      array.unshift(file.parent);
      return this.buildPath(file.parent, array);
    }
    return array;
  }

  fillFromFile(file) {
    this.name = File.getNameWithoutExt(file)
    this.attachment = new Attachment({refName: file.name, ext: this.getExt(file), name: file.name})
    this.type = file.type
    this.size = file.size
    this.file = file;
    this.createdBy = SessionService.getUserId()
    return this;
  }

  getExt(file: File) {
    let split = file.name.split(".");
    return split[split.length - 1]
  }

  getFullname() {
    return `${this.name}.${this.attachment.ext}`
  }

  static getNameWithoutExt(file) {
    let index = file.name.lastIndexOf('.');
    if (index === -1) return file.name;
    return file.name.substr(0, index)
  }

  isFolder() {
    return !this.type
  }

  isImage() {
    return this.type?.split("/")[0] === "image";
  }

  isPdf() {
    return this.type === "application/pdf";
  }

  static sizeText(size) {
    if (!size) return ""
    if (size < 1000) return `${size} o`;
    if (size < 1000000) return `${(size / 1000).toFixed(2)} Ko`;
    if (size < 1000000000) return `${(size / 1000000).toFixed(2)} Mo`;
    if (size > 1000000000) return `${(size / 1000000).toFixed(2)} Go`;
    return "";
  }

  getSizeText() {
    return File.sizeText(this.size)
  }

  toMapApi() {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      parentId: this.parentId,
      createdBy: this.createdBy,
      isPublic: this.isPublic,
      visible: this.visible,
      userIds: this.userIds,
      groupIds: this.groupIds,
      attachment: this.attachment?.toMapApi()
    }
  }

}
