import Model from '../Model';
import MorphMixin from './MorphMixin';
import Attachment from '../Attachment';
import StorageService from '../../services/StorageService';

export default class Attachable extends MorphMixin<Attachment> {

  public morphName = "attachable";

  constructor(model: Model, json) {
    super(model);
    this.models = this.parentModel.mapArrayModel(Comment, json.attachments)
  }

  async saveAll() {
    this.models.forEach((a) => {
      StorageService.uploadFile("forms", a.file).then((url) => {
        a.url = url
        this.create(a);
      })
    });
  }

}