import * as React from 'react';
import { Form as FormHTML } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import FormManager from '../../managers/FormManager';
import Form from '../../models/form/Form';
import Submission from '../../models/form/Submission';
import ToastrService from '../../services/ToastrService';
import FormElementComponent from './FormElementComponent';

export interface IFormShowProps {
  formId?: string | number
  form?: Form
  onFormSubmitted?: () => void
  withTitle?: boolean
}

export interface IFormShowState {
  form: Form
}

export default class FormShow extends React.Component<IFormShowProps, IFormShowState> {

  public submission: Submission;

  constructor(props: IFormShowProps) {
    super(props);

    this.submission = new Submission({formId: props.formId || props.form.id}).fromMe();

    this.state = {
      form: null
    }
  }

  async componentDidMount() {
    let form: Form;
    if (!this.props.form) {
      form = await new FormManager().show(this.props.formId);
    } else {
      form = this.props.form
    }
    this.setState({form})
  }

  onSubmit = async (e) => {
    const { form } = this.state;
    e.preventDefault();
    this.submission.initSubmissionValues(form);
    this.submission.statusId = 6;
    await this.submission.create();
    this.props.onFormSubmitted && this.props.onFormSubmitted();
    ToastrService.toaster.show("Urioz", "Le formulaire a été envoyé avec succès.")

  }

  public render() {
    const { form } = this.state;
    if (!form) return <></>
    return (<>
      <FormHTML onSubmit={this.onSubmit}>
        { this.props.withTitle && <div className="form-header mb-3">
          <h4 className="text-center capitalize fw-bold darkgray">{ form.title }</h4>
          <div>{ form.subTitle }</div>
        </div>}
        <div className="form-body">
          { form.formElements.map(element => <FormElementComponent key={element.id} element={element} />)}
        </div>
        <div className="form-footer">
          <Button size="lg" color="primary">Valider</Button>
        </div>
      </FormHTML>
      </>
    );
  }
}
