import * as React from 'react';
import { consumeRoomBloc, IRoomBlocState } from '../../bloc/RoomBloc';
import RoomBody from './RoomBody';
import RoomBottomBar from './RoomBottomBar';
import RoomHeader from './RoomHeader';


class RoomsView extends React.Component<IRoomBlocState> {

  public render() {
    return (
      <div className="room-container">
        <RoomHeader/>
        <RoomBody/>
        <RoomBottomBar/>
      </div>
    );
  }
}
export default consumeRoomBloc(RoomsView);
