import EventType from '../models/Option';
export default class Filter {

  public isActive: boolean
  public eventType: EventType;
  public onChange: any

  constructor({isActive, eventType, onChange}) {
    this.isActive = isActive;
    this.eventType = eventType;
    this.onChange = onChange;
  }
}
