import * as React from 'react';
import ReactDOM from 'react-dom';
import { consumeDriveBloc, driveBloc, IDriveBlocState } from '../../bloc/DriveBloc';
import IconButton from '../buttons/IconButton';
import DriveGrid from './DriveGrid';
import DriveTable from './DriveTable';
import DriveTopBar from './DriveTopBar';
import FileCard from './FileCard';
import FileRow from './FileRow';
import UploadFrame from './UploadFrame';
import File from '../../models/File';
import DriveBreadCrumb from './DriveBreadCrumb';

export interface IDocumentsProps {
  folderId: string
  rootFolder?: boolean
}

export interface IDocumentsState {
}

class Documents extends React.Component<IDriveBlocState & IDocumentsProps, IDocumentsState> {

  isTableMode() {
    return this.props.tableMode && window.innerWidth > 800;
  }

  renderFile() {
    return this.isTableMode() ?
      <DriveTable renderItem={ (f => this.renderRowItem(f)) } /> :
      <DriveGrid  renderItem={ (f => this.renderCardItem(f)) } />
  }

  renderRowItem(f: File) {
    return <FileRow key={ f.id } file={ f } />
  }

  renderCardItem(f: File) {
    return <FileCard key={ f.id } file={ f } />
  }

  public render() {
    const { isUploading, tableMode, searchMode, rootFolder } = this.props;
    return (
      <div className="drive">
        <div className="mb-3 px-3">
          <DriveTopBar/>
        </div>
        <div className="drive-secondary-bar">
        { searchMode ? <div>Résultat de votre recherche</div> : <DriveBreadCrumb rootFolder={rootFolder} /> }
          <div className="px-3 d-none d-md-block">
            <IconButton onClick={() => driveBloc.toggleView()} iconData={["fal", tableMode ? "th" : "list"]} />
          </div>
        </div>
        { isUploading && ReactDOM.createPortal(<UploadFrame/>, document.getElementById("pop")) }
        { this.renderFile() }
      </div>
    );
  }
}

export default consumeDriveBloc(Documents)
