import { UploadTaskSnapshot } from '@firebase/storage-types';
import React from 'react';
import FileShareModal from '../components/modal/FileShareModal';
import EntityManager from '../managers/EntityManager';
import FileModel from '../models/File';
import DocumentsService from '../services/DocumentsService';
import DOMService from '../services/DOMService';
import StorageService from '../services/StorageService';
import ToastrService from '../services/ToastrService';
import { appBloc } from './AppBloc';

export interface IDriveBlocProps {
}
export interface IDriveBlocState {
  searchedFiles: FileModel[]
  currentFile: FileModel
  selectedFile: FileModel
  searchMode: boolean
  isUploading: boolean
  isCreatingFolder: boolean
  searchInput: string
  uploadPercent: number
  tableMode: boolean
  uploadingFile: FileModel
  usedStorage: number
}

export const Context = React.createContext({})

export let driveBloc: DriveBloc

class DriveBloc extends React.Component<IDriveBlocProps, IDriveBlocState> {

  public folderInputRef = React.createRef<HTMLInputElement>();
  public documentsService: DocumentsService;

  constructor(props: IDriveBlocProps) {
    super(props)
    driveBloc = this

    this.state = {
      searchedFiles: [],
      searchMode: false,
      currentFile: null,
      selectedFile: null,
      isUploading: false,
      isCreatingFolder: false,
      searchInput: "",
      uploadPercent: 0,
      uploadingFile: null,
      tableMode: !appBloc.search?.grid,
      usedStorage: 0,
    }

    appBloc.onClickBody(this.onUnselectItem);
  }

  /////// LOAD DATA ////////

  async init(folderId?: string) {
    const currentFile = await this.documentsService.load(folderId)
    this.setState({currentFile});
  }

  getUsedStorage = async () => {
    let usedStorage = await EntityManager.get<FileModel>(FileModel, "usedStorage", {}, true)
    this.setState({usedStorage});
  }

  getMatchedFile = async (input: string) => {
    let searchedFiles = (await EntityManager.all<FileModel>(FileModel, {search: input})).models;
    this.setState({searchedFiles});
  }

  reload = async () => {
    this.init(this.state.currentFile?.id.toString());
  }

  /////// HANDLE ///////

  handleSearch = (searchInput: string) => {
    if (searchInput !== "") {
      this.getMatchedFile(searchInput);
      this.setState({searchMode: true});
    }
    else this.setState({searchMode: false})
  }

  handleChange = (searchInput: string) => {
    this.setState({searchInput});
  }

  clearSearch = () => {
    this.setState({searchInput: "", searchMode: false})
  }

  toggleView = () => {
    appBloc.push({search: this.state.tableMode ? "?grid=true" : ""});
    this.setState({tableMode: !this.state.tableMode});
  }

  onSelectItem = (file: FileModel) => {
    // this.setState({selectedFile: file})
  }

  onUnselectItem = () => {
    this.setState({selectedFile: null});
  }

  ////// FOLDERCREATE ///////

  onNewFolder = () => {
    this.setState({isCreatingFolder: true}, () => appBloc.onClickBody(this.closeNewFolder))
  }

  createFolder = async (name: string) => {
    await this.documentsService.createFolder(this.state.currentFile, name)
    this.reload();
    this.setState({isCreatingFolder: false})
  }

  closeNewFolder = (e) => {
    if (this.folderInputRef.current !== e.target) {
      this.setState({isCreatingFolder: false});
      appBloc.cancelClickBody(this.closeNewFolder);
    }
  }

  //////// UPLOAD ///////

  progressIndicator = (uploadTask: UploadTaskSnapshot) => {
    let uploadPercent = Math.floor(uploadTask.bytesTransferred / uploadTask.totalBytes * 100);
    this.setState({uploadPercent})
  }

  onUpload = async (file: File, fileModel?: FileModel) => {
    await this.documentsService.upload(file, this.state.currentFile, this.progressIndicator)
    this.setState({isUploading: true, uploadingFile: fileModel});
    this.reload();
    setTimeout(() => this.setState({isUploading: false}), 1500)
  }

  cancelUpload = () => {
    StorageService.cancelUpload();
    this.setState({isUploading: false, uploadingFile: null, uploadPercent: 0})
  }

  //////// UPDATE /////

  renameFile = async (file: FileModel, name: string) => {
    file.name = name;
    await file.update({only: ["name"]});
  }

  moveInCurrentFile = async (folder: FileModel, file: FileModel) => {
    file.parentId = folder?.id || -1;
    await file.update({only: ["parentId"] });
    ToastrService.toaster.show("Urioz", "Le fichier a bien été déplacé.");
    this.reload();
  }

  async onDownload(fileModel?: FileModel) {
    this.documentsService.download(fileModel);
  }

  onDeleteItem = async (file: FileModel) => {
    await file.delete();
    this.reload();
  }

  updateRights = async (file: FileModel) => {
    await file.update();
    ToastrService.toaster.show("Urioz", "Les droits d'accès à ce fichier ont bien été modifiés.");
    DOMService.close();
    this.reload();
  }

  onShareItem = (file: FileModel) => {
    DOMService.modal(<FileShareModal onSubmit={(groups) => this.updateRights(file)} file={file} />)
  }

  hideUploadFrame = () => {
    this.setState({isUploading: false})
  }

  navigateInFolder = (file: FileModel) => {
    appBloc.push(this.documentsService.getFileUrl(file));
  }

  returnFolder() {
    appBloc.push(this.documentsService.getParentFileUrl(this.state.currentFile));
  }

  componentWillUnmount() {
    appBloc.cancelClickBody(this.onUnselectItem);
  }

  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default DriveBloc;

export function consumeDriveBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
