import * as React from 'react';
import User from '../../models/User';
import UserService from '../../services/UserService';
import AvatarName from '../common/AvatarName';
import SelectMultiple from './SelectMultiple';

export default class MembersSelectMultiple extends SelectMultiple<User> {

  getData = async (search) => {
    return (await UserService.loadUsers(search)).models;
  }

  async componentDidMount() {
    if (!this.props.defaultValue) {
      let relations = await UserService.loadFriends();
      this.models = this.models.concat(relations);
      this.setState({filteredModels: this.models})
    }
  }

  public render() {
    const { filteredModels } = this.state;
    const listStyle = this.getListStyle();
    return (
      <div className="friends-list">
        {this.renderSearchInput()}
        { filteredModels && <div className="py-2 mt-2" style={listStyle}>
          { filteredModels.length === 0 ? <div className="p-4 d-flex">
            <div className="m-auto darkgray text-center">
              <div> Aucun résultat ne corresponds à votre recherche.</div>
            </div>
          </div> : filteredModels.map((user: User)  => (
            <div key={user.id} className="row-between p-2">
              <AvatarName user={user} />
              { this.isSelected(user) ?
                <div className="link text-danger" onClick={() => this.removeUser(user)}>Retirer</div> :
                <div className="link text-primary" onClick={() => this.addUser(user)}>Ajouter</div>
              }
            </div>
          ))}
        </div>}
      </div>
    );
  }
}
