import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Closable from '../common/Closable';
import Model from '../../models/Model';

export interface ISelectDesignProps<T> {
  options: T[];
  onChange?: (selected: T) => void;
  field: string
  title?: string;
  icon?: JSX.Element
  value: T
  render?: (model: T, selected: boolean) => JSX.Element
}

export interface ISelectDesignState<T> {
  isOpen: boolean;
}

export default class SelectDesign<T extends Model> extends React.Component<ISelectDesignProps<T>, ISelectDesignState<T>> {

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  public onChange = (option) => {
    this.setState({isOpen: false});
    this.props.onChange(option);
  }

  public render() {
    const { isOpen } = this.state;
    const { options, title, icon, value, field, render } = this.props;
    return (
      <div className="select-design">
        <div onClick={() => this.setState({isOpen: !isOpen})} className="select-button">
          <div className="row-flex pl-2">
            { icon && <div className="select-icon">
              {icon}
            </div>}
            <div>
              { title && <div className="select-title">{title}</div>}
              <div className="selected-value">{value[field]}</div>
            </div>
          </div>
          <div className="caret">
            <FontAwesomeIcon icon={["fas", "caret-down"]}/>
          </div>
        </div>
        <Closable show={isOpen} close={() => this.setState({isOpen: false})} >
          <div className="select-options">
            { options.map((o) => <div key={o.id} onClick={() => this.onChange(o)}>{ render(o, o.id === value.id) }</div> )}
          </div>
        </Closable>
      </div>
    );
  }
}
