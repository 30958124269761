import * as React from 'react';
import { driveBloc } from '../../bloc/DriveBloc';
import Documents from './Documents';
import LocalDocumentsService from '../../services/LocalDocumentsService';
import FileRow from './FileRow';
import File from '../../models/File';
import FileCard from './FileCard';

export interface ILocalDocumentsProps {
}

export interface ILocalDocumentsState {
}

export default class LocalDocuments extends Documents {

  componentDidMount() {
    driveBloc.documentsService = new LocalDocumentsService();
    driveBloc.init(this.props?.folderId || "1");
  }

  renderRowItem(f: File) {
    return <FileRow key={ f.id } file={ f } />
  }

  renderCardItem(f: File) {
    return <FileCard key={ f.id } file={ f } />
  }

}
