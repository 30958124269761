import * as React from 'react';
import { Button } from 'reactstrap';
import { projectBloc } from '../../bloc/ProjectBloc';
import EntityManager from '../../managers/EntityManager';
import Column from '../../models/Column';
import DOMService from '../../services/DOMService';
import ToastrService from '../../services/ToastrService';
import IconButton from '../buttons/IconButton';
import ColorInput from '../input/SmartColorInput';

export interface IColumnFormProps {
  column: Column
}

export interface IColumnFormState {
  hasChanged: boolean
}

export default class ColumnForm extends React.Component<IColumnFormProps, IColumnFormState> {
  constructor(props: IColumnFormProps) {
    super(props);

    this.state = {
      hasChanged: false
    }
  }

  update = async () => {
    await this.props.column.update({ only: ["color"] })
    projectBloc.loadProject();
  }

  archiveTickets = async () => {
    let onConfirm = async () => {
      EntityManager.post<Column>(Column, this.props.column.id + "/archive");
      ToastrService.toaster.show("Urioz", "Les tickets ont été archivés.");
      DOMService.close();
      projectBloc.loadProject();
    }
    DOMService.alert({
      title: "Archiver tous les tickets ?",
      message: "Cette action va archiver tous les tickets de cette colonne.",
      buttonText: "Archiver",
      onCancel: () => DOMService.close(),
      onConfirm: onConfirm
    })
  }

  public render() {
    const { column } = this.props;
    return (
      <div>
        <div className="divider my-3 mx-0"></div>
        <div className="column-form row-between pb-2">
          <div>
            <div className="text-small">
              Couleurs des tickets
            </div>
            <div className="row-flex">
              <ColorInput onChange={() => this.setState({hasChanged: true})} model={ column } />
              <Button disabled={!this.state.hasChanged} onClick={() => this.update()} className="text-small ml-2" size="sm" color="light">Mettre à jour</Button>
            </div>
          </div>
          { column.hasTickets() && <div >
            <IconButton onClick={this.archiveTickets} tooltip="Archiver les tickets" iconData={["fal", "archive"]} light />
          </div>}
        </div>
      </div>
    );
  }
}
