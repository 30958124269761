import * as React from 'react';
import { Button, ModalBody } from 'reactstrap';
import { driveBloc } from '../../bloc/DriveBloc';
import File from '../../models/File';
import Group from '../../models/Group';
import AssignableSelectMultiple from '../members/AssignableSelectMultiple';
import CustomModalHeader from './customModalHeader';
import EntityManager from '../../managers/EntityManager';

export interface IFileShareModalProps {
  file: File;
  onSubmit?: (arg: Group[]) => void
}

export default class FileShareModal extends React.Component<IFileShareModalProps, any> {

  public file: File;

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  async componentDidMount() {
    this.file = await EntityManager.show<File>(File, this.props.file.id);
    this.setState({})
  }

  onAssignationsChange = (state) => {
    this.file.groupIds = state.groupIds;
    this.file.userIds = state.userIds;
  }

  public render() {
    const title = "Partager";
    if (!this.file) return <></>;
    return (
      <div className="share-modal">
        <CustomModalHeader title={title} />
        <ModalBody>
          <AssignableSelectMultiple overflowHeight="500px" defaultValue={this.file} onChange={this.onAssignationsChange} />
        </ModalBody>
        <ModalBody className="text-right pb-3">
          <Button onClick={() => driveBloc.updateRights(this.file)} color="primary">Valider</Button>
        </ModalBody>
      </div>
    );
  }
}
