import * as React from 'react';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import Event from '../../models/Event';
import File from '../../models/File';
import Model from '../../models/Model';
import StaticMessage from '../../models/StaticMessage';
import FileIcon from '../drive/fileIcon';
import { AvatarSize } from './Avatar';
import AvatarLetter from './AvatarLetter';

export interface IActuComponentProps {
  model: Model
}

export interface IActuComponentState {
}

export default class ActuComponent extends React.Component<IActuComponentProps, IActuComponentState> {
  constructor(props: IActuComponentProps) {
    super(props);

    this.state = {
    }
  }

  renderCreatedAt(createdAt) {
    return <div className="created-at row-end" style={{flex: "0 0 110px"}}>
    <Moment fromNow>{createdAt}</Moment>
  </div>
  }

  renderEventItem(model) {
    return <div>
      <div>
        <span>Nouvel évènement créé par </span><b>{model.creator.getFullName()}</b>
      </div>
      <div className="row-between">
        <div>{model.name}</div>
      </div>
    </div>
  }

  renderMessageItem(model) {
    return <div>
      <NavLink to={model.getFrontUrl()} color="primary">Nouveau message </NavLink>de <b>{model.sender.getFullName()}</b>
      <div className="row-between">
        <div className="message-show-content">{model.content}</div>
        <div>

        </div>
      </div>
    </div>
  }

  renderFileItem(model) {
    return <div>
      <div>
        <span>Nouveau fichier importé par </span><b>{model.creator.getFullName()}</b>
      </div>
      <div className="row-flex">
        <div className="mr-3">
          <FileIcon height={"30px"} file={model} />
        </div>
        <div>
          {model.name}
        </div>
      </div>
    </div>
  }

  layout(icon, createdAt, user, body) {
    return <div className="border-gray-bottom py-3">
      <div className="row-flex">
        <div style={{flex: "0 0 60px"}}>
          <AvatarLetter size={AvatarSize.sm} user={user} />
        </div>
        {/* <FontAwesomeIcon icon={icon} /> */}
        {/* {this.renderCreatedAt(createdAt)} */}
        <div style={{flex: "1 1"}}>
          {body}
        </div>
        <div style={{flex: "0 0 100px"}}>
          {this.renderCreatedAt(createdAt)}
        </div>
      </div>
    </div>
  }

  public render() {
    const { model } = this.props;
    if (model instanceof StaticMessage) return this.layout(["fal", "comment"], model.createdAt, model.sender, this.renderMessageItem(model))
    if (model instanceof Event)   return this.layout(["fal", "calendar"], model.createdAt, model.creator, this.renderEventItem(model))
    if (model instanceof File)    return this.layout(["fal", "file"], model.createdAt, model.creator, this.renderFileItem(model))
    return <></>
  }
}
