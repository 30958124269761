import * as React from 'react';
import { consumeMessagingBloc, IMessagingBlocState, messagingBloc } from '../../bloc/MessagingBloc';
import MessageRow from './MessageRow';
import { appBloc } from '../../bloc/AppBloc';
import MessagingHeader from './MessagingHeader';

export interface IMessagingContentProps {
  view: string
}

export interface IMessagingContentState {
}

class MessagingContent extends React.Component<IMessagingBlocState & IMessagingContentProps, IMessagingContentState> {
  constructor(props: IMessagingBlocState & IMessagingContentProps) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
    messagingBloc.view = this.props.view;
    messagingBloc.setState({currentPage: appBloc.search.page || 1}, () => messagingBloc.loadMessage())
    ;
  }

  public render() {
    const { messages } = this.props;
    return (
      <div>
        <MessagingHeader/>
        <div className="messages-list">
          {messages.map(m => <MessageRow key={m.id} message={m} />)}
        </div>
      </div>
    );
  }
}

export default consumeMessagingBloc(MessagingContent)
