import classNames from 'classnames';
import * as React from 'react';
import { consumeRoomBloc, IRoomBlocState, roomBloc } from '../../bloc/RoomBloc';
import MessagesBox from './MessageBox';

export interface IRoomBodyProps {
}

export interface IRoomBodyState {
}

class RoomBody extends React.Component<IRoomBlocState & IRoomBodyProps, IRoomBodyState> {
  constructor(props: IRoomBlocState & IRoomBodyProps) {
    super(props);

    this.state = {
    }
  }


  public render() {
    const { activeRoom, isLoading ,typingUsers, showFilePreview } = this.props;
    return (
      <div className={classNames({"room-body-container": true, "show-file-preview": showFilePreview})}>
        <div className="room-body">
          { isLoading && <></>}
          { !isLoading && activeRoom ? <MessagesBox innerRef={roomBloc.messageListRef} room={activeRoom} typingUsers={typingUsers} /> : <div></div> }
        </div>
      </div>
    );
  }
}

export default consumeRoomBloc(RoomBody);
