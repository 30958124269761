import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { appBloc, consumeAppBloc } from '../../bloc/AppBloc';
import Logo from '../common/Logo';
import Navigation from './Navigation';
import ProfileMenu from './ProfileMenu';

export interface ISidebarProps {
  minimize: boolean
}

export interface ISidebarState {
}

class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  constructor(props: ISidebarProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { minimize } = this.props;
    return (
      <>
      <div className={"sidebar d-none d-lg-block"}>
        <div className="sidebar-header px-2">
          <Logo size="auto" imgStyle={{height: "100%"}} url={appBloc.state.application.logoUrl} />
        </div>
        <div className="divider"></div>
        <ProfileMenu minimize={minimize} />
        <div className="divider"></div>
        <div className="sidebar-body">
          <Navigation minimize={minimize} />
        </div>
        <div className="minimize-btn" onClick={appBloc.toggleMinimize}>
          <FontAwesomeIcon icon={["fal", "chevron-left"]}/>
        </div>
      </div>
      </>
    );
  }
}



export default consumeAppBloc(Sidebar)
