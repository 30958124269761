import DocumentsService from "./DocumentsService";
import FileModel from '../models/File';
import DropboxService from "./DropboxService";
import StorageService from "./StorageService";

export default class DropboxDocumentsService extends DocumentsService {

  public baseUrl = "/documents/dropbox";

  async createFolder(currentFile: FileModel, name: string) {
    DropboxService.createFolder(currentFile.id + "/" + name);
  }

  async upload(file: File, currentFile: FileModel, progressIndicator?) {
    await DropboxService.upload(file, currentFile.id.toString() + "/" + file.name)
  }

  async load(folderId: string) {
    let param = folderId || "";
    let files = await DropboxService.loadFiles(param);
    let currentFile = new FileModel({ id: folderId });
    currentFile.files = files;
    return currentFile;
  }

  async download(fileModel?: FileModel) {
    let file: any = await DropboxService.download(fileModel.id.toString());
    StorageService.downloadBlob(file.fileBlob, fileModel.name)
  }

}
