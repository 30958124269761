import Axios, { AxiosResponse } from "axios";
import env from '../config/env';
import SessionService from "./SessionService";
// import sessionService from "./sessionService";

interface IHeaders {
  headers: { Authorization: string }
}

class ApiService {

  public get(path: string, params = {}): Promise<AxiosResponse> {
    return Axios.get(this.getBaseUrl() + path + this.createUrlParam(params), this.buildConfig())
  }

  public post(path: string, data: any, option = {}): Promise<AxiosResponse> {
    return Axios.post(this.getBaseUrl() + path, data, this.buildConfig(option))
  }

  public put(path: string, data: any, option = {}): Promise<AxiosResponse> {
    return Axios.put(this.getBaseUrl() + path, data, this.buildConfig());
  }

  public delete(path: string): Promise<AxiosResponse> {
    return Axios.delete(this.getBaseUrl() + path, this.buildConfig());
  }

  public buildConfig(options?: any) {
    let headers: IHeaders = this.buildHeader(options?.multipart);
    return Object.assign(headers, options);
  }

  private buildHeader(multipart = false): IHeaders {
    let token: string = SessionService.getToken();
    let header = { headers: { Authorization: `Bearer ${token}` } };
    if (multipart) header.headers['content-type'] = 'multipart/form-data';
    return header;
  }

  public getBaseUrl(): string {
    return env.protocol + env.apiUrl + "api/";
  }

  private createUrlParam(params: any) {
    let paramsKeys = Object.keys(params)
    if (paramsKeys.length > 0 ) {
      let urlParams = '?'
      paramsKeys.forEach((p, i) => {
        urlParams += p + '=' + params[p]
        if (i + 1 !== paramsKeys.length) urlParams += '&'
      });
      return urlParams
    }
    return ''
  }
}

export default new ApiService()
