import * as React from 'react';
import Event from '../../models/Event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';

export interface IDateRangeTextProps {
  event: Event
  withIcon?: boolean
}

export interface IDateRangeTextState {
}

export default class DateRangeText extends React.Component<IDateRangeTextProps, IDateRangeTextState> {
  constructor(props: IDateRangeTextProps) {
    super(props);

    this.state = {
    }
  }

  isOneDay(event: Event) {
    return event.startAt.getDay() === event.endAt.getDay();
  }

  public render() {
    const { event, withIcon } = this.props;
    return <div className="row-flex event-date">
        { withIcon &&
          <div className="calendar-icon mr-2">
            <FontAwesomeIcon icon={ ["fal", "calendar-alt"] } />
          </div>
        }
        { this.isOneDay(event) &&
          <div className="row-flex">
            <Moment className="capitalize" format={ "dddd DD MMMM - hh:mm" }>{ event.startAt }</Moment>
            <div className="mx-2 d-flex">
              <FontAwesomeIcon style={ { fontSize: "0.4rem" } } icon={ ["fas", "circle"] } />
            </div>
            <Moment className="capitalize" format={ "hh:mm" }>{ event.endAt }</Moment>
          </div>
        }
        { (!event.allDay && !this.isOneDay(event)) &&
          <div className="row-flex">
            <Moment className="capitalize" format={ "dddd DD MMMM - hh:mm" }>{ event.startAt }</Moment>
            <span className="mx-2">au</span>
            <Moment className="capitalize" format={ "dddd DD MMMM - hh:mm" }>{ event.endAt }</Moment>
          </div>
        }
        { (event.allDay && !this.isOneDay(event)) &&
          <div className="row-flex">
            <Moment className="capitalize" format={ "DD - " }>{ event.startAt }</Moment>
            <Moment className="capitalize" format={ "DD MMMM" }>{ event.endAt }</Moment>
          </div>
        }
    </div>
  }
}
