import FullCalendar, { DateSelectArg, EventChangeArg, EventClickArg, EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { consumeEventsBloc, eventsBloc, IEventsBlocState } from '../../bloc/EventsBloc';
import Event from '../../models/Event';
import DOMService from '../../services/DOMService';
import TextIcon from '../common/TextIcon';
import EventModal from '../modal/EventModal';
import InvitationsModal from '../modal/InvitationsModal';
import NewEventModal from '../modal/NewEventModal';
import classNames from 'classnames';
import OptionService from '../../services/OptionService';

export interface ICalendarProps {
}

export interface ICalendarState {
}

class Calendar extends React.Component<IEventsBlocState, ICalendarState> {
  constructor(props: IEventsBlocState) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {
  }

  convertToCalendarEvent(events: Event[]): EventInput[] {
    return events.map(e => this.buildCalendarEvent(e))
  }

  buildCalendarEvent(event: Event): EventInput {
    return {
      id: event.id.toString(),
      title: event.name,
      start: event.startAt,
      end: event.endAt,
      allDay: event.allDay,
      color: event.type.color,
      className: "urioz-event",
      backgroundColor: event.type.color + "29",
      textColor: event.type.color
      // style: {color: event.type.color}
    }
  }

  findEvent(id): Event {
    return this.props.events.find(e => id === e.id)
  }

  eventClick = (arg: EventClickArg) => {
    let event: Event = this.findEvent(parseInt(arg.event._def.publicId))
    DOMService.modal(<EventModal event={event} />, {maxWidth: "550px"})
  }

  eventDateChange = (eventChange: EventChangeArg) => {
    let event = this.findEvent(parseInt(eventChange.event._def.publicId))
    event.startAt = eventChange.event.start;
    event.endAt = eventChange.event.end;
    eventsBloc.updateEvent(event);
  }

  select = (e: DateSelectArg) => {
    let event = new Event({
      agendaId: eventsBloc.getPrivateAgendaId(),
      startAt: e.allDay ? moment(e.start).add(12, "hours").toDate() : e.start,
      endAt: e.allDay ? moment(e.end).subtract(12, "hours").toDate() : e.end,
      allDay: e.allDay,
      typeId: OptionService.getOptionsByType("eventType")[0].id
    }).fromMe();
    DOMService.modal(<NewEventModal event={event}/>, eventsBloc.modalStyle);
  }

  onWheel(e: React.WheelEvent<HTMLDivElement>) {
    if (e.deltaY > 0) eventsBloc.getCalendar().next();
    else              eventsBloc.getCalendar().prev();
  }

  hasInvitation() {
    return this.props.invitations.length > 0
  }

  getInvitationsBtnText() {
    return "Invitations" + (this.hasInvitation() ? ` (${this.props.invitations.length})` : '')
  }

  public render() {
    const { events } = this.props;
    let ele = document.querySelector(".fc-invitations-button");
    return (
      <div className={ classNames({ "no-invitations": !this.hasInvitation() }) } onWheel={ this.onWheel }>
        { ele && ReactDOM.createPortal(<TextIcon onClick={() => DOMService.modal(<InvitationsModal />)} leftIcon={["fas", "bell"]} >{this.getInvitationsBtnText()}</TextIcon>, ele) }
        <FullCalendar
          plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
          ref={eventsBloc.calendar}
          initialView={eventsBloc.initialView}
          customButtons={{
            invitations: {
              text: '',
            }
          }}
          headerToolbar={{
            left: "today,prev,next,title",
            end: "invitations dayGridMonth,timeGridWeek,timeGridDay",
          }}
          buttonText={{
            today : "Aujourd'hui",
            month : "Mois",
            week  : "Semaine",
            day   : "Jour"
          }}
          editable
          viewDidMount={eventsBloc.onViewChange}
          // allDaySlot={false}
          allDayText={""}
          initialDate={eventsBloc.initialDate}
          selectable
          locale={"fr"}
          height={"calc(100vh - 120px"}
          select={this.select}
          // eventDidMount={(e) => console.log(e)} Return event visible
          datesSet={eventsBloc.onDateChanges}
          eventResizableFromStart
          eventDurationEditable
          eventResize={this.eventDateChange}
          eventDrop={this.eventDateChange}
          eventClick={this.eventClick}
          events={this.convertToCalendarEvent(events)}
        />
      </div>
    );
  }
}

export default consumeEventsBloc(Calendar)
