import * as React from 'react';
import Draggable from 'react-draggable';
import { consumeVisioBloc, IVisioBlocState } from '../../bloc/VisioBloc';
import Viseo from './Viseo';

export interface IRealTimeFrameProps {
}

export interface IRealTimeFrameState {
}

class RealTimeFrame extends React.PureComponent<IVisioBlocState, IRealTimeFrameState> {
  constructor(props: IVisioBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { visio, isMuted, cameraActive } = this.props;
      return <Draggable>
        <div className="realtime-frame">
          <Viseo visio={visio} cameraActive={cameraActive} isMuted={isMuted}/>
        </div>
      </Draggable>
  }
}

export default consumeVisioBloc(RealTimeFrame)
