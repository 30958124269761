import * as React from 'react';
import FormElement from '../../models/form/FormElement';
import FormSelectOption from '../../models/form/FormSelectOption';
import ListInputs from '../common/ListInputs';
import SmartInputText from '../input/SmartInputText';
import SmartSwitch from '../input/SmartSwitch';
import IconButton from '../buttons/IconButton';
import { FormElementType } from '../../models/form/FormElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import StyleButton from './StyleButton';
import ColorInput from '../input/SmartColorInput';
import { uniqueId } from 'lodash';
import SmartInputTextarea from '../input/SmartInputTextarea';

export interface IFormElementEditProps {
  formElement: FormElement
  onChange: () => void
  onClose: () => void
  id?: any
}

export interface IFormElementEditState {
  newOption: boolean
}

export interface StyleButtonProp {
  icon: IconProp
  name: string
  value: string
}

export default class FormElementEdit extends React.Component<IFormElementEditProps, IFormElementEditState> {

  public styleButtons: StyleButtonProp[] = [
    {
      icon: ["fas", "italic"],
      name: "fontStyle",
      value: "italic"
    }, {
      icon: ["fas", "bold"],
      name: "fontWeight",
      value: "bold"
    }, {
      icon: ["fal", "align-left"],
      name: "textAlign",
      value: "left"
    }, {
      icon: ["fal", "align-center"],
      name: "textAlign",
      value: "center"
    }, {
      icon: ["fal", "align-right"],
      name: "textAlign",
      value: "right"
    }
  ];

  public positionButtons: StyleButtonProp[] = [
    {
      icon: ["fal", "align-left"],
      name: "marginRight",
      value: "auto"
    }, {
      icon: ["fal", "align-center"],
      name: "margin",
      value: "auto"
    }, {
      icon: ["fal", "align-right"],
      name: "marginLeft",
      value: "auto"
    }
  ];

  onStyleChange = (name: any, value: any) => {
    let style = JSON.parse(JSON.stringify(this.props.formElement.style));
    if (style[name] === value) delete style[name];
    else style[name] = value
    this.props.formElement.style = style
    this.props.onChange()
  }

  onPositionChange = (name: any, value: any) => {
    let style = JSON.parse(JSON.stringify(this.props.formElement.style));
    delete style.margin
    delete style.marginLeft
    delete style.marginRight
    style[name] = value
    this.props.formElement.style = style
    this.props.onChange()
  }

  onWidthChange = (val: string) => {
    if (!val || parseInt(val) > 100) val = "100";
    let width = val + "%";
    if (this.props.formElement.style.width === width) return;
    this.onStyleChange("width", width);
  }

  onColorChange = (prop, color: string) => {
    let style = JSON.parse(JSON.stringify(this.props.formElement.style));
    style[prop] = color
    this.props.formElement.style = style
    this.props.onChange()
  }

  public render() {
    const { formElement, onClose } = this.props;
    return (
      <div>
        <div className="row-between mb-4">
          <h4 className="float-left">{formElement.humanName}</h4>
          <IconButton iconData={["fal", "times"]} onClick={onClose}/>
        </div>
        <div>
          { formElement.isField && <SmartInputText id={uniqueId()} model={formElement} name="label" label="Label" floating onChange={this.props.onChange}/>}
          { !formElement.isField && <SmartInputTextarea id={uniqueId()} model={formElement} name="label" label="Contenu" floating onChange={this.props.onChange}/>}
          { formElement.isField && <SmartSwitch id={uniqueId()} className="mb-3" model={ formElement } name="required" label="Obligatoire" />}
          { formElement.typeKey === FormElementType.DatePicker &&
            <SmartSwitch className="mb-3" model={ formElement } name="withTime" label="Choix de l'heure" onChange={this.props.onChange}/>
          }
          { formElement.hasUrl &&
            <SmartInputText id={uniqueId()} model={ formElement } name="url" label="Adresse" floating onChange={this.props.onChange} />
          }
          { formElement.isCutomable &&
            <div className="row-flex">
            { this.styleButtons.map(btn => <StyleButton key={btn.name + btn.value} btn={ btn } onStyleChange={ this.onStyleChange } formElement={ formElement } />) }
            <ColorInput value={formElement.style?.color} onChange={(e, color) => this.onColorChange("color", color)}>
              <div style={{borderBottom: `5px solid ${formElement.style?.color}`}} className={ classNames({ "style-btn": true })}>
                <FontAwesomeIcon icon={["fal", "tint"]} />
              </div>
            </ColorInput>
            {/* <ColorInput value={formElement.style?.backgroundColor} onChanged={(color) => this.onColorChange("backgroundColor", color)}>
              <div style={{borderBottom: `5px solid ${formElement.style?.backgroundColor}`}} className={ classNames({ "style-btn": true })}>
                <FontAwesomeIcon icon={["fal", "fill-drip"]} />
              </div>
            </ColorInput> */}
            </div>
          }
          { formElement.sizable &&
            <>
            <div className="row-flex">
              <div className="row-flex mr-2">
                { this.positionButtons.map(btn => <StyleButton key={btn.name + btn.value} btn={ btn } onStyleChange={ this.onPositionChange } formElement={ formElement } />) }

              </div>
                <SmartInputText id={uniqueId()} noMargin label="Largeur en pourcentage" floating onChange={ (e, val) => this.onWidthChange(val) } />
              </div>
            </>
          }
          { formElement.hasOptions &&
            <ListInputs onChanged={this.props.onChange} options={formElement.formSelectOptions} modelClass={FormSelectOption} title={"Options"} name={"label"} />
          }
        </div>
      </div>
    );
  }
}
