import * as React from 'react';


export default class BasePage<P, S> extends React.Component<P, S> {

  public title: string;

  setTitle() {
    document.title = this.title;
  }

}
