import * as React from 'react';
import { consumeRoomBloc, IRoomBlocState } from '../../bloc/RoomBloc';
import SidebarItem from './SidebarItem';

export interface IChatSidebarItemProps {
  minimize?: boolean
}

export interface IChatSidebarItemState {
}

class ChatSidebarItem extends React.Component<IChatSidebarItemProps & IRoomBlocState, IChatSidebarItemState> {
  constructor(props: IChatSidebarItemProps & IRoomBlocState) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { minimize, unreadCount } = this.props;
    return (
      <SidebarItem iconData={ ["fal", "comments"] } minimize={ minimize } label="Chat" to="/messages" childRender={
        <>
          { unreadCount > 0 && <div className="chat-sidebar-unread-value">
            {unreadCount}
          </div>
          }
        </>
      } />
    );
  }
}

export default consumeRoomBloc(ChatSidebarItem)
