import RoomComposition from "../models/RoomComposition";
import EntityManager from "./EntityManager";


export default class RoomCompositionManager extends EntityManager<RoomComposition> {

  protected modelUrl: string = "room_compositions";

  protected getEntity(json: any): RoomComposition {
    return new RoomComposition(json)
  };

  public read(param) {
    return this.post("read", param);
  }

}
