import Model from '../../models/Model';
import SearchableSelect from '../common/SearchableSelect';

export interface ISelectMultipleProps<T> {
  onChange: (arg: T[]) => void;
  overflowHeight?: string;
  defaultValue?: T[]
}

export interface ISelectMultipleState<T> {
  input: string
  isSending: boolean
  selectedModels: T[]
  filteredModels: T[]
}

export default class SelectMultiple<T extends Model> extends SearchableSelect<ISelectMultipleProps<T>, ISelectMultipleState<T>> {

  public models: T[] = [];

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      isSending: false,
      selectedModels: props.defaultValue || [],
      filteredModels: props.defaultValue || null
    };

  }

  getData = async (search): Promise<T[]> => {
    return null;
  }

  loadData = async (search) => {
    this.models = await this.getData(search || {});
    this.setState({filteredModels: this.models});
  }

  public addUser = (user: T) => {
    this.setState({selectedModels: [...this.state.selectedModels, user]}, () => this.props.onChange(this.state.selectedModels))
  }

  public removeUser = (user: T) => {
    let selectedModels = this.state.selectedModels.filter((u) => user.id !== u.id)
    this.setState({selectedModels}, () => this.props.onChange(this.state.selectedModels))
  }

  public isSelected = (model: T) => {
    return this.state.selectedModels.find(m => m.id === model.id);
  }

  getListStyle() {
    const listStyle = {}
    if (this.props.overflowHeight) {
      listStyle["maxHeight"] = this.props.overflowHeight;
      listStyle["overflow"] = "auto";
    }
    return listStyle;
  }

  // public handleChange = (e) => {
  //   let searchInput: string = e.target.value;
  //   let filteredFriends = this.friends.filter((f) => f.getFullName().match(new RegExp(searchInput, 'i')));
  //   this.setState({searchInput, filteredFriends});
  // }

}
