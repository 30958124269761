import classNames from 'classnames';
import { uniqueId } from 'lodash';
import * as React from 'react';
import SmartInput, { ISmartInputProps } from './SmartInput';

export interface ISmartCheckboxProps extends ISmartInputProps {
  checked?: boolean;
  primary?: boolean;
  color?: string;
}

export interface ISmartCheckboxState {
}

export default class SmartCheckbox extends SmartInput<ISmartCheckboxProps ,{}> {

  public id = uniqueId();
  public targetProperty = "checked";

  public render() {
    const { name, label, className, primary } = this.props;
    if (true) return <div className={ classNames({ [className]: !!className, "smart-checkbox": true, "smart-switch-primary": primary })}>
      <div className="styled-input-single">
        <input type="checkbox" onChange={this.handleChange} name={name} id={this.id} checked={this.getValue()} />
        <label htmlFor={this.id}>{label}</label>
      </div>
    </div>
  }
}
