import Model from "./Model";
import User from "./User";
import Room from "./Room";
import SessionService from "../services/SessionService";

export default class Message extends Model {

  public content: string;

  public userId: number;
  public user: User;
  public room: Room;
  public roomId: number;
  public createdAt: Date;
  public mediaUrl: string;
  public mediaType: string;
  public parentMessageId: number;
  public parentMessage: Message;

  public hasError: boolean = false;


  static modelUrl: string = "messages";
  public modelName: string = 'message';

  constructor(json: any) {
    super(json);
    this.content         = json.content;
    this.userId          = json.userId;
    this.parentMessageId = json.parentMessageId;
    this.parentMessage   = json.parentMessage && new Message(json.parentMessage);
    this.user            = json.user && new User(json.user);
    this.room            = json.recipient?.room && new Room(json.recipient.room);
    this.roomId          = json.roomId;
    this.createdAt       = json.createdAt && new Date(json.createdAt);
    this.mediaUrl        = json.mediaUrl;
    this.mediaType       = json.mediaType;
  }

  isFromMe() {
    return this.user.id === SessionService.getUserId();
  }

  fromMe() {
    this.userId = SessionService.getUserId();
    return this;
  }

  setUser() {
    this.user = SessionService.user;
  }
}
