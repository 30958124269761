import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';
import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './App';
import './assets/scss/app.scss';
import AppBloc from './bloc/AppBloc';
import PrivateRoute from './components/auth/PrivateRoute';
import FullScreenLoading from './components/common/FullScreenLoading';
import Toaster from './components/common/Toaster';
import ForgotPassword from './pages/ForgotPasswordPage';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/logoutPage';
import PublicPage from './pages/PublicPage';
import SigninPage from './pages/SigninPage';
import authService from './services/AuthService';
import SessionService from './services/SessionService';

moment.locale("fr");
moment.defineLocale('fr-short', {
  parentLocale:'fr',
  relativeTime : {
    future: "dans %s",
    past:   "%s ",
    s:  "1s",
    m:  "1 mn",
    mm: "%d mn",
    h:  "1 h",
    hh: "%d h",
    d:  "1 j",
    dd: "%d j",
    M:  "a mois",
    MM: "%d mois",
    y:  "a",
    yy: "%d a"
  }
});
export interface IRootProps {
}

export interface IRootState {
  initialized: boolean
}

export default class Root extends React.Component<IRootProps, IRootState> {
  constructor(props: IRootProps) {
    super(props);

    this.state = {
      initialized: false
    }
  }

  componentDidMount() {
    this.init();
  }

  public async initSession() {
    if (!SessionService.hasToken()) return;
    await authService.me();
  }

  init = async () => {
    await this.initSession();
    this.setState({initialized: true})
  }

  public render() {
    if (!this.state.initialized) return <FullScreenLoading/>
    return (
      <>
        <BrowserRouter>
          <Switch>
            {/* <Route path='/users/new/:token'        component={(p) => <NewUserPage token={p.match.params.token} /> }/> */}
            <Route path='/public/:appId/'           component={(p) => <PublicPage appId={p.match.params.appId}/> }/>
            <Route exact path="/login"             component={() => <LoginPage/>} />
            <Route exact path="/forgot_password"   component={() => <ForgotPassword/>} />
            <Route exact path="/signin_beta"            component={() => <SigninPage/>} />
            <Route exact path='/logout'            component={() => <LogoutPage/> }/>
            <PrivateRoute path={'/'}               component={() => <AppBloc><App/></AppBloc>} />
          </Switch>
          <Toaster/>
        </BrowserRouter>
      </>
    );
  }
}
