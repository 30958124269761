import SessionService from "../services/SessionService";
import Model from "./Model";
import User from './User';

export default class Comment extends Model {

  public content: string;
  public user: User;
  public userId: number;
  public commentableId: number;
  public commentableType: string;
  static modelUrl = "comments";

  constructor(json: any) {
    super(json)
    this.content  = json.content;
    this.user     = json.user && new User(json.user);
    this.userId   = json.userId;
    this.commentableId   = json.commentableId;
    this.commentableType   = json.commentableType;
  }

  fromMe() {
    this.userId = SessionService.getUserId();
    return this;
  }

  toMapApi() {
    return {
      content: this.content,
      userId: this.userId,
      commentableId: this.commentableId,
      commentableType: this.commentableType,
    }
  }

}
