import LogManager from '../managers/LogManager';
import Log from '../models/Log';

class LogService {

  public logManager = new LogManager();
  public active = true;

  init() {
    // appBloc.onUrlChange((e) => this.log(e));
  }

  log(e) {
    if (this.active) {
      let pathname = e.pathname;
      let log = new Log({data: pathname, type: "navigation"}).fromMe();
      this.logManager.create(log)
      this.active = false;
      setTimeout(() => this.active = true, 1000)
    }
  }

}

export default new LogService();