import EntityManager from "./EntityManager";
import Application from "../models/Application";

export default class ApplicationManager extends EntityManager<Application> {

  protected modelUrl: string = "applications";
  public authBase = true;

  protected getEntity(json: any): Application {
    return new Application(json);
  };

  public async init() {
    let response = await this.get("init", {}, true)
    return response;
  }

  public migrate(appId) {
    return this.post("migrate", {appId});
  }

  public seed(appId) {
    return this.post("seed", {appId});
  }

  public wipe(appId) {
    return this.post("wipe", {appId});
  }

}
