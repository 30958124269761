import * as React from 'react';
import { Button } from 'reactstrap';
import { projectBloc } from '../../bloc/ProjectBloc';
import Project from '../../models/Project';
import SessionService from '../../services/SessionService';
import TextIcon from '../common/TextIcon';
import SmartInputText from '../input/SmartInputText';
import SmartSwitch from '../input/SmartSwitch';
import BaseForm from './BaseForm';

export interface IprojectFormProps {
}

export interface IprojectFormState {
}

export default class projectForm extends React.Component<IprojectFormProps, IprojectFormState> {

  public project = new Project({createdBy: SessionService.getUserId()})

  public render() {
    return (
      <BaseForm<Project>
        model={this.project}
        onSubmitted={projectBloc.onProjectCreated}
        render={(state) => (
          <>
            <SmartInputText label="Nom" name="name" model={this.project} floating/>
            <SmartInputText label="Description" type="textarea" name="description" model={this.project} floating/>
            <SmartSwitch label="Rendre public" model={this.project} name="isPublic" />
            <div className="row-center mt-4">
              <Button type="submit" size="lg" color="success"><TextIcon leftIcon={["fal", "plus"]}>Créer un projet</TextIcon></Button>
            </div>
          </>
        )}
      />
    );
  }
}
