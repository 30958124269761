import * as React from 'react';
import FileLightRow from './FileLightRow';
import File from '../../models/File';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { driveBloc } from '../../bloc/DriveBloc';
import EntityManager from '../../managers/EntityManager';

export interface IDriveLightProps {
  movingFile: File
  onSubmit: (e: any) => void
}

export interface IDriveLightState {
  files: File[]
  currentFile: File
}

export default class DriveLight extends React.Component<IDriveLightProps, IDriveLightState> {

  constructor(props: IDriveLightProps) {
    super(props);

    this.state = {
      files: [],
      currentFile: null
    }
  }

  componentDidMount() {
    this.getRootFiles()
  }

  getRootFiles = async () => {
    let files = (await EntityManager.all<File>(File)).models;
    this.setState({files, currentFile: null})
  }

  getFileWithChildren = async (fileId) => {
    let currentFile = await EntityManager.show<File>(File, fileId);
    this.setState({currentFile, files: currentFile.files})
  }

  back = () => {
    let parentId = this.state.currentFile.parentId;
    if (parentId) this.getFileWithChildren(this.state.currentFile.parentId);
    else this.getRootFiles();
  }

  onMove = (e) => {
    driveBloc.moveInCurrentFile(this.state.currentFile, this.props.movingFile)
    this.props.onSubmit(e);
  }

  onOpenFolder = (file: File) => {
    if (!file.isFolder()) return;
    this.getFileWithChildren(file.id)
  }

  public render() {
    const { files, currentFile } = this.state;
    return (
      <div className="drive-light">
        <div className="drive-light-header">
          <div>
            { currentFile && <FontAwesomeIcon className="mr-3" onClick={this.back} icon={["fal", "arrow-alt-left"]} />}
          </div>
          <div>
            { currentFile?.name || "Drive"}
          </div>
        </div>
        <div className="drive-light-table">
          {files.map((f) => (
            <FileLightRow
              key={f.id}
              onOpenFolder={this.onOpenFolder}
              file={f}
            />
          ))}
        </div>
        <div className="row-end p-2">
          <Button onClick={this.onMove} color="primary">Déplacer ici</Button>
        </div>
      </div>
    );
  }
}
