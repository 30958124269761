import { debounce } from 'lodash';
import io from 'socket.io-client';
import { appBloc } from '../bloc/AppBloc';
import env from '../config/env';
import Room from '../models/Room';
import RoomService from './RoomService';
import SessionService from './SessionService';


let socketService: SocketService;

export default class SocketService {

  public socket: SocketIOClient.Socket;
  public debouncedTyping;

  public isTyping = false;

  constructor() {
    this.socket = io(env.protocol + env.nodeUrl, {autoConnect: false, reconnection: true})
    this.debouncedTyping = debounce((room) => {
      this.isTyping = false;
      this.emitUntyping(room.id)
    }, 3000);

    this.socket.on('connect', () => {
      console.log("connexion ok")
      this.emitAuth();
    });

  }

  // STATIC
  static get() {
    if (socketService) return socketService;
    SocketService.initSocket();
    return socketService;
  }

  static on(eventType, callBack) {
    SocketService.get().socket.on(eventType, callBack);
  }

  static off(eventType, callBack) {
    SocketService.get().socket.off(eventType, callBack);
  }

  static close() {
    SocketService.get().socket.close();
  }

  static open() {
    SocketService.get().socket.open();
  }

  static initSocket() {
    if (!socketService) socketService = new SocketService();
    socketService.socket.open();
  }

  // EMIT
  public emitAuth = () => {
    console.log("emitauth")
    this.socket.emit("auth", {
      "user": SessionService.user.toMap(),
      "roomIds": RoomService.getRoomIds(),
      "relationIds": SessionService.user.relationIds,
      "appId": appBloc.state?.application?.id,
      "token": SessionService.getToken()
      // "isVisible": settingsService.isVisible()
    });
  }

  public emitTyping(roomId: number) {
    this.socket.emit("typing", {
      "user": SessionService.user.toMap(),
      "roomId": roomId,
      "domain": appBloc.getDomain()
    });
  }

  public emitUntyping(roomId: number) {
    if (this.isTyping) {
      this.debouncedTyping.cancel();
      this.isTyping = false;
    }
    this.socket.emit("untyping", {
      "user": SessionService.user.toMap(),
      "roomId": roomId,
      "domain": appBloc.getDomain()
    });
  }

  // public emitWhoIsConnected = (roomCompos): Promise<Room[]> => {
  //   return new Promise((resolve, reject) => {
  //     this.socket.emit("whoIsConnected", {roomCompos}, (models: any[]) => {
  //       let roomCompositions = models.map(m => roomService.roomsManager.toModel(m));
  //       resolve(roomCompositions);
  //     });
  //   })
  // }

  public notify(eventType: string, data: any, to: number[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.socket.emit("notify", { eventType, data, to, domain: appBloc.getDomain() }, () => resolve());
    });
  }

  public notifyRoom(eventType: string, data: any, roomId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.socket.emit("notifyRoom", { eventType, data, roomId, domain: appBloc.getDomain() }, () => resolve());
    });
  }
  // FUNCTIONS

  public type(room: Room) {
    if (room.isNew()) return;
    if (!this.isTyping) {
      this.isTyping = true;
      this.emitTyping(room.id);
    }
    this.debouncedTyping(room);
  }

}

