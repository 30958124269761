import moment from 'moment';
import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { eventsBloc } from '../../bloc/EventsBloc';
import EntityManager from '../../managers/EntityManager';
import Event from '../../models/Event';
import Invitation from '../../models/Invitation';
import User from '../../models/User';
import OptionService from '../../services/OptionService';
import SessionService from '../../services/SessionService';
import Loading from '../common/Loading';
import AlertCrud from '../events/AlertCrud';
import SmartDatePicker from '../input/SmartDatePicker';
import SmartInputText from '../input/SmartInputText';
import SmartSwitch from '../input/SmartSwitch';
import MembersSelectMultiple from '../members/MembersSelectMultiple';
import BaseForm from './BaseForm';

export interface IEventFormProps {
  event?: Event
  ref?: any
  edit?: boolean
  onlyInvitations?: boolean
  onlyForm?: boolean
}

export interface IEventFormState {
}

class EventForm extends React.Component<IEventFormProps, IEventFormState> {

  public model: Event;
  public invitedUsers: User[] = [];

  constructor(props: IEventFormProps) {
    super(props);

    if (props.event && !props.edit) this.model = props.event;
    if (!props.event) this.model = this.createModel();

    this.state = {
      eventTypes: null
    }
  }

  async componentDidMount() {
    if (this.props.event?.id) this.model = await EntityManager.show<Event>(Event, this.props.event.id);
    this.setState({});
  }

  createModel() {
    return new Event({
      agendaId: eventsBloc.getPrivateAgendaId(),
      startAt: moment().set({hour:20,minute:0}).toDate(),
      endAt: moment().set({hour:20,minute:0}).add(10, "hour").toDate(),
      typeId: OptionService.getOptionsByType("eventType")[0].id,
      allDay: true
    }).fromMe();
  }

  handleUsers = (users: User[]) => {
    this.invitedUsers = users;
  }

  onSubmitted = async (model: Event) => {
    await EntityManager.createMany(Invitation, this.toInvitations(this.invitedUsers, model))
    eventsBloc.loadEvents();
  }

  toInvitations(users: User[], event: Event) {
    return users.map(u => { return {userId: u.id, eventId: event.id, createdBy: SessionService.getUserId()}})
  }

  displayErrors() {}

  public render() {
    const { onlyInvitations, onlyForm } = this.props;
    if (!OptionService.getOptionsByType("eventType") || !this.model) return <></>
    const btnText = !this.props.edit ? "Creer l'évènement" : "Modifier l'évènement";
    return (
      <BaseForm<Event>
        model={this.model}
        onSubmitted={ this.onSubmitted }
        render={ (state) => (
          <div className="d-flex">
            { (onlyForm || (!onlyInvitations && !onlyForm)) && <div className="mr-2 w-100">
                <SmartInputText label="Nom" name="name" model={ this.model } id="name" floating />
                <SmartInputText label="Description" name="description" type="textarea" model={ this.model } floating />
                <SmartInputText label="Lieu" disable={ this.model.isHoliday() } name="place" model={ this.model } floating />
                <SmartInputText label="Type" type="select" name="typeId" model={ this.model } floating>
                  { OptionService.getOptionsByType("eventType").map((et) => <option key={ et.id } value={ et.id.toString() }>{ et.name }</option>) }
                </SmartInputText>
                <SmartSwitch onChange={() => this.setState({})} className="mb-3" model={this.model} name={"allDay"} label="Toute la journée" />
                <Row>
                  <Col xs="12" md="6">
                    <SmartDatePicker
                      label={ "Début" }
                      name="startAt"
                      model={ this.model }
                      withTime={!this.model.allDay}
                    />
                  </Col>
                  <Col xs="12" md="6">
                    <SmartDatePicker
                      label={ "Fin" }
                      name="endAt"
                      model={ this.model }
                      withTime={!this.model.allDay}
                    />
                  </Col>
                </Row>
                {/* <div className="error-message">{error.date}</div> */ }
                <SmartInputText label="Agenda" type="select" name="agendaId" model={ this.model } floating>
                  { eventsBloc.state.agendas.map((a) => <option key={ a.id } value={ a.id }>{ a.name }</option>) }
                </SmartInputText>
                <div className="mb-3">
                  <AlertCrud event={ this.model } />
                </div>
                <div className="text-right pt-3">
                  <Button type="submit" color="primary">{ state.isSubmitting ? <Loading/> : btnText }</Button>
                </div>
              </div>
            }
            { (onlyInvitations || (!onlyInvitations && !onlyForm)) &&
              <div className="ml-2 w-100">
                <MembersSelectMultiple placeholder="Inviter des utilisateurs" onChange={ this.handleUsers } overflowHeight={ "550px" } />
              </div>
            }
          </div>
        )}
      />
    );
  }
}

export default EventForm