import * as React from 'react';
import { ModalBody } from 'reactstrap';
import { eventsBloc } from '../../bloc/EventsBloc';
import Agenda from '../../models/Agenda';
import DOMService from '../../services/DOMService';
import AgendaItem from '../events/AgendaItem';
import CustomModalHeader from './customModalHeader';

export interface IAgendaModalProps {
  agendas: Agenda[]
}

export interface IAgendaModalState {
}

class AgendaModal extends React.Component<IAgendaModalProps, IAgendaModalState> {

  public agenda = new Agenda({}).fromMe();

  constructor(props: IAgendaModalProps) {
    super(props);

    this.state = {
    }
  }

  createAgenda = async () => {
    let newAgenda = await this.agenda.create();
    eventsBloc.setState({agendas: [...eventsBloc.state.agendas, newAgenda]});
    DOMService.close();
  }

  public render() {
    let privateAgendas = this.props.agendas.filter(a => a.isPrivate);
    return (
      <div className="agenda-modal">
        <CustomModalHeader title={"Gestion de vos agendas"} />
        <ModalBody>
          {/* <div>
            <div className="mb-3 darkgray fw-bold">Ajouter un agenda</div>
            <SmartInputText label="Nom de l'agenda" model={this.agenda} name="name" floating />
            <div className="row-end">
              <Button onClick={() => this.createAgenda()} color="primary">Ajouter</Button>
            </div>
          </div> */}
          <div>
            <div className="mb-3 darkgray fw-bold">Vos agenda privés</div>
            <div>
              { privateAgendas.map(a => <AgendaItem agenda={a} />)}
            </div>
          </div>
        </ModalBody>
      </div>
    );
  }
}

export default AgendaModal