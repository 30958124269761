import Model from "./Model";
import RoomComposition from "./RoomComposition";
import User from "./User";
import Group from "./Group";
import moment from "moment";
import Message from "./Message";
import SessionService from "../services/SessionService";


export default class Room extends Model {

  public name: string;
  public type: string;
  public visibility: string;
  public roomCompositions: RoomComposition[];
  public lastMessage: Message;
  public messages: Message[];
  public users: User[];
  public userIds: number[];

  public requiredField = ["name"]

  static modelUrl: string = "rooms";
  public modelName: string = "room";

  constructor(json: any) {
    super(json)
    this.name             = json.name;
    this.visibility       = json.visibility;
    this.type             = json.type;
    this.userIds          = json.userIds;
    this.users            = this.mapArrayModel(User, json.users);
    this.lastMessage      = json.lastMessage && new Message(json.lastMessage)
    this.messages         = this.mapArrayModel(Message, json.messages) || [];
    this.roomCompositions = this.mapArrayModel(RoomComposition, json.roomCompositions);
  }

  getName(): string {
    return "";
  }

  getAvatar(): string {
    return "";
  }

  getChildId(): number {
    return null;
  }

  getChild(): User | Group {
    return null;
  }

  getState(): string {
    return "";
  }

  findMyRoomComposition(): RoomComposition {
    return this.roomCompositions.find((compo) => compo.user.id === SessionService.getUserId());
  }

  isRead(): boolean {
    if (this.roomCompositions.length === 0 || this.messages.length === 0) return true;
    let roomComposition = this.findMyRoomComposition();
    if (!roomComposition) return true;
    return roomComposition.readAt && moment(roomComposition.readAt).isAfter(moment(this.messages[this.messages.length - 1].createdAt));
  }

  isNew = (): boolean => {
    return !this.id;
  }

  getLoadParam() {
    if (this.id) return {roomId: this.id};
    return null;
  }

  hasConnected() {
    return null;
  }

  toMap = () => {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      userIds: this.userIds
    }
  }

  toMapApi = () => {
    return {
      id: this.id,
      type: this.type,
      visibility: this.visibility
    }
  }


}
