import * as React from 'react';
import { Button } from 'reactstrap';
import { visioBloc } from '../../bloc/VisioBloc';
import DOMService from '../../services/DOMService';
import { AvatarSize } from '../common/Avatar';
import AvatarLetter from '../common/AvatarLetter';
import AvatarName from '../common/AvatarName';
import TextIcon from '../common/TextIcon';
import NewMessageModal from '../modal/NewMessageModal';
import User from '../../models/User';
import classNames from 'classnames';

export interface IProfileShowProps {
  user: User
  small?: boolean
}

export interface IProfileShowState {
}

export default class ProfileShow extends React.Component<IProfileShowProps, IProfileShowState> {
  constructor(props: IProfileShowProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const {user, small} = this.props;
    return (
      <div className="profile">
        <div className="row-center mb-1">
          <AvatarLetter user={user} size={small ? AvatarSize.md : AvatarSize.lg} />
        </div>
        <div className="row-center mb-2">
          <div className="profile-name">{user.getFullName()}</div>
        </div>
        { !user.isMe() && <div className="profile-actions">
          <Button color="link" className="mr-3" onClick={() => DOMService.modal(<NewMessageModal to={[user]} />)}>
            <TextIcon leftIcon={["fal", "envelope"]}>Message</TextIcon>
          </Button>
          <Button color="link" className="mr-3" onClick={() => visioBloc.createVisio([user])}>
            <TextIcon leftIcon={["fal", "video"]}>Appel Video</TextIcon>
          </Button>
        </div>}
        <div className={classNames({"profile-info mt-3": true, "profile-info-small": small})}>
          <TextIcon className="break-word" spacing={4} leftIcon={["fal", "envelope"]}>
            {user.email}
          </TextIcon>
          <TextIcon spacing={4} leftIcon={["fal", "phone"]}>
            {user.phone}
          </TextIcon>
          <TextIcon className="break-word" spacing={4} leftIcon={["fal", "briefcase"]}>
            {user.job}
          </TextIcon>
          { user.linkedin && <div>
            <a target="_blank" rel="noopener noreferrer" href={user.linkedin}>
              <TextIcon spacing={4} leftIcon={["fab", "linkedin"]}>
                Compte Linkeding
              </TextIcon>
            </a>
          </div>}
          { user.responsible && <div>
            <label htmlFor="">Responsable</label>
            <AvatarName link user={user.responsible} />
          </div>
          }
        </div>
      </div>
    );
  }
}
