import EntityManager, { IndexResponse } from "./EntityManager";
import Room from "../models/Room";
import OneOneRoom from "../models/OneOneRoom";
import GroupRoom from "../models/GroupRoom";
import CustomRoom from "../models/CustomRoom";


export default class RoomManager extends EntityManager<Room> {

  protected modelUrl: string = "rooms";

  public getEntity(json: any): Room {
    switch (json.type) {
      case "oneone":
        return new OneOneRoom(json)
      case "group":
        return new GroupRoom(json)
      case "custom":
        return new CustomRoom(json)
    }
    return new Room(json);
  };

  public async from(param) {
    return (await this.get("from", param)) as Room
  }

  public async search(input: string): Promise<Room[]> {
    let response: IndexResponse<Room> = (await this.get("search", {input})) as IndexResponse<Room>;
    return response.models;
  }

}
