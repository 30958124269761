import Room from "./Room";
import Group from "./Group";


export default class GroupRoom extends Room {

  public group: Group;
  public type = "group";
  public connectedTotal: number;

  constructor(json: any) {
    super(json)
    this.group = json.group && new Group(json.group);
    this.connectedTotal = json.connectedTotal;
  }

  getName(): string {
    return this.group?.name || "";
  }

  getChildId(): number {
    return this.group?.id;
  }

  getChild(): Group {
    return this.group;
  }

  getAvatar(): string {
    return null;
    // return this.group?.pictureUrl || "";
  }

  getFrontUrl(): string {
    return this.group.getRoomUrl();
  }

  getState(): string {
    if (!this.connectedTotal || this.connectedTotal === 0) return "Inactif"
    return `${this.connectedTotal} connectés`
  }

  hasConnected() {
    return this.connectedTotal && this.connectedTotal > 0;
  }

}
