import SessionService from "../services/SessionService";
import CheckItem from "./CheckItem";
import Attachable from './mixin/Attachable';
import Commentable from './mixin/Commentable';
import Model from "./Model";
import User from './User';

export default class Ticket extends Model {

  public title: string;
  public description: string;
  public columnId: number;
  public priority: number;
  public projectId: number;
  public userId: number;
  public user: User;
  public createdBy: number;
  public deadline: Date;
  public creator: User;
  public isLate: boolean;
  // public commentsCount: number;
  public checkItems: CheckItem[];
  public commentable: Commentable;
  public attachable: Attachable;

  public requiredField = ["title"]
  public modelName = "ticket"
  static modelUrl = "tickets";

  public alertTitle: string = "Supprimer ce ticket ?";
  public confirmMessage: string = "Vous allez supprimer ce ticket.\n Les pièces jointes, la Checklist et les commentaires seront définitivement supprimé !";

  constructor(json: any) {
    super(json)
    this.title         = json.title;
    this.description   = json.description;
    this.columnId      = json.columnId;
    this.isLate        = json.isLate;
    this.deadline      = json.deadline && new Date(json.deadline);
    this.priority      = json.priority || 2;
    this.projectId     = json.projectId;
    this.userId        = json.userId || SessionService.getUserId();
    this.createdBy     = json.createdBy;
    this.user          = json.user && new User(json.user);
    this.creator       = json.creator && new User(json.creator);
    // this.commentsCount = json.commentsCount || 0
    this.checkItems    = this.mapArrayModel(CheckItem, json.checkItems)
    this.commentable   = new Commentable(this, json);
    this.attachable    = new Attachable(this, json);
  }

  hasCheckList() {
    return this.checkItems.length > 0;
  }

  getCheckState() {
    return this.checkItems.filter(i => i.isDone).length + "/" + this.checkItems.length;
  }

  getPriority() {
    switch (this.priority) {
      case 3: return "haute"
      case 2: return "modéré"
      case 1: return "basse"
    }
  }

  getPriorityColor() {
    switch (this.priority) {
      case 1: return "yellow"
      case 2: return "orange"
      case 3: return "red"
    }
  }

  hasAttachments() {
    return this.attachable.models.length > 0;
  }

  hasImageAttachment() {
    return this.attachable.models.find(a => a.isImage()) !== undefined;
  }

  getFirstImageAttachment() {
    return this.attachable.models.find(a => a.isImage())?.url;
  }

  toMapApi() {
    return {
      title: this.title,
      description: this.description,
      columnId: this.columnId,
      priority: this.priority,
      projectId: this.projectId,
      userId: this.userId,
      createdBy: this.createdBy,
      deadline: this.deadline,
    }
  }
}
