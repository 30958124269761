import { AxiosResponse } from "axios";
import ApplicationManager from '../managers/ApplicationManager';
import UserManager from "../managers/UserManager";
import Application from '../models/Application';
import User from "../models/User";
import ApiService from "./ApiService";
import SessionService from "./SessionService";

export interface Credential {
  email: string,
  password: string
}

export interface LoginResponse {
  user: User;
  token: string;
  appId: string;
}


export enum AuthState {
  ok,
  unauthorized,
  error
}
export class AuthService {

  public usersManager: UserManager = new UserManager();
  public applicationManager = new ApplicationManager();

  public async login(credential: Credential): Promise<AuthState> {
    try {
      let loginResponse: LoginResponse = await this.usersManager.login(credential);
      SessionService.setUser(loginResponse.user);
      SessionService.storeUser(loginResponse.user);
      SessionService.storeToken(loginResponse.token);
      return AuthState.ok;
    } catch (error) {
      return AuthState.unauthorized;
    }
  }

  public async me() {
    try {
      let user = await this.usersManager.me();
      SessionService.setUser(user);
      SessionService.storeUser(user);
    } catch (error) {
      SessionService.deleteToken();
      return AuthState.unauthorized;
    }
  }

  public async checkDomain(name: string) {
    return (await ApiService.post("/applications/checkDomain", {name})).data
  }

  public async createApplication(application: Application, user: User) {
    return await this.applicationManager.create({application, user});
  }

  public async updateApplication(application) {
    return await this.applicationManager.update(application);
  }

  public async getData() {
    return await this.applicationManager.init();
  }

  public async validateEmail(email: string): Promise<boolean> {
    let response: AxiosResponse = await ApiService.post("validate", {email});
    return response.data.exist;
  }

  public async signin(credential: any) {
    try {
      await this.usersManager.create(credential);
      await this.login(credential);
      return AuthState.ok;
    } catch (error) {
      return AuthState.error;
    }
  }

  public logout() {
    SessionService.deleteToken();
  }

}

const authService = new AuthService();
export default authService;
