import React from 'react';
import SearchManager from '../managers/SearchManager';
import Event from '../models/Event';
import File from '../models/File';
import Group from '../models/Group';
import User from '../models/User';
import { appBloc } from './AppBloc';
import Ticket from '../models/Ticket';
import { groupBy } from 'lodash';

export interface models {
  users: User[]
  events: Event[]
  groups: Group[]
  files: File[]
  tickets: Ticket[]
}

export interface ISearchBlocProps {}

export interface ISearchBlocState {
  models: any
  input: string
}

export const Context = React.createContext({})

export let searchBloc: SearchBloc

class SearchBloc extends React.Component<ISearchBlocProps, ISearchBlocState> {

  public searchManager: SearchManager = new SearchManager();

  constructor(props: ISearchBlocProps) {
    super(props)
    searchBloc = this

    let search: any = appBloc.search
    this.onSearch(search?.input);

    this.state = {
      models: null,
      input: search?.input ?? ""
    }

  }

  async init() {
  }

  // onFocus = () => {
  //   if (this.props.location.pathname === "/search") return;
  //   this.props.history.push("/search")
  // }

  handleChange = (input: string) => {
    this.setState({input})
    appBloc.push({pathname: "/search", search: "?input=" + input});
  }

  onClear = () => {
    this.setState({input: ""})
  }

  onSearch = async (input: string) => {
    if (!input || input === "") return;
    let models = (await this.searchManager.search(input)).models as any;
    this.setState({models: groupBy(models, "modelName")})
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
  }


  public render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    )
  }
}

export default SearchBloc;

export function consumeSearchBloc(Component) {

  return class extends React.Component<any> {

    render() {
      return (
        <Context.Consumer>
          { (context) => (
            <Component {...this.props } {...context}/>
          )}
        </Context.Consumer>
      )
    }
  }
}
