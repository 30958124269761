import * as React from 'react';
import { Button, Form, ModalBody } from 'reactstrap';
import RightType from '../../models/RightType';
import Role from '../../models/Role';
import DOMService from '../../services/DOMService';
import RightService from '../../services/RightService';
import SmartInputText from '../input/SmartInputText';
import SmartSwitch from '../input/SmartSwitch';
import CustomModalHeader from './customModalHeader';

export interface IRoleModalProps {
  role: Role
  submit: (role: Role) => void
}

export interface IRoleModalState {
}

export default class RoleModal extends React.Component<IRoleModalProps, IRoleModalState> {
  constructor(props: IRoleModalProps) {
    super(props);

    this.state = {
    }
  }

  handleChange = (rightType: RightType) => {
    const { role } = this.props;
    role.updateRightIds(rightType);
    this.setState({})
  }

  submit = (e) => {
    e.preventDefault();
    this.props.submit(this.props.role);
    DOMService.close();
  }

  public render() {
    return (
      <div className="role-modal">
        <CustomModalHeader title={"Nouveau rôle"} />
        <Form onSubmit={this.submit}>
          <ModalBody>
            <SmartInputText label="Nom du rôle" model={this.props.role} name="name" autoFocus floating/>
            { RightService.rightTypes.map(rt => (
            <div className="py-2">
              <SmartSwitch
                primary
                label={rt.label}
                name={rt.name}
                onChange={(e) => this.handleChange(rt)}
                checked={this.props.role.can(rt.id)}
              />
            </div>
          ))}
          </ModalBody>
          <div className="p-3 row-end">
            <Button type="submit" color="primary">Créer le rôle</Button>
          </div>
        </Form>
      </div>
    );
  }
}
