import classNames from 'classnames';
import * as React from 'react';
import { DragSource } from 'react-dnd';
import Moment from 'react-moment';
import { Input } from 'reactstrap';
import FileIcon from './fileIcon';
import FileItem from './FileItem';
import FolderTarget from './FolderTarget';
import MovingFrame from './MovingFrame';

export interface IFileRowProps {
}

export interface IFileRowState {
}

const source = {
  canDrag(props) {
    return true
  },

  isDragging(props, monitor) {
    return monitor.getItem().id === props.file.id
  },

  beginDrag(props, monitor, component: FileRow) {
    const item = { id: props.file.id, model: props.file }
    return item
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

class FileRow extends FileItem {

  connectDnD(component) {
    const { file, connectDragSource } = this.props;
    if (file.isFolder()) return <FolderTarget file={file} >
      {connectDragSource(component)}
    </FolderTarget>
    return connectDragSource(component)
  }

  public render() {
    const { file, isDragging} = this.props;
    const { filename, isRenaming, isMoving } = this.state;
    return this.connectDnD(
      <div
        className={classNames({
          'file-dragging': isDragging,
          'drive-row': true
        })}
        onClick={this.onDoubleClick}
        onMouseLeave={this.hideTooltip}
        onMouseEnter={this.debouncedShowTooltip}
      >
        <div className="c-1 c-body-1"><FileIcon file={file} /></div>
        <div className="c-2 c-body-2">
          { isRenaming ?
            <Input onKeyPress={(e) => { if (e.key === "Enter") this.updateName()}} innerRef={this.inputRef} value={filename} onChange={this.handleRename} autoFocus /> :
            <div>
              {file.name}
            </div>
          }
        </div>
        <div className="c-3 c-body-3">{file.creator?.getFullName() || ""}</div>
        <div className="c-4 c-body-4"><Moment format="DD MMMM YYYY">{file.updatedAt}</Moment></div>
        <div className="c-5 c-body-5">{file.getSizeText()}</div>
        <div className="c-6 c-body-6">
          {/* <div className="row-flex" onMouseEnter={this.hideTooltip}> */}
            { this.renderDropdown()}
          {/* </div> */}
        </div>
        { isMoving && <div ref={this.movingFrameRef}><MovingFrame onSubmit={(this.close)} movingFile={file} /></div>}
        {/* { showTooltip && this.renderTooltip()} */}
      </div>
    );
  }
}

export default DragSource("file", source, collect)(FileRow)
