import * as React from 'react';
import { NavLink } from 'react-router-dom';
import image404 from '../assets/img/404.png';

export interface IPage404Props {
}

export interface IPage404State {
}

export default class Page404 extends React.Component<IPage404Props, IPage404State> {
  constructor(props: IPage404Props) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className="bg-white h-100">
        <div className="row-center">
          <img className="img-fluid" style={{maxHeight: "60vh"}} src={ image404 } alt="" />
        </div>
        <h3 className="text-center mt-3">
          Cette page n'existe pas...
        </h3>
        <div className="text-center pt-3">
          <NavLink className="text-large" to="/" >
            <h5>Aller à l'accueil</h5>
          </NavLink>
        </div>
      </div>
    );
  }
}
