import * as React from 'react';
import FormElementComponent from './FormElementComponent';
import SortableElement from '../formBuilder/builder/sortable-element';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IEditableFormElementComponentProps {
}

export interface IEditableFormElementComponentState {
}

class EditableFormElementComponent extends React.Component<any, IEditableFormElementComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
    }
  }

  componentWillReceiveProps() {
    this.setState({})
  }

  public render() {
    return (
      <>
        {/* <div className={ classNames({ "canDrop": this.props.canDrop }) }></div> */}
        <div
          onClick={ () => this.props.editModeOn(this.props.element) }
          className={ classNames({ 'SortableItem rfb-item d-flex justify-content-between': true, 'selected': this.props.selected, "canDrop-sortable": this.props.canDrop }) }
        >
          <div className="w-100 mb-2">
            <FormElementComponent edit {...this.props} />
          </div>
          <div onClick={ () => this.props.onDestroy(this.props.element) } className="gray-btn">
            <FontAwesomeIcon style={{fontSize: "1.5rem"}} icon={["fal", "times"]} />
          </div>
        </div>
        {/* <div className={ classNames({ "canDrop": this.props.canDrop }) }></div> */}
      </>
    );
  }
}

export default SortableElement(EditableFormElementComponent)