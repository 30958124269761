import Notification from './Notification';
import Event from '../Event';

export default class EventNotification extends Notification {

  public event: Event;

  constructor(json: any) {
    super(json);
    this.event = json.data.event && new Event(json.data.event);
    this.notifier = this.event.creator
  }

  link() {
    localStorage.setItem("agendaStart", this.event.startAt.toISOString());
    return "/events?show=" + this.event.id
  }

  humanName() {
    return "Nouvel évènement !";
  }
}