import * as React from 'react';
import CustomModalHeader from './customModalHeader';
import { ModalBody } from 'reactstrap';
import Ticket from '../../models/Ticket';
import TicketShow from '../projects/TicketShow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { projectBloc } from '../../bloc/ProjectBloc';
import TicketForm from '../form/TicketForm';

export interface ITicketModalProps {
  ticket?: Ticket
}

export interface ITicketModalState {
  show: boolean
}

export default class TicketModal extends React.Component<ITicketModalProps, ITicketModalState> {
  constructor(props: ITicketModalProps) {
    super(props);

    this.state = {
      show: !!props.ticket?.id
    }
  }

  public render() {
    const { ticket } = this.props;
    return (
      <div>
        <CustomModalHeader title={this.state.show ? ticket.title : `Nouveau Ticket`} actions={ticket && ticket.id ? [
          <div style={{cursor: "pointer"}} onClick={() => projectBloc.deleteTicket(ticket)} className="px-3">
            <FontAwesomeIcon icon={["fal", "trash"]} />
          </div>
        ] : []} />
        <ModalBody>
          { this.state.show ? <TicketShow ticket={ticket} /> : <TicketForm ticket={ticket} />}
        </ModalBody>
      </div>
    );
  }
}
