import Model from "./Model";
import User from './User';

export default class Group extends Model {

  public name: string;
  public createdBy: number;
  public users: User[];
  public userIds: number[];
  public roomId: number
  public requiredField = ["name"]
  public modelName = "group"
  static modelUrl = "groups";

  constructor(json: any) {
    super(json)
    this.name      = json.name;
    this.createdBy = json.createdBy;
    this.roomId    = json.roomId;
    this.userIds   = json.userIds;
    this.users = this.mapArrayModel(User, json.users);
  }

  getRoomUrl(): string {
    return `/messages/group/${this.name.replace(new RegExp(" ", "g"), ".").toLowerCase()}.${this.roomId}`;
  }

  toMapApi() {
    const {id, name, createdBy, userIds} = this;
    return {id, name, createdBy, userIds}
  }

}
