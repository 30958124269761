import SessionService from "../services/SessionService";
import Event from "./Event";
import Model from "./Model";

export default class Agenda extends Model {

  public name: string;
  public events: Event[];
  public userId: number;
  public isPrivate: boolean;
  static modelUrl = "agendas";

  public requiredField = ["name"]

  constructor(json: any) {
    super(json)
    this.name = json.name;
    this.isPrivate = json.isPrivate;
    this.userId = json.userId;
    this.events      = this.mapArrayModel(Event, json.events);
  }

  fromMe() {
    this.userId = SessionService.getUserId();
    return this;
  }

}
