import * as React from 'react';
import { Redirect } from 'react-router';
import RoomService from '../../services/RoomService';
import RoomsView from './RoomsView';
import RoomsSidebar from './RoomsSidebar';

export interface IRoomRedirectProps {
}

export default class RoomRedirect extends React.Component<IRoomRedirectProps> {

  canRedirectToLast() {
    return RoomService.rooms.length > 0;
  }

  public render() {
    if (this.canRedirectToLast()) return <Redirect to={RoomService.getLastUsedRoom().getFrontUrl()} />
    return (
      <div className="rooms-page">
        <RoomsView/>
        <RoomsSidebar/>
      </div>
    )
  }
}
