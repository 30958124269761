import Model from "./Model";
import RightType from './RightType';

export default class Right extends Model {

  public type: RightType;
  public userId: number;
  public typeId: number;
  static modelUrl = "rights";

  constructor(json: any) {
    super(json)
    this.userId = json.userId;
    this.typeId = json.rightTypeId;
    this.type   = json.type && new RightType(json.type);
  }

}
