import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { relationBloc } from '../../bloc/RelationBloc';
import { visioBloc } from '../../bloc/VisioBloc';
import User from '../../models/User';
import DOMService from '../../services/DOMService';
import RightService from '../../services/RightService';
import ToastrService from '../../services/ToastrService';
import IconButton from '../buttons/IconButton';
import Dropdown, { DropDownDirection, DropDownPosition } from '../common/Dropdown';
import TextIcon from '../common/TextIcon';
import NewMessageModal from '../modal/NewMessageModal';
import RightsModal from '../modal/RightsModal';

export interface IMemberDropdownProps {
  user?: User
}

export interface IMemberDropdownState {
}

export default class MemberDropdown extends React.Component<IMemberDropdownProps, IMemberDropdownState> {
  constructor(props: IMemberDropdownProps) {
    super(props);

    this.state = {
    }
  }

  onInviteRelation = async () => {
    await relationBloc.requestFriend(this.props.user);
    relationBloc.reloadMembers();
    ToastrService.toaster.show("Urioz", "L'invitation a bien été envoyé.");
  }

  onAcceptFriend = async () => {
    await relationBloc.acceptFriend(this.props.user)
    relationBloc.reloadMembers();
  }

  onCancelFriend = async () => {
    await relationBloc.cancelFriend(this.props.user)
    relationBloc.reloadMembers();
  }

  public render() {
    const { user } = this.props;
    return (
      <Dropdown
        position={DropDownPosition.bottom}
        direction={DropDownDirection.left}
        button={<FontAwesomeIcon size={"2x"} icon={["fal", "ellipsis-h"]} />}
        content={(
          <div className="dropdown-frame">
            <NavLink to={user.getFrontUrl()}>
              <TextIcon className="dropdown-item" leftIcon={["fal", "user"]} >Profil</TextIcon>
            </NavLink>
            { user.isRequested() && <div className="dropdown-item">
                <TextIcon className="text-success" rightIcon={["fal", "check"]}>Demande déjà envoyé</TextIcon>
              </div>
            }
            { user.isWaitingToBeAccepted() && <div className="row-flex dropdown-item" style={{"whiteSpace": "nowrap"}}>
                <div className="mr-2">
                  <span>Invitation reçu</span>
                </div>
                <IconButton className="text-success" onClick={this.onAcceptFriend} iconData={["fal", "check"]}></IconButton>
                <IconButton className="text-danger ml-3" onClick={this.onCancelFriend} iconData={["fal", "times"]}></IconButton>
              </div>
            }
            { user.isStranger() && <TextIcon onClick={this.onInviteRelation} className="dropdown-item" leftIcon={["fal", "user-friends"]} >Ajouter à mes relations</TextIcon>}
            <TextIcon onClick={() => DOMService.modal(<NewMessageModal to={[this.props.user]} />)} className="dropdown-item" leftIcon={["fal", "comment-alt"]} >Envoyer un message</TextIcon>
            <NavLink to={user.getRoomUrl()}>
              <TextIcon className="dropdown-item" leftIcon={["fal", "comments"]} >Discuter</TextIcon>
            </NavLink>
            <TextIcon onClick={() => visioBloc.createVisio([user])} className="dropdown-item" leftIcon={["fal", "video"]} >Appel Video</TextIcon>
            { RightService.can("users") && <TextIcon onClick={() => DOMService.modal(<RightsModal user={this.props.user}/>)} className="dropdown-item" leftIcon={["fal", "hand-rock"]} >Gérer les droits</TextIcon>}
          </div>
        )}
      />
    );
  }
}
